import React from "react";
import createReactClass from "create-react-class";
import {RaisedButton} from "material-ui";
import {apiUrl} from "js/base-urls";

const Page = createReactClass({

  render() {

    return <div style={{padding: "1rem"}}>
      <h1 style={headerStyle}>Active Admin Console Users Report</h1>
      <div style={{marginTop: "1.5rem"}}>
        <RaisedButton
            label={"Download Active Users"}
            linkButton={true}
            href={apiUrl + "/user-report"}
            style={{margin: "0.5rem"}} />
      </div>
    </div>;
  }
});


const headerStyle = {
  float: "left",
  margin: 0,
  width: "96%",
  fontSize: "1.35rem"
};

export default Page;

import "fixed-data-table-2/dist/fixed-data-table.css"

import React from "react";
import createReactClass from "create-react-class";
import Radium from "radium";
import FileSaver from "browser-filesaver";
import {Table, Column, Cell} from "fixed-data-table-2";
import getContainerDimensions from "react-dimensions";
import RaisedButton from "material-ui/RaisedButton";

// TODO immutable?
// TODO column index should become unique key, allows for columns to be decoupled from row layout

const identity = cell => cell;

export default getContainerDimensions()(createReactClass({

    getDefaultProps() {
        return {
            maxTableHeight: 300,
            initialSortBy: 0,
            initialSortDirection: "ASC",
            onCellClick: () => {},

            downloadable: false,
            filenameForDownload: "Data.csv",
            onDownloadClick: () => {},

            searchable: true
        };
    },

    getInitialState() {
        const columns = this.props.columns.map((column, index) => parseColumn(column, index, this.props.rows));
        const rows = parseRows(this.props.rows, columns);

        const sortBy = this.props.initialSortBy;
        const sortDirection = this.props.initialSortDirection.toUpperCase();
        const sortedRows = columns.length === 0 ? rows : applySort(rows, sortBy, sortDirection);

        return {
            sortBy,
            sortDirection,
            filterText: "",
            originalRows: rows,
            rows: sortedRows,
            columns,
            columnWidths: calculateColumnWidths(columns.map(c => c.label), rows)
        };
    },

    componentWillReceiveProps(nextProps) {
        if (nextProps.columns === this.props.columns && nextProps.rows === this.props.rows) {
            return;
        }
        if (nextProps.columns.length === 0) {
            return;
        }

        if (nextProps.rows.length !== `this.props.rows`.length) {
            // we must force a resize event to ensure AutoDimensionTable adjusts its height based on new content
            window.dispatchEvent(new Event("resize"));
        }

        const columns = nextProps.columns.map((column, index) => parseColumn(column, index, nextProps.rows));
        const rows = parseRows(nextProps.rows, columns);

        const sortBy = this.state.sortBy;
        const sortDirection = this.state.sortDirection;
        const sortedRows = applySort(rows, sortBy, sortDirection);
        const sortedFilteredRows = applyFilter(this.state.filterText, sortedRows);

        this.setState({
            originalRows: rows,
            rows: sortedFilteredRows,
            columns,
            columnWidths: calculateColumnWidths(columns.map(c => c.label), sortedFilteredRows)
        });
    },

    render() {
        const {
            containerWidth,
            maxTableHeight,
            downloadable,
            searchable,
            style
        } = this.props;
        const {columns, rows, filterText} = this.state;
        const rowsCount = rows.length;
        const ROW_HEIGHT = 30;

        return (
            <div style={style ? style : {marginTop: "1rem", marginBottom: "2rem"}}>
                <div style={{width: "100%", textAlign: "right"}}>
                    <div style={{float: "left", paddingLeft: "1rem"}}>
                        {downloadable && <RaisedButton label="Download" onClick={this.handleDownloadClick} />}
                    </div>
                    {searchable && <Filter value={filterText} onChange={this.handleFilterChange} />}
                </div>
                <div style={{height: Math.min(ROW_HEIGHT * (rowsCount + 1), maxTableHeight)}}>
                    <Table
                        rowsCount={rowsCount}
                        width={containerWidth}
                        maxHeight={maxTableHeight}
                        headerHeight={ROW_HEIGHT}
                        rowHeight={ROW_HEIGHT}
                        touchScrollEnabled={true}>
                        {columns.map(this.renderColumn)}
                    </Table>
                </div>
            </div>);
    },

    renderColumn(column, index) {
        const header = (
            <SortHeader
                label={column.label}
                direction={this.getSortDirectionForColumn(index)}
                onClick={() => this.handleSortChange(index)} />);
        const cell = this.getCellForColumn(column, index);
        return (
            <Column
                key={index}
                header={header}
                cell={cell}
                flexGrow={1}
                width={column.width || this.state.columnWidths[index] || 150} />);
    },

    getCellForColumn(column, index) {
        const CellClass = types[column.type].cell || DefaultCell;
        return (
            <CellClass
                columnIndex={index}
                rows={this.state.rows}
                onClick={this.props.onCellClick} />);
    },

    getSortDirectionForColumn(index) {
        return index === this.state.sortBy ? this.state.sortDirection : "NONE";
    },

    handleDownloadClick() {
        const csvColumns = this.state.columns
            .map(c => c.label)
            .map(quote)
            .join(",");
        const csvRows = this.state.rows
            .map(row => {
                return row
                    .map(c => c.displayValue)
                    .map(quote)
                    .join(",");
            })
            .join("\n");
        const byteOrderMark = "\uFEFF";
        const csv = byteOrderMark + csvColumns + "\n" + csvRows;
        const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
        FileSaver.saveAs(blob, this.props.filenameForDownload);

        this.props.onDownloadClick();
    },

    handleSortChange(index) {
        const sortBy = index;
        const sortDirection = nextSortDirection(this.getSortDirectionForColumn(index));
        const sortedRows = applySort(this.state.rows, sortBy, sortDirection);
        this.setState({
            sortBy,
            sortDirection,
            rows: sortedRows
        });
    },

    handleFilterChange(e) {
        const filterText = e.target.value;
        const continuesFromPreviousFilter = filterText.length > this.state.filterText.length;
        const rowsToFilter = continuesFromPreviousFilter ? this.state.rows : this.state.originalRows;
        const filteredRows = applyFilter(filterText, rowsToFilter);

        const sortBy = this.state.sortBy;
        const sortDirection = this.state.sortDirection;
        const sortedFilteredRows = continuesFromPreviousFilter ? filteredRows : applySort(filteredRows, sortBy, sortDirection);
        this.setState({
            filterText,
            rows: sortedFilteredRows
        });
    }

}));

const FILTER_STYLE = {
    fontSize: "0.75rem",
    color: "#222",
    borderRadius: 15,
    border: "1px solid #ccc",
    backgroundColor: "#f9f9f9",
    marginBottom: "1rem",
    padding: "7px 15px",
    width: 250,

    ":focus": {
        outline: "none",
        boxShadow: "0 0 5px #0089ff"
    }
};
export const Filter = Radium(({
    value,
    onChange
}) => <input placeholder="Search for..." value={value} onChange={onChange} style={FILTER_STYLE} />);

const quote = str => "\"" + (str + "").replace(/"/g, "\"\"") + "\"";

const parseRows = (rows, columns) => {
    return rows.map(row => {
        const newRow = row.map((originalValue, index) => {
            const column = columns[index];
            const commonMappedValue = column.commonMapper(originalValue);
            return {
                originalValue,
                sortValue: column.sortMapper(commonMappedValue, originalValue),
                lengthValue: column.lengthMapper(commonMappedValue, originalValue),
                displayValue: column.displayMapper(commonMappedValue, originalValue),
            };
        });
        /*
         * awful hack to preserve id on table rows, mainly for click events
         * potentially fixed by having rows as objects instead of arrays
         */
        newRow.id = row.id;
        return newRow;
    });
};

const lengthFn = x => x === null || x === undefined ? 0 : (x + "").length;

const parseColumn = (column, index, rows) => {
    if (typeof column === "string") {
        column = {label: column};
    }

    if (!column.type && rows.length > 0) {
        /*
         * very hacky type detection, requiring invalid usage of display and common mappers
         * this will break if core types define a common mapper
         */
        const firstRow = rows[0] || [];
        const displayMapper = (column.displayMapper || identity);
        const commonMapper = (column.commonMapper || identity);
        const cell = displayMapper(commonMapper(firstRow[index]));
        column.type = detectType(cell);
    } else {
        column.type = "STRING";
    }
    column.sortMapper = column.sortMapper || types[column.type].sortMapper || identity;
    column.lengthMapper = column.lengthMapper || types[column.type].lengthMapper || lengthFn;
    column.displayMapper = column.displayMapper || types[column.type].displayMapper || identity;
    column.commonMapper = column.commonMapper || types[column.type].commonMapper || identity;
    return column;
};

const detectType = cell => {
    if (typeof cell === "number") {
        return "NUMBER";
    } else {
        return "STRING";
    }
};

const CELL_PADDING = 8 * 2;
const MAX_WIDTH = 350;
const PIXELS_PER_CHAR = 8;
const ADJUSTMENT_FOR_SORT_ARROW = 2;
const calculateColumnWidths = (columnLabels, rows) => {
    const maxCellLengths = [];
    columnLabels.forEach((label, index) => {
        const length = (label + "").length + ADJUSTMENT_FOR_SORT_ARROW;
        if (!maxCellLengths[index] || maxCellLengths[index] < length) {
            maxCellLengths[index] = length;
        }
    });
    rows.forEach(row => {
        row.forEach((cell, index) => {
            const length = cell.lengthValue;
            if (!maxCellLengths[index] || maxCellLengths[index] < length) {
                maxCellLengths[index] = length;
            }
        });
    });
    return maxCellLengths.map(length => CELL_PADDING + Math.min(MAX_WIDTH, length * PIXELS_PER_CHAR));
};

const SortHeader = ({label, direction, onClick}) => (
    <Cell onClick={onClick}>
        <span>{label}</span> <i style={{paddingLeft: "15px"}} className={`fa ${sortArrowByDirection[direction]}`} />
    </Cell>);

const nextSortDirection = direction => direction === "ASC" ? "DESC" : "ASC";
const sortArrowByDirection = {
    ASC: "fa-caret-up",
    DESC: "fa-caret-down",
    NONE: ""
};

const applyFilter = (text, rows) => {
    if (!text) {
        return rows;
    } else {
        const words = text.toLowerCase().split(" ");
        return rows.filter(row => {
            const rowStr = row.map(cell => cell.displayValue).join(" ").toLowerCase();
            return words.every(word => rowStr.indexOf(word) !== -1);
        });
    }
};

const applySort = (rows, sortByColumnIndex, sortDirection) => {
    const sortFn = sortDirection === "ASC" ? defaultSort : reverseSort;
    return rows
        .slice()
        .sort((a, b) => sortFn(a[sortByColumnIndex].sortValue, b[sortByColumnIndex].sortValue));
};
const defaultSort = (a, b) => a > b ? 1 : (a < b ? -1 : 0);
const reverseSort = (a, b) => a > b ? -1 : (a < b ? 1 : 0);

const DefaultCell = ({rows, rowIndex, columnIndex, onClick, ...props}) => {
    const row = rows[rowIndex];
    const cell = row[columnIndex];
    const textStyle = {
        whiteSpace: "nowrap",
        width: props.width - CELL_PADDING
    };
    let displayValue;
    if (cell) {
        if (cell.displayValue === true || cell.displayValue === false) {
            displayValue = cell.displayValue ? "1" : "0";
        } else if(typeof cell.displayValue === "object" && !React.isValidElement(cell.displayValue)) {
            displayValue = JSON.stringify(cell.displayValue)
        } else {
            displayValue = cell.displayValue;
        }
    } else {
        displayValue = null;
    }
    return (
        <Cell
            {...props}
            onClick={() => onClick(displayValue, row, columnIndex, rowIndex)}>
            <div style={textStyle}>{displayValue}</div>
        </Cell>);
};

const types = {
    STRING: {
        sortMapper: x => (x + "").toLowerCase()
    },
    NUMBER: {}
};


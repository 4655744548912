import React from "react";
import Modal from "react-modal";

const ModalRoot = ({
    headerLabel = "",
    onRequestClose = () => {},
    children
}) => (
    <Modal
        isOpen={true}
        onRequestClose={onRequestClose}
        style={{ overlay: { zIndex: 9999 } }}
        contentLabel="Modal">
        <div className="clearfix" style={{ marginBottom: "0.5rem" }}>
            <h1 style={headerStyle}>{headerLabel}</h1>
            <button style={closeButtonStyle} onClick={onRequestClose}>X</button>
        </div>
        <div style={{ height: "auto", width: "100%" }}>
            {children}
        </div>
    </Modal>);

const headerStyle = {
    float: "left",
    margin: 0,
    width: "96%",
    fontSize: "1.35rem"
};

const closeButtonStyle = {
    float: "right",
    fontWeight: "bold",
    cursor: "pointer"
};

export default ModalRoot;

/** @jsxImportSource @emotion/react */

import Immutable from "immutable";
import reactCreateClass from "create-react-class";
import {RaisedButton, TextField} from "material-ui";
import {connect} from "react-redux";

import ImmutableSelect from "js/components/immutable-select";
import {Err, Success} from "js/components/notification";
import {
  loadAvailableResults,
  loadAvailableTests,
  loadTestSets,
  runTest,
  runTestSets,
  setResultId
} from "js/app-areas/functional-test/data";

const TestsSection = reactCreateClass({

  getInitialState() {
    return {
      successMessage: null,
      errorMessage: null,
      selectedTestName: null,
      selectedTestSetIds: Immutable.List(),
      jsonStr: "{}",
      jsonIsValid: true
    };
  },

  componentDidMount() {
    this.props.loadAvailableTests();
    this.props.loadTestSets();
  },

  render() {
    const {loadingAvailableTests, availableTests, loadingTestSets, testSets} = this.props;
    const {successMessage, errorMessage, selectedTestName, selectedTestSetIds, jsonStr, jsonIsValid} = this.state;
    const testOptions = availableTests
        .map(testName => Immutable.fromJS({name: testName}))
        .sortBy(entry => entry.get("name"));
    const testSetOptions = testSets
        .map(testSet => testSet.set("label", `${testSet.get("name")} - ${testSet.get("description")}`))
        .sortBy(entry => entry.get("label"));
    return (
        <div style={{padding: "1rem"}}>
          <h3>1. Choose a test or test set(s):</h3>
          <div style={{display: "flex"}}>
            <ImmutableSelect
                keys={["name", "name"]}
                multi={false}
                placeholder={"Tests"}
                disabled={!selectedTestSetIds.isEmpty()}
                loading={loadingAvailableTests}
                options={testOptions}
                selectedValue={selectedTestName}
                css={{width: "30vw"}}
                onChange={testName => this.setState({selectedTestName: testName})} />
            <span style={{marginLeft: "1rem", marginRight: "1rem", marginTop: "auto", marginBottom: "auto"}}>OR</span>

            <ImmutableSelect
                keys={["id", "label"]}
                multi={true}
                placeholder={"Test Sets"}
                disabled={!!selectedTestName}
                loading={loadingTestSets}
                options={testSetOptions}
                selectedValues={selectedTestSetIds}
                css={{width: "40vw"}}
                onChange={testSetIds => this.setState({selectedTestSetIds: testSetIds})} />
          </div>

          {selectedTestName &&
          <div>
            <h3>2. Enter parameters:</h3>
            <TextField
                hintText={"Enter parameters JSON."}
                multiLine={true}
                fullWidth={true}
                value={jsonStr}
                errorText={jsonIsValid ? "" : "Invalid JSON"}
                onChange={x => this.setState({jsonStr: x.target.value})}
                onBlur={() => this.setState({jsonIsValid: isValidJson(jsonStr)})} />

            <RaisedButton
                label={"Minify"}
                css={buttonStyle}
                disabled={!jsonIsValid}
                onClick={() => this.setState({jsonStr: JSON.stringify(JSON.parse(jsonStr), null, 0)})} />
            <RaisedButton
                label={"Prettify"}
                css={buttonStyle}
                disabled={!jsonIsValid}
                onClick={() => this.setState({jsonStr: JSON.stringify(JSON.parse(jsonStr), null, 2)})} />
            <RaisedButton
                label={"Submit"}
                css={buttonStyle}
                disabled={!jsonIsValid}
                onClick={() => this.submitTest(selectedTestName, jsonStr)} />
          </div>}
          {!selectedTestSetIds.isEmpty() &&
          <RaisedButton
              label={"Submit"}
              css={buttonStyle}
              onClick={() => this.submitTestSets(selectedTestSetIds)} />}

          <Success message={successMessage} onRequestClose={() => this.setState({successMessage: null})} />
          <Err message={errorMessage} onRequestClose={() => this.setState({errorMessage: null})} />
        </div>
    );
  },

  submitTest(testName, jsonStr) {
    runTest(testName, JSON.parse(jsonStr))
        .then(response => response.json())
        .then(body => {
          this.setState({successMessage: "Successfully started test. Result ID: " + body.id});
          this.props.setResultId(body.id);
          this.props.loadAvailableResults();
        })
        .catch(e => e.response
            .json()
            .then(body => this.setState({errorMessage: "An error occurred: " + body.message})));
  },

  submitTestSets(testSetIds) {
    runTestSets(testSetIds)
        .then(response => response.json())
        .then(_ => this.setState({successMessage: "Successfully started test sets."}))
        .catch(e => e.response
            .json()
            .then(body => this.setState({errorMessage: "An error occurred: " + body.message})));
  }

});

const isValidJson = str => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

const buttonStyle = {marginRight: "1rem", marginTop: "1rem"};


const stateToProps = state => state.get("functionalTests").toObject();
const dispatchToProps = {
  loadAvailableTests,
  loadTestSets,
  loadAvailableResults,
  setResultId
};

export default connect(stateToProps, dispatchToProps)(TestsSection);
import React from "react";
import createReactClass from "create-react-class";
import $ from "jquery";
import Err from "js/components/error";
import Button from "js/app-areas/login/button";
import {apiUrl} from "js/base-urls";
import * as fetch from "js/fetch";

const Login = createReactClass({

  getInitialState() {
    return {
      username: "",
      password: "",
      usernameIsEmpty: false,
      passwordIsEmpty: false,
      invalidLogin: false,
      loading: false
    };
  },

  componentDidMount() {
    $(document.body).on("keydown", this.handleKeyDown);
  },

  componentWillUnmount() {
    $(document.body).off("keydown", this.handleKeyDown);
  },

  render() {
    const {usernameIsEmpty, passwordIsEmpty, invalidLogin} = this.state;
    const hasError = usernameIsEmpty || passwordIsEmpty || invalidLogin;
    return (
        <div style={{padding: "1rem"}}>
          <div style={{display: "table"}}>
            <div style={inputContainerStyle}>
              <label htmlFor="username" style={labelStyle}>
                Username
              </label>
              <input
                  type="text"
                  name="username"
                  placeholder="Your username"
                  style={inputStyle}
                  onChange={e => this.handleTypingName(e)} />
            </div>
            <div style={inputContainerStyle}>
              <label htmlFor="password" style={labelStyle}>
                Password
              </label>
              <input
                  type="password"
                  name="password"
                  placeholder="Your password"
                  style={inputStyle}
                  onChange={e => this.handleTypingPassword(e)} />
            </div>
          </div>
          <div style={{marginTop: "1rem", marginRight: "0.5rem", marginBottom: "0.5rem", marginLeft: "0.5rem"}}>
            <Button
                label="Login"
                type="basicSignIn"
                onClick={this.handleLoginRequest}
                isDisabled={this.state.loading} />
            {hasError && this.renderErrorMessage()}
          </div>
          <div style={{marginRight: "1rem", marginLeft: "1rem", fontWeight: "bold"}}>
            OR
          </div>
          <div style={{margin: "0.5rem"}}>
            <Button
                label="Sign-in With Google"
                type="googleSignIn"
                onClick={this.googleSignin}
                isDisabled={this.state.loading} />
            {this.state.loading && <i className="fa fa-circle-o-notch fa-spin fa-fw" />}
          </div>
        </div>
    );
  },

  renderErrorMessage() {
    const {usernameIsEmpty, passwordIsEmpty, invalidLogin} = this.state;
    let message = "Oops! An unexpected error has occured.";
    if (invalidLogin) {
      message = "Invalid login. Please check the username and password.";
    }
    if (usernameIsEmpty && passwordIsEmpty) {
      message = "Username and password required";
    }
    if (!usernameIsEmpty && passwordIsEmpty) {
      message = "Password required";
    }
    if (usernameIsEmpty && !passwordIsEmpty) {
      message = "Username required";
    }
    return <Err type="error"
                message={message}
                customStyle={{display: "inline", height: 36, verticalAlign: "middle"}} />;
  },

  handleKeyDown: function(e) {
    if (e.keyCode === 13) {
      this.handleLoginRequest();
    }
  },

  handleTypingName(e) {
    this.setState({
      username: e.target.value,
      usernameIsEmpty: false,
      invalidLogin: false
    });
  },

  handleTypingPassword(e) {
    this.setState({
      password: e.target.value,
      passwordIsEmpty: false,
      invalidLogin: false
    });
  },

  handleLoginRequest() {
    const {username, password} = this.state;
    const usernameIsEmpty = username.length === 0;
    const passwordIsEmpty = password.length === 0;
    if (usernameIsEmpty || passwordIsEmpty) {
      this.setState({
        usernameIsEmpty,
        passwordIsEmpty
      });
    } else {
      this.setState({loading: true});
      fetch
          .post("login", {username, password}, null, false)
          .then(() => {
            window.location.reload();
          }, () => {
            this.setState({invalidLogin: true});
          })
          .finally(() => {
            this.setState({loading: false});
          });
    }
  },

  googleSignin() {
    window.location.replace(apiUrl + "/google-oauth");
  }
});

export default Login;

const inputContainerStyle = {
  display: "table-row",
  height: 42
};

const inputStyle = {
  display: "table-cell",
  height: 32,
  border: "1px solid #ccc",
  borderRadius: 4,
  padding: "5px 10px"
};

const labelStyle = {
  paddingRight: "1rem",
  display: "table-cell"
};

import * as Fetch from "js/fetch";

const saveEntityUiLabel = (clientId, dwGroupingEntity, entityUiLabel, comment) => Fetch.post(
    "entity-renaming/entity",
    {
      "cube19-client-id": clientId,
      "dw-grouping-entity": dwGroupingEntity,
      "entity-ui-label": entityUiLabel,
      comment
    });

const saveEntityFieldCustomisation = (
    clientId,
    entityFieldMappingId,
    entityFieldUiLabel,
    entityFieldTrueValueLabel,
    entityFieldFalseValueLabel,
    entityFieldPiiCategory,
    shouldPrefix,
    needsTimezoneConversion,
    isDeepLinkableOverride,
    comment) => Fetch.post(
    "entity-renaming/field",
    {
      "cube19-client-id": clientId,
      "entity-field-mapping-id": entityFieldMappingId,
      "field-ui-label": entityFieldUiLabel,
      "true-value-label": entityFieldTrueValueLabel,
      "false-value-label": entityFieldFalseValueLabel,
      "pii-category": entityFieldPiiCategory,
      "should-prefix?": shouldPrefix,
      "needs-timezone-conversion?": needsTimezoneConversion,
      "is-deep-linkable-override": isDeepLinkableOverride,
      comment
    });

const saveEntityLinkField = (
    clientId,
    entityFieldMappingId,
    linkToGroupingEntity,
    linkContext,
    linkIsComponent,
    isDeepLinkableOverride,
    comment) => Fetch.post(
    "entity-renaming/link-field",
    {
      "cube19-client-id": clientId,
      "entity-field-mapping-id": entityFieldMappingId,
      "link-to-grouping-entity": linkToGroupingEntity,
      "link-context": linkContext,
      "link-is-component": linkIsComponent,
      "is-deep-linkable-override": isDeepLinkableOverride,
      comment
    });


export {saveEntityUiLabel, saveEntityFieldCustomisation, saveEntityLinkField};

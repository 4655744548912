import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";

import TextField from "material-ui/TextField";
import Checkbox from "material-ui/Checkbox";
import RaisedButton from "material-ui/RaisedButton";
import {Success, Err} from "js/components/notification";
import * as fetch from "js/fetch";
import {CurrentUserContext} from "js/data/contexts";

const buttonMargins = { marginTop: "1rem", marginRight: "1rem" };
const paragraphFontSize = { fontSize: 13 };

const Form =  createReactClass({

    getInitialState() {
        return {
            sqlStr: "",
            useTransaction: true,
            comment: "",
            isSubmittingRequest: false
        };
    },

	render() {
        const {canSubmitWithoutTxn} = this.props;
		const {comment, sqlStr, isSubmittingRequest} = this.state;
		return (
			<div>
				<h3 style={{ margin: 0 }}>Submit DB Change Request</h3>
                <p style={paragraphFontSize}>
                    <b>REFERENCE SALESFORCE AND ZENDESK</b> e.g. include SF6003g01000RkvNWBAZ or Z123 in your comment and they will be converted to hyperlinks
                </p>
                <TextField
                	multiLine={true}
            		fullWidth={true}
                	floatingLabelText="Comments"
                	value={comment}
                	errorText={!comment && "Please enter a comment for DB change request"}
                    disabled={isSubmittingRequest}
                	onChange={e => this.setState({comment: e.target.value})}/>
				<TextField
            		multiLine={true}
            		fullWidth={true}
            		floatingLabelText="SQL Script"
            		value={sqlStr}
            		errorText={!sqlStr && "Please enter SQL script for DB change request"}
                    disabled={isSubmittingRequest}
            		onChange={e => this.setState({sqlStr: e.target.value})}/>
				<div style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
                    {canSubmitWithoutTxn && <Checkbox
                        label="I am fully aware of the consequences and wish to run this SQL without a transaction."
                        checked={!this.state.useTransaction}
                        onCheck={() => this.setState({useTransaction: !this.state.useTransaction})} />}
					<RaisedButton
           				primary={true}
                    	label="Submit"
                    	disabled={!sqlStr || !comment || isSubmittingRequest}
                    	onClick={this.handleSubmitRequest}
                    	style={buttonMargins}/>
                   	<Success message={this.state.successMessage} onRequestClose={() => this.setState({successMessage: null})} />
            		<Err message={this.state.errorMessage} onRequestClose={() => this.setState({errorMessage: null})} />
				</div>
			</div>);
	},

    handleSubmitRequest() {
        const {comment, sqlStr, useTransaction} = this.state;
        this.setState({isSubmittingRequest: true});
        submitSqlChangeRequest(comment, sqlStr, useTransaction)
            .then(
                () => this.setState({
                    ...this.getInitialState(),
                    successMessage: "Change Request Submitted"
                }),
                () => this.setState({
                    isSubmittingRequest: false,
                    errorMessage: "An error occurred, contact support@cube19.com "
                }));
    }

});

const submitSqlChangeRequest = (comment, sqlStr, useTransaction) => fetch
	.post("change-submissions/sql", {
        comment,
        sqlStr,
        "use-transaction?": useTransaction
    })
	.then(response => Immutable.fromJS(response));

const ConnectedForm = props => {
	const {currentUser} = React.useContext(CurrentUserContext);
	return <Form {...props} canSubmitWithoutTxn={currentUser.get("permissions").includes("SUBMIT_NO_TXN_CUSTOM_SQL")} />
}

export default ConnectedForm;

export const toTitleCase = str => {
  return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

const similarityScore = (string, word, {rewardCaseMatch = false, rewardAcronyms = false, fuzziness} = {}) => {
  if (string === word) {
    return 1;
  }
  if (word === "") {
    return 0;
  }

  let runningScore = 0,
      charScore,
      finalScore,
      lString = string.toLowerCase(),
      strLength = string.length,
      lWord = word.toLowerCase(),
      wordLength = word.length,
      idxOf,
      startAt = 0,
      fuzzies = 1,
      fuzzyFactor,
      i;

  // Cache fuzzyFactor for speed increase
  if (fuzziness) { fuzzyFactor = 1 - fuzziness; }

  // Walk through word and add up scores.
  for (i = 0; i < wordLength; i++) {
    // Find next first case-insensitive match of a character.
    idxOf = lString.indexOf(lWord[i], startAt);

    if (idxOf === -1) {
      if (fuzziness) {
        fuzzies += fuzzyFactor;
      } else {
        return 0;
      }
    } else {
      if (startAt === idxOf) {
        // Consecutive letter & start-of-string Bonus
        charScore = 0.7;
      } else {
        charScore = 0.1;

        // Acronym Bonus
        // Weighing Logic: Typing the first character of an acronym is as if you
        // preceded it with two perfect character matches.
        if (rewardAcronyms && string[idxOf - 1] === ' ') { charScore += 0.8; }
      }

      // Same case bonus.
      if (rewardCaseMatch && string[idxOf] === word[i]) { charScore += 0.1; }

      // Update scores and startAt position for next round of indexOf
      runningScore += charScore;
      startAt = idxOf + 1;
    }
  }

  // Reduce penalty for longer strings.
  finalScore = 0.5 * (runningScore / strLength    + runningScore / wordLength) / fuzzies;

  if ((lWord[0] === lString[0]) && (finalScore < 0.85)) {
    finalScore += 0.15;
  }

  return finalScore;
};

export {similarityScore};

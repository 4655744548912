import React from "react";
import createReactClass from "create-react-class";
import moment from "moment";
import Immutable from "immutable";
import readableTime from "timeago.js";

import * as fetch from "js/fetch";

import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import Popover from "material-ui/Popover";
import Checkbox from "material-ui/Checkbox";
import Paper from "material-ui/Paper";
import Dialog from "material-ui/Dialog";

import pure from "js/pure";
import DataTable from "js/components/data-table";
import LoadingSpinner from "js/components/loading-spinner";
import {AccordionContainer, AccordionSection} from "js/components/accordion";
import ReactDiffViewer, {DiffMethod} from "react-diff-viewer";
import {configToText} from "js/app-areas/etl-config/config-rendering";
import {Toggle} from "material-ui";
import Error from "js/components/error";

const modalZIndex = 9000;

const spacingStyle = {display: "inline-block", marginRight: "1rem"};

const SqlSubmissionOutput = createReactClass({

  getDefaultProps() {
    return {
      tableProps: {}
    };
  },

  getInitialState() {
    return {
      selectedData: null
    };
  },

  render() {
    const {submission, tableProps} = this.props;
    const statementOutputPairs = toStatementOutputPairs(submission);
    const submissionHasBeenRun = runStatuses.contains(submission.get("status"));
    return (
        <div>
          <Dialog
              modal={false}
              style={{zIndex: modalZIndex}}
              open={!!this.state.selectedData}
              onRequestClose={() => this.setState({selectedData: null})}
              autoScrollBodyContent={true}>
                    <pre>
                        {formatData(this.state.selectedData)}
                    </pre>
          </Dialog>
          {statementOutputPairs.map(([[sql, ...params], output], index) => {
            const outputType = detectOutputTypeFromSql(submissionHasBeenRun, sql, output);
            const formattedOutput = formatStatementOutput(
                outputType,
                output,
                this.handleCellClick,
                tableProps);
            const formattedSql = formatStatementSql(sql, params);
            return (
                <Paper key={index} style={{padding: "0.5rem 1rem"}}>
                  <pre style={{whiteSpace: "pre-wrap", overflow: "auto"}}>{formattedSql};</pre>
                  <pre style={{whiteSpace: "pre-wrap", overflow: "auto"}}>{formattedOutput}</pre>
                </Paper>);
          })}
          {submission.get("status") === "REJECTED" &&
              <p style={{marginLeft: "1rem"}}>Reason for rejection: {submission.get("rejection_comment")}</p>}
        </div>);
  },

  handleCellClick(cell) {
    this.setState({selectedData: cell});
  }

});

const PlainTextSubmissionOutput = pure(({submission}) => {
  return (
      <Paper style={{padding: "0.5rem 1rem"}}>
            <pre
                style={{
                  whiteSpace: "pre-wrap",
                  overflow: "auto"
                }}>Input: {JSON.stringify(submission.get("payload"))}</pre>
        <pre
            style={{
              whiteSpace: "pre-wrap",
              overflow: "auto"
            }}>Output: {JSON.stringify(submission.get("output"))}</pre>
      </Paper>);
});

const EtlConfigSubmissionOutput = pure(({submission}) => {
  const payload = submission.get("payload");
  const configBefore = payload.get("config-at-submission");
  const configAfter = payload.get("new-config");
  const crmType = payload.get("crm-type", "bullhornrest");
  return (
      <Paper style={{padding: "0.5rem 1rem"}}>
        {(submission.get("output") && !submission.get("output").isEmpty()) && <span>
                <h3>Output</h3>
                <pre
                    style={{
                      whiteSpace: "pre-wrap",
                      overflow: "auto"
                    }}>{JSON.stringify(submission.get("output"), null, 2)}</pre>
            </span>}
        <ReactDiffViewer
            compareMethod={DiffMethod.WORDS}

            leftTitle="Before"
            oldValue={configToText(configBefore, crmType)}

            rightTitle="After"
            newValue={configToText(configAfter, crmType)}

            splitView={true} />
      </Paper>);
});

const looksLikeJsonObjectStr = str => typeof (str) === "string" && str.startsWith("{") && str.endsWith("}");
const looksLikeJsonArrayStr = str => typeof (str) === "string" && str.startsWith("[") && str.endsWith("]");

const tryToParseToJson = str => {
  if (looksLikeJsonObjectStr(str) || looksLikeJsonArrayStr(str)) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};

const toTextMap = m => {
  if (m) {
    return m
        .entrySeq()
        .filter(([_, value]) => !!value)
        .map(([key, value]) => {
          let label;
          if (key.startsWith("_")) {
            label = key.substring(1);
          }
          const json = tryToParseToJson(value);
          if (json && Object.keys(json).length > 1) {
            return label + "\n" + JSON.stringify(json, null, 2);
          } else if (typeof value === "string" && value.includes(",")) {
            return label + ": \n" + value.split(",")
                .sort((a, b) => a.localeCompare(b, undefined, {numeric: true}))
                .join("\n");
          } else {
            return label + ": \n" + value;
          }
        })
        .sortBy((v, k) => k)
        .join("\n");
  } else {
    return null;
  }
};

const renderDiff = (params, prefill) => {
  return <ReactDiffViewer
      compareMethod={DiffMethod.WORDS}

      leftTitle="Before"
      oldValue={toTextMap(prefill)}

      rightTitle="After"
      newValue={toTextMap(params)}

      splitView={true} />;
};

const renderPayload = (params, prefill) => {
  return (
      <React.Fragment>
        <h3>Parameters</h3>
        {(prefill && !prefill.isEmpty())
            ? renderDiff(params, prefill)
            : <pre>{toTextMap(params)}</pre>}
      </React.Fragment>);
};

const renderCsProcOutput = (output, onCellClick) => {
  if (output.isEmpty()) {
    return formatStatementOutput("empty", output, onCellClick);
  } else if (output.count() === 1 && typeof output.get(0) === "string") {
    return formatStatementOutput("error", output.get(0), onCellClick);
  } else {
    return output.flatten(true).filter(x => x !== null).map(stepOutput => {
      if (typeof stepOutput === "string") {
        return formatStatementOutput("text", stepOutput, onCellClick);
      } else if (Immutable.isList(stepOutput)) {
        return formatStatementOutput("result-set", stepOutput, onCellClick);
      } else {
        throw Error("Unexpected output format for cs-proc");
      }
    });
  }
};

const CsProcSubmissionOutput = React.memo(({submission}) => {
  const [cell, setCell] = React.useState(null);
  const onRequestClose = React.useCallback(() => setCell(null), []);

  const payload = submission.get("payload");
  const params = payload.get("param-name->value");
  const prefill = payload.get("prefill-name->value");
  return (
      <Paper style={{padding: "0.5rem 1rem"}}>
        <Dialog
            modal={false}
            style={{zIndex: modalZIndex}}
            open={!!cell}
            onRequestClose={onRequestClose}
            autoScrollBodyContent={true}>
                <pre>
                    {formatData(cell)}
                </pre>
        </Dialog>
        {(params && !params.isEmpty()) && renderPayload(params, prefill)}
        <pre
            style={{whiteSpace: "pre-wrap", overflow: "auto"}}>{renderCsProcOutput(
            submission.get("output"),
            setCell)}</pre>
        {submission.get("status") === "REJECTED" &&
            <p style={{marginLeft: "1rem"}}>Reason for rejection: {submission.get("rejection_comment")}</p>}
      </Paper>);
});

const KpiUpdateAuditOutput = React.memo(({submission}) => {
  const {originalConfigs, newConfigs} = JSON.parse(submission.get("payload"));
  return (
      <Paper style={{padding: "0.5rem 1rem"}}>
        <ReactDiffViewer
            compareMethod={DiffMethod.WORDS}

            leftTitle="Before"
            oldValue={JSON.stringify(originalConfigs, null, 2)}

            rightTitle="After"
            newValue={JSON.stringify(newConfigs, null, 2)}

            splitView={true} />
      </Paper>);
});

const KpiChangeOutput = React.memo(({submission}) => {
  const payload = submission.get("payload");
  const kpiChanges = payload.get("kpis");
  const rejectionComment = submission.get("rejection_comment");
  const output = submission.get("output");
  const [showOnlyChanges, setShowOnlyChanges] = React.useState(true);
  return (
      <Paper style={{padding: "0.5rem 1rem"}}>
        {(rejectionComment || !output.isEmpty()) && <div style={{paddingBottom: "1rem", overflow: "auto"}}>
          {rejectionComment && <pre>Rejection reason: {rejectionComment}</pre>}
          {!output.isEmpty() && <pre>Output: {output.get(0)}</pre>}
        </div>}
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Toggle
              style={{width: 250, marginBottom: "0.5rem"}}
              toggled={showOnlyChanges}
              label={"Only changes and failures"}
              onToggle={() => setShowOnlyChanges(!showOnlyChanges)} />
        </div>
        <AccordionContainer expandAll={true} allowOnlyOneOpen={false}>
          {kpiChanges.map(kpiChange => {
            const currentKpi = kpiChange.get("currentKpi");
            const newKpi = kpiChange.get("newKpi");
            const oldText = !!currentKpi.get("config") ? kpiToText(currentKpi) : "";
            const newText = kpiToText(newKpi);
            const hasChanges = oldText !== newText;
            const permission = kpiChange.get("permission");
            if (showOnlyChanges && !hasChanges && (!permission || permission === "METRIC_APPROVAL")) {
              return null;
            }
            const config = newKpi.get("config");
            const id = config.get("id");

            const testTimings = kpiChange.get("testTimings");
            const thresholds = kpiChange.get("thresholds");
            const failureReasons = kpiChange.get("reasons");
            const label = <span>
                {(id ?? "<NEW>") + " - " + config.get("name")}
              {permission && permission !== "METRIC_APPROVAL" && <i
                  className="fa fa-exclamation-triangle"
                  style={{
                    display: "table-cell",
                    padding: "0 8px",
                    color: permissionToColor[permission]
                  }} />}
              </span>;
            return <AccordionSection
                key={label}
                title={label}>
              <div style={{overflow: "auto"}}>
                {!!failureReasons && failureReasons.size > 0 && <Error
                    customStyle={{margin: 10}}
                    message={<div style={{display: "flex"}}>
                      <span>{failureReasons.map(reason => <>{reason}<br /></>)}</span>
                    </div>}
                    type={permission === "STANDARD_PERFORMANCE_APPROVAL" ? "warn" : "error"}
                />}
                <div style={{fontSize: "0.9rem", display: "flex", flexDirection: "column"}}>
                  {showOnlyChanges && !hasChanges && <div>This metric depends on a changed metric</div>}
                  {!!testTimings &&
                      <div style={{margin: "10px 15px", padding: 10, backgroundColor: "#f7f7f7"}}>
                        <div>
                          Performance Test Timings
                        </div>
                        <div style={{display: "flex", padding: "10px 0"}}>
                          {testTimings.map((testToTiming, userOrGroup) => <div>
                            <div>{capitaliseWords(userOrGroup)}</div>
                            <div style={{paddingLeft: 15}}>
                              {testToTiming.map((result, test) => {
                                const failurePermission = thresholds && getTestTimingFailurePermission(
                                    test,
                                    result.get("timing"),
                                    thresholds);
                                return <div>
                                                            <span
                                                                style={{opacity: "50%"}}>{capitaliseWords(test)
                                                                + ": "}</span>
                                  <span
                                      style={{color: permissionToColor[failurePermission]}}>{result.get("timing")
                                      + " ms"}</span>
                                </div>;
                              }).toList()}
                            </div>
                          </div>).toList()}</div>
                        <span>Rows returned: {testTimings.getIn(["GROUP", "REPORT", "valueCount"])}</span>
                      </div>}
                </div>
                <ReactDiffViewer
                    compareMethod={DiffMethod.WORDS}

                    leftTitle="Before"
                    oldValue={oldText}

                    rightTitle="After"
                    newValue={newText}

                    splitView={true} />
              </div>
            </AccordionSection>;
          })}
        </AccordionContainer>
      </Paper>);
});

const getTestTimingFailurePermission = (test, resultTimeMillis, testToThresholds) => {
  const thresholds = testToThresholds.get(test, Immutable.List());
  const threshold = thresholds.find(threshold => threshold.get("lowerLimit") < resultTimeMillis &&
      (threshold.get("upperLimit") === null || threshold.get("upperLimit") >= resultTimeMillis));
  return threshold && threshold.get("requiredPermission");
};

const permissionToColor = {
  STANDARD_PERFORMANCE_APPROVAL: "#FFBF00",
  ENGINEERING_PERFORMANCE_APPROVAL: "#F12F2F"
};

const capitaliseWords = (str) => {
  if (!str) {
    return str;
  }
  return str.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const kpiChangeKeysToIgnore = Immutable.Set([
  "id",
  "order",
  "readOnlyCombined"
]);
const kpiQueryParamJsonKeys = Immutable.Set([
  "coreTypeFilters", "dynamicFilter", "idQueryJoinPaths", "kpisToSum", "ownedByLinkedEntities", "forwardReport"
]);

const kpiToText = (kpi) => {
  const config = kpi.get("config");
  const configLines = config.entrySeq().flatMap(([key, value]) => {
    if (kpiChangeKeysToIgnore.has(key) || value === null || (Immutable.Map.isMap(value) && value.isEmpty())) {
      return Immutable.List();
    }
    if (key === "queryParams") {
      return value
          .entrySeq()
          .flatMap(([key, value]) => {
            if (kpiQueryParamJsonKeys.has(key)) {
              return safeKeyValueStringList(key, tryToParseToJson(value));
            } else {
              return safeKeyValueStringList(key, value);
            }

          })
          .map(line => withIndent(line, 2))
          .toList()
          .unshift("query params");
    }
    return safeKeyValueStringList(key, value);
  });
  const columns = kpi.get("columns") || Immutable.List();
  const columnsLines = columns
      .filter(column => column.get("visible"))
      .sortBy(column => column.get("order"))
      .map(column => column.get("label"));

  let result = configLines.toList();
  if (!columnsLines.isEmpty()) {
    result = result.push("columns")
        .concat(columnsLines.map(text => withIndent(text, 2)));
  }

  return result.join("\n");
};

const withIndent = (text, indent) => " ".repeat(indent) + text;
const safeKeyValueStringList = (key, value) => {
  const stringValue = JSON.stringify(Immutable.isImmutable(value) ? value.toJSON() : value, null, 2);
  const keyValueString = key + ": " + stringValue;
  return Immutable.List(keyValueString.split("\n"));
};

const dispatchTypeToOutputClass = Immutable.Map({
  "sql": SqlSubmissionOutput,
  "etl-config": EtlConfigSubmissionOutput,
  "cs-proc-call": CsProcSubmissionOutput,
  "kpi:update": KpiUpdateAuditOutput,
  "kpi-change": KpiChangeOutput
});

const noActionDispatchTypes = Immutable.Set([
  "kpi-change"
]);

const AccordionSubmissions = createReactClass({

  getInitialState() {
    return {
      isProcessing: false
    };
  },

  render() {
    const {idToClient, idToSubmission, isLoading} = this.props;
    let typeToOutput;
    if (this.props.dispatchTypeToOutputClass) {
      typeToOutput = dispatchTypeToOutputClass.merge(this.props.dispatchTypeToOutputClass);
    } else {
      typeToOutput = dispatchTypeToOutputClass;
    }
    return (
        <div>
          {isLoading ? <LoadingSpinner /> : <AccordionContainer allowOnlyOneOpen={false} stateless={true}>
            {idToSubmission
                .valueSeq()
                .sortBy(c => c.get("submitted_date"))
                .reverse()
                .map(changeSubmission => {
                  const OutputClass = typeToOutput.get(
                      changeSubmission.get("dispatch_type"),
                      PlainTextSubmissionOutput);
                  return (
                      <AccordionSection
                          key={changeSubmission.get("submission_id")}
                          title={titleForSubmission(changeSubmission, idToClient)}
                          titleStyle={{backgroundColor: titleColorForSubmission(changeSubmission)}}>
                        <OutputClass submission={changeSubmission} />
                        {!finalStatuses.contains(changeSubmission.get("status")) &&
                            !noActionDispatchTypes.contains(changeSubmission.get("dispatch_type")) &&
                            <SubmissionActionForm
                                changeSubmission={changeSubmission}
                                currentUserId={this.props.currentUserId}
                                permissions={this.props.permissions}
                                isProcessing={this.state.isProcessing}
                                onRejectClick={this.handleRejectClick}
                                onApproveClick={this.handleApproveClick}
                                onEmergencyAutoApproveClick={this.handleEmergencyAutoApproveClick}
                                onRunClick={this.handleRunClick} />}
                      </AccordionSection>);
                })}
          </AccordionContainer>}
        </div>);
  },

  handleSubmissionChange(submission, tempData) {
    this.props.onChange(submission, tempData);
    this.setState({isProcessing: false});
  },

  handleSubmissionError(error) {
    this.props.onError(error);
    this.setState({isProcessing: false});
  },

  handleRejectClick(submissionId, comment) {
    this.setState({isProcessing: true});
    reject(submissionId, comment).then(
        submission => this.handleSubmissionChange(submission),
        e => this.handleSubmissionError(e));
  },

  handleApproveClick(submissionId) {
    this.setState({isProcessing: true});
    approve(submissionId).then(
        submission => this.handleSubmissionChange(submission),
        e => this.handleSubmissionError(e));
  },

  handleEmergencyAutoApproveClick(submissionId) {
    this.setState({isProcessing: true});
    emergencyAutoApprove(submissionId).then(
        submission => this.handleSubmissionChange(submission),
        e => this.handleSubmissionError(e));
  },

  handleRunClick(submissionId) {
    this.setState({isProcessing: true});
    run(submissionId).then(
        response => this.handleSubmissionChange(response.get("submission"), response.get("temp-data")),
        e => this.handleSubmissionError(e));
  }

});

const reject = (id, comment) => fetch
    .put("change-submissions/" + id + "/reject", {comment})
    .then(res => res.json())
    .then(body => Immutable.fromJS(body));

const approve = id => fetch
    .put("change-submissions/" + id + "/approve")
    .then(res => res.json())
    .then(body => Immutable.fromJS(body));

const emergencyAutoApprove = id => fetch
    .put("change-submissions/" + id + "/emergency-auto-approve")
    .then(res => res.json())
    .then(body => Immutable.fromJS(body));

const run = id => fetch
    .put("change-submissions/" + id + "/run")
    .then(res => res.json())
    .then(body => Immutable.fromJS(body));

const finalStatuses = Immutable.Set.of("SUCCEEDED", "FAILED", "REJECTED");
const runStatuses = Immutable.Set.of("SUCCEEDED", "FAILED");

const statusToColor = {
  FAILED: "#ff8c8c",
  SUCCEEDED: "#91e094",
  APPROVED: "#abd8ff",
  REJECTED: "#ff8c8c",
  SUBMITTED: "#abd8ff"
};

const titleColorForSubmission = c => isSelfApproved(c) && c.get("status") === "SUCCEEDED" ? "#ffb84e" :
    statusToColor[c.get("status")];

const titleForSubmission = (c, idToClient) => {
  const txnMessage = c.get("use_transaction") ? "" : "NO TXN - ";
  const status = c.get("status");

  const clientId = c.get("cube19_client_id");
  const clientMessage = clientId
      ? clientId + " " + (idToClient.get(clientId)?.get("internal_name") ?? "") + " - "
      : "";

  const permissionMessage = status === "SUBMITTED" ? " - requires " + c.get("approval_permission") : "";

  return (
      <span style={{display: "table"}}>
      {isSelfApproved(c) &&
          <i className="fa fa-exclamation-triangle" style={{display: "table-cell", paddingRight: 6}} />}
        <span style={{display: "table-cell"}}>
        {txnMessage}{status} - {clientMessage}{submittedInfo(c)} {permissionMessage} {status
            !== "SUBMITTED"
            && getStatusInfo(c)}
      </span>
    </span>);
};

const isAutoApproved = c => c.get("reviewed_by") === "auto-approved";
const isSelfApproved = c => c.get("reviewed_by") === c.get("submitted_by")
    && c.get("status") !== "REJECTED"
    && !c.get("auto_approved");

const getStatusInfo = c => {
  const status = c.get("status");
  if (status === "SUCCEEDED" || status === "FAILED") {
    return ranInfo(c);
  } else if (status === "REJECTED" || status === "APPROVED" || status === "SELF APPROVED") {
    return reviewedInfo(c);
  }
};

const submittedInfo = c => (
    <span>
        {parseHyperlinks(c.get("submitted_comment"))} Submitted <NiceDate
        str={c.get("submitted_date")} /> by {c.get("submitted_by")}
    </span>);

const reviewedInfo = c => {
  if (isAutoApproved(c)) {
    return <span>- Auto approved and run <NiceDate str={c.get("reviewed_date")} /></span>;
  } else if (isSelfApproved(c)) {
    return <span>- Self approved <NiceDate str={c.get("reviewed_date")} /> by {c.get("reviewed_by")}</span>;
  } else {
    return <span>- Reviewed <NiceDate str={c.get("reviewed_date")} /> by {c.get("reviewed_by")}</span>;
  }
};

const ranInfo = c => <span>- Ran <NiceDate str={c.get("run_date")} /> by {c.get("run_by")}</span>;

const momentDateToReadble = date => readableTime().format(date.toDate());

const NiceDate = ({str}) => {
  if (!str) {
    return null;
  } else {
    const date = moment(str);
    return <span style={{fontWeight: 900}} title={date.toString()}>{momentDateToReadble(date)}</span>;
  }
};

const parseHyperlinks = str => {
  return str
      .split(/(z\d+)|(sf\w+)/i)
      .filter(str => !!str)
      .map((str, index) => {
        if (isZendeskId(str)) {
          return createZendeskUrl(str);
        } else if (isSalesforceId(str)) {
          return createSalesforceUrl(str);
        } else {
          return <span key={index}>{str}</span>;
        }
      });
};

const isZendeskId = str => /[Zz]\d+/.test(str);
const createZendeskUrl = str => {
  const id = str.substring(1);
  return <a
      key={id}
      target="_blank"
      rel={"noopener noreferrer"}
      href={"https://cube19.zendesk.com/agent/tickets/" + id}>
    {str}
  </a>;
};

const isSalesforceId = str => /sf\w+/i.test(str);
const createSalesforceUrl = str => {
  const id = str.substring(2);
  return <a
      key={id}
      target="_blank"
      rel={"noopener noreferrer"}
      href={`https://bullhorn.lightning.force.com/lightning/r/Case/${id}/view`}>
    {str}
  </a>;
};

const SubmissionActionForm = createReactClass({

  getInitialState() {
    return {
      rejectionComment: "",
      isConfirmEmergencyPopoverOpen: false
    };
  },

  render() {
    const {changeSubmission, currentUserId, permissions, isProcessing} = this.props;
    const {rejectionComment, isConfirmEmergencyPopoverOpen, anchorEl} = this.state;
    const submissionId = changeSubmission.get("submission_id");
    const isOwnSubmission = changeSubmission.get("submitted_by") === currentUserId;
    const isSubmitted = changeSubmission.get("status") === "SUBMITTED";
    const isApproved = changeSubmission.get("status") === "APPROVED";

    const hasPermissionToRejectOrRun = permissions.includes(changeSubmission.get("approval_permission"))
        || permissions.includes(changeSubmission.get("submission_permission"));

    const canApproveOwnChanges = permissions.includes("SELF_APPROVAL");
    const hasPermissionToApprove = permissions.includes(changeSubmission.get("approval_permission"));
    let approvalLabel;
    if (isOwnSubmission && hasPermissionToApprove) {
      approvalLabel = "Approve (can't approve own changes)";
    } else if (!hasPermissionToApprove) {
      approvalLabel = "Approve (requires " + changeSubmission.get("approval_permission") + " permission)";
    } else {
      approvalLabel = "Approve";
    }

    let rejectionLabel;
    if (!hasPermissionToRejectOrRun) {
      rejectionLabel = "Reject (requires " + changeSubmission.get("submission_permission") + " or " +
          changeSubmission.get("approval_permission") + ")";
    } else if (!rejectionComment) {
      rejectionLabel = "Reject (add a comment first)";
    } else {
      rejectionLabel = "Reject";
    }

    let runLabel;
    if (!hasPermissionToRejectOrRun) {
      runLabel = "Run (requires " + changeSubmission.get("submission_permission") + " or " +
          changeSubmission.get("approval_permission") + ")";
    } else {
      runLabel = "Run";
    }

    return (
        <div style={{margin: "1rem"}}>
          {(isSubmitted || isApproved) && <span>
                    <TextField
                        style={spacingStyle}
                        multiLine={true}
                        fullWidth={true}
                        floatingLabelText="Rejection comment"
                        value={rejectionComment}
                        disabled={!hasPermissionToRejectOrRun}
                        onChange={e => this.setState({rejectionComment: e.target.value})} />
                    <br />
                    <RaisedButton
                        label={rejectionLabel}
                        disabled={!rejectionComment || isProcessing || !hasPermissionToRejectOrRun}
                        onClick={() => this.props.onRejectClick(submissionId, rejectionComment)} />
                    <br />
                    <br />
                    </span>}
          {(isSubmitted) &&
              <RaisedButton
                  style={spacingStyle}
                  disabled={isOwnSubmission || isProcessing || !hasPermissionToApprove}
                  label={approvalLabel}
                  onClick={() => this.props.onApproveClick(submissionId)} />}
          {(isSubmitted && isOwnSubmission && hasPermissionToApprove && canApproveOwnChanges) &&
              <RaisedButton
                  style={spacingStyle}
                  backgroundColor="#dc3434"
                  labelColor="#fff"
                  label="Auto-approve this, it's an emergency!"
                  disabled={isProcessing}
                  onClick={e => {
                    this.setState({
                      isConfirmEmergencyPopoverOpen: true,
                      anchorEl: e.currentTarget
                    });
                  }} />}
          <Popover
              open={isConfirmEmergencyPopoverOpen}
              anchorEl={anchorEl}
              anchorOrigin={{horizontal: "left", vertical: "bottom"}}
              targetOrigin={{horizontal: "left", vertical: "top"}}
              onRequestClose={this.closeConfirmEmergencyPopover}>
            <div style={{padding: "1rem"}}>
              <Checkbox
                  label="I do solemnly swear that this is an emergency and I have double checked my SQL! 😱"
                  onCheck={this.onCheckboxClick} />
            </div>
          </Popover>
          {isApproved &&
              <RaisedButton
                  style={spacingStyle}
                  label={runLabel}
                  disabled={isProcessing || !hasPermissionToRejectOrRun}
                  onClick={() => this.props.onRunClick(submissionId)} />}
        </div>
    );
  },

  onCheckboxClick(_, isChecked) {
    if (isChecked) {
      const submissionId = this.props.changeSubmission.get("submission_id");
      this.props.onEmergencyAutoApproveClick(submissionId);
    }
    this.closeConfirmEmergencyPopover();
  },

  closeConfirmEmergencyPopover() {
    this.setState({isConfirmEmergencyPopoverOpen: false});
  }

});

const toStatementOutputPairs = submission => {
  const statements = submission.get("payload");
  const outputs = submission.get("output");

  let statementOutputPairs = Immutable.List();
  for (let i = 0; i < statements.count(); i++) {
    const statement = statements.get(i);
    const output = outputs.get(i);
    statementOutputPairs = statementOutputPairs.push([statement, output]);
  }
  return statementOutputPairs;
};

const formatStatementSql = (sql, params) => {
  if (sql.indexOf("\n") === -1 && sql.indexOf("\t") === -1) {
    const replacements = [
      [" WHERE ", "\nWHERE\n\t"],
      [" GROUP BY ", "\nGROUP BY\n\t"],
      [" HAVING ", "\nHAVING\n\t"],
      [" ORDER BY ", "\nORDER BY\n\t"],
      [" VALUES", "\nVALUES"],
      [" SET", "\nSET"],
      [" FROM", "\nFROM"],
      [" LEFT JOIN", "\nLEFT JOIN"],
      [" JOIN", "\nJOIN"],
      [" AND ", "\n\tAND "]
    ];
    replacements.forEach(([fromStr, toStr]) => {
      sql = sql.replace(new RegExp(fromStr, "g"), toStr);
    });
  }

  let i = 0;
  return sql.replace(/\?/g, () => `'${params[i++]}'`);
};

const detectOutputTypeFromSql = (submissionHasBeenRun, sql, output) => {
  const isSelectStatement = sql.toLowerCase().startsWith("select");
  const isStoredProcedureCall = sql.toLowerCase().startsWith("{call");
  const statementHasOutput = output !== undefined && (output + "").length > 0;

  if (statementHasOutput) {
    if (typeof (output) === "number") {
      return "row-count";
    } else if (typeof (output) === "string") {
      return "error";
    } else if (isSelectStatement) {
      return "result-set";
    } else if (isStoredProcedureCall) {
      return "result-sets";
    } else {
      console.log("sql", sql);
      console.log("output", output);
      throw new Error("cannot detect output type");
    }
  } else if (submissionHasBeenRun) {
    return "not-run";
  } else {
    return "empty";
  }
};

const formatStatementOutput = (outputType, output, onCellClick = () => {
}, tableProps = {}) => {
  switch (outputType) {
    case "row-count":
      return "Row(s) affected: " + output;
    case "error":
      return "Error: " + output;
    case "text":
      return output;
    case "result-set":
      const resultSet = convertToResultSet(output);
      const columns = resultSet.first();
      const isHeaderData = columns.first().includes("cs_proc_section_");
      if (isHeaderData) {
        const titleIndex = columns.indexOf("cs_proc_section_title");
        const infoIndex = columns.indexOf("cs_proc_section_info");
        const rows = resultSet.last();
        const title = titleIndex !== -1 ? rows.get(titleIndex) : null;
        const info = infoIndex !== -1 ? rows.get(infoIndex) : null;
        return <>
          {title && <h3 style={{marginTop: "2rem"}}>{title}</h3>}
          {info && <p>{info}</p>}
        </>;
      }
      return renderResultSetAsTable(convertToResultSet(output), onCellClick, tableProps);
    case "result-sets":
      return output.map((resultSet, index) => <div key={index}>
        {formatStatementOutput("result-set", resultSet, onCellClick, tableProps)}
      </div>);
    case "not-run":
      return "Not run due to earlier errors";
    case "empty":
      return "";
    default:
      throw new Error("unknown output type: " + outputType);
  }
};

const formatData = val => {
  if (!val) {
    return val;
  }

  const mightBeJson = typeof (val) === "string" && (val.indexOf("{") === 0 || val.indexOf("[") === 0);
  if (mightBeJson) {
    try {
      return JSON.stringify(JSON.parse(val), null, 2);
    } catch (e) {
      return val;
    }
  } else {
    return val;
  }
};

const convertToResultSet = listOfMaps => {
  if (listOfMaps.isEmpty()) {
    return Immutable.List();
  } else {
    const firstRow = listOfMaps.get(0);
    if (Immutable.Map.isMap(firstRow)) {
      const columns = firstRow.keySeq();
      const rows = listOfMaps.map(row => columns.map(column => row.get(column)));
      return Immutable.List.of(columns).concat(rows);
    } else {
      // NOTE not a list of maps therefore backend has already given us a valid result set
      return listOfMaps;
    }
  }
};

const renderResultSetAsTable = (resultSet, onCellClick, tableProps) => {
  if (resultSet.count() === 1) {
    return <p>Empty result set</p>;
  } else {
    const columns = resultSet.get(0);
    const rows = resultSet.shift();
    return <DataTable
        downloadable={true}
        initialSortDirection="DESC"
        maxTableHeight={400}
        columns={columns.toArray()}
        onCellClick={onCellClick}
        rows={rows.toJS()}
        {...tableProps} />;
  }
};

export {
  AccordionSubmissions
};

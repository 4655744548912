import {apiUrl} from "js/base-urls";

const DEFAULT_OPTIONS = {
  credentials: "include",
  mode: "cors"
};

let lastRequestTime;

const getLastRequestTime = () => {
  return lastRequestTime;
};

const get = (url, params, options = {}, reloadOnAuthenticationError = true) => {
  lastRequestTime = new Date();
  const baseUrl = `${apiUrl}/${url}`;
  const requestUrl = params ? `${baseUrl}?${convertToQueryParamString(params)}` : `${baseUrl}`;
  return fetch(
      requestUrl,
      {
        ...DEFAULT_OPTIONS,
        ...options,
        method: "GET"
      })
      .then(res => checkStatus(res, reloadOnAuthenticationError));
};

const getJson = (url, params, options, reloadOnAuthenticationError) => {
  return get(url, params, options, reloadOnAuthenticationError).then(res => res.json());
};

const post = (url, body = {}, options = {}, reloadOnAuthenticationError = true) => {
  lastRequestTime = new Date();
  const requestUrl = `${apiUrl}/${url}`;
  return fetch(
      requestUrl,
      {
        ...DEFAULT_OPTIONS,
        ...options,
        body: JSON.stringify(body),
        headers: {"Content-Type": "application/json"},
        method: "POST"
      })
      .then(res => checkStatus(res, reloadOnAuthenticationError));
};

const put = (url, body = {}, options = {}, reloadOnAuthenticationError = true) => {
  lastRequestTime = new Date();
  const requestUrl = `${apiUrl}/${url}`;
  return fetch(
      requestUrl,
      {
        ...DEFAULT_OPTIONS,
        ...options,
        body: JSON.stringify(body),
        headers: {"Content-Type": "application/json"},
        method: "PUT"
      })
      .then(res => checkStatus(res, reloadOnAuthenticationError));
};

const checkStatus = (response, reloadOnAuthenticationError) => {
  const statusCode = response.status;
  if (statusCode >= 200 && statusCode < 300) {
    return response;
  } else if (statusCode === 401 && reloadOnAuthenticationError) {
    window.location.reload();
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
};

const escape = encodeURIComponent;
const convertToQueryParamString = params => Object.keys(params)
    .map(k => {
      const value = params[k];
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return null;
        } else {
          return value
              .map(v => `${escape(k)}=${escape(v)}`)
              .join("&");
        }
      }
      return `${escape(k)}=${escape(value)}`;
    })
    .filter(x => !!x)
    .join("&");

export {
  get,
  getJson,
  post,
  put,
  getLastRequestTime
};

import React from "react";
import {Toggle} from "material-ui";

import ImmutableSelect from "js/components/immutable-select";

const CommonSelect = (props) => <ImmutableSelect
    css={{paddingBottom: 5}}
    multi={false}
    {...props} />;

const LinkToOverrideSelect = ({selectedValue, options, onChange, ...props}) => <CommonSelect
    placeholder={"Link to override"}
    selectedValue={selectedValue}
    onChange={onChange}
    options={options}
    {...props} />;

const LinkToGroupingEntitySelect = ({selectedValue, options, onChange, ...props}) => <CommonSelect
    placeholder={"Link to Grouping Entity"}
    selectedValue={selectedValue}
    onChange={onChange}
    options={options}
    {...props} />;

const LinkContextSelect = ({selectedValue, options, onChange, ...props}) => <CommonSelect
    placeholder={"Link Context"}
    clearable={false}
    selectedValue={selectedValue}
    onChange={onChange}
    options={options}
    {...props} />;

const LinkIsComponentToggle = ({toggled, onToggle, ...props}) => <Toggle
    label={"Link is Component"}
    toggled={toggled}
    onToggle={onToggle}
    {...props} />;


const PrefixToggle = ({toggled, onToggle, ...props}) => <Toggle
    label={"Prefix"}
    title={"Prefixes the column label with the entity label"}
    style={{width: "unset", height: "unset", display: "inline-block"}}
    toggled={toggled}
    onToggle={onToggle}
    {...props} />;

const ConvertTimezonesToggle = ({toggled, onToggle, ...props}) => <Toggle
    label={"Convert timezones"}
    toggled={toggled}
    onToggle={onToggle}
    {...props} />;

const IsDeepLinkableToggle = ({toggled, onToggle, ...props}) => <Toggle
    label={"Is Deep Linkable?"}
    toggled={toggled}
    onToggle={onToggle}
    {...props} />;

export {
  LinkToOverrideSelect,
  LinkToGroupingEntitySelect,
  LinkContextSelect,
  LinkIsComponentToggle,
  PrefixToggle,
  ConvertTimezonesToggle,
  IsDeepLinkableToggle
};

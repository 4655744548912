import Immutable from "immutable";
import store from "store";
import moment from "moment";
import {formatDateTime, parseDateTime} from "js/utils/time";

const retrieveStoredChangeDateTime = (storeKeyRoot) => {
  const dateStr = store.get(storeKeyRoot + ".unsaved-changes-datetime");
  return dateStr ? parseDateTime(dateStr) : null;
};

const saveChangesToLocalStorage = (storeKeyRoot, clientConfig, etlConfig, entityToKpiConfigsState) => {
  store.set(storeKeyRoot + ".unsaved-changes-datetime", formatDateTime(moment()));
  store.set(storeKeyRoot + ".client-config", clientConfig.toJS());
  store.set(storeKeyRoot + ".etl-config", etlConfig.toJS());
  store.set(storeKeyRoot + ".entity-to-kpi-configs-state", entityToKpiConfigsState.toJS());
};
const retrieveStoredChanges = (storeKeyRoot) => {
  const clientConfig = Immutable.fromJS(store.get(storeKeyRoot + ".client-config"));
  const etlConfig = Immutable.fromJS(store.get(storeKeyRoot + ".etl-config"));
  const entityToKpiConfigsState = Immutable.fromJS(store.get(storeKeyRoot + ".entity-to-kpi-configs-state"));
  return {clientConfig, etlConfig, entityToKpiConfigsState};
};

const clearStoredChanges = (storeKeyRoot) => {
  store.remove(storeKeyRoot + ".unsaved-changes-datetime");
  store.remove(storeKeyRoot + ".client-config");
  store.remove(storeKeyRoot + ".etl-config");
  store.remove(storeKeyRoot + ".entity-to-kpi-configs-state");
};

export {retrieveStoredChangeDateTime, saveChangesToLocalStorage, retrieveStoredChanges, clearStoredChanges};
import React from "react";
import ImmutableSelect from "js/components/immutable-select";

const LabelledSelect = React.memo(({
  label,
  ...props
}) => {
  return <div>
    {label}
    <ImmutableSelect {...props} />
  </div>;
});

export default LabelledSelect;
import React from "react";
import Immutable from "immutable";
import * as fetch from "js/fetch";
import * as Rata from "js/data/remote-data";
import LoadingSpinner from "js/components/loading-spinner";
import {AccordionSection} from "js/components/accordion";
import {TextField} from "material-ui";
import TimestampedRefreshButton from "js/components/timestamped-refresh-button";

// TODO claims - per cube19 env (don't bother with beanstalk env for now, too granular to ensure safety)
//  register claim
//    time bounded and unbounded
//  release claim
//  display current claim (who, how long)
//    dig into claim history
//  colour code by claims

// TODO jira
//  extract from branch name if possible, otherwise don't render

// TODO database
//  probably solved by putting deploy info into db

// TODO ui
//  already goes into db for app-ui
//  do the same for admin console?

// TODO pull requests
//  how to find the PR?
//    change buildkite workflow to work off PRs
//    OR use github api to find based on branch (or branch)


const Page = React.memo(() => {
  const [servicesWrapper, setServicesWrapper] = React.useState(Rata.wrapInitialValue(Immutable.List()));
  const [filter, setFilter] = React.useState("");
  const [openCube19Envs, setOpenCube19Envs] = React.useState(Immutable.Set());

  const loadAndSetServices = React.useCallback(() => {
    setServicesWrapper(Rata.toLoading);
    loadServices().then(services => setServicesWrapper(x => Rata.toLoaded(x, services)));
  }, []);

  React.useEffect(() => {
    loadAndSetServices();
  }, [loadAndSetServices]);

  const toggleOpenCube19Env = React.useCallback(cube19Env => {
    setOpenCube19Envs(openCube19Envs => {
      if (openCube19Envs.contains(cube19Env)) {
        return openCube19Envs.remove(cube19Env);
      } else {
        return openCube19Envs.add(cube19Env);
      }
    });
  }, []);

  if (Rata.isLoaded(servicesWrapper)) {
    const cube19EnvToServices = Rata.getValue(servicesWrapper).groupBy(env => env.get("cube19-env-label"));
    return <div style={{padding: "0.5rem 1rem 2rem 1rem"}}>
      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "0.5rem"}}>
        <TextField placeholder="Search" value={filter} onChange={e => setFilter(e.target.value)} />
        <TimestampedRefreshButton
            title="Reload latest status from AWS and Buildkite"
            disabled={Rata.isLoading(servicesWrapper)}
            millis={servicesWrapper.get("loadedAtMillis")}
            onClick={loadAndSetServices} />
      </div>
      {cube19EnvToServices
          .entrySeq()
          .sortBy(([cube19Env]) => cube19Env)
          .map(([cube19Env, services]) => {
            const jirasDeployed = services
                .map(service => service.get("jira-id"))
                .filter(jiraId => !!jiraId)
                .toSet()
                .toList()
                .sort();
            const branchesDeployed = services
                .map(service => service.get("source-branch"))
                .filter(jiraId => !!jiraId)
                .toSet()
                .toList()
                .sort();

            const hasFilter = !!filter;
            const matchesCube19Env = hasFilter && strIncludes(cube19Env, filter);
            const matchesAnyService = hasFilter && services
                .some(env => strIncludes(env.get("source-branch"), filter)
                    || strIncludes(env.get("buildkite-build-number"), filter)
                    || strIncludes(env.get("jira-id"), filter));
            const matches = matchesCube19Env || matchesAnyService;

            return <AccordionSection
                key={cube19Env}
                titleElement={<span>
                  <span style={{fontWeight: 800, color: "#000", marginRight: 10}}>{cube19Env}</span>Tickets {jirasDeployed.map(jiraLink).interpose(<span>, </span>)} | Branches {branchesDeployed.join(", ")}</span>}
                isOpen={openCube19Envs.contains(cube19Env) || matches}
                containerStyle={{marginBottom: "0.5rem"}}
                showArrows={true}
                onClick={() => toggleOpenCube19Env(cube19Env)}>
              <div style={{display: "flex", flexWrap: "wrap", padding: "0.5rem"}}>
                {services
                    .sortBy(service => service.get("name"))
                    .map(service => <ServicePanel key={service.get("id")} service={service} />)}
              </div>
            </AccordionSection>;
          })}
    </div>;
  } else if (Rata.isLoading(servicesWrapper)) {
    return <LoadingSpinner />;
  } else {
    return <div></div>;
  }
});

const ServicePanel = React.memo(({service}) => {
  return <div style={{width: "25%"}}>
    <div style={{border: "1px solid #e3e3e3", margin: "0.5rem"}}>
      <div style={{fontWeight: "800", color: "#000", backgroundColor: "#efefef", padding: "0.35rem 0.5rem"}}>
       {capitalise(service.get("name"))}
      </div>
      <div style={{padding: "0.35rem", fontSize: "0.85rem", lineHeight: "1.5rem"}}>
        <i style={iconStyle} className="fa fa-code-fork" /><span style={{marginLeft: 5, minWidth: 50, display: "inline-block"}}>Source</span> {githubBranchLink(service.get("source-repo"), service.get("source-branch"), service.get("source-path"))} / {githubCommitLink(service.get("source-repo"), service.get("source-commit"))}
        <br /><i style={iconStyle} className="fa fa-cube" /><span style={{marginLeft: 5, minWidth: 50, display: "inline-block"}}>Build</span> {buildkiteLink(service.get("buildkite-pipeline"), service.get("buildkite-build-number"))}
        <br /><i style={iconStyle} className="fa fa-ticket" /><span style={{marginLeft: 5,minWidth: 50, display: "inline-block"}}>Ticket</span> {jiraLink(service.get("jira-id"))}
      </div>
    </div>
  </div>;
});

const acronyms = Immutable.Set(["ui", "api", "etl", "rss"]);

const capitalise = name => {
  return name
      .split("-")
      .filter(x => !!x)
      .map(x => {
        if (acronyms.has(x)) {
          return x.toUpperCase();
        } else {
          return x.charAt(0).toUpperCase() + x.substring(1);
        }
      })
      .join(" ");
};

const iconStyle = {
  background: "#efe3e3",
  borderRadius: "50%",
  width: 20,
  height: 20,
  textAlign: "center",
  lineHeight: "20px",
  opacity: 0.8
}

const linkStyle = {
  color: "#4A89DC"
}

/* {service.get("type") === "aws-beanstalk" && <><br />Beanstalk {beanstalkLink(service.get("id"))}</>}

const beanstalkLink = id => {
  const url = `https://eu-west-1.console.aws.amazon.com/elasticbeanstalk/home?region=eu-west-1#/environment/dashboard?environmentId=${id}`;
  return <a href={url} target="_blank" rel="noreferrer">{id}</a>;
};*/

const jiraLink = id => {
  const url = `https://bullhorn.atlassian.net/browse/${id}`;
  return <a style={linkStyle} href={url} target="_blank" rel="noreferrer">{id}</a>;
};

const buildkiteLink = (pipeline, buildNumber) => {
  const url = `https://buildkite.com/cube19/${pipeline}/builds/${buildNumber}`;
  return <a style={linkStyle} href={url} target="_blank" rel="noreferrer">{buildNumber}</a>;
};

const githubCommitLink = (repo, commit) => {
  commit = commit || "";
  const url = `https://github.com/cube19/${repo}/commit/${commit}`;
  return <a style={linkStyle} href={url} target="_blank" rel="noreferrer">{commit.substring(0, 8)}</a>;
};

const githubBranchLink = (repo, branch, path) => {
  const url = `https://github.com/cube19/${repo}/tree/${branch}/${path}`;
  return <a style={linkStyle} href={url} target="_blank" rel="noreferrer">{branch}</a>;
};

const strIncludes = (str, substring) => {
  if (str) {
    return (str + "").toLowerCase().includes(substring.toLowerCase());
  } else {
    return false;
  }
};

const loadServices = () => fetch
    .getJson("boxlab/services")
    .then(res => Immutable.fromJS(res));


// Test data
// const loadServices = () => fetch
//     .getJson("boxlab/services")
//     .then(res => {
//       const services = Immutable.fromJS(res).map(service => service
//           .set("source-branch", "BA-8812-metric-tooling")
//           .set("source-commit", "bf5597e1b5a8177738d3f90c90e60400a14fec45")
//           .set("jira-id", "BA-8812")
//           .set("buildkite-pipeline", "app-layer")
//           .set("buildkite-build-number", "9938"));
//       const otherServices = services.map(service => service.set("cube19-env-label","Beta 2"));
//       return services.concat(otherServices);
//     });

export default Page;

const onboardGrowthSubmissionPermission = "ONBOARDING_GROWTH_SUBMISSION";
const onboardCorporateSubmissionPermission = "ONBOARDING_X_SUBMISSION";
const onboardEnterpriseSubmissionPermission = "ONBOARDING_ENTERPRISE_SUBMISSION";

const productTiers = {
  growth: {
    enum: "GROWTH",
    label: "Growth Client (Net New)",
    onboardSubmissionPermission: onboardGrowthSubmissionPermission
  },
  growthAddOn: {
    enum: "GROWTH_ADD_ON",
    label: "Growth Client (Add-On)",
    onboardSubmissionPermission: onboardGrowthSubmissionPermission
  },
  corporate: {
    enum: "CORPORATE",
    label: "Corporate Client (Add-On)",
    onboardSubmissionPermission: onboardCorporateSubmissionPermission
  },
  enterprise: {
    enum: "ENTERPRISE",
    label: "Enterprise Client",
    onboardSubmissionPermission: onboardEnterpriseSubmissionPermission
  }
};

export {productTiers};
import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import moment from "moment";

import TextField from "material-ui/TextField";
import NumberField from "js/components/number-field";
import RaisedButton from "material-ui/RaisedButton";
import Checkbox from "material-ui/Checkbox";
import LoadingSpinner from "js/components/loading-spinner";
import ImmutableSelect from "js/components/immutable-select";
import {Err, Success} from "js/components/notification";
import {DatePickerInput} from "rc-datepicker";
import {AccordionContainer, AccordionSection} from "js/components/accordion";
import DynamicFilterEditor from "js/app-areas/customer-support/dynamic-filter-editor";
import ConsolePermissionTree from "js/app-areas/customer-support/console-permission-tree";
import pure from "js/pure";
import * as fetch from "js/fetch";
import {ClientsContext, CurrentUserContext, SelectedClientIdContext} from "js/data/contexts";

const Page = createReactClass({

  getInitialState() {
    return {
      runningProc: false,
      procIdToPrefillData: Immutable.Map(),
      procIdToComment: Immutable.Map(),
      procIdToParamNameToValue: Immutable.Map(),
      procIdToShowSource: Immutable.Map(),
      procIdToShownParamNames: Immutable.Map()
    };
  },

  componentDidMount() {
    this.fillInDefaultsForAllProcs(this.props.procs);
  },

  componentWillReceiveProps(nextProps) {
    this.fillInDefaultsForAllProcs(nextProps.procs);
  },

  fillInDefaultsForAllProcs(procs) {
    let {procIdToParamNameToValue} = this.state;
    procs.forEach(proc => {
      let originalParamNameToValue = procIdToParamNameToValue.get(proc.get("id"), Immutable.Map());
      const paramNameToValue = this.fillInDefaultsForProc(proc, originalParamNameToValue);
      procIdToParamNameToValue = procIdToParamNameToValue.set(proc.get("id"), paramNameToValue);
    });
    this.setState({procIdToParamNameToValue});
  },

  fillInDefaultsForProc(proc, paramNameToValue) {
    proc
        .get("parameters")
        .filter(param => param.has("default-value"))
        .forEach(param => {
          if (!paramNameToValue.get(param.get("name"))) {
            paramNameToValue = paramNameToValue.set(param.get("name"), param.get("default-value"));
          }
        });

    // NOTE set implicit unchecked value for TINYINT checkboxes
    proc
        .get("parameters")
        .filter(param => param.get("type").toLowerCase().indexOf("tinyint") !== -1)
        .filter(param => !param.get("default-value"))
        .forEach(param => {
          if (!paramNameToValue.get(param.get("name"))) {
            paramNameToValue = paramNameToValue.set(param.get("name"), 0);
          }
        });
    return paramNameToValue;
  },

  render() {
    const {procs} = this.props;
    if (procs) {
      return this.renderPage();
    } else {
      return <p>Something went wrong</p>;
    }
  },

  renderPage() {
    const {procs, filterText} = this.props;
    const categoryToProcs = procs
        .filter(this.shouldShowProc)
        .map(p => {
          const separator = " - ";
          const indexOfSeparator = p.get("name").indexOf(separator);
          const category = p.get("name").substring(0, indexOfSeparator);
          const nameWithoutCategory = p.get("name").substring(indexOfSeparator + separator.length);
          return p
              .set("category", category)
              .set("name", nameWithoutCategory);
        })
        .groupBy(p => p.get("category"));
    return (
        <React.Fragment>
          <AccordionContainer
              allowOnlyOneOpen={false}
              expandAll={filterText}
              containerStyle={{marginBottom: "1rem"}}>
            {categoryToProcs
                .entrySeq()
                .sortBy(([category]) => category.toLowerCase())
                .map(([category, procsForCategory]) => {
                  const procsToRender = procsForCategory.sortBy(p => (p.get("readonly") ? "0" : "1") + p.get("name"));
                  return (
                      <AccordionSection
                          key={category}
                          showArrows={true}
                          title={category || "Other"}
                          titleStyle={{fontSize: "1.1rem", color: "#222", backgroundColor: "#eee"}}
                          containerStyle={{marginTop: "0.5rem", border: "none"}}>
                        {this.renderProcs(procsToRender)}
                      </AccordionSection>
                  );
                })
            }
          </AccordionContainer>
          <Success
              message={this.state.successMessage}
              onRequestClose={() => this.setState({successMessage: null})} />
          <Err message={this.state.errorMessage} onRequestClose={() => this.setState({errorMessage: null})} />
        </React.Fragment>
    );
  },

  renderProcs(procs) {
    const {
      procIdToComment,
      runningProc,
      procIdToParamNameToValue,
      procIdToShowSource
    } = this.state;
    return <AccordionContainer allowOnlyOneOpen={false} stateless={true}>
      {procs.map(proc => {
        const procId = proc.get("id");
        const showSource = procIdToShowSource.get(procId);
        const readonly = proc.get("readonly");

        const requiresComment = proc.get("requires-comment");
        const commentPrefix = getCommentPrefix(proc);
        const noCommentWhenRequired = requiresComment && !procIdToComment.get(procId, "");

        const title = proc.get("name") + (proc.get("description") ? " - " + proc.get("description") : "");

        return (
            <AccordionSection
                key={procId}
                title={title}
                titleStyle={{color: readonly ? "#208624" : "#c71e1e"}}
                onClick={open => {
                  if (open) {
                    this.prefillIfNoRequiredFields(procId);
                  }
                }}>
              <div>
                <div style={{padding: "1rem"}}>
                  <div
                      style={{display: "inline-block", cursor: "pointer", fontSize: "0.8rem", marginRight: "0.8rem"}}
                      onClick={() => this.setState({procIdToShowSource: procIdToShowSource.set(procId, !showSource)})}>
                    {showSource ? "[hide sql]" : "[view sql]"}
                  </div>
                    <span style={{fontSize: "0.8rem"}}>Submitting needs {proc.get("submission-permission")}</span>
                    {proc.get("requires-approval")
                        ? <span style={{fontSize: "0.8rem"}}>, Approving needs {proc.get("approval-permission")}</span>
                        : <span style={{fontSize: "0.8rem"}}>, Approval is not needed</span>}
                  {showSource && <pre>{proc.get("sql-str")}</pre>}
                  <TextField
                      fullWidth={true}
                      floatingLabelText={requiresComment ? "Comment" : "Comment (optional)"}
                      errorText={noCommentWhenRequired && "A comment is required for this cs proc"}
                      value={commentPrefix + procIdToComment.get(procId, "")}
                      onChange={e => this.setCommentForProc(procId, e.target.value.substring(commentPrefix.length))} />
                  <br />
                </div>
                {this.props.loading
                    ? <LoadingSpinner />
                    : <span>
                          {this.renderParams(proc)}
                      <RaisedButton
                          label={runningProc ? "Running" : "Run"}
                          style={{marginLeft: "1.5rem", marginBottom: "1.5rem"}}
                          disabled={noCommentWhenRequired
                          || runningProc
                          ||
                          !allParamsHaveValues(proc.get("parameters"), procIdToParamNameToValue.get(procId, Immutable.Map()))}
                          onClick={() => this.handleRunClick(proc)} />
                        </span>}
              </div>
            </AccordionSection>
        );
      })}
    </AccordionContainer>;
  },

  renderParams(proc) {
    const procId = proc.get("id");
    const hasPrefillSql = !!proc.get("prefill-sql");
    const prefillDataLoaded = this.state.procIdToPrefillData.has(procId);
    const prefillRequiredFields = proc.get("prefill-required-fields").toSet();

    const shownParamNames = this.state.procIdToShownParamNames.get(procId, Immutable.Set());
    const paramNameToValue = this.state.procIdToParamNameToValue.get(procId, Immutable.Map());

    return (
        <div>
          {proc
              .get("parameters")
              .groupBy(parameter => parameter.get("section", ""))
              .entrySeq()
              .map(([section, parameters]) => {
                const parametersToShow = parameters.filter(parameter => {
                  const value = paramNameToValue.get(parameter.get("name"));
                  const isBlank = value === ""
                      || value === null
                      || value === undefined
                      || value === parameter.get("blank-value")
                      || value === parameter.get("default-value");
                  const display = parameter.get("display", "show");
                  return display === "show"
                      || (display === "show-if-not-blank" && !isBlank)
                      || shownParamNames.includes(parameter.get("name"));
                });

                const showingAll = parametersToShow.count() === parameters.count();

                const allFieldsAlwaysShow = parameters.every(p => p.get("display", "show") === "show");

                return (
                    <div key={section}>
                      {(!allFieldsAlwaysShow || !!section) && <div style={{
                        margin: 0,
                        backgroundColor: "#eeeedd",
                        padding: "0.4rem",
                        paddingLeft: "1.5rem",
                        display: "flex",
                        alignItems: "center"
                      }}>
                        <div style={{fontWeight: "bold", fontSize: "1rem"}}>{section}</div>
                        {!allFieldsAlwaysShow && <div
                            style={{marginLeft: "0.5rem", fontSize: "0.8rem"}}
                            onClick={() => {
                              let newProcIdToShownParamNames;
                              if (showingAll) {
                                newProcIdToShownParamNames =
                                    this.state.procIdToShownParamNames.set(procId, Immutable.Set());
                              } else {
                                const newShownParamNames = shownParamNames.union(parameters.map(p => p.get("name"))
                                    .toSet());
                                newProcIdToShownParamNames =
                                    this.state.procIdToShownParamNames.set(procId, newShownParamNames);
                              }
                              this.setState({procIdToShownParamNames: newProcIdToShownParamNames});
                            }}>
                          {showingAll ? "[hide all blank]" : "[show all]"}
                        </div>}
                        {!allFieldsAlwaysShow && <span style={{marginLeft: "0.5rem", fontSize: "0.8rem"}}>
                  Showing {parametersToShow.count()} / {parameters.count()} fields
                </span>}
                      </div>}
                      <div style={{paddingLeft: "1.5rem", paddingRight: "1.5rem", paddingBottom: "1.5rem"}}>
                        {parametersToShow.map(parameter => fieldForParam(
                            parameter,
                            paramNameToValue.get(parameter.get("name"), ""),
                            paramNameToValue,
                            value => this.setParamForProc(procId, parameter.get("name"), value),
                            hasPrefillSql && !prefillDataLoaded &&
                            !prefillRequiredFields.includes(parameter.get("name")),
                            this.props.dropdownNameToAliases,
                            this.props.dropdownNameToData))}
                      </div>
                    </div>);
              })}
        </div>);
  },

  shouldShowProc(proc) {
    const {filterText, permissions} = this.props;
    const searchableStr = proc.get("name") + " - " + proc.get("description");
    const canSubmit = !proc.get("submission-permission") || permissions.includes(proc.get("submission-permission"));
    return canSubmit && (!filterText || searchableStr.toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
  },

  handleRunClick(proc) {
    const {client} = this.props;
    const {procIdToComment, procIdToParamNameToValue, procIdToPrefillData} = this.state;
    const procId = proc.get("id");
    const fullComment = getCommentPrefix(proc) + procIdToComment.get(procId, "");
    const paramNameToValue = procIdToParamNameToValue.get(procId, Immutable.Map());
    const prefillNameToValue = procIdToPrefillData.get(procId, Immutable.Map());
    this.setState({runningProc: true});
    runProc(client.get("id"), procId, fullComment, paramNameToValue, prefillNameToValue)
        .then(
            submission => {
              if (submission.get("status") !== "FAILED") {
                const defaults = this.fillInDefaultsForProc(proc, Immutable.Map());
                this.setState({
                  procIdToComment: procIdToComment.set(procId, ""),
                  procIdToParamNameToValue: procIdToParamNameToValue.set(procId, defaults),
                  procIdToPrefillData: procIdToPrefillData.delete(procId)
                });
                this.prefillIfNoRequiredFields(procId);
              }
              this.setState({runningProc: false});
              this.props.onRequestReload();
            },
            error => {
              console.log(error, error.response);
              error.response
                  .json()
                  .then(res => {
                    this.setState({
                      errorMessage: res.message,
                      runningProc: false
                    });
                    this.props.onRequestReload();
                  });
            });
  },

  setCommentForProc(procId, comment) {
    const {procIdToComment} = this.state;
    this.setState({
      procIdToComment: procIdToComment.set(procId, comment)
    });
  },

  setParamForProc(procId, name, value) {
    // TODO lookup should be by id
    const proc = this.props.procs.find(p => p.get("id") === procId);
    const prefillRequiredFields = proc.get("prefill-required-fields").toSet();

    let newProcIdToParamNameToValue = this.state.procIdToParamNameToValue.setIn([procId, name], value);

    const allRequiredFieldsSet = prefillRequiredFields.every(name => !!newProcIdToParamNameToValue.get(procId)
        .get(name));

    let newProcIdToPrefillData;
    if (prefillRequiredFields.includes(name)) {
      newProcIdToPrefillData = this.state.procIdToPrefillData.delete(procId);
      const newParamNameToValue = newProcIdToParamNameToValue
          .get(procId)
          .filter((value, paramName) => prefillRequiredFields.includes(paramName));
      newProcIdToParamNameToValue = newProcIdToParamNameToValue.set(procId, newParamNameToValue);
    } else {
      newProcIdToPrefillData = this.state.procIdToPrefillData;
    }

    this.setState({
      procIdToParamNameToValue: newProcIdToParamNameToValue,
      procIdToPrefillData: newProcIdToPrefillData
    });

    if (allRequiredFieldsSet && proc.get("prefill-sql") && !newProcIdToPrefillData.has(procId)) {
      loadPrefillData(this.props.client.get("id"), procId, newProcIdToParamNameToValue.get(procId))
          .then(prefillNameToValue => {
            this.setState({
              procIdToPrefillData: newProcIdToPrefillData.set(procId, prefillNameToValue),
              procIdToParamNameToValue: newProcIdToParamNameToValue.update(procId, paramNameToValue => {
                return this.fillInDefaultsForProc(proc, paramNameToValue.merge(prefillNameToValue));
              })
            });
          });
    }
  },

  prefillIfNoRequiredFields(procId) {
    // TODO lookup should be by id
    const proc = this.props.procs.find(p => p.get("id") === procId);
    const prefillRequiredFields = proc.get("prefill-required-fields").toSet();
    if (prefillRequiredFields.isEmpty() && proc.get("prefill-sql")) {
      loadPrefillData(this.props.client.get("id"), procId, this.state.procIdToParamNameToValue.get(procId))
          .then(prefillNameToValue => {
            this.setState({
              procIdToPrefillData: this.state.procIdToPrefillData.set(procId, prefillNameToValue),
              procIdToParamNameToValue: this.state.procIdToParamNameToValue.update(procId, x => x.merge(prefillNameToValue))
            });
          });
    }
  }

});

const paramFieldWidth = 360;

// TODO make React.memo component, use React.useCallback for onChangeForMulti, use React.useMemo for valueForMulti
const paramDropdown = (parameter, placeholder, value, onChange, disabled, options) => {
  const isMulti = !!parameter.get("multi?");
  const onChangeForMulti = (vals) => {
    if (isMulti) {
      onChange(vals.join(","));
    } else {
      onChange(vals);
    }
  };

  const valueForMulti = (str) => {
    if (isMulti) {
      return Immutable.Set((str || "").split(","));
    } else {
      return str;
    }
  };
  return (
      <div
          key={parameter.get("name")}
          title={placeholder}
          style={{
            display: "inline-block",
            width: paramFieldWidth,
            marginTop: "1rem",
            marginRight: "1rem"
          }}>
        <span style={{color: "#bbb", fontSize: "12px", fontFamily: "Roboto,sans-serif"}}><b>{placeholder}</b></span>
        <div style={{marginTop: 3}}>
          <ImmutableSelect
              placeholder={placeholder}
              options={options}
              multi={isMulti}
              searchable={true}
              clearable={!!parameter.get("optional?") || isMulti}
              disabled={disabled}
              selectedValueOrValues={valueForMulti(value)}
              onChange={onChangeForMulti} />
        </div>
      </div>);
};

const getNameForDropdownParamType = (dropdownNameToAliases, nameOrAlias) => {
  if (dropdownNameToAliases.has(nameOrAlias)) {
    return nameOrAlias;
  } else {
    return dropdownNameToAliases.findKey(aliases => {
      return aliases.includes(nameOrAlias);
    });
  }
};

const validateJson = str => {
  if (!str) {
    return null;
  } else {
    try {
      JSON.parse(str);
      return null;
    } catch (e) {
      return e.message || "Invalid JSON";
    }
  }
};

const nameToComponent = Immutable.Map({
  "dynamic-filter": DynamicFilterEditor,
  "console-permission-tree": ConsolePermissionTree
});

const fieldForParam = (parameter, value, paramNameToValue, onChange, disabled, dropdownNameToAliases, dropdownNameToData) => {
  const typeStr = parameter.get("type").toLowerCase();

  const dropdownName = getNameForDropdownParamType(dropdownNameToAliases, typeStr);

  let type;
  if (dropdownName) {
    type = typeStr;
  } else if (typeStr.indexOf("tinyint") !== -1) {
    type = "boolean";
  } else if (typeStr.indexOf("int") !== -1) {
    type = "number";
  } else {
    type = typeStr;
  }

  const name = parameter.get("name");
  const nicerName = name.indexOf("_") === 0 ? name.substring(1) : name;
  const label = <span><b>{nicerName}</b> <i>{typeStr}</i></span>;
  const info = parameter.get("info");
  const wikiLink = parameter.get("wiki-link");
  let extraDetails;
  if (info || wikiLink) {
    extraDetails = <div>
      <b>{info} {wikiLink && <a href={wikiLink} target="_blank" rel={"noopener noreferrer"}>Wiki</a>}</b>
    </div>;
  }

  const componentName = parameter.get("component");
  if (nameToComponent.has(componentName)) {
    const Component = nameToComponent.get(parameter.get("component"));
    return <Component
        key={parameter.get("name")}
        parameter={parameter}
        paramNameToValue={paramNameToValue}
        dropdownNameToData={dropdownNameToData}
        label={nicerName}
        value={value}
        onChange={onChange}
        disabled={disabled} />;
  } else if (dropdownNameToData.has(dropdownName)) {
    const sortedOptions = dropdownNameToData.get(dropdownName);
    return paramDropdown(parameter, nicerName, value, onChange, disabled, sortedOptions);
  } else if (type === "number") {
    return <div style={{display: "inline-block"}}>
      <NumberField
          key={parameter.get("name")}
          style={{marginRight: "1rem", width: paramFieldWidth}}
          floatingLabelText={label}
          value={value}
          onChange={onChange}
          disabled={disabled} />
      {extraDetails}
    </div>;
  } else if (type === "date") {
    return <div style={{display: "inline-block"}}>
      <DatePicker
        key={parameter.get("name")}
        style={{marginRight: "1rem"}}
        label={label}
        container="inline"
        value={value}
        onChange={onChange}
        disabled={disabled} />
      {extraDetails}
    </div>;
  } else if (type === "boolean") {
    return <div style={{display: "inline-block"}}>
      <Checkbox
        key={parameter.get("name")}
        style={{marginRight: "1rem", width: paramFieldWidth, verticalAlign: "middle"}}
        label={label}
        checked={value === "1" || value === 1 || value === true}
        onCheck={(_, val) => onChange(val ? 1 : 0)}
        disabled={disabled} />
      {extraDetails}
    </div>;
  } else {
    value = value || "";
    if (type === "edn") {
      // TODO edn highlighting and linting would be cool
      return <div style={{width: "100%"}}>
        <TextField
          key={parameter.get("name")}
          style={{marginRight: "1rem"}}
          floatingLabelText={label}
          fullWidth={true}
          multiLine={true}
          value={value}
          onChange={e => onChange(e.target.value)}
          disabled={disabled} />
        {extraDetails}
      </div>;
    } else if (type === "json"
        || (value.indexOf("{") === 0 && value.indexOf("}") === value.length - 1)
        || (value.indexOf("[") === 0 && value.indexOf("]") === value.length - 1)) {
      // TODO json highlighting would be cool
      return <div style={{width: "100%"}}>
        <TextField
          key={parameter.get("name")}
          floatingLabelText={label}
          style={{marginRight: "1rem"}}
          fullWidth={true}
          multiLine={true}
          value={value}
          onChange={e => onChange(e.target.value)}
          disabled={disabled}
          errorText={validateJson(value)} />
        {extraDetails}
      </div>;
    } else if (type === "sql" || value.indexOf("-- sql") === 0) {
      // TODO sql highlighting and linting would be cool
      return <div style={{width: "100%"}}>
        <TextField
          key={parameter.get("name")}
          style={{marginRight: "1rem"}}
          floatingLabelText={label}
          fullWidth={true}
          multiLine={true}
          value={value}
          onChange={e => onChange(e.target.value)}
          disabled={disabled} />
        {extraDetails}
      </div>;
    } else {
      return <div style={{display: "inline-block"}}>
        <TextField
          key={parameter.get("name")}
          style={{marginRight: "1rem", width: paramFieldWidth}}
          floatingLabelText={label}
          value={value}
          onChange={e => onChange(e.target.value)}
          disabled={disabled} />
        {extraDetails}
      </div>;
    }
  }
};

const MYSQL_DATE_FORMAT = "YYYYMMDD";
const DISPLAY_FORMAT = "YYYY-MM-DD";

const DatePicker = pure(({label, style, placeholder, value, onChange}) => {
  return (
      <div style={style}>
        <label style={{color: "#AAA"}}>{label}</label>
        <DatePickerInput
            iconClassName="fa fa-calendar"
            displayFormat={DISPLAY_FORMAT}
            placeholder={placeholder}
            value={value && moment(value).format(MYSQL_DATE_FORMAT)}
            onChange={(jsDate, dateStr) => onChange(moment(dateStr).format(MYSQL_DATE_FORMAT))} />
      </div>);
});

const allParamsHaveValues = (parameters, paramNameToValue) => {
  return parameters.every(parameter => {
    const value = paramNameToValue.get(parameter.get("name"));
    return parameter.get("optional?") || value === 0 || !!value;
  });
};

const getCommentPrefix = proc => proc.get("category")
    + " - " + proc.get("name")
    + (proc.get("requires-comment") ? ": " : "");

const runProc = (cube19ClientId, id, comment, paramNameToValue, prefillNameToValue) => fetch
    .put(
        "procedures/" + id + "/run",
        {
          "cube19-client-id": cube19ClientId,
          "comment": comment,
          "param-name-to-value": paramNameToValue.toJS(),
          "prefill-name-to-value": prefillNameToValue.toJS()
        })
    .then(response => response.json())
    .then(submission => Immutable.fromJS(submission));

const loadPrefillData = (cube19ClientId, id, paramNameToValue) => fetch
    .put(
        "procedures/" + id + "/prefill",
        paramNameToValue
            .set("cube19-client-id", cube19ClientId)
            .toJS())
    .then(res => res.json())
    .then(res => Immutable.fromJS(res).map(x => typeof (x) === "number" ? x + "" : x));

const loadUndefinedMasterMetrics = (cube19ClientId) => fetch
    .getJson("procedures/undefined-core-key-kpis", {"cube19-client-id": cube19ClientId})
    .then(undefinedKeyKpis => Immutable.fromJS(undefinedKeyKpis));

export {loadUndefinedMasterMetrics};

const ConnectedPage = props => {
  const {idToClient} = React.useContext(ClientsContext);
  const {selectedClientId} = React.useContext(SelectedClientIdContext);
  const {currentUser} = React.useContext(CurrentUserContext);
  return <Page
      {...props}
      client={idToClient.get(selectedClientId)}
      permissions={currentUser.get("permissions")} />;
};

export default ConnectedPage;

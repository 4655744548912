import React from "react";
import createReactClass from "create-react-class";
import pure from "js/pure";
import * as Immutable from "immutable";
import moment from "moment";

import * as Fetch from "js/fetch";
import {AccordionContainer, AccordionSection} from "js/components/accordion";
import LoadingSpinner from "js/components/loading-spinner";
import ClientPicker from "js/components/client-picker";
import {ClientsContext, CrmsContext, SelectedClientIdContext} from "js/data/contexts";

const Page = createReactClass({

  getInitialState() {
    return {
      loading: false,
      error: null,
      integrationSearches: Immutable.Map()
    };
  },

  componentDidMount() {
    if (this.props.selectedClientId) {
      this.loadAndSet(this.props.selectedClientId);
    }
  },

  loadAndSet(clientId) {
    this.setState({loading: true, error: null});
    loadIntegrations(clientId).then(
        integrationSearches => {
          this.setState({integrationSearches, loading: false});
        },
        error => {
          if (error.response && error.response.json) {
            error.response.json().then(body => {
              this.setState({error: Immutable.fromJS(body), loading: false});
            });
          } else {
            this.setState({error: Immutable.Map({message: "unknown error"}), loading: false});
          }
        });
  },

  render() {
    const {selectedClientId} = this.props;
    return (
        <div style={{margin: "1rem"}}>
          <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", marginBottom: "1rem"}}>
            <ClientPicker
                style={{width: 400, marginRight: "1rem"}}
                value={selectedClientId}
                onChange={clientId => {
                  this.props.setClientId(clientId);
                  this.loadAndSet(clientId);
                }}
                idToClient={this.props.idToClient} />
          </div>
          {selectedClientId && this.renderClientSection()}
        </div>);
  },

  clientHasValidCrm(clientId) {
    const client = this.props.idToClient.get(clientId);
    const crmType = this.props.idToCrm.get(client.get("crm_id")).get("type");
    return crmType === "bullhornrest";
  },

  renderClientSection() {
    const {selectedClientId} = this.props;
    if (this.state.loading) {
      return <LoadingSpinner />;
    } else if (selectedClientId && !this.clientHasValidCrm(selectedClientId)) {
      return <div>Functionality is only supported on Bullhorn REST</div>;
    } else if (this.state.error) {
      return <div>{this.state.error.get("message", "unknown error")}</div>;
    } else {
      const integrations = this.state.integrationSearches.filter(integration => integration.get("has-integration"));
      if (integrations.isEmpty()) {
        return <div>No integrations found</div>;
      } else {
        return <AccordionContainer
            stateless={true}
            allowOnlyOneOpen={false}
            lazyRender={true}>
          {integrations
              .sortBy(integration => integration.get("name"))
              .map(integration => {
                const name = integration.get("name");
                const hasMetadataSearch = integration.get("metadata-search-description") ||
                    integration.get("metadata-search-result");
                const hasEntitySearch = integration.get("entity-search-description") ||
                    integration.get("entity-search-result");
                return (
                    <AccordionSection
                        key={name}
                        title={name}
                        showArrows={true}>
                      {hasMetadataSearch && <MetadataSearchResult
                          description={integration.get("metadata-search-description")}
                          match={integration.get("metadata-search-result")}
                      />}
                      {hasEntitySearch && <EntitySearchResult
                          description={integration.get("entity-search-description")}
                          result={integration.get("entity-search-result")}
                      />}
                    </AccordionSection>);
              })}
        </AccordionContainer>;
      }
    }
  }

});

const ConnectedPage = props => {
  const {selectedClientId, setSelectedClientId} = React.useContext(SelectedClientIdContext);
  const {idToClient} = React.useContext(ClientsContext);
  const {idToCrm} = React.useContext(CrmsContext);
  return <Page
      {...props}
      idToClient={idToClient}
      selectedClientId={selectedClientId}
      setClientId={setSelectedClientId}
      idToCrm={idToCrm} />;
};

export default ConnectedPage;

const MetadataSearchResult = pure(({description, match}) => {
  return <div style={{margin: "0.5rem"}}>
    <div style={{display: "inline-block", marginRight: "0.5rem", verticalAlign: "top", width: 16}}>
      <SearchMatchIcon match={match} />
    </div>
    <div style={{display: "inline-block"}}>
      <span style={{fontWeight: "bold"}}>Metadata search: </span>
      <span>{description}</span>
    </div>
  </div>;
});

const EntitySearchResult = pure(({description, result}) => {
  const total = result.get("total");
  const earliestDate = moment(result.get("earliest-date"));
  const latestDate = moment(result.get("latest-date"));
  return <div style={{margin: "0.5rem"}}>
    <div style={{display: "inline-block", marginRight: "0.5rem", verticalAlign: "top", width: 16}}>
      <SearchMatchIcon match={total > 0} />
    </div>
    <div style={{display: "inline-block"}}>
      <span style={{fontWeight: "bold"}}>Entity search: </span>
      <span>{description}</span>
      {total > 0 && <div>
        <p>{`Matching records: ${total}`}</p>
        <p>{`Earliest date: ${earliestDate.format("YYYY-MM-DD")}`}</p>
        <p>{`Latest date: ${latestDate.format("YYYY-MM-DD")}`}</p>
      </div>}
    </div>
  </div>;
});

const SearchMatchIcon = pure(({match}) => {
  const iconClass = match ? "fa fa-check" : "fa fa-times";
  const color = match ? "green" : "red";
  return <i className={iconClass} style={{color}} />;
});

const loadIntegrations = cube19ClientId => Fetch
    .getJson("bullhorn-integrations/" + cube19ClientId)
    .then(integrations => Immutable.fromJS(integrations));

import React from "react";
import {Dialog, RaisedButton} from "material-ui";

const UnsavedChangesDialog = (({
  onRetrieveClick,
  onDiscardClick,
  open,
  lastUpdated,
  customMessage,
  actions = [
    <RaisedButton
        key="retrieve-changes"
        primary={true}
        label="Retrieve changes"
        style={{marginLeft: "0.5rem", marginRight: "0.5rem", marginBottom: "1rem"}}
        onClick={onRetrieveClick} />,
    <RaisedButton
        key="discard-changes"
        secondary={true}
        label="Discard changes"
        style={{marginLeft: "0.5rem", backgroundColor: "red", marginRight: "0.5rem", marginBottom: "1rem"}}
        onClick={onDiscardClick} />
  ]
}) => (
    <Dialog
        bodyStyle={{overflow: "visible"}}
        actionsContainerStyle={{paddingRight: "2rem"}}
        title={"You have unsaved changes" + (lastUpdated ? " from " + lastUpdated : "")}
        actions={actions}
        autoDetectWindowHeight={true}
        titleStyle={{fontSize: "1.1rem", fontWeight: "bold"}}
        open={open}>
      <span>{customMessage ?? "Would you like to recover them?"}</span>
    </Dialog>
));

export default UnsavedChangesDialog;
import React from "react";
import * as Immutable from "immutable";
import {
  Table,
  TableBody,
  TableRow,
  TableHeader,
  TableHeaderColumn,
  TableRowColumn,
  Dialog,
  Popover,
  Menu,
  MenuItem,
  IconButton
} from "material-ui";
import moment from "moment";

const BatchBulkTable = React.memo(({
  entities,
  existingBulkJobs,
  actionToFunction,
  filterText
}) => {

  const [bulkData, setBulkData] = React.useState(null);

  React.useEffect(() => {

    const referenceEntities = entities
        .filter(entity => entity.getIn(["properties", "isReference"]) === true)
        .map(entity => entity.get("name"));

    const groupedBatchedJobs = existingBulkJobs
        .filter(job => job.get("batch-id") !== null)
        .groupBy(job => job.get("batch-id"));

    const topLevelData = groupedBatchedJobs.map((batch, key) => {

      const entityLevelData = batch.groupBy(job => {
        const metadata = Immutable.fromJS(JSON.parse(job.get("metadata")));
        const entity = metadata
            .get("entitiesToLoad")
            .first();
        if (entity === "EdsData") {
          if (metadata.get("edsEntitiesToLoad") !== undefined
              && !metadata.get("edsEntitiesToLoad").isEmpty()) {
            const edsEntity = metadata
                .get("edsEntitiesToLoad")
                .first();
            const edsLabel = edsEntity.get("sourceSystem") + " - " + (edsEntity.get("entityName") === undefined
                ? "All"
                : edsEntity.get("entityName"));
            return "DataHub " + edsLabel;
          } else {
            return "DataHub"
          }
        } else {
          return entity;
        }
      })
          .map((jobs, entity) => Immutable.Map({
            children: jobs,
            entity: entity,
            status: getSummaryStatus(jobs),
            menuItems: getMenuItems(jobs, "ENTITY"),
            progress: getBatchProgress(jobs),
            mostRecentCompletedTimestamp: jobs.maxBy(x => x.get("finished-timestamp")).get("finished-timestamp"),
            durationSeconds: jobs.reduce((t, job) => t += job.get("duration-seconds"), 0),
            rowsUpserted: jobs.reduce((t, job) => t += job.get("rows-upserted"), 0),
            rowsDeleted: jobs.reduce((t, job) => t += job.get("rows-deleted"), 0)
          })).valueSeq().toList();

      const groupedEntityData = entityLevelData
          .sortBy(m => m.get("entity"))
          .groupBy(entity => referenceEntities.contains(entity.get("entity")))
          .mapKeys(key => key ? "Reference" : "Activity");

      const summaryStatus = getSummaryStatus(batch);

      let chosenEntities = Immutable.fromJS(JSON.parse(batch.first().get("batch-params")))
          .get("entities", Immutable.List())
          .map(entity => entity === "EdsData" ? "DataHub" : entity)
          .sort();

      if (chosenEntities.size === 0) {
        chosenEntities = entities
            .map(entity => entity.get("name") === "EdsData" ? "DataHub" : entity.get("name"))
            .sort();
      }

      return Immutable.Map({
        children: groupedEntityData,
        id: key,
        minJobId: batch.map(job => job.get("id")).min(),
        menuItems: getMenuItems(batch, "BATCH"),
        message: batch.first().get("message"),
        metadata: JSON.stringify(Immutable.OrderedMap(JSON.parse(batch
            .first()
            .get("batch-params")))
            .sortBy((value, key) => key === "client-id")),
        "ignore-client-schedule": batch.first().get("ignore-client-schedule"),
        progress: getBatchProgress(batch),
        entities: chosenEntities.join(", \n"),
        "is-batch": "yes",
        "duration-seconds": batch.reduce((t, job) => t += job.get("duration-seconds"), 0),
        "rows-upserted": batch.reduce((t, job) => t += job.get("rows-upserted"), 0),
        "rows-deleted": batch.reduce((t, job) => t += job.get("rows-deleted"), 0),
        "status": summaryStatus,
        "start-after-timestamp": "N/A",
        "required-timestamp": batch.first().get("required-timestamp"),
        "accepted-timestamp": batch.minBy(x => x.get("accepted-timestamp")).get("accepted-timestamp"),
        "finished-timestamp": summaryStatus === "COMPLETED" && batch.maxBy(x => x.get("finished-timestamp"))
            .get("finished-timestamp")
      });
    }).valueSeq().toList();

    const processedData = existingBulkJobs
        .filter(job => job.get("batch-id") === null)
        .concat(topLevelData)
        .sortBy(batchOrSingleJob => batchOrSingleJob.get("is-batch")
            ? batchOrSingleJob.get("minJobId")
            : batchOrSingleJob.get("id"))
        .reverse();

    setBulkData(processedData);

  }, [entities, existingBulkJobs]);

  return bulkData && <div style={{maxHeight: 500}}>
    <CustomTable
        data={applyFilter(filterText, bulkData)}
        actionToFunction={actionToFunction}
        columns={topLevelColumns}
        customBodyStyle={{width: 2500, overflowY: "scroll"}}
        customStyle={{width: 2500}}
    /></div>;
});

const CustomTable = React.memo(({
  data,
  columns,
  actionToFunction,
  customStyle = {},
  customBodyStyle
}) => {
  return <Table
      fixedHeader={true}
      style={{...customStyle, border: "1px solid black"}}
      bodyStyle={{...customBodyStyle, marginBottom: 10, maxHeight: 460}}>
    <TableHeader style={{backgroundColor: "#E5E5E3", color: "black"}}>
      <Columns columns={columns} />
    </TableHeader>
    <TableBody>
      <Rows data={data} columns={columns} actionToFunction={actionToFunction} />
    </TableBody>
  </Table>;
});

const Columns = React.memo(({columns}) => {
  return <TableRow style={{height: 40}}>
    <TableHeaderColumn
        style={{
          zIndex: 999,
          backgroundColor: "rgb(229, 229, 227)",
          left: 0,
          height: "100%",
          width: 50
        }}></TableHeaderColumn>
    {columns.map(column => <TableHeaderColumn
        key={column.get("label")}
        style={{
          fontSize: 14,
          color: "black",
          height: "100%",
          width: column.get("width")
        }}>{column.get("label")}</TableHeaderColumn>)}
  </TableRow>;
});


const Rows = React.memo(({data, columns, actionToFunction}) => {
  return data.map(bulk => {
    return <Row data={bulk} columns={columns} actionToFunction={actionToFunction} />;
  });
});

const Row = React.memo(({data, columns, actionToFunction}) => {

  const [isExpanded, setIsExpanded] = React.useState(false);
  const shouldExpand = !!(data.get("is-batch") || data.get("entity"));
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const [anchor, setAnchor] = React.useState(null);

  const getJobActions = job => job.get("status") === "FAILED" || job.get("status") === "COMPLETED"
      ? Immutable.fromJS([{label: "Clone Job", action: "CLONE_JOB"}])
      : Immutable.fromJS([{label: "Clone Job", action: "CLONE_JOB"}, {label: "Cancel Job", action: "CANCEL_JOB"}]);
  const onActionsClick = (e) => {
    setAnchor(e.currentTarget);
    setIsMenuOpen(!isMenuOpen);
  };

  const entityTypeHeaderStyle = {
    fontSize: 15,
    margin: "10px 0"
  };

  const isEntityLevel = data.getIn(["children", "Reference"]) || data.getIn(["children", "Activity"]);

  const getScrollTo = (action) => {
    if (action === "CLONE_BATCH") {
      return "#BATCH";
    } else if (action === "CLONE_JOB") {
      return "#JSON";
    }
  };

  return <>
    <TableRow>
      <TableRowColumn style={{position: "sticky", backgroundColor: "white", left: 0, width: 50, padding: 0}}>
        <div style={{display: "flex", marginLeft: 5}}>
          <div>
            <IconButton
                onClick={(e) => onActionsClick(e)}
                style={{width: 20, padding: 0}}
                iconStyle={{fontSize: 15}}
                iconClassName="fa fa-ellipsis-v">
            </IconButton>
          </div>
          {shouldExpand && <div>
            <IconButton
                onClick={() => setIsExpanded(!isExpanded)}
                style={{width: 20, padding: 0}}
                iconStyle={{fontSize: 15}}
                iconClassName={isExpanded ? "fa fa-chevron-up" : "fa fa-chevron-down"}>
            </IconButton>
          </div>}
        </div>
        <Popover
            open={isMenuOpen}
            anchorEl={anchor}
            anchorOrigin={{horizontal: "right", vertical: "bottom"}}
            targetOrigin={{horizontal: "left", vertical: "top"}}
            onRequestClose={() => setIsMenuOpen(false)}>
          <Menu>
            {data
                .get("menuItems", getJobActions(data))
                .map(item => <a
                    key={item.get("action")}
                    style={{textDecoration: "none"}}
                    href={getScrollTo(item.get("action"))}
                >
                  <MenuItem
                      onClick={() => {
                        actionToFunction.get(item.get("action"))(data);
                        setIsMenuOpen(false);
                      }}
                      style={{fontSize: "0.75rem", border: "1px solid black"}}>
                    {item.get("label")}
                  </MenuItem>
                </a>)}
          </Menu>
        </Popover>
      </TableRowColumn>
      {columns.map(column => {
            return <TableRowColumn
                key={column.get("label")}
                style={{width: column.get("width"), overflowX: "hidden", textOverflow: "revert"}}>
              <div
                  onClick={() => {
                    setIsModalOpen(true);
                    setModalContent(column.get("displayFn")(data));
                  }}>
                {column.get("displayFn")(data)}
              </div>
            </TableRowColumn>;
          }
      )}
    </TableRow>
    {isExpanded && shouldExpand && <TableRow>
      <TableRowColumn colSpan={isEntityLevel ? 13 : 8}>
        {isEntityLevel ? <>
          {data.getIn(["children", "Reference"]) && <>
            <div style={entityTypeHeaderStyle}>Reference</div>
            <CustomTable
                data={data.getIn(["children", "Reference"])}
                columns={entityLevelColumns}
                actionToFunction={actionToFunction} />
          </>}
          {data.getIn(["children", "Activity"]) && <>
            <div style={entityTypeHeaderStyle}>Activity</div>
            <CustomTable
                data={data.getIn(["children", "Activity"])}
                columns={entityLevelColumns}
                actionToFunction={actionToFunction} />
          </>}
        </> : <>
          <CustomTable
              data={data.get("children")}
              columns={jobColumns}
              actionToFunction={actionToFunction} />
        </>}
      </TableRowColumn>
    </TableRow>}
    <Dialog
        open={isModalOpen}
        bodyStyle={{overflowY: "scroll"}}
        style={{zIndex: 9999}}
        onRequestClose={() => setIsModalOpen(false)}>
      <pre>{formatData(modalContent)}</pre>
    </Dialog>
  </>;
});

const topLevelColumns = Immutable.fromJS([
  {label: "ID", width: 70, displayFn: data => data.get("id")},
  {label: "Status", width: 165, displayFn: data => data.get("status")},
  {label: "Is a batch?", width: 100, displayFn: data => data.get("is-batch")},
  {label: "Entities", width: 250, displayFn: data => data.get("entities")},
  {label: "Progress", width: 300, displayFn: data => data.get("progress")},
  {label: "Metadata", width: 200, displayFn: data => data.get("metadata")},
  {label: "Duration (s)", width: 90, displayFn: data => data.get("duration-seconds")},
  {
    label: "Ignore Client Schedule",
    width: 160,
    displayFn: data => data.get("ignore-client-schedule") ? "true" : "false"
  },
  {label: "Message", displayFn: data => data.get("message")},
  {label: "Start After", displayFn: data => formatDate(data.get("start-after-timestamp"))},
  {label: "Required Timestamp", displayFn: data => formatDate(data.get("required-timestamp"))},
  {label: "Accepted Timestamp", displayFn: data => formatDate(data.get("accepted-timestamp"))},
  {label: "Finished Timestamp", displayFn: data => formatDate(data.get("finished-timestamp"))},
  {label: "TXN ID", width: 125, displayFn: data => data.get("txn-id")},
  {label: "Rows Upserted", width: 120, displayFn: data => data.get("rows-upserted") || 0},
  {label: "Rows Deleted", width: 120, displayFn: data => data.get("rows-deleted") || 0}
]);

const entityLevelColumns = Immutable.fromJS([
  {label: "Entity", width: 85, displayFn: data => data.get("entity")},
  {label: "Status", width: 80, displayFn: data => data.get("status")},
  {label: "Progress", width: 80, displayFn: data => data.get("progress")},
  {label: "Duration (s)", width: 40, displayFn: data => data.get("durationSeconds")},
  {
    label: "Most recent job completed timestamp",
    width: 120,
    displayFn: data => formatDate(data.get("mostRecentCompletedTimestamp"))
  },
  {label: "Rows Upserted", width: 60, displayFn: data => data.get("rowsUpserted")},
  {label: "Rows Deleted", width: 350, displayFn: data => data.get("rowsDeleted")}
]);

const jobColumns = Immutable.fromJS([
  {label: "ID", width: 70, displayFn: data => data.get("id")},
  {label: "Status", width: 120, displayFn: data => data.get("status")},
  {label: "Progress", width: 300, displayFn: data => data.get("progress")},
  {label: "Metadata", width: 300, displayFn: data => data.get("metadata")},
  {label: "Duration (s)", width: 90, displayFn: data => data.get("duration-seconds")},
  {label: "Message", displayFn: data => data.get("message")},
  {label: "Required Timestamp", displayFn: data => formatDate(data.get("required-timestamp"))},
  {label: "Accepted Timestamp", displayFn: data => formatDate(data.get("accepted-timestamp"))},
  {label: "Finished Timestamp", displayFn: data => formatDate(data.get("finished-timestamp"))},
  {label: "TXN ID", width: 125, displayFn: data => data.get("txn-id")},
  {label: "Rows Upserted", width: 120, displayFn: data => data.get("rows-upserted") || 0},
  {label: "Rows Deleted", width: 120, displayFn: data => data.get("rows-deleted") || 0}
]);

const getSummaryStatus = (batch) => {
  const statuses = batch.map(job => job.get("status"));

  if (statuses.every(status => status === "FAILED")) {
    return "FAILED";
  } else if (statuses.every(status => status === "ACCEPTED")) {
    return "ACCEPTED";
  } else if (statuses.every(status => status === "COMPLETED")) {
    return "COMPLETED";
  } else if (statuses.every(status => status === "FAILED" || status === "COMPLETED")) {
    return "COMPLETED W/ FAILS";
  } else {
    return "RUNNING";
  }
};

const getMenuItems = (jobs, type) => {
  let menuItems = [{label: "Reschedule All", action: "RESCHEDULE_ALL"}];
  const statuses = jobs.map(job => job.get("status"));
  const isRescheduled = Immutable.Map(JSON.parse(jobs.first().get("batch-params"))).get("rescheduled");

  if (statuses.some(status => status === "FAILED")) {
    menuItems.push({label: "Reschedule Failed", action: "RESCHEDULE_FAILED"});
  }
  if (statuses.some(status => status !== "FAILED" && status !== "COMPLETED")) {
    menuItems.push({label: "Cancel Remaining", action: "CANCEL_REMAINING"});
  }
  if (type === "BATCH") {
    menuItems.push({label: isRescheduled ? "Clone Original Batch" : "Clone Batch", action: "CLONE_BATCH"});
  }
  return Immutable.fromJS(menuItems);
};

const statusOrder = Immutable.List(["COMPLETED", "ACCEPTED", "FAILED", "RUNNING"]);

const getBatchProgress = (batch) => {

  const failMessageToCount = batch
      .filter(job => job.get("status") === "FAILED")
      .countBy(job => job.get("message"))
      .map((value, key) => "\n\t" + key + ": " + value)
      .join(", ");

  let statuses = batch
      .countBy(job => job.get("status"))
      .sortBy((value, key) => statusOrder.indexOf(key))
      .map((value, key) => {
        if (key === "FAILED") {
          return key + ": " + value + failMessageToCount;
        } else {
          return key + ": " + value;
        }
      })
      .join(",\n");

  return <div>{statuses}</div>;
};

const formatDate = str => {
  if (moment(str).isValid()) {
    return moment(str).format("YYYY-MM-DD k:mm:ss");
  } else {
    return "";
  }
};

const applyFilter = (text, rows) => {
  if (!text) {
    return rows;
  } else {
    const words = text.toLowerCase().split(" ");
    return rows.filter(row => {
      const rowStr = row.join(" ").toLowerCase();
      return words.every(word => rowStr.indexOf(word) !== -1);
    });
  }
};

const formatData = val => {
  if (!val) {
    return val;
  }

  const mightBeJson = typeof (val) === "string" && (val.indexOf("{") === 0 || val.indexOf("[") === 0);
  if (mightBeJson) {
    try {
      return JSON.stringify(JSON.parse(val), null, 2);
    } catch (e) {
      return val;
    }
  } else {
    return val;
  }
};

export default BatchBulkTable;
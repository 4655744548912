import React from "react";
import pure from "js/pure";

import TextField from "material-ui/TextField";

export default pure(({
    range: [lower = Number.NEGATIVE_INFINITY, upper = Number.POSITIVE_INFINITY] = [],
    nullable = true,
    onChange = () => {},
    allowFloats = false,
    ...props
}) => (
    <TextField
        type="number"
        min={lower}
        max={upper}
        onBlur={e => {
            let num = allowFloats ? parseFloat(e.target.value) : parseInt(e.target.value, 10);
            if (Number.isNaN(num)) {
                num = nullable ? null : 0;
            } else {
                num = Math.max(num, lower);
                num = Math.min(num, upper);
            }
            if (num !== props.value) {
                onChange(num, e);
            }
        }}
        onChange={e => {
          let num = allowFloats ? parseFloat(e.target.value) : parseInt(e.target.value, 10);
          if (Number.isNaN(num)) {
                num = nullable ? null : 0;
            }
          if (num !== props.value) {
                onChange(num, e);
            }
        }}
        {...props} />));

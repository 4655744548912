import Immutable from "immutable";

import {apiLoadedSuccessfully} from "js/data/entity-field-mappings/reducer";
import {getErrorMessage} from "js/utils/errors";
import {fetchDwGroupingEntities, fetchEntityFieldMappings, fetchLinkContexts} from "js/data/entity-field-mappings/api";

const mergeEntityFieldMappingsState = newState => ({
  type: "MERGE_ENTITY_FIELD_MAPPINGS",
  newState
});
const mergeInEntityFieldMappingsState = (
    path,
    newState,
    defaultState = Immutable.Map()) => ({
  type: "MERGE_IN_ENTITY_FIELD_MAPPINGS",
  path,
  defaultState,
  newState
});
const setInEntityFieldMappingsState = (path, newValue) => ({
  type: "SET_IN_ENTITY_FIELD_MAPPINGS",
  path,
  newValue
});

const loadEntityFieldMappings = clientId => dispatch => {
  return fetchEntityFieldMappings(clientId).then(
      mappings => dispatch(mergeEntityFieldMappingsState(Immutable.fromJS({
        mappings,
        api: {mappings: apiLoadedSuccessfully}
      }))),
      error => getErrorMessage(error).then(message => {
        return dispatch(setInEntityFieldMappingsState(["api", "mappings", "error"], message));
      }));
};

const loadDwGroupingEntities = () => dispatch => {
  return fetchDwGroupingEntities().then(
      dwGroupingEntities => dispatch(mergeEntityFieldMappingsState(Immutable.fromJS({
        dwGroupingEntities
      }))),
      error => getErrorMessage(error).then(message => {
        return dispatch(setInEntityFieldMappingsState(["api", "mappings", "error"], message));
      }));
};

const loadLinkContexts = () => dispatch => {
  return fetchLinkContexts().then(
      linkContexts => dispatch(mergeEntityFieldMappingsState(Immutable.fromJS({
        linkContexts
      }))),
      error => getErrorMessage(error).then(message => {
        return dispatch(setInEntityFieldMappingsState(["api", "mappings", "error"], message));
      }));
};

export {
  mergeEntityFieldMappingsState,
  mergeInEntityFieldMappingsState,
  setInEntityFieldMappingsState,
  loadEntityFieldMappings,
  loadDwGroupingEntities,
  loadLinkContexts
};

import Immutable from "immutable";
import moment from "moment";

import * as fetch from "js/fetch";
import {formatDate} from "js/utils/time";

const INVALID_PARAMS = "INVALID_PARAMS";
const FETCH = "FETCH_CLIENT_DETAILS";
const SUCCESS = "FETCHED_CLIENT_DETAILS";
const ERROR = "ERROR_FETCHING_CLIENT_DETAILS";
const FAILED_TO_LOAD = "FAILED_TO_LOAD";
const NO_DATA_FOUND = "NO_DATA_FOUND";
const END_DATE_BEFORE_START_DATE = "END_DATE_BEFORE_START_DATE_FOR_CLIENT_DETAILS";

const SET_CLIENT_FILTER = "FILTER_CLIENT_DETAILS_BY_CLIENT";
const SET_START_DATE = "SET_CLIENT_DETAILS_START_DATE";
const SET_END_DATE = "SET_CLIENT_DETAILS_END_DATE";

const COLUMNS = [
    "Client ID",
    "Client",
    "User/Group",
    "Group Name",
    "User Name",
    "Group Breadcrumbs",
    "Category",
    "Name",
    "Value",
    "Last Date",
    "Last Date Time"
];

const startOfLastMonth = moment().date(0).startOf("month");
const endOfLastMonth = moment().date(0).endOf("month");

const initialState = Immutable.Map({
    isFetching: false,
    error: null,
    filters: Immutable.Map({
        clientIds: Immutable.Set(),
        startDate: startOfLastMonth,
        endDate: endOfLastMonth
    }),
    data: null
});

const clientDetailsData = (state = initialState, action) => {
    switch (action.type) {
    case INVALID_PARAMS:
        return state.set("error", action.error);
    case FETCH:
        return state
            .set("isFetching", true)
            .set("error", null)
            .set("data", initialState.get("data"));
    case SUCCESS:
        return state
            .set("isFetching", false)
            .set("data", action.data);
    case ERROR:
        return state
            .set("isFetching", false)
            .set("error", action.error)
            .set("data", initialState.get("data"));
    case SET_CLIENT_FILTER:
        return state.setIn(["filters", "clientIds"], action.clientIds);
    case SET_START_DATE:
        return state.setIn(["filters", "startDate"], action.startDate);
    case SET_END_DATE:
        return state.setIn(["filters", "endDate"], action.endDate);
    default:
        return state;
    }
};

// ACTIONS

const onClientPickerChange = clientIds => ({
    type: SET_CLIENT_FILTER,
    clientIds
});

const onStartDateChange = (startDate, isInvalidDate) => ({
    type: SET_START_DATE,
    startDate
});

const onEndDateChange = (endDate, isInvalidDate) => ({
    type: SET_END_DATE,
    endDate
});

const loadData = filters => {
    return dispatch => {
        const startDate = filters.get("startDate");
        const endDate = filters.get("endDate");
        if (endDate.isBefore(startDate)) {
            dispatch({
                type: INVALID_PARAMS,
                error: END_DATE_BEFORE_START_DATE
            });
            return;
        }

        const params = {
            "client-ids": filters.get("clientIds").toArray(),
            "start-date": formatDate(startDate),
            "end-date": formatDate(endDate)
        };
        dispatch({ type: FETCH });
        return fetch.getJson("client-detailed-pivot", params).then(
            data => {
                if (data.length === 0) {
                    dispatch({
                        type: ERROR,
                        error: NO_DATA_FOUND
                    });
                } else {
                    dispatch({
                        type: SUCCESS,
                        data: [ COLUMNS, ...data ]
                    });
                }
            },
            error => {
                dispatch({
                    type: ERROR,
                    error: FAILED_TO_LOAD
                });
            });
    };
};

export default clientDetailsData;
export {
    onClientPickerChange,
    onStartDateChange,
    onEndDateChange,
    loadData,
    COLUMNS,
    FAILED_TO_LOAD,
    NO_DATA_FOUND,
    END_DATE_BEFORE_START_DATE
};

/** @jsxImportSource @emotion/react */

import React from "react";
import {Checkbox, CircularProgress, IconButton, Menu, MenuItem, Paper, RaisedButton, TextField} from "material-ui";
import Immutable from "immutable";

import ImmutableSelect from "js/components/immutable-select";
import {indexBy} from "js/utils/collections";
import {v4 as uuidv4} from "uuid";
import {
  areTypeFiltersErroring,
  toTypeFilters,
  toFilterParam} from "js/app-areas/onboarding/step-2/config-helpers";
import {
  kpiCombineWithMasterKpiTypePath,
  kpiConfigsPath,
  kpiDateEntityColumnIdPath,
  kpiDateJoinPathPath,
  kpiExplanationPath,
  kpiFilterPath,
  kpiForwardReportPath,
  kpiIdPath,
  kpiMasterKpiTypePath,
  kpiNamePath,
  kpiOriginalExplanationPath,
  kpiOriginalNamePath,
  kpiSimpleSumMasterKpiTypesPath,
  kpiVisibilitySetManuallyPath,
  kpiVisiblePath
} from "js/app-areas/onboarding/onboarding-clients-config";

const entityTypeToEntity = Immutable.fromJS({
  ACTUALS: {label: "Actuals", type: "ACTUALS"},
  CALL: {label: "Calls", type: "CALL"},
  CANDIDATE: {label: "Candidates", type: "CANDIDATE"},
  CV_SENT: {label: "CVs Sent", type: "CV_SENT"},
  INTERVIEW: {label: "Interviews", type: "INTERVIEW"},
  JOB: {label: "Jobs", type: "JOB"},
  LEAD: {label: "Leads", type: "LEAD"},
  MEETING: {label: "Meetings", type: "MEETING"},
  OFFER: {label: "Offers", type: "OFFER"},
  OPPORTUNITY: {label: "Opportunities", type: "OPPORTUNITY"},
  CLIENT: {label: "Clients", type: "CLIENT"},
  CLIENT_CONTACT: {label: "Client Contacts", type: "CLIENT_CONTACT"},
  PLACEMENT: {label: "Placements", type: "PLACEMENT"}
});

const TextFieldWithRollback = ({
  title,
  disabled,
  value,
  rollbackValue,
  label,
  onChange,
  errorMessage,
  multiline = false
}) => {

  const labelLength = label.length * 4 + 15;
  const hasChanged = rollbackValue !== value;

  return <div style={{position: "relative"}}>
    {rollbackValue && <IconButton
        tooltip="Rollback to original value"
        disabled={!hasChanged || disabled}
        onClick={() => onChange(rollbackValue)}
        iconClassName="fa fa-rotate-left"
        style={{position: "absolute", left: labelLength, zIndex: 99}}
        iconStyle={{fontSize: "1rem"}}
    />}
    <TextField
        title={title}
        disabled={disabled}
        multiLine={multiline}
        value={value}
        style={{width: "100%"}}
        onChange={e => onChange(e.target.value)}
        errorText={errorMessage}
        floatingLabelText={label}
        floatingLabelFixed={true}
    />
  </div>;

};

const blankFilter = Immutable.fromJS({
  type: "CONDITION",
  joinPath: null,
  entityColumnId: null,
  operator: "IN",
  value: Immutable.List()
});

const operatorOptions = Immutable.fromJS([
  {label: "Include only", value: "IN"},
  {label: "Exclude", value: "NOT_IN"}]);

const TypeFilters = ({fieldConfig, filters, onChange, isErroring}) => {

  const addNewRow = () => {
    onChange(filters.push(blankFilter));
  };

  const removeRow = index => {
    onChange(filters.delete(index));
  };

  const availableTypeFields = fieldConfig.get("typeFields", Immutable.List());

  const entityColumnIdToTypeField = indexBy(typeField => typeField.get("entityColumnId"), availableTypeFields);

  const selectedEntityColumnIds = filters.map(config => config.get("entityColumnId"));
  const remainingTypeFieldOptions = availableTypeFields
      .filter(config => !selectedEntityColumnIds.includes(config.get("entityColumnId")))
      .map(config => Immutable.Map({value: config.get("entityColumnId"), label: config.get("label")}));

  const onChangeEntityColumnId = (entityColumnId, index) => {
    const typeField = entityColumnIdToTypeField.get(entityColumnId);
    onChange(filters.update(index, config => config
        .set("entityColumnId", entityColumnId)
        .set("value", Immutable.List())
        .set("joinPath", typeField.get("joinPath"))));
  };

  return <div>
    Type Filters
    <IconButton
        iconClassName="fa fa-plus"
        disabled={remainingTypeFieldOptions.isEmpty()}
        onClick={addNewRow}
        iconStyle={{color: remainingTypeFieldOptions.isEmpty() ? "lightgray" : "gray", fontSize: "1.3rem"}}
    />
    <div style={{display: "grid", gridTemplateColumns: "1fr auto 1fr auto", columnGap: "1rem"}}>
      <p>Filter By Field</p>
      <p>Include Only/Exclude</p>
      <p>Types</p>
      <p></p>
      {filters
          .filter(filter => !filter.get("entityColumnId") || entityColumnIdToTypeField.has(filter.get("entityColumnId")))
          .map((filter, index) => {
        const entityColumnId = filter.get("entityColumnId");
        const remainingTypeFieldOptionsWithSelectedValue = filter.get("entityColumnId")
            ? remainingTypeFieldOptions.push(
                Immutable.Map({
                  label: entityColumnIdToTypeField.getIn([entityColumnId, "label"], ""),
                  value: entityColumnId
                })
            )
            : remainingTypeFieldOptions;

        const selectedTypeField = entityColumnIdToTypeField.get(entityColumnId);
        const selectedTypeOptions = selectedTypeField
            ? entityColumnIdToTypeField
                .getIn([entityColumnId, "combinedOptions"], Immutable.List())
                .toSet()
                .union(filter.get("value"))
                .map(option => Immutable.Map({label: option, value: option}))
                .toList()
            : Immutable.List();

        return [
          <ImmutableSelect
              key={index + "entityColumn"}
              multi={false}
              clearable={false}
              options={remainingTypeFieldOptionsWithSelectedValue}
              style={{alignSelf: "center"}}
              selectedValue={entityColumnId}
              onChange={value => onChangeEntityColumnId(value, index)} />,
          <ImmutableSelect
              key={index + "operator"}
              multi={false}
              clearable={false}
              options={operatorOptions}
              style={{alignSelf: "center"}}
              selectedValue={filter.get("operator")}
              onChange={value => onChange(filters.setIn([index, "operator"], value))} />,
          <ImmutableSelect
              key={index + "value"}
              placeholder={"Type Options"}
              selectedValues={filter.get("value")}
              onChange={value => onChange(filters.setIn([index, "value"], value))}
              options={selectedTypeOptions}
              newValuesAllowed={selectedTypeField ? selectedTypeField.get("customValues") : false} />,
          <IconButton
              key={index + "remove"}
              iconClassName="fa fa-times"
              disabled={false}
              onClick={() => removeRow(index)}
              iconStyle={{color: "gray", fontSize: "1.3rem", marginBottom: 10}} />];
      })}
    </div>
    <div
        style={{color: "rgba(255, 122, 122, 1)", fontSize: "0.8rem", marginTop: 5, marginBottom: 10}}>
      {isErroring && "Please ensure all fields are filled for each filter row "}
    </div>
  </div>;
};

const MenuBar = ({selectedEntityType, setSelectedEntityType, entityToKpiConfigState}) => {
  return <div style={{borderRight: "1px solid grey"}}>
    <Menu disableAutoFocus={true}>
      {entityTypeToEntity.toList().sort().map(entity => {
        const entityType = entity.get("type");
        const isSelected = selectedEntityType === entityType;
        const isErroring = entityToKpiConfigState.getIn([entityType, "isErroring"]);

        const label = <div>
          {entity.get("label")}
          {isErroring && <i style={{color: "red", marginLeft: 8}} className={"fa fa-exclamation-triangle"} />}
        </div>;

        return <MenuItem
            key={entityType}
            style={isSelected ? selectedMenuItemStyle : {}}
            primaryText={label}
            onClick={() => setSelectedEntityType(entity.get("type"))} />;
      })}
    </Menu>
  </div>;
};

const selectedMenuItemStyle = {color: "red"};

const kpiSectionStyle = {
  backgroundColor: "lightgray",
  display: "flex",
  padding: 10,
  marginBottom: 20,
  borderRadius: 5,
  justifyContent: "space-between",
  alignItems: "center"
};

const labelStyle = {
  fontSize: "0.9rem",
  opacity: "0.9",
  marginBottom: 5
};

const KpiSection = ({
  onUpdateKpi,
  kpi,
  fieldConfig,
  id,
  isExpanded,
  onClick,
  onDuplicateKpiClick,
  onDeleteKpiClick,
  masterKpiTypeToKpiName,
  duplicateNames,
  isReconfiguring,
  isLocked
}) => {
  const dateFieldOptions = fieldConfig.get("dateFields", Immutable.List())
      .map(field => Immutable.Map({value: field, label: field.get("label")}));
  const dateFieldForKpi = dateFieldOptions
      .map(option => option.get("value"))
      .find(dateField => {
        return dateField.get("entityColumnId") === kpi.getIn(kpiDateEntityColumnIdPath)
            && Immutable.is(dateField.get("joinPath"), kpi.getIn(kpiDateJoinPathPath));
      });
  const masterKpiType = kpi.getIn(kpiMasterKpiTypePath);
  const isNew = (typeof kpi.getIn(kpiIdPath)) === "string";
  const combineWithMasterKpiType = kpi.getIn(kpiCombineWithMasterKpiTypePath);
  const isErroring = kpi.get("isErroring");
  const hasDuplicateName = duplicateNames.includes(kpi.getIn(kpiNamePath));

  const typeFilters = toTypeFilters(kpi.getIn(kpiFilterPath));

  const onTypeFilterChange = typeFilters => {
    onUpdateKpi(kpi => kpi.setIn(kpiFilterPath, toFilterParam(typeFilters)));
  };

  const incompleteFieldError = "Please complete this field";
  const duplicateNameError = "Duplicate name";
  let nameErrorMessage = null;
  if (!kpi.getIn(kpiNamePath)) {
    nameErrorMessage = incompleteFieldError;
  } else if (hasDuplicateName) {
    nameErrorMessage = duplicateNameError;
  }

  const isForwardReport = !!kpi.getIn(kpiForwardReportPath);
  const isSimpleSum = !!kpi.getIn(kpiSimpleSumMasterKpiTypesPath);
  const customDateSupported = kpi.getIn(kpiDateEntityColumnIdPath) || !(isForwardReport || isSimpleSum);
  const customTypeFiltersSupported = (kpi.getIn(kpiFilterPath) || !(isForwardReport || isSimpleSum)) &&
      !fieldConfig.get("typeFields", Immutable.List()).isEmpty();

  return <>
    <div
        onClick={() => onClick(id)}
        css={kpiSectionStyle}>
      <div style={{display: "flex", padding: 5}}>
        <div style={{padding: 5}}>{kpi.getIn(kpiNamePath)}</div>
        {isErroring && <i style={{color: "red", margin: "5px 8px 0"}} className={"fa fa-exclamation-triangle"} />}
        {combineWithMasterKpiType && <div
            style={{
              backgroundColor: "white",
              borderRadius: 5,
              padding: 5,
              marginLeft: 5
            }}>
          Inherits from {masterKpiTypeToKpiName.get(combineWithMasterKpiType)}
        </div>}
      </div>
      <div style={{display: "flex", fontSize: "0.9rem"}}>
        {!isLocked && <div onClick={e => e.stopPropagation()}>
          {(isNew && !masterKpiType) && <RaisedButton
              style={{width: 150, marginRight: 15, backgroundColor: "red"}}
              onClick={onDeleteKpiClick}
              label={"Delete"}
              secondary={true} />}
        </div>}
        {!isLocked && <div
            style={{display: "flex", width: 200, margin: 5}}
            onClick={e => e.stopPropagation()}>
          <Checkbox
              label="Show in app?"
              checked={kpi.getIn(kpiVisiblePath, true)}
              onCheck={(e, isChecked) => onUpdateKpi(kpi => kpi
                  .setIn(kpiVisiblePath, isChecked)
                  .setIn(kpiVisibilitySetManuallyPath, true))} />
        </div>}
        <div style={{display: "flex"}}>
          {!isLocked && <div onClick={e => e.stopPropagation()}>
            <RaisedButton
                style={{width: 150, marginRight: 15}}
                onClick={onDuplicateKpiClick}
                label={"Duplicate"}
                primary={true} />
          </div>}
          <i
              className={isExpanded ? "fa fa-angle-up" : "fa fa-angle-down"}
              style={{paddingRight: 8, paddingTop: 8, fontSize: "1.4rem", cursor: "pointer"}} />
        </div>
      </div>
    </div>
    {isExpanded && <div style={{padding: "0 30px 20px 20px", pointerEvents: isLocked && "none"}}>
      <div style={{display: "flex"}}>
        <div style={{display: "flex", flexDirection: "column", flexGrow: 3}}>
          <TextFieldWithRollback
              title={!isNew && isReconfiguring && "Must be edited in the app."}
              disabled={!isNew && isReconfiguring}
              errorMessage={nameErrorMessage}
              value={kpi.getIn(kpiNamePath)}
              rollbackValue={kpi.getIn(kpiOriginalNamePath)}
              label="Name"
              onChange={value => onUpdateKpi(kpi => kpi.setIn(kpiNamePath, value))} />
          <TextFieldWithRollback
              title={!isNew && isReconfiguring && "Must be edited in the app."}
              disabled={!isNew && isReconfiguring}
              value={kpi.getIn(kpiExplanationPath)}
              rollbackValue={kpi.getIn(kpiOriginalExplanationPath)}
              label="Explanation"
              multiline={true}
              onChange={value => onUpdateKpi(kpi => kpi.setIn(kpiExplanationPath, value))} />
        </div>
        {customDateSupported && <div style={{flexGrow: 1, paddingLeft: 50, marginBottom: 10, minWidth: 240}}>
          <div style={labelStyle}>
            Date field
            {!kpi.getIn(kpiDateEntityColumnIdPath) && <span
                style={{
                  color: "red",
                  marginLeft: 10
                }}>Required field</span>}
          </div>
          <ImmutableSelect
              clearable={false}
              multi={false}
              selectedValue={dateFieldForKpi}
              onChange={dateField => onUpdateKpi(kpi => kpi
                  .setIn(kpiDateEntityColumnIdPath, dateField.get("entityColumnId"))
                  .setIn(kpiDateJoinPathPath, dateField.get("joinPath")))}
              options={dateFieldOptions} />
        </div>}
      </div>
      {customTypeFiltersSupported && <TypeFilters
          fieldConfig={fieldConfig}
          onChange={onTypeFilterChange}
          filters={typeFilters}
          isErroring={areTypeFiltersErroring(typeFilters)} />}
    </div>}
  </>;
};

const KpiList = ({fieldConfig, kpis, onUpdateKpis, duplicateNames, isReconfiguring, isLocked}) => {

  const [expandedKpis, setExpandedKpis] = React.useState(Immutable.List());
  const masterKpiTypeToKpiName = indexBy(kpiConfig => kpiConfig.getIn(kpiMasterKpiTypePath), kpis)
      .map(kpi => kpi.getIn(kpiNamePath));

  const onKpiClick = id => {
    if (expandedKpis.includes(id)) {
      setExpandedKpis(expandedKpis.delete(expandedKpis.indexOf(id)));
    } else {
      setExpandedKpis(expandedKpis.push(id));
    }
  };

  return <>
    {kpis && !kpis.isEmpty()
        ? kpis.map((kpi, index) => {

          const handleUpdateKpi = updateFn => {
            onUpdateKpis(kpis => kpis.update(index, updateFn));
          };

          const handleDuplicateKpiClick = () => onUpdateKpis(kpis => {
            return kpis
                .insert(index + 1, kpi
                    .updateIn(kpiNamePath, name => "Duplicate of " + name)
                    .setIn(kpiIdPath, uuidv4())
                    .deleteIn(kpiMasterKpiTypePath));
          });

          const handleDeleteKpiClick = () => onUpdateKpis(kpis => kpis.delete(index));

          return <KpiSection
              key={index}
              kpi={kpi}
              fieldConfig={fieldConfig}
              onUpdateKpi={handleUpdateKpi}
              id={kpi.getIn(kpiIdPath)}
              onClick={onKpiClick}
              isExpanded={expandedKpis.includes(kpi.getIn(kpiIdPath))}
              masterKpiTypeToKpiName={masterKpiTypeToKpiName}
              onDuplicateKpiClick={handleDuplicateKpiClick}
              onDeleteKpiClick={handleDeleteKpiClick}
              duplicateNames={duplicateNames}
              isReconfiguring={isReconfiguring}
              isLocked={isLocked} />;
        })
        : <div style={{paddingLeft: 20}}>No kpis for selected entity</div>}
  </>;
};

const KpiEditor = ({entityFieldConfig, entityToKpiConfigsState, onEntityKpiConfigChange, isReconfiguring, isLocked, isLoading}) => {

  const [selectedEntityType, setSelectedEntityType] = React.useState("ACTUALS");

  const handleUpdateKpis = updateFn => {
    onEntityKpiConfigChange(
        entityToKpiConfigsState => {
          return entityToKpiConfigsState.updateIn([selectedEntityType, ...kpiConfigsPath], updateFn);
        });
  };

  const kpiConfigs = entityToKpiConfigsState
      .map(kpiConfigState => kpiConfigState.getIn(kpiConfigsPath))
      .valueSeq()
      .flatten(true);

  const duplicateNames = kpiConfigs
      .countBy(kpi => kpi.getIn(kpiNamePath))
      .filter(count => count > 1)
      .keySeq();

  return <Paper style={{padding: "10px 30px 20px 30px", marginBottom: 20}}>
    <div style={{display: "flex", justifyContent: "space-between"}}>
      <h3>Metric Editor</h3>
    </div>
    <div style={{display: "flex", height: "80vh"}}>
      <MenuBar
          entityToKpiConfigState={entityToKpiConfigsState}
          setSelectedEntityType={setSelectedEntityType}
          selectedEntityType={selectedEntityType} />
      <div style={{margin: "0 20px", width: "100%", overflow: "auto"}}>
        {isLoading ? <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 30
            }}>
          <CircularProgress />
        </div> :
        <KpiList
            onUpdateKpis={handleUpdateKpis}
            fieldConfig={entityFieldConfig.get(selectedEntityType, Immutable.Map())}
            kpis={entityToKpiConfigsState.getIn([selectedEntityType, ...kpiConfigsPath], Immutable.List())}
            isErroring={entityToKpiConfigsState.getIn([selectedEntityType, "isErroring"])}
            duplicateNames={duplicateNames}
            isReconfiguring={isReconfiguring}
            isLocked={isLocked} />}
      </div>
    </div>
  </Paper>;
};

export default KpiEditor;
import * as Fetch from "js/fetch";
import * as Immutable from "immutable";

const load = cube19ClientId => Fetch
    .getJson("client/bulk-schedule", { "cube19-client-id": cube19ClientId})
    .then(res => Immutable.fromJS(res));


export {
  load
};

/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Immutable from "immutable";
import tinycolor from "tinycolor2";
import pure from "js/pure";

const AccordionContainer = createReactClass({
	mixins: [PureRenderMixin],

	getDefaultProps() {
		return {
			stateless: false,
			expandAll: false,
			lazyRender: true,
			allowOnlyOneOpen: true,
			initialOpenIndex: null
		};
	},

	getInitialState() {
		return {
			openIndexes: this.props.expandAll ? getIndexesForChildren(this.props.children) : Immutable.Set.of(this.props.initialOpenIndex),
			previouslyOpenedIndexes: Immutable.Set()
		};
	},

	// TODO this should be a controlled component with an uncontrolled wrapper
	componentWillReceiveProps(nextProps) {
		if (this.props.expandAll !== nextProps.expandAll) {
			if (nextProps.expandAll) {
				this.setState({openIndexes: getIndexesForChildren(nextProps.children)});
			} else {
				this.setState({openIndexes: Immutable.Set()});
			}
		}
	},

	render() {
		const {
			children,
			lazyRender,
			stateless,
			style
		} = this.props;
		const {
			openIndexes,
			previouslyOpenedIndexes
		} = this.state;
		return (
			<div style={style}>
				{React.Children.map(children, (child, index) => {
					if (!child) {
						return null;
					}

					const props = {
						key: index,
						isOpen: openIndexes.contains(index),
						onClick: () => {
							this.handleClick(index);
							if (child.props.onClick) {
								child.props.onClick(!openIndexes.contains(index));
							}
						},
						shouldRender: !stateless && (!lazyRender || previouslyOpenedIndexes.contains(index))
					};
					return React.cloneElement(child, props);
				})}
			</div>);
	},

	handleClick(index) {
		const {openIndexes} = this.state;
		const isAlreadyOpen = openIndexes.contains(index);

		let newOpenIndexes;
		if (isAlreadyOpen) {
			newOpenIndexes = openIndexes.remove(index);
		} else if (this.props.allowOnlyOneOpen) {
			newOpenIndexes = Immutable.Set.of(index);
		} else {
			newOpenIndexes = openIndexes.add(index)
		}
		this.setState({
			openIndexes: newOpenIndexes,
			previouslyOpenedIndexes: this.state.previouslyOpenedIndexes.add(index)
		});
	}

});

const getIndexesForChildren = children => Immutable.Set(React.Children.map(children, (_, index) => index));

const AccordionSection = pure(({
	title,
	titleElement,
	children,
	isOpen,
	shouldRender,
	onClick,
	showArrows,
	containerStyle = {},
	titleStyle = {},
	activeTitleStyle = {},
	contentStyle = {},
}) => (
	<div style={{ borderColor: "#e3e3e3", borderWidth: 1, borderStyle: "solid", ...containerStyle }}>
		<TitleHeader
			title={title}
      titleElement={titleElement}
			onClick={onClick}
			isActive={isOpen}
			style={titleStyle}
			activeStyle={activeTitleStyle}
			showArrows={showArrows} />
		<div style={{ display: isOpen ? "block" : "none", ...contentStyle }}>
			{(isOpen || shouldRender) && children}
		</div>
	</div>));

const TitleHeader = pure(({
	title,
	titleElement,
	onClick,
	isActive,
	style = {},
	activeStyle = {},
	showArrows = false
}) => {
	const finalStyle = {
		...defaultTitleStyle,
		...style
	};
	finalStyle[":hover"] =  {
		backgroundColor: tinycolor(finalStyle.backgroundColor).darken(15).toHexString()
	};
	const finalActiveStyle = {
		...finalStyle,
		...activeStyle
	};

	const icon = isActive ? "fa fa-chevron-up" : "fa fa-chevron-down";

	return <div css={isActive ? finalActiveStyle : finalStyle} onClick={onClick}>
		{titleElement ? titleElement : <span>{title}</span>}
		{showArrows && <i className={icon} style={{ fontSize: 16, float: "right" }} />}
	</div>;
});

const defaultTitleStyle = {
	cursor: "pointer",
	backgroundColor: "#eed",
	marginTop: 0,
	marginBottom: 0,
	padding: "0.5rem"
};

export {
	AccordionSection,
	AccordionContainer
};

import React from "react";
import createReactClass from "create-react-class";
import Modal from "react-modal";
import Immutable from "immutable";
import moment from "moment";
import {DatePicker, RaisedButton, Toggle} from "material-ui";

import * as fetch from "js/fetch";
import LoadingSpinner from "js/components/loading-spinner";
import DataTable from "js/components/data-table";
import {CurrentUserContext} from "js/data/contexts";


const spacingStyle = {display: "inline-block", marginRight: "1rem"};

const Page = createReactClass({

  getInitialState() {
    return {
      date: moment().startOf("month"),
      clientIdToUsers: Immutable.Map(),
      includeTestClients: false
    };
  },

  componentDidMount() {
    this.loadAndSetUsers();
  },

  render() {
    const {
      date,
      includeTestClients,
      clientIdToUsers,
      isLoading,
      isModalDisplaying
    } = this.state;
    const columns = ["Client ID", "Client Name", "Bullhorn Corp Id", "Bullhorn SF Id", "Is Test", "# Chargeable Users"];
    const displayRunUserTracking = this.props.hasSalesforcePermission;
    const displaySendToSalesforce = this.props.hasSalesforcePermission && date.date() === 1;
    const rows = clientIdToUsers
        .keySeq()
        .map(clientId => {
          const clientRows = clientIdToUsers.get(clientId);
          const firstRow = clientRows.first();
          return [
            clientId,
            firstRow.get("client-name"),
            firstRow.get("bullhorn-corp-id"),
            firstRow.get("bullhorn-sf-id"),
            firstRow.get("is-test"),
            clientRows.count()];
        })
        .toArray();
    return <div style={{padding: "1rem"}}>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <div>
          <DatePicker
              style={spacingStyle}
              autoOk={true}
              floatingLabelText="Date"
              container="inline"
              value={date.toDate()}
              onChange={(e, date) => {
                this.setState(
                    {date: moment(date)},
                    () => this.loadAndSetUsers());
              }} />
          <Toggle
              style={{width: 200, marginRight: "1rem", display: "inline-block"}}
              toggled={includeTestClients}
              label={"Include test clients?"}
              onToggle={(_, newVal) => this.setState({includeTestClients: newVal}, () => this.loadAndSetUsers())} />
        </div>
        <div style={{marginTop: "1.5rem"}}>
          {displayRunUserTracking && <RaisedButton
              label={"Rerun user tracking"}
              onClick={() => runUserTracking()}
              style={{margin: "0.5rem"}} />}
          {displaySendToSalesforce && <RaisedButton
              label={"Resend to Salesforce"}
              onClick={() => sendToSalesforce(date)}
              style={{margin: "0.5rem"}} />}
        </div>
      </div>
      {isLoading
          ? <LoadingSpinner />
          : <DataTable
              rows={rows}
              columns={columns}
              downloadable={true}
              filenameForDownload="UserNumbers.csv"
              onCellClick={(displayValue, row) => this.setState({
                isModalDisplaying: true,
                modalClientId: row[0].originalValue
              })}
          />}
      {isModalDisplaying && this.renderModal()}
    </div>;
  },

  renderModal() {
    const {
      isModalDisplaying,
      modalClientId,
      clientIdToUsers
    } = this.state;
    const users = clientIdToUsers.get(modalClientId);
    const clientName = users.first().get("client-name");
    const columns = ["Username", "User email", "Full name"];
    const rows = users
        .map(user => [user.get("username"), user.get("user-email"), user.get("user-full-name")])
        .toArray();
    return (
        <Modal
            isOpen={isModalDisplaying}
            onRequestClose={() => this.setState({isModalDisplaying: false})}
            style={{overlay: {zIndex: 9999}}}
            contentLabel="Modal">
          <div className="clearfix" style={{marginBottom: "0.5rem"}}>
            <h1 style={headerStyle}>Chargeable Users - {clientName}</h1>
            <button style={closeButtonStyle} onClick={() => this.setState({isModalDisplaying: false})}>X</button>
          </div>
          <div style={{height: "auto", width: "100%"}}>
            <DataTable
                columns={columns}
                rows={rows}
                downloadable={true}
                filenameForDownload={`ChargeableUsers-${modalClientId}.csv`}
            />
          </div>
        </Modal>
    );
  },

  loadAndSetUsers() {
    this.setState({isLoading: true});
    const {date, includeTestClients} = this.state;
    loadUsers(date, includeTestClients).then(users => {
      this.setState({
        clientIdToUsers: users.groupBy(user => user.get("client-id")),
        isLoading: false
      });
    });
  }

});

export const loadUsers = (date, includeTestClients) => fetch
    .getJson("chargeable-users", {date: date.format("YYYY-MM-DD"), includeTestClients})
    .then(users => Immutable.fromJS(users));

const sendToSalesforce = (date) => fetch
    .post("chargeable-users/send", {date: date.format("YYYY-MM-DD")});

const runUserTracking = () => fetch
    .post("chargeable-users/user-tracking");

const headerStyle = {
  float: "left",
  margin: 0,
  width: "96%",
  fontSize: "1.35rem"
};

const closeButtonStyle = {
  float: "right",
  fontWeight: "bold",
  cursor: "pointer"
};

const ConnectedPage = props => {
  const {currentUser} = React.useContext(CurrentUserContext);
  return <Page {...props} hasSalesforcePermission={currentUser.get("permissions").includes("SALESFORCE")} />;
};

export default ConnectedPage;

import React from "react";
import createReactClass from "create-react-class";
import Radium from "radium";

export default Radium(createReactClass({

    getDefaultProps() {
        return {
            isDisabled: false,
            type: "basicSignIn"
        };
    },

    render() {
        const { label, isDisabled, onClick, type, customStyle } = this.props;
        const style = {
            cursor: isDisabled ? "not-allowed" : "pointer",
            ...buttonStyle,
            ...buttonColorsByType[type],
            ...customStyle
        };
        return (
            <div id={type} style={style} onClick={isDisabled ? () => {} : onClick}>
                <span>
                    {type === "googleSignIn" && <i className="fa fa-google" style={{ paddingRight: 8 }} />}
                    {label}
                </span>
            </div>
        );
    }

}));

const buttonColorsByType = {
    basicSignIn: {
        color: "#333",
        backgroundColor: "#bada55",

        ":hover": {
            backgroundColor: "#c0ff33"
        }
    },
    googleSignIn: {
        color: "#fff",
        backgroundColor: "#4285f4",

        ":hover": {
            backgroundColor: "#1b6ffb"
        }
    }
};

const buttonStyle = {
    textTransform: "uppercase",
    display: "inline-block",
    height: "36px",
    padding: "7px 15px",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    boxShadow: "2px 2px 4px #ccc",
    transitionTimingFunction: "ease-in-out"
};

/** @jsxImportSource @emotion/react */

import "pivottable/dist/pivot.css";
import "jquery-ui/ui/widgets/sortable";
import "pivottable";

import createReactClass from "create-react-class";
import ReactDOM from "react-dom";
import Immutable from "immutable";
import $ from "jquery";
import {css, Global} from "@emotion/react";

window.jQuery = $;
require("lib/nreco-pivot-table-extensions");

export default createReactClass({

  componentDidMount() {
    this.renderPivotUI();
  },

  render() {
    return (
        <div>
          <Global
              styles={css`
                .hide-pivot-totals thead > tr > th.pvtTotalLabel,
                .hide-pivot-totals thead > tr > td.rowTotal,
                .hide-pivot-totals thead > tr > td.colTotal,
                .hide-pivot-totals thead > tr > td.pvtGrandTotal,
                .hide-pivot-totals tbody > tr > th.pvtTotalLabel,
                .hide-pivot-totals tbody > tr > td.rowTotal,
                .hide-pivot-totals tbody > tr > td.colTotal,
                .hide-pivot-totals tbody > tr > td.pvtGrandTotal {
                  display: none;
                }

                .pvtUi {
                  padding: 0.5rem;
                }

                .pvtSearch {
                  margin-bottom: 1rem;
                }

                .pvtFilterBox button {
                  margin: 0 0.5rem;
                }
              `} />
          <div className={(this.props.showTotals ? "" : "hide-pivot-totals") + " pivot-container"} />
        </div>);
  },

  renderPivotUI() {
    customizePivotTableAggregators();

    const {config, data, onCellClick, onRefresh} = this.props;
    const pivotTableExtension = new window.NRecoPivotTableExtensions({
      drillDownHandler: onCellClick
    });
    const TABLE = "Table";
    const TABLE_BARCHART = "Table Barchart";
    const HEATMAP = "Heatmap";
    const ROW_HEATMAP = "Row Heatmap";
    const COL_HEATMAP = "Col Heatmap";
    let renderers = $.extend({}, $.pivotUtilities.renderers);
    renderers[TABLE] = pivotTableExtension.wrapTableRenderer(renderers[TABLE]);
    renderers[TABLE_BARCHART] = pivotTableExtension.wrapTableRenderer(renderers[TABLE_BARCHART]);
    renderers[HEATMAP] = pivotTableExtension.wrapTableRenderer(renderers[HEATMAP]);
    renderers[ROW_HEATMAP] = pivotTableExtension.wrapTableRenderer(renderers[ROW_HEATMAP]);
    renderers[COL_HEATMAP] = pivotTableExtension.wrapTableRenderer(renderers[COL_HEATMAP]);

    const options = {
      renderers,
      autoSortUnusedAttrs: true,
      unusedAttrsVertical: false,
      menuLimit: 9999,
      onRefresh: config => {
        const newConfig = Immutable
            .fromJS(config)
            .delete("sorters")          // delete some values which are functions
            .delete("rendererOptions")  // delete some bulky default values
            .delete("renderers")
            .delete("aggregators")
            .delete("localeStrings")
            .delete("colOrder")
            .delete("rowOrder")
            .delete("hiddenAttributes")
            .delete("hiddenFromAggregators")
            .delete("hiddenFromDragDrop");
        onRefresh(newConfig);
      },
      ...config.toJS()
    };
    const $pivotTable = $(ReactDOM.findDOMNode(this)).find(".pivot-container");
    $pivotTable.pivotUI(data, options);
    this.customizePivotTableUI();
  },

  customizePivotTableUI() {
    const $pivotTable = $(ReactDOM.findDOMNode(this)).find(".pivot-container");
    $pivotTable.find("td.pvtCols").prepend("<h4>Columns</h4>");
    $pivotTable.find("td.pvtRows").prepend("<h4>Rows</h4>");
  }

});

const customizePivotTableAggregators = () => {
  const aggregatorsToRemove = [
    "Sum",
    "Sum over Sum",
    "80% Upper Bound",
    "80% Lower Bound",
    "Sum as Fraction of Rows",
    "Sum as Fraction of Columns",
    "Count as Fraction of Rows",
    "Count as Fraction of Columns"
  ];
  aggregatorsToRemove.forEach(name => {
    delete $.pivotUtilities.aggregators[name];
  });

  const aggregatorNameMap = {
    "Count": "Count All",
    "Integer Sum": "Sum (to Whole Numbers)"
  };
  Object.keys(aggregatorNameMap).forEach(key => {
    const oldName = key;
    const newName = aggregatorNameMap[key];
    if (!$.pivotUtilities.aggregators[oldName]) {
      return;
    }
    $.pivotUtilities.aggregators[newName] = $.pivotUtilities.aggregators[oldName];
    delete $.pivotUtilities.aggregators[oldName];
  });
};

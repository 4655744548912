import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import moment from "moment";

import {Success, Err} from "js/components/notification";
import TextField from "material-ui/TextField";
import NumberField from "js/components/number-field";
import RaisedButton from "material-ui/RaisedButton";
import Checkbox from "material-ui/Checkbox";
import LoadingSpinner from "js/components/loading-spinner";

import * as fetch from "js/fetch";
import {ClientsContext, SelectedClientIdContext} from "js/data/contexts";
import {getErrorMessage} from "js/utils/errors";

const buttonMargins = {marginTop: "1rem", marginRight: "1rem"};
const textFieldMargins = {marginRight: "1rem"};

const Form = createReactClass({

  getInitialState() {
    return {
      snoozeMinutes: 0,
      loading: false,
      submitting: false,
      etlJob: null,
      errorMessage: null,
      comment: ""
    };
  },

  componentDidMount() {
    this.loadClientEtlJobToState();
  },

  loadClientEtlJobToState() {
    const {client} = this.props;
    this.setState({loading: true, errorMessage: null});
    loadEtlJobForClient(client.get("id"))
        .then(
            etlJob => this.setState({etlJob, loading: false}),
            error => getErrorMessage(error)
                .then(message => this.setState({errorMessage: message, loading: false})));
  },

  render() {
    const {loading, etlJob, errorMessage} = this.state;
    const error = errorMessage ?? "Something went wrong";
    if (loading) {
      return <LoadingSpinner />;
    } else if (etlJob) {
      return this.renderForm();
    } else {
      return <p style={{marginLeft: "1rem"}}>{error}</p>;
    }
  },

  renderForm() {
    const client = this.props.client;
    const {
      snoozeMinutes,
      comment,
      etlJob,
      loading,
      submitting
    } = this.state;

    const isMetadataValid = isValidJson(etlJob.get("metadata"));
    const commentPrefix = `Change delta job for ${client.get("internal_name")} via UI: `;
    return (
        <div style={{padding: "1rem"}}>
          <div style={{display: "inline-block"}}>
            <div style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>
              <label>ID:</label> {etlJob.get("id")}
            </div>
            <div style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>
              <label>Source:</label> {etlJob.get("crm")}
            </div>
            <div style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>
              <label>Last Ran :</label> {moment(etlJob.get("last-run-timestamp")).format("DD/MM/YYYY k:mm:ss")}
            </div>
          </div>
          <Checkbox
              label="Enabled"
              checked={etlJob.get("enabled")}
              onCheck={(e, value) => this.setJobField("enabled", value)} />
          <Checkbox
              label="Track dropped events (expensive)"
              checked={etlJob.get("track-dropped-events")}
              onCheck={(e, value) => this.setJobField("track-dropped-events", value)} />
          <Checkbox
              label="No Data Alerts Enabled"
              checked={etlJob.get("no-data-alerts-enabled")}
              onCheck={(e, value) => this.setJobField("no-data-alerts-enabled", value)} />
          <TextField
              style={textFieldMargins}
              floatingLabelText="No Data Period"
              value={etlJob.get("no-data-period") || ""}
              onChange={e => this.setJobField("no-data-period", e.target.value)} />
          <TextField
              floatingLabelText="Metadata"
              value={etlJob.get("metadata")}
              multiLine={true}
              fullWidth={true}
              onChange={e => this.setJobField("metadata", e.target.value)}
              errorText={!isMetadataValid && "The metadata is not valid JSON"} />
          <div style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>
            <label>Snooze Alerts Until :</label> {moment(etlJob.get("snooze-alerts-until"))
              .format("DD/MM/YYYY k:mm:ss")}
            <br/>
            <label>Snooze Run Until :</label> {moment(etlJob.get("snooze-run-until"))
              .format("DD/MM/YYYY k:mm:ss")}
          </div>
          <div>
            <NumberField
                style={textFieldMargins}
                floatingLabelText="Minutes to snooze for"
                value={snoozeMinutes}
                onChange={snoozeMinutes => this.setState({snoozeMinutes})} />
          </div>
          <NumberField
              style={textFieldMargins}
              floatingLabelText="Pickup Delay"
              value={etlJob.get("pickup-delay-seconds")}
              onChange={pickup => this.setJobField("pickup-delay-seconds", pickup)}
              range={[0]} />
          <TextField
              floatingLabelText="Comment"
              value={commentPrefix + comment}
              multiLine={true}
              fullWidth={true}
              onChange={e => this.setState({comment: e.target.value.substring(commentPrefix.length)})}
              errorText={comment.length === 0 && "A reason for this change is required"} />
          <div style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>
            <RaisedButton
                label="Cancel"
                style={buttonMargins}
                onClick={() => {
                  this.setState({...this.getInitialState()});
                  this.loadClientEtlJobToState();
                }} />
            <RaisedButton
                primary={true}
                style={buttonMargins}
                label="Update"
                disabled={submitting || loading || !comment || !isMetadataValid}
                onClick={() => {
                  this.setState({submitting: true});
                  updateEtlJob(this.state.etlJob, commentPrefix + comment, snoozeMinutes).then(
                      () => {
                        this.props.onRequestReload();
                        this.setState({
                          submitting: false,
                          snoozeMinutes: 0,
                          comment: "",
                          successMessage: "ETL Job updated"
                        });
                        this.loadClientEtlJobToState();
                      },
                      (error) => {
                        this.props.onRequestReload();
                        error.response
                            .json()
                            .then(response => this.setState({errorMessage: response.message, submitting: false}));
                      });
                }} />
          </div>
          <Success message={this.state.successMessage} onRequestClose={() => this.setState({successMessage: null})} />
          <Err message={this.state.errorMessage} onRequestClose={() => this.setState({errorMessage: null})} />
        </div>);
  },

  setJobField(field, value) {
    this.setState({etlJob: this.state.etlJob.set(field, value)});
  }

});

const loadEtlJobForClient = clientId => fetch
    .getJson("etl2-delta-job", {"client-id": clientId})
    .then(response => Immutable.fromJS(response));

const updateEtlJob = (
    etlJob,
    comment,
    snoozeMinutes
) => fetch.post("etl2-delta-job", {comment, snoozeMinutes, ...etlJob.toJS()});

const isValidJson = str => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

const ConnectedForm = props => {
  const {idToClient} = React.useContext(ClientsContext);
  const {selectedClientId} = React.useContext(SelectedClientIdContext);
  return <Form {...props} client={idToClient.get(selectedClientId)} />;
};

export default ConnectedForm;

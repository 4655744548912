import Immutable from "immutable";

import createReducer from "js/create-reducer";

const REFRESH = "REFRESH_CLIENT_METRICS_PIVOT_TABLE_CONFIG";
const TOGGLE_SHOW_TOTALS = "TOGGLE_SHOW_TOTALS_FOR_CLIENT_METRICS";

const initialState = Immutable.Map({
    showTotals: false,
    options: Immutable.Map({
        aggregatorName: "List Unique Values",
        vals: [ "Value" ],
        rows: [
            "Client",
            "Client's Metric Name",
            "Metric Template",
            "Metric Template Type",
            "Metric Template Sub-Type",
            "Default Metric Name",
            "Client Filter",
            "Trendable",
            "# Visible Sections",
            "Summary Action",
            "Report Action",
            "Value",
            "Config - Filter",
            "Config - Status Filter",
            "Config - Owner",
            "Config - Override Date Column"
        ]
    })
});

const clientMetricsPivotTableConfig = createReducer(initialState, {
    [REFRESH]: (state, action) => state.set("options", action.options),
    [TOGGLE_SHOW_TOTALS]: (state, action) => state.set("showTotals", action.showTotals)
});

// ACTIONS

const toggleShowTotals = showTotals => ({
    type: TOGGLE_SHOW_TOTALS,
    showTotals
});

const onPivotTableRefresh = options => ({
    type: REFRESH,
    options
});

export default clientMetricsPivotTableConfig;
export {
    toggleShowTotals,
    onPivotTableRefresh
};

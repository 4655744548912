const SANDBOX_CLIENTS = ["Sandbox 2.0"];
const DEMO_CLIENTS = ["Bullhorn Demo", "cube19 Demo", "Demo System", "US Demo"];
const TEST_CLIENTS = ["iKas Test", "RDB Cube19 Test"];

const getC19TestClients = () => {
    return new Set([
        ...SANDBOX_CLIENTS,
        ...DEMO_CLIENTS,
        ...TEST_CLIENTS
    ]);
};

export default getC19TestClients;

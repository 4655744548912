import React from "react";
import {Tab, Tabs} from "material-ui";
import {connect} from "react-redux";

import ResultsSection from "js/app-areas/functional-test/results-section";
import TestsSection from "js/app-areas/functional-test/tests-section";
import pure from "js/pure";

const Page = pure(() => {
  return (
      <div>
        <Tabs tabItemContainerStyle={tabItemContainerStyle} inkBarStyle={inkBarStyle}>
          <Tab buttonStyle={buttonStyle} label={"Tests"}>
            <TestsSection />
          </Tab>
          <Tab buttonStyle={buttonStyle} label={"Results"}>
            <ResultsSection />
          </Tab>
        </Tabs>
      </div>
  );
});

const tabItemContainerStyle = {backgroundColor: "#f5f5f5", borderBottom: "1px solid #e5e5e5"};
const inkBarStyle = {backgroundColor: "#222"};
const buttonStyle = {color: "black"};

export const baseEndpoint = "functional-tests";

export default connect()(Page);

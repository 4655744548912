import React from "react";
import Immutable from "immutable";
import {Checkbox, RaisedButton, TextField} from "material-ui";

const nameMatches = (filterText, name) => filterText && name.indexOf(filterText) !== -1;

const setMatches = (filterText, set, setToPermissions) => {
  const permissionsMatch = setToPermissions
      .get(set, Immutable.List())
      .some(permission => permission.indexOf(filterText) !== -1);
  return nameMatches(filterText, set) || permissionsMatch;
};

const getSetsToShow = (filterText, rootSet, setToPermissions, parentSetToChildSets) => {
  const rootMatches = setMatches(filterText, rootSet, setToPermissions);

  const children = parentSetToChildSets.get(rootSet, Immutable.List());
  const childrenToShow = children
      .flatMap(childSet => getSetsToShow(filterText, childSet, setToPermissions, parentSetToChildSets))
      .toSet();

  const showRoot = rootMatches || !childrenToShow.isEmpty();

  if (showRoot) {
    return childrenToShow.add(rootSet);
  } else {
    return childrenToShow;
  }
};

export default React.memo(({
  parameter,
  paramNameToValue,
  dropdownNameToData,
  label,
  value,
  onChange,
  disabled
}) => {
  const [showEverything, setShowEverything] = React.useState(false);

  const parentSetToChildSets = React.useMemo(
      () => dropdownNameToData
          .get("console-permission-set-has-set")
          .map(x => {
            const [parent, child] = x.get("value").split(":");
            return Immutable.Map({parent, child});
          })
          .groupBy(x => x.get("parent"))
          .map(xs => xs.map(x => x.get("child"))),
      [dropdownNameToData]);

  const sets = React.useMemo(
      () => dropdownNameToData
          .get("console-permission-set")
          .map(x => x.get("value")),
      [dropdownNameToData]);

  const setToPermissions = React.useMemo(
      () => dropdownNameToData
          .get("console-permission-set-has-permission")
          .map(x => {
            const [set, permission] = x.get("value").split(":");
            return Immutable.Map({set, permission});
          })
          .groupBy(x => x.get("set"))
          .map(xs => xs.map(x => x.get("permission"))),
      [dropdownNameToData]);

  const setToLoginExtras = React.useMemo(
      () => dropdownNameToData
          .get("console-permission-set-has-login-extras")
          .map(x => {
            const [set, type, value] = x.get("value").split(":");
            return Immutable.Map({set, type, value});
          })
          .groupBy(x => x.get("set"))
          .map(xs => xs.groupBy(x => x.get("type")).map(items => items.map(item => item.get("value")))),
      [dropdownNameToData]);

  const rootSets = React.useMemo(() => {
    if (showEverything) {
      const allChildren = parentSetToChildSets.valueSeq().flatMap(x => x);
      return sets.toSet().subtract(allChildren.toSet());
    } else {
      return null;
    }
  }, [sets, parentSetToChildSets, showEverything]);

  let currentSets;
  if (showEverything) {
    currentSets = rootSets;
  } else if (value.indexOf("field:") === 0) {
    const fieldNameForValue = value.split(":")[1];
    const setStr = paramNameToValue.get(fieldNameForValue) || "";
    currentSets = setStr ? Immutable.List(setStr.split(",")) : Immutable.List();
  } else {
    currentSets = value ? Immutable.List(value.split(",")) : Immutable.List();
  }

  const [filterText, setFilterText] = React.useState("");
  const upperFilterText = filterText.toUpperCase();
  const setsToShow = React.useMemo(() => {
    if (upperFilterText) {
      return currentSets
          .flatMap(set => getSetsToShow(upperFilterText, set, setToPermissions, parentSetToChildSets))
          .toSet();
    } else {
      return sets.toSet();
    }
  }, [upperFilterText, sets, setToPermissions, currentSets, parentSetToChildSets]);

  const [collapsedSets, setCollapsedSets] = React.useState(Immutable.Set());

  const toggleCollapsed = React.useCallback(set => {
    setCollapsedSets(collapsedSets => {
      const isCollapsed = collapsedSets.includes(set);
      return isCollapsed
          ? collapsedSets.delete(set)
          : collapsedSets.add(set);
    });
  }, []);

  return <div>
    <TextField floatingLabelText="Search..." value={filterText} onChange={e => setFilterText(e.target.value)} />
    <RaisedButton label="Expand All" style={{marginLeft: "1rem"}} onClick={() => {
      setCollapsedSets(Immutable.Set());
    }} />
    <RaisedButton label="Collapse All" style={{marginLeft: "1rem"}} onClick={() => {
      if (currentSets.count() === 1) {
        setCollapsedSets(parentSetToChildSets.get(currentSets.first()).toSet());
      } else {
        setCollapsedSets(currentSets.toSet());
      }
    }} />
    <Checkbox
        style={{marginLeft: "1rem", display: "inline-block", width: 200, verticalAlign: "middle"}}
        label="Show all sets"
        checked={showEverything}
        onCheck={(e, flag) => setShowEverything(flag)} />
    {currentSets
        .sortBy(s => s)
        .filter(s => setsToShow.includes(s))
        .map(set => <div key={set} style={{marginTop: "1rem"}}>
          <SetTree
              rootSet={set}
              collapsedSets={collapsedSets}
              toggleCollapsed={toggleCollapsed}
              parentSetToChildSets={parentSetToChildSets}
              setToPermissions={setToPermissions}
              setToLoginExtras={setToLoginExtras}
              setsToShow={setsToShow}
              filterText={upperFilterText} />
        </div>)}
  </div>;
});

const SetTree = React.memo(({
  rootSet,
  collapsedSets,
  toggleCollapsed,
  parentSetToChildSets,
  setToPermissions,
  setToLoginExtras,
  setsToShow,
  filterText
}) => {
  const isCollapsed = !filterText && collapsedSets.includes(rootSet);
  const childSets = parentSetToChildSets.get(rootSet, Immutable.List());
  const highlightColor = "#5c2";
  const icon = childSets.isEmpty()
      ? <span className={"fa fa-circle"}
              onClick={() => toggleCollapsed(rootSet)}
              style={{verticalAlign: "middle", fontSize: "0.5rem", color: "#666", marginRight: "0.2rem"}} />
      : <span className={isCollapsed ? "fa fa-chevron-right" : "fa fa-chevron-down"}
              onClick={() => toggleCollapsed(rootSet)}
              style={{verticalAlign: "middle", fontSize: "0.7rem", color: "#666", marginRight: "0.2rem"}} />;
  const permissions = setToPermissions.get(rootSet, Immutable.List());
  return <div style={{marginLeft: "1.5rem"}}>
    <span>
      {icon}
      <span style={{
        verticalAlign: "middle",
        color: nameMatches(filterText, rootSet) ? highlightColor : null
      }}>
        {rootSet}
      </span>
    </span>
    <div style={{marginLeft: "2rem", marginBottom: "0.1rem", fontSize: "0.7rem", color: "#777"}}>
      {setToLoginExtras
          .get(rootSet, Immutable.Map())
          .entrySeq()
          .map(([type, values]) => <span key={type}>{type + ": " + values.join(", ")}<br /></span>)}
      {!permissions.isEmpty() && <>console permissions: {permissions.sortBy(p => p).map(p => {
        return <span key={p} style={{color: nameMatches(filterText, p) ? highlightColor : null, fontWeight: nameMatches(filterText, p) ? "bold" : null}}>{p}, </span>;
      })}</>}
    </div>
    {!isCollapsed && childSets
        .sortBy(s => s)
        .filter(s => setsToShow.includes(s))
        .map(childSet => {
          return <SetTree
              key={childSet}
              rootSet={childSet}
              collapsedSets={collapsedSets}
              toggleCollapsed={toggleCollapsed}
              parentSetToChildSets={parentSetToChildSets}
              setToPermissions={setToPermissions}
              setToLoginExtras={setToLoginExtras}
              setsToShow={setsToShow}
              filterText={filterText} />;
        })}
  </div>;
});

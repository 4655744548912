import React from "react";
import {MenuItem, Paper, SelectField, TextField} from "material-ui";

const paperStyle = {
  padding: "10px 30px 20px 30px",
  marginBottom: 20
};

const selectFieldStyle = {
  width: "45%"
};

export const cleanAdminUsername = clientName => clientName.toLowerCase().replaceAll(" ", "_").replace(/\W/g, "");

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"];

const ClientConfigEditor = React.memo(({clientConfig, setClientConfig, dropdownData, productTier, isLocked}) => {
  const onChangeRegion = region => {
    setClientConfig(clientConfig => clientConfig
        .set("region", region)
        .set("locale-name", region)
        .set("timezone", region === "UK" ? "Etc/GMT" : "EST5EDT")
        .set("hq-currency", region === "UK" ? "GBP" : "USD"));
  };

  return <Paper style={paperStyle}>
    <h3>Client Config</h3>
    <div style={{display: "flex", flexDirection: "column", pointerEvents: isLocked && "none"}}>
      <TextField
          floatingLabelText="Admin Username"
          errorText={!clientConfig.get("admin-username") && "Required field"}
          floatingLabelStyle={{color: "gray"}}
          value={clientConfig.get("admin-username")}
          style={{width: "45%"}}
          onChange={(e, value) => setClientConfig(clientConfig.set("admin-username", cleanAdminUsername(value)))}
          floatingLabelFixed={true} />
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <SelectField
            value={clientConfig.get("region")}
            iconStyle={{fill: "gray"}}
            floatingLabelStyle={{color: "gray", overflow: "hidden", whiteSpace: "nowrap", maxWidth: "120%"}}
            style={selectFieldStyle}
            onChange={(e, index, value) => onChangeRegion(value)}
            floatingLabelText="Region (affects onboarding setup e.g. metric naming)"
            floatingLabelFixed={true}>
          <MenuItem key="UK" value="UK" primaryText="UK" />
          <MenuItem key="US" value="US" primaryText="US" />
        </SelectField>
        <SelectField
            floatingLabelText="Root Group Locale"
            iconStyle={{fill: "gray"}}
            floatingLabelStyle={{color: "gray"}}
            value={clientConfig.get("locale-name")}
            onChange={(e, index, value) => setClientConfig(clientConfig.set("locale-name", value))}
            style={selectFieldStyle}
            floatingLabelFixed={true}>
          {dropdownData && dropdownData.get("locales")
              .map(locale => <MenuItem
                  key={locale.get("name")}
                  value={locale.get("name")}
                  primaryText={locale.get("name")} />)}
        </SelectField>
      </div>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <SelectField
            floatingLabelText="Root Group Timezone"
            iconStyle={{fill: "gray"}}
            floatingLabelStyle={{color: "gray"}}
            value={clientConfig.get("timezone")}
            onChange={(e, index, value) => setClientConfig(clientConfig.set("timezone", value))}
            style={selectFieldStyle}
            floatingLabelFixed={true}>
          {dropdownData && dropdownData.get("timezones")
              .map(timezone => <MenuItem key={timezone} value={timezone} primaryText={timezone} />)}
        </SelectField>
        <SelectField
            floatingLabelText="Root Group Currency"
            iconStyle={{fill: "gray"}}
            floatingLabelStyle={{color: "gray"}}
            onChange={(e, index, value) => setClientConfig(clientConfig.set("hq-currency", value))}
            value={clientConfig.get("hq-currency")}
            style={selectFieldStyle}
            floatingLabelFixed={true}>
          {dropdownData && dropdownData.get("currencies")
              .map(currency => <MenuItem
                  key={currency.get("code")}
                  value={currency.get("code")}
                  primaryText={currency.get("code")} />)}
        </SelectField>
      </div>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <SelectField
            floatingLabelText="Week Starts On"
            iconStyle={{fill: "gray"}}
            floatingLabelStyle={{color: "gray"}}
            value={clientConfig.get("week-start-day")}
            onChange={(e, index, value) => setClientConfig(clientConfig.set("week-start-day", value))}
            style={selectFieldStyle}
            floatingLabelFixed={true}>
          {days.map(day => <MenuItem key={day} value={day.toUpperCase()} primaryText={day} />)}
        </SelectField>
        <SelectField
            floatingLabelText="Company Financial Year Starts In"
            iconStyle={{fill: "gray"}}
            floatingLabelStyle={{color: "gray"}}
            value={clientConfig.get("year-start-month")}
            onChange={(e, index, value) => setClientConfig(clientConfig.set("year-start-month", value))}
            style={selectFieldStyle}
            floatingLabelFixed={true}>
          {months.map(month => <MenuItem key={month} value={month.toUpperCase()} primaryText={month} />)}
        </SelectField>
      </div>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <TextField
            floatingLabelText="Bullhorn SF ID"
            errorText={clientConfig.get("bullhorn-sf-id") &&
            (!(clientConfig.get("bullhorn-sf-id").length === 18) || !clientConfig.get("bullhorn-sf-id").match(/^[0-9a-zA-Z]+$/)) &&
            "Must be 18 characters long and alphanumeric"}
            floatingLabelStyle={{color: "gray"}}
            value={clientConfig.get("bullhorn-sf-id")}
            style={{width: "45%"}}
            onChange={(e, value) => setClientConfig(clientConfig.set("bullhorn-sf-id", value.substring(0, 18)))}
            floatingLabelFixed={true} />
        {productTier === "CORPORATE" && <SelectField
            floatingLabelText="Corporate Option"
            iconStyle={{fill: "gray"}}
            floatingLabelStyle={{color: "gray"}}
            value={clientConfig.get("product-option")}
            onChange={(e, index, value) => setClientConfig(clientConfig.set("product-option", value))}
            style={selectFieldStyle}
            floatingLabelFixed={true}>
          <MenuItem key="jobs-pipeline" value="jobs-pipeline" primaryText="Jobs Pipeline" />
          <MenuItem key="cube-tv" value="cube-tv" primaryText="Gamification" />
        </SelectField>}
      </div>
    </div>
  </Paper>;
});

export default ClientConfigEditor;

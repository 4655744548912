
// Converts a #ffffff hex string into an [r,g,b] array
const hexToRgb = function(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null;
};

// Inverse of the above
const rgbToHex = function(rgb) {
  return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
};

// Interpolates two [r,g,b] colors and returns an [r,g,b] of the result
// Taken from the awesome ROT.js roguelike dev library at
// https://github.com/ondras/rot.js
const interpolateRgb = function(color1, color2, factor) {
  if (arguments.length < 3) { factor = 0.5; }
  let result = color1.slice();
  for (let i=0;i<3;i++) {
    result[i] = Math.round(result[i] + factor*(color2[i]-color1[i]));
  }
  return result;
};

const interpolateHex = (c1, c2, factor) => rgbToHex(interpolateRgb(hexToRgb(c1), hexToRgb(c2), factor));

export {
  interpolateRgb,
  interpolateHex
};

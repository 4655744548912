import {
  Paper,
  RaisedButton,
  Snackbar,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TextField
} from "material-ui";
import Immutable from "immutable";
import React from "react";
import Dialog from "material-ui/Dialog";

import {capitalise} from "js/data/global-state";
import LoadingSpinner from "js/components/loading-spinner";
import {onboardingPages} from "js/app-areas/onboarding/app";
import {getErrorMessage} from "js/utils/errors";
import {
  clientNamePath,
  productTierPath,
  step1BulkStatusPath,
  step1StatusPath,
  step1SubmissionIdPath,
  step1SubmittedByPath,
  step2StatusPath,
  step2SubmissionIdPath,
  step2SubmittedByPath
} from "js/app-areas/onboarding/onboarding-clients-config";
import {retryFailedDataBulks, retryFailedUserBulk} from "js/app-areas/onboarding/home/api";
import {ClientsContext, SelectedClientIdContext} from "js/data/contexts";
import ClientPicker from "js/components/client-picker";
import * as Rata from "js/data/remote-data";

const HomePage = ({
  setCurrentPage,
  reloadClients,
  isLoading,
  onboardingClients,
  setCurrentClient,
  idToClient
}) => {

  const [selectedField, setSelectedField] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const handleStep1Click = (client) => {
    setCurrentPage(onboardingPages.step1);
    setCurrentClient(client);
  };

  const handleStep2Click = (client) => {
    setCurrentPage(onboardingPages.step2);
    setCurrentClient(client);
  };

  const handleRetryBulks = (client) => {
    const promises = [];
    if (hasUserBulkFailure(client)) {
      promises.push(retryFailedUserBulk(client.get("client-id"), client.getIn(step1SubmissionIdPath)));
    }
    if (hasDataBulkFailure(client)) {
      promises.push(retryFailedDataBulks(
          client.get("client-id"),
          client.getIn(step2SubmissionIdPath)));
    }
    Promise.all(promises)
        .then(
            reloadClients,
            error => getErrorMessage(error).then(setErrorMessage));
  };

  const {idToClientStatus} = React.useContext(ClientsContext);
  const {selectedClientId, setSelectedClientId} = React.useContext(SelectedClientIdContext);
  const isClientSelected = selectedClientId != null

  return <div>
    <div style={{marginBottom: 10}}>
      <ClientPicker
          css={{width: 320, marginRight: "0.5rem"}}
          loading={idToClientStatus === Rata.Status.LOADING}
          value={selectedClientId}
          onChange={setSelectedClientId}
          clients={idToClient.valueSeq()}/>
    </div>
    <Paper style={{padding: "0px 20px 20px 20px"}}>
      {isLoading ? <LoadingSpinner/> : <>
        {onboardingClients.isEmpty()
            ? <div style={{paddingTop: 20}}>{
              isClientSelected
                  ? "This client wasn’t implemented using the Onboarding Tool"
                  : "No activity in the last 14 days."
            }</div>
            : <ProgressTable
                onboardingClients={onboardingClients}
                idToClient={idToClient}
                onStep1Click={handleStep1Click}
                onStep2Click={handleStep2Click}
                setSelectedField={setSelectedField}
                onRetryBulks={handleRetryBulks}
                onRefresh={reloadClients}/>
        }
      </>}

    </Paper>
    <Dialog
        modal={false}
        style={{zIndex: 9000}}
        open={!!selectedField}
        onRequestClose={() => setSelectedField(null)}
        autoScrollBodyContent={true}>
      <pre style={{overflowX: "auto", whiteSpace: "pre-wrap", wordWrap: "break-word"}}>{selectedField}</pre>
    </Dialog>
    <Snackbar
        open={!!errorMessage}
        onRequestClose={() => setErrorMessage(null)}
        style={{backgroundColor: "red", left: "20%"}}
        message={errorMessage ?? ""}
        autoHideDuration={10000}
    />
  </div>;
};

const idToColumn = Immutable.OrderedMap({
  "clientId": Immutable.Map({name: "Client Id", width: 60}),
  "name": Immutable.Map({name: "Client Name"}),
  "productTier": Immutable.Map({name: "Product Tier", width: 100}),
  "onboardedDate": Immutable.Map({name: "Onboarded Date", width: 160}),
  "onboardedBy": Immutable.Map({name: "Onboarded By"}),
  "status": Immutable.Map({name: "Status", width: 160}),
  "userBulkProgress": Immutable.Map({name: "User Load Progress", width: 120}),
  "dataBulkProgress": Immutable.Map({name: "Data Load Progress"}),
  "actions": Immutable.Map({name: "Actions", width: 290})
});

const ProgressTable = ({onboardingClients, setSelectedField, onStep1Click, onStep2Click, onRetryBulks, idToClient, onRefresh}) => {

  const [searchString, setSearchString] = React.useState("");
  const processedClients = React.useMemo(() => {
    return onboardingClients.map(client => {
      const clientId = client.get("client-id");
      return Immutable.Map({
        client: client.set("reconfigurationTool", idToClient.getIn([client.get("client-id"), "reconfiguration_tool"])),
        clientId: clientId,
        productTier: client.getIn(productTierPath),
        name: client.getIn(clientNamePath),
        onboardedDate: new Date(client.get("onboarded-date")).toLocaleString(),
        onboardedBy: client.getIn(step2SubmittedByPath) ?? client.getIn(step1SubmittedByPath),
        status: getClientStatus(client),
        userBulkProgress: getSingleBulkProgress(client.get("step-1-bulk-load")),
        dataBulkProgress: getBatchBulkProgress(client.get("step-2-bulk-loads", Immutable.Map()))
      });
    });
  }, [idToClient, onboardingClients]);

  const filteredClients = processedClients
      .filter(client => {
            const lowerCaseStr = searchString.toLowerCase();
            return client.get("clientId").toString().toLowerCase().includes(lowerCaseStr) ||
                client.get("name").toString().toLowerCase().includes(lowerCaseStr) ||
                client.get("onboardedDate").toString().toLowerCase().includes(lowerCaseStr) ||
                client.get("productTier").toString().toLowerCase().includes(lowerCaseStr) ||
                client.get("onboardedBy").toString().toLowerCase().includes(lowerCaseStr) ||
                client.get("status").toString().toLowerCase().includes(lowerCaseStr) ||
                client.get("userBulkProgress").toString().toLowerCase().includes(lowerCaseStr) ||
                client.get("dataBulkProgress").toString().toLowerCase().includes(lowerCaseStr);
          }
      )
      .sortBy(client => Number(client.get("clientId")))
      .reverse();

  const {selectedClientId} = React.useContext(SelectedClientIdContext);
  const isSearchFieldDisabled = selectedClientId != null

  return <div>
    <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "space-between"
        }}>
    <TextField
        value={searchString}
        floatingLabelText="Search last 14 days"
        disabled={isSearchFieldDisabled}
        onChange={e => setSearchString(e.target.value)} />
    <RaisedButton
        onClick={onRefresh}
        style={{minWidth: 45}}
        label={<i className="fa fa-refresh" />} />
    </div>
    <Table selectable={false}>
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          {idToColumn.map((column, id) => <TableHeaderColumn
              style={column.get("width") ? {width: column.get("width")} : {}}
              key={id}>
            {column.get("name")}
          </TableHeaderColumn>).toArray()}
        </TableRow>
      </TableHeader>
      <TableBody displayRowCheckbox={false}>
        {filteredClients.map(processedClient => {
          const isReadOnly = processedClient.getIn(["client", "reconfigurationTool"]) !== "ONBOARDING";
          return <TableRow
              key={processedClient.get("clientId")}
              style={{backgroundColor: isReadOnly && "rgb(235, 235, 235)"}}>
            {idToColumn.map((column, id) => {
              return id === "actions" ?
                  <TableRowColumn
                      key={processedClient.get("id") + "-actions"}
                      style={{textOverflow: "hidden", width: column.get("width")}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                      <div>{getActions(processedClient.get("client"), onStep1Click, onStep2Click, onRetryBulks)}</div>
                      {isReadOnly && <i style={{fontSize: "1.2rem", marginTop: 8}} className="fa fa-lock" />}
                    </div>
                  </TableRowColumn> :
                  <TableRowColumn
                      style={column.get("width") ? {width: column.get("width")} : {}}
                      key={processedClient.get("clientId") + "-" + id}>
                    <div onClick={() => setSelectedField(processedClient.get(id))}>{processedClient.get(id)}</div>
                  </TableRowColumn>;
            }).toArray()}
          </TableRow>;
        })}
      </TableBody>
    </Table>
  </div>;
};

const getClientStatus = client => {
  const step1Status = client.get("step-1-submission") && Immutable.Map({
    stage: "Step 1",
    status: client.getIn(step1StatusPath)
  });
  const userBulkStatus = client.get("step-1-bulk-load") && Immutable.Map({
    stage: "User Bulk",
    status: client.getIn(step1BulkStatusPath)
  });
  const step2Status = client.get("step-2-submission") && Immutable.Map({
    stage: "Step 2",
    status: client.getIn(step2StatusPath)
  });
  const dataBulkStatus = client.get("step-2-bulk-loads") && !client.get("step-2-bulk-loads").isEmpty() &&
      Immutable.Map({
        stage: "Data Bulk",
        status: ["ACCEPTED", "RUNNING", "FAILED", "COMPLETED"].find(status => client.get("step-2-bulk-loads")
            .has(status))
      });

  const currentPhase = dataBulkStatus || step2Status || userBulkStatus || step1Status;
  const status = currentPhase.get("status");
  const stage = currentPhase.get("stage");

  if (status === "FAILED") {
    return stage + " Failed";
  } else if (status === "ACCEPTED" || status === "RUNNING") {
    return stage + " In Progress";
  } else if (status === "SUCCEEDED" || status === "COMPLETED") {
    if (stage === "Data Bulk") {
      return "Onboarding Completed";
    } else {
      return "Awaiting " + getNextStepName(stage);
    }
  }
};

const getSingleBulkProgress = bulkData => {
  if (!bulkData) {
    return "Not started";
  } else if (bulkData.get("status") === "RUNNING") {
    return "Bulk running";
  } else {
    return capitalise(bulkData.get("status").toLowerCase());
  }
};

const getBatchBulkProgress = batchBulks => {
  if (!batchBulks || batchBulks.isEmpty()) {
    return "Not started";
  }
  const failedCount = batchBulks.get("FAILED", 0);
  const completedCount = batchBulks.get("COMPLETED", 0);
  const finishedCount = failedCount + completedCount;
  const totalCount = batchBulks.reduce((total, value) => total + value, 0);
  if (failedCount === totalCount) {
    return "Failed";
  } else if (completedCount === totalCount) {
    return "Completed";
  } else if (finishedCount === totalCount) {
    return "Completed With Failures";
  } else {
    const progressPercentage = Math.round(100 * finishedCount / totalCount);
    return progressPercentage + "%";
  }
};

const getActions = (client, onStep1Click, onStep2Click, onRetryBulks) => {
  const isReadOnly = client.get("reconfigurationTool") !== "ONBOARDING";
  let actions = [];

  if (isReadOnly) {
    actions.push(<RaisedButton
        key="view"
        style={{fontSize: "0.8rem", minWidth: 20, height: 30, marginTop: 3}}
        label={"View"}
        onClick={() => onStep1Click(client)}
        primary={true} />);
  } else {
    const step2Completed = client.getIn(step2StatusPath) === "SUCCEEDED";
    if (step2Completed) {
      actions.push(<RaisedButton
          key="reconfigure"
          style={{fontSize: "0.8rem", minWidth: 20, height: 30, marginTop: 3}}
          label={"Reconfigure"}
          onClick={() => onStep1Click(client)}
          primary={true} />);
    } else {
      actions.push(<RaisedButton
          key="resume"
          style={{fontSize: "0.8rem", minWidth: 20, height: 30, marginTop: 3}}
          label="Resume"
          backgroundColor="#EEBA56"
          onClick={() => onStep2Click(client)} />);
    }
  }

  if (hasUserBulkFailure(client) || hasDataBulkFailure(client)) {
    actions.push(<RaisedButton
        key="retry"
        style={{fontSize: "0.8rem", minWidth: 20, height: 30, marginTop: 3, marginLeft: 5}}
        backgroundColor="#EEBA56"
        label="Retry Load"
        onClick={() => onRetryBulks(client)} />);
  }
  return actions;
};

const hasUserBulkFailure = (client) => client.getIn(step1BulkStatusPath) === "FAILED";
const hasDataBulkFailure = (client) => {
  const statusToCount = client.get("step-2-bulk-loads", Immutable.Map());
  const failedCount = statusToCount.get("FAILED", 0);
  const finishedCount = failedCount + statusToCount.get("COMPLETED", 0);
  const totalCount = statusToCount.reduce((total, value) => total + value, 0);
  return finishedCount === totalCount && failedCount > 0;
};

const getNextStepName = stepName => {
  if (stepName === "Step 1") {
    return "User Bulk";
  } else if (stepName === "User Bulk") {
    return "Step 2";
  } else if (stepName === "Step 2") {
    return "Data Bulk";
  }
};

export default HomePage;
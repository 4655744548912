import React from "react";
import pure from "js/pure";

export default pure(({show, message = "", backgroundColor = "rgb(0,0,0,0.3)", color = "#eee"}) => {
  return (
    <div style={{
      display: show ? "block" : "none",
      zIndex: 999,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor,
      color
    }}>
      {message}
    </div>);
});

import Immutable from "immutable";

import createReducer from "js/create-reducer";
import * as Fetch from "js/fetch";
import {indexBy} from "js/utils/collections";
import {getErrorMessage} from "js/utils/errors";

const initialState = Immutable.fromJS({
  loadingAvailableTests: false,
  loadingAvailableResults: false,
  loadingTestSets: false,
  resultId: null,
  availableTests: Immutable.List(),
  resultIdToResult: Immutable.List(),
  testSets: Immutable.List()
});

const reducer = createReducer(initialState, {
  MERGE: (state, {newState}) => state.merge(newState)
});

const merge = newState => ({type: "MERGE", newState: Immutable.fromJS(newState)});

const setResultId = resultId => merge({resultId});

const loadAvailableTestsAction = () => (dispatch, _) => {
  dispatch(merge({
    loadingAvailableTests: true
  }));
  fetchAvailableTests()
      .then(tests => dispatch(merge({availableTests: tests})))
      .catch(error => getErrorMessage(error).then(console.error))
      .finally(() => dispatch(merge({loadingAvailableTests: false})));
};

const loadAvailableResultsAction = () => (dispatch, _) => {
  dispatch(merge({
    loadingResults: true
  }));
  fetchAvailableResults()
      .then(results => dispatch(merge({resultIdToResult: indexBy(x => x.get("id"), results)})))
      .catch(error => getErrorMessage(error).then(console.error))
      .finally(() => dispatch(merge({loadingResults: false})));
};

const loadTestSetsAction = () => (dispatch, _) => {
  dispatch(merge({
    loadingTestSets: true
  }));
  fetchTestSets()
      .then(testSets => dispatch(merge(({testSets: testSets}))))
      .catch(error => getErrorMessage(error).then(console.error))
      .finally(() => dispatch(merge({loadingTestSets: false})));
};

const baseEndpoint = "functional-tests";

const fetchAvailableResults = () => Fetch
    .getJson(`${baseEndpoint}/available-results`)
    .then(response => Immutable.fromJS(response));

const fetchAvailableTests = () => Fetch
    .getJson(`${baseEndpoint}/available-tests`)
    .then(response => Immutable.fromJS(response));

const fetchTestSets = () => Fetch
    .getJson(`${baseEndpoint}/test-sets`)
    .then(response => Immutable.fromJS(response));

const runTest = (testName, json) => Fetch
    .post(`${baseEndpoint}/tests/${testName}`, json);

const runTestSets = (testSetIds) => Fetch
    .post(`${baseEndpoint}/run-test-sets`, Immutable.Map().set("set-ids", testSetIds).toJS());

export {
  reducer,
  baseEndpoint,
  fetchAvailableResults,
  loadAvailableTestsAction as loadAvailableTests,
  loadAvailableResultsAction as loadAvailableResults,
  loadTestSetsAction as loadTestSets,
  runTest,
  runTestSets,
  setResultId
};
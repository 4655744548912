import React, {useEffect} from "react";
import * as Immutable from "immutable";

import Dialog from "material-ui/Dialog";
import ReactDiffViewer, {DiffMethod} from "react-diff-viewer";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";

import LoadingSpinner from "js/components/loading-spinner";
import {configToText} from "js/app-areas/etl-config/config-rendering";
import {lintConfig} from "js/data/etl-configs/api";
import {Checkbox} from "material-ui";

const ReviewDialog = React.memo(({
  open,
  onRequestClose,
  crmType,
  originalTypeToConfig = Immutable.Map(),
  currentTypeToConfig = Immutable.Map(),
  onSaveClick
}) => {
  const [comment, setComment] = React.useState("");
  const [isLoadingProblems, setIsLoadingProblems] = React.useState(false);
  const [problems, setProblems] = React.useState(Immutable.List());
  const [shouldIgnoreProblems, setShouldIgnoreProblems] = React.useState(false);
  const mergedConfig = currentTypeToConfig.get("merged");

  useEffect(() => {
    if (open) {
      setIsLoadingProblems(true);
      lintConfig(mergedConfig)
          .then(problems => {
            setProblems(problems);
            setIsLoadingProblems(false);
          });
    }
  }, [open, mergedConfig]);

  return (
      <Dialog
          modal={false}
          style={{zIndex: 9000}}
          open={open}
          onRequestClose={newOpen => newOpen || onRequestClose()}
          contentStyle={{width: "98%", maxWidth: "none", height: "98%", maxHeight: "none"}}
          autoScrollBodyContent={true}
          autoDetectWindowHeight={false}
          repositionOnUpdate={false}>
        {open && <div style={{width: "100%", height: "100%"}}>
          <div>
            {isLoadingProblems && <LoadingSpinner />}
            {!isLoadingProblems && !problems.isEmpty() && <div>
              <h3 style={{color: "red"}}>Problems to fix before saving</h3>
              <ul>
              {problems
                  .sort((a, b) => a.localeCompare(b, "en", {ignorePunctuation: true, numeric: true}))
                  .map((problem, index) => <li key={index}>{problem}</li>)}
              </ul>
            </div>}
          </div>
          <h3>Client Diff</h3>
          <MemoDiffViewer
              oldConfig={originalTypeToConfig.get("client")}
              currentConfig={currentTypeToConfig.get("client")}
              crmType={crmType} />
          <h3>Merged Diff</h3>
          <MemoDiffViewer
              oldConfig={originalTypeToConfig.get("merged")}
              currentConfig={currentTypeToConfig.get("merged")}
              crmType={crmType} />
          <div style={{marginTop: "1rem", display: "flex", alignItems: "center"}}>
            <TextField
                style={{marginRight: "1rem"}}
                floatingLabelText="Comment"
                value={comment}
                onChange={e => setComment(e.target.value)} />
            <RaisedButton
                label="Save"
                style={{marginRight: "1rem"}}
                disabled={!comment || isLoadingProblems || (!problems.isEmpty() && !shouldIgnoreProblems)}
                onClick={() => {
                  onSaveClick(comment);
                  setComment("");
                }} />
            {!problems.isEmpty() && <Checkbox
                label={"Ignore problems?"}
                style={{ali: "middle"}}
                checked={shouldIgnoreProblems}
                onCheck={(evt, val) => setShouldIgnoreProblems(val)} />}
          </div>
        </div>}
      </Dialog>);
});

const MemoDiffViewer = React.memo(({
  oldConfig,
  currentConfig,
  crmType
}) => <ReactDiffViewer
    compareMethod={DiffMethod.WORDS}

    leftTitle="Before"
    oldValue={configToText(oldConfig, crmType)}

    rightTitle="After"
    newValue={configToText(currentConfig, crmType)}

    splitView={true} />)

export default ReviewDialog;
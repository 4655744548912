import Immutable from "immutable";

import * as fetch from "js/fetch";

const INVALID_PARAMS = "INVALID_PARAMS";
const FETCH = "FETCH_CLIENT_METRICS";
const SUCCESS = "FETCHED_CLIENT_METRICS";
const ERROR = "ERROR_FETCHING_CLIENT_METRICS";
const FAILED_TO_LOAD = "FAILED_TO_LOAD";
const NO_DATA_FOUND = "NO_DATA_FOUND";

const SET_CLIENT_FILTER = "FILTER_CLIENT_METRICS_BY_CLIENT";

const COLUMNS = [
    "Client",
    "Metric Template",
    "Metric Template Type",
    "Metric Template Sub-Type",
    "Client's Metric Name",
    "Default Metric Name",
    "Client Filter",
    "Trendable",
    "# Visible Sections",
    "Summary Action",
    "Report Action",
    "Value",
    "Config - Filter",
    "Config - Status Filter",
    "Config - Owner",
    "Config - Override Date Column"
];

const initialState = Immutable.Map({
    isFetching: false,
    error: null,
    filters: Immutable.Map({
        clientIds: Immutable.List()
    }),
    data: null
});

const clientMetricsData = (state = initialState, action) => {
    switch (action.type) {
    case INVALID_PARAMS:
        return state.set("error", action.error);
    case FETCH:
        return state
            .set("isFetching", true)
            .set("error", null)
            .set("data", initialState.get("data"));
    case SUCCESS:
        return state
            .set("isFetching", false)
            .set("data", action.data);
    case ERROR:
        return state
            .set("isFetching", false)
            .set("error", action.error)
            .set("data", initialState.get("data"));
    case SET_CLIENT_FILTER:
        return state.setIn(["filters", "clientIds"], action.clientIds);
    default:
        return state;
    }
};

// ACTIONS

const onClientPickerChange = clientIds => ({
    type: SET_CLIENT_FILTER,
    clientIds
});

const loadData = filters => {
    return dispatch => {
        const clientIds = filters.get("clientIds").toArray();
        const params = { "client-ids": clientIds };
        dispatch({ type: FETCH });

        return fetch.getJson("client-kpi-pivot", params).then(
            data => {
                if (data.length === 0) {
                    dispatch({
                        type: ERROR,
                        error: NO_DATA_FOUND
                    });
                } else {
                    dispatch({
                        type: SUCCESS,
                        data: [ COLUMNS, ...data ]
                    });
                }
            },
            error => {
                dispatch({
                    type: ERROR,
                    error: FAILED_TO_LOAD
                });
            });
    };
};

export default clientMetricsData;
export {
    onClientPickerChange,
    loadData,
    COLUMNS,
    FAILED_TO_LOAD,
    NO_DATA_FOUND
}

import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";

import { Success, Err } from "js/components/notification";
import * as fetch from "js/fetch";

const containerStyle = {
    marginTop: "0.5rem",
    padding: "1rem",
    border: "1px solid #d3d3d3",
    borderRadius: 4
};

const padding = {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem"
};

const buttonMargins = {
    marginTop: "1rem",
    marginRight: "1rem"
};

export default createReactClass({

    getInitialState() {
        return {
            isFetchingValue: false,
            decryptedValue: null,
            newValue: "",
            comment: "",
            isUpdatingValue: false,
            successMessage: null,
            errorMessage: null
        };
    },

    componentDidUpdate(prevProps) {
        const currentConfigProperty = this.props.configProperty;
        const prevConfigProperty = prevProps.configProperty;
        if (!Immutable.is(currentConfigProperty, prevConfigProperty)) {
            this.setState(this.getInitialState());
        }
    },

    render() {
        const { configProperty } = this.props;
        const {
            isFetchingValue,
            decryptedValue,
            newValue,
            comment,
            isUpdatingValue,
            successMessage,
            errorMessage
        } = this.state;
        const isShowingDecryptedValue = decryptedValue !== null;
        const hasValueChanged = newValue.length > 0;
        return (
            <div style={containerStyle}>
                <div style={{ display: "inline-block", width: 250 }}>
                    <div style={padding}>
                        <strong>UID: </strong>{configProperty.get("uid")}
                    </div>
                    <div style={padding}>
                        <strong>Client ID: </strong>{configProperty.get("clientId")}
                    </div>
                    <div style={padding}>
                        <strong>Category: </strong>{configProperty.get("category")}
                    </div>
                    <div style={padding}>
                        <strong>Key: </strong>{configProperty.get("propertyKey")}
                    </div>
                </div>
                <div>
                    <div style={{ ...padding, display: "inline-block" }}>
                        <strong>Value: </strong>
                        {isFetchingValue ?
                            <i className="fa fa-spinner fa-pulse" style={{ paddingLeft: "1rem", paddingRight: "1rem" }} /> :
                            <span>{isShowingDecryptedValue ? decryptedValue : "ENCRYPTED"}</span>}
                    </div>
                    <RaisedButton
                        label="Decrypt"
                        secondary={true}
                        style={{ marginLeft: "1rem", marginRight: "1rem" }}
                        disabled={isShowingDecryptedValue}
                        onClick={this.onDecryptClick} />
                </div>
                <TextField
                    fullWidth={true}
                    floatingLabelText="New Value"
                    value={newValue}
                    onChange={e => this.setState({ newValue: e.target.value })} />
                <TextField
                    style={{ display: "block" }}
                    fullWidth={true}
                    floatingLabelText="Comment (optional)"
                    value={comment}
                    onChange={e => this.setState({ comment: e.target.value })} />
                <div style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
                    <RaisedButton
                        label="Cancel"
                        style={buttonMargins}
                        disabled={!hasValueChanged}
                        onClick={() => this.setState(this.getInitialState())} />
                    <RaisedButton
                        primary={true}
                        style={buttonMargins}
                        label={<span>Update{isUpdatingValue && <i className="fa fa-spinner fa-pulse" />}</span>}
                        disabled={!hasValueChanged}
                        onClick={this.onUpdateClick} />
                </div>
                <Success message={successMessage} onRequestClose={() => this.setState({ successMessage: null })} />
                <Err message={errorMessage} onRequestClose={() => this.setState({ errorMessage: null })} />
            </div>
        );
    },

    onDecryptClick() {
        this.setState({ isFetchingValue: true });
        getDecryptedPropertyValue(this.props.configProperty.get("uid"))
            .then(decryptedValue => {
                this.setState({
                    isFetchingValue: false,
                    decryptedValue
                });
            }, () => {
                this.setState({
                    isFetchingValue: false,
                    errorMessage: "Unable to get decrypted value, contact support@cube19.com \u{1F430}"
                });
            });
    },

    onUpdateClick() {
        const { newValue, comment } = this.state;
        this.setState({ isUpdatingValue: true });
        const params = {
            uid: this.props.configProperty.get("uid"),
            "property-value": newValue,
            comment
        };
        updateConfigProperties(params)
            .then(() => {
                this.setState({
                    ...this.getInitialState(),
                    successMessage: "Configuration property value changed"
                });
            }, () => {
                this.setState({
                    isUpdatingValue: false,
                    errorMessage: "Unable to update configuration property value, contact support@cube19.com \u{1F430}"
                });
            });
    }

});

const getDecryptedPropertyValue = uid => fetch
    .getJson("decrypt-property-value", { uid })
    .then(result => result.value);

const updateConfigProperties = params => fetch
    .post("update-config-properties", params)
    .then(result => Immutable.fromJS(result));

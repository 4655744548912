
const set = (key, value) => window.localStorage[key] = JSON.stringify(value);
const get = (key, defaultValue) => {
	const valueStr = window.localStorage[key];
	if (valueStr) {
		try {
			return JSON.parse(valueStr);
		} catch (e) {
			return defaultValue;
		}
	} else {
		return defaultValue;
	}
};

export {
	get,
	set
};

import "gridlex/dist/gridlex.css";

import React from "react";

import Checkbox from "material-ui/Checkbox";

import Header from "js/components/header";
import LoadingSpinner from "js/components/loading-spinner";
import PivotTable from "js/pivot-table/pivot-table";
import RaisedButton from "material-ui/RaisedButton";

const PivotTableContainer = ({
  isFetchingData,
  data,
  options,
  hideTotalsOptions = false,
  showTotals,
  onToggleShowTotals,
  onRefresh,
  onCellClick,
  onDownloadReportRequest
}) => {
  const isDataLoaded = !!data;
  return (
      <div>
        {!hideTotalsOptions && <span>
                <Header icon="cogs" label="Pivot table display options:" />
                <div className="grid">
                    <div className="col-4" style={{margin: "0.5rem"}}>
                        <Checkbox
                            label="Show totals"
                            checked={showTotals}
                            onCheck={(evt, value) => onToggleShowTotals(value)}
                            style={{width: 145}} />

                        <RaisedButton
                            label="Download"
                            onClick={onDownloadReportRequest}
                            disabled={!data}
                            primary={true}
                            style={{marginTop: "1.15em", marginBottom: "0.5em"}} />
                    </div>
                </div>
            </span>}
        {isFetchingData && <LoadingSpinner />}
        {isDataLoaded &&
        <PivotTable
            showTotals={showTotals}
            config={options}
            data={data}
            onCellClick={onCellClick}
            onRefresh={onRefresh}
        />}
      </div>
  );
};

export default PivotTableContainer;

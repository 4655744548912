import React from "react";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";

import Err from "js/components/error";
import {ClientsContext} from "js/data/contexts";
import {getErrorMessage} from "js/utils/errors";
import {onboardingPages} from "js/app-areas/onboarding/app";
import {declareClient, redeclareClient} from "js/app-areas/onboarding/step-1/api";
import {Checkbox} from "material-ui";

const clientNamePath = ["step-1-submission", "payload", "client-name"];

const StepOnePage = ({setCurrentPage, setCurrentClient, productTier, currentClient, updateClient}) => {
  const [clientName, setClientName] = React.useState(currentClient?.getIn(clientNamePath) ?? "");
  const [bhApiUsername, setBhApiUsername] = React.useState("");
  const [bhApiPassword, setBhApiPassword] = React.useState("");
  const [preferNewClientIfDuplicateCorpId, setPreferNewClientIfDuplicateCorpId] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");

  const {reloadIdToClient} = React.useContext(ClientsContext);
  const isReadOnly = (currentClient?.get("reconfigurationTool") ?? "ONBOARDING") !== "ONBOARDING";


  const handleStepSubmit = (goToStep2) => {
    if (clientName) {
      setIsLoading(true);
      setErrorMsg("");
      let submitPromise;
      if (!currentClient) {
        submitPromise = declareClient(clientName, bhApiUsername, bhApiPassword, productTier, preferNewClientIfDuplicateCorpId);
      } else if (!bhApiUsername && !bhApiPassword && clientName === currentClient.getIn(clientNamePath)) {
        submitPromise = Promise.resolve(currentClient);
      } else {
        submitPromise = redeclareClient(
            currentClient.get("client-id"),
            clientName,
            bhApiUsername,
            bhApiPassword,
            productTier);
      }

      submitPromise
          .then(client => {
            reloadIdToClient().then(() => {
              updateClient(client);
              if (goToStep2) {
                setCurrentPage(onboardingPages.step2);
                setCurrentClient(client);
              } else {
                setCurrentPage(onboardingPages.main);
              }
            });
          })
          .catch(error => {
            if (error?.response?.statusText === "Forbidden") {
              setErrorMsg("You do not have onboarding permissions for this client type");
            } else {
              getErrorMessage(error).then(setErrorMsg);
            }
          })
          .finally(() => setIsLoading(false));
    } else {
      setErrorMsg("Client name cannot be empty");
    }
  };

  return <div style={{width: " 95%"}}>
    <form style={{border: "3px solid #F2F2F2", padding: "40px"}} action="">
      <h3 style={{margin: 0}}>Client details</h3>

      {errorMsg
          && <Err
              type="error"
              message={errorMsg}
              customStyle={{
                display: "block",
                margin: "1em 0 0",
                borderColor: "#DA4453",
                backgroundColor: "#DA4453",
                color: "#fff"
              }} />}

      <TextField
          name="clientName"
          disabled={isReadOnly}
          value={clientName}
          autoFocus={true}
          floatingLabelText="Client name"
          onChange={e => setClientName(e.target.value)}
          style={{display: "block"}} />

      <TextField
          name="bhApiUsername"
          value={bhApiUsername}
          disabled={isReadOnly}
          floatingLabelFixed={!!currentClient}
          floatingLabelText="Bullhorn API username"
          onChange={e => setBhApiUsername(e.target.value)}
          hintText={currentClient && "************"}
          style={{display: "block"}} />

      <TextField
          type="password"
          name="bhApiPassword"
          value={bhApiPassword}
          disabled={isReadOnly}
          onKeyUp={event => event.key === "Enter" && handleStepSubmit()}
          floatingLabelFixed={!!currentClient}
          floatingLabelText="Bullhorn API password"
          onChange={e => setBhApiPassword(e.target.value)}
          hintText={currentClient && "************"}
          style={{display: "block"}} />
      {!currentClient && <Checkbox
          checked={preferNewClientIfDuplicateCorpId}
          label={"Disable SSO for existing Analytics clients against this ATS corp"}
          style={{display: "block", marginTop: "1em"}}
          onCheck={(_, isChecked) => setPreferNewClientIfDuplicateCorpId(isChecked)} />}
    </form>
    <div style={{textAlign: "right"}}>

      <RaisedButton
          label={isLoading ? "Validating..." : "Next Step ➝"}
          onClick={() => handleStepSubmit(true)}
          primary={true}
          disabled={isLoading}
          style={{
            marginTop: "1.15em",
            marginBottom: "0.5em"
          }} />
    </div>
  </div>;
};

export default StepOnePage;
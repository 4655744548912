import React from "react";

import ValueEditor from "js/app-areas/etl-config/value-editor";

export default React.memo(({
  attr,
  width,
  title,
  value,
  onChange,
  disabled,
  options
}) => {
  const name = attr.get("name");

  const onChangeAttr = React.useCallback(
    newValue => onChange(name, newValue),
    [name, onChange]);

  return <ValueEditor
    width={width || 180}
    prop={attr}
    title={title}
    value={value}
    disabled={disabled}
    onChange={onChangeAttr}
    options={options} />;
});

import Immutable from "immutable";

const isDataTableTypeReport = renderer => {
  const downloadableReportTypes = Immutable
      .fromJS([
        "Table",
        "Table Barchart",
        "Heatmap",
        "Row Heatmap",
        "Col Heatmap"
      ])
      .toSet();
  return downloadableReportTypes.includes(renderer);
};

const downloadTableAsExcelFile = tableHtml => {
  tableHtml = tableHtml
      .replace(/>\s*=/g, ">")
      .replace(/>\s*\+/g, ">\t+")
      .replace(/>\s*-/g, ">\t-")
      .replace(/>\s*@/g, ">\t@");
  let excelFile = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>";
  excelFile += "<head>";
  excelFile += "<meta charset=\"utf-8\">";
  excelFile += "<!--[if gte mso 9]>";
  excelFile += "<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>";
  excelFile += "<x:Name>Data</x:Name>";
  excelFile += "<x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>";
  excelFile += "</x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml>";
  excelFile += "<![endif]-->";
  excelFile += "</head>";
  excelFile += "<body><table>" + tableHtml + "</table></body>";
  excelFile += "</html>";
  return new Blob([excelFile], {type: "application/vnd.ms-excel;charset=utf-8", endings: "native"});
};

export {
  isDataTableTypeReport,
  downloadTableAsExcelFile
};
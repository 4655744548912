import * as Immutable from "immutable";

const indexBy = (f, coll) => coll.groupBy(f).map(xs => xs.first());

const matchesSomeWords = (str, words) => words.some(word => str.indexOf(word) !== -1);
const matchesAllWords = (str, words) => words.every(word => str.indexOf(word) !== -1);

const entityMatches = (entity, words) => {
  const entityName = entity.get("name").trim().toLowerCase();
  const nameMatches = matchesSomeWords(entityName, words);

  const c19EntityName = entity.get("cube19EntityToMapTo", "").trim().toLowerCase();
  const cube19EntityToMapToMatches = matchesSomeWords(c19EntityName, words);

  return nameMatches || cube19EntityToMapToMatches;
};

const entityFieldsMatch = (entity, words) => {
  const entityName = entity.get("name").trim().toLowerCase();
  const c19EntityName = entity.get("cube19EntityToMapTo", "").trim().toLowerCase();

  return entity.get("fieldMappings", Immutable.List()).some(field => {
    const fieldName = field.get("name", "").trim().toLowerCase();
    const mapTo = field.get("mapTo", "").trim().toLowerCase();
    const translateTo = field.get("translateTo", "").trim().toLowerCase();

    const combos = Immutable.List([
      entityName + " " + fieldName,
      c19EntityName + " " + fieldName,

      entityName + " " + mapTo,
      c19EntityName + " " + mapTo,

      entityName + " " + translateTo,
      c19EntityName + " " + translateTo]);
    return combos.some(combo => matchesAllWords(combo, words));
  });
};

const markMatches = (config, nameToCommonEntity, words) => {
  return config
    .get("entities")
    .map(e => {
      const commonEntity = nameToCommonEntity.get(e.get("name"));
      return Immutable.Map({
        entityMatches: entityMatches(e, words) || (commonEntity && entityMatches(commonEntity, words)),
        fieldsMatch: entityFieldsMatch(e, words) || (commonEntity && entityFieldsMatch(commonEntity, words)),
        entityName: e.get("name")
      })
    });
};

const toWords = (filterText, shouldFilter) => {
  if (shouldFilter) {
    return Immutable
      .List(filterText.split(" "))
      .map(word => word.trim().toLowerCase())
      .filter(word => !!word);
    } else {
      return Immutable.List();
    }
};

const getEntityNameToMatch = (config, nameToCommonEntity, words, shouldFilter) => {
  let matches;
  if (shouldFilter) {
    matches = markMatches(config, nameToCommonEntity, words);
  } else {
    matches = (config.get("entities") || Immutable.List()).map(e => Immutable.Map({
      entityMatches: true,
      fieldMatches: true,
      entityName: e.get("name")
    }));
  };

  return indexBy(m => m.get("entityName"), matches);
};

export {
  getEntityNameToMatch,
  toWords,
  matchesAllWords,
  matchesSomeWords
};

import Immutable from "immutable";
import * as Fetch from "js/fetch";

const retryFailedUserBulk = (clientId, submissionId) => Fetch
    .post("onboarding/retry-failed-user-bulk", {"client-id": clientId, "submission-id": submissionId})
    .then(response => Immutable.fromJS(response));

const retryFailedDataBulks = (clientId, submissionId) => Fetch
    .post("onboarding/retry-failed-data-bulks", {"client-id": clientId, "submission-id": submissionId})
    .then(response => Immutable.fromJS(response));

export {retryFailedUserBulk, retryFailedDataBulks}
import React, {useState} from "react";
import * as Immutable from "immutable";
import ImmutableSelect from "js/components/immutable-select";

const deadStatuses = Immutable.Set.of("DEAD", "CRM_DATA_DELETED", "FULLY_DELETED");

const toOptions = (clients, showDead = false) => {
  return clients
      .filter(c => {
        const isDead = deadStatuses.includes(c.get("type"));
        if (showDead) {
          return isDead;
        } else {
          return !isDead;
        }
      })
      .map(c => Immutable.Map({
        label: c.get("id") + " - " + c.get("internal_name") + " - " + c.get("type"),
        value: c.get("id"),
        name: c.get("internal_name")
      }));
};

export default React.memo(({
  loading,
  value,
  onChange,
  clients,
  idToClient,
  showDead = false,
  style,
  ...props
}) => {
  const [inputType, setInputType] = useState("name");
  const masterList = toOptions(clients || idToClient.valueSeq(), showDead);

  return <div style={style}>
    <ImmutableSelect
        placeholder="Search for Client"
        clearable={false}
        multi={false}
        searchable={true}

        {...props}

        options={masterList.sort((a, b) => a.get(inputType) - b.get(inputType))}

        loading={loading}
        selectedValueOrValues={value}
        onChange={onChange}
        onInputChange={(inputValue) => setInputType(isNaN(inputValue) || inputValue.length === 0 ? "name" : "value")} />
  </div>;
});

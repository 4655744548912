import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import {TextField, RaisedButton, Toggle, DatePicker} from "material-ui";

import ImmutableSelect from "js/components/immutable-select";
import {Success, Err} from "js/components/notification";
import * as fetch from "js/fetch";
import moment from "moment";
import {CrmsContext} from "js/data/contexts";

const ClientProvisioning = createReactClass({

  getInitialState() {
    return {
      clientType: null,
      internalName: "",
      displayName: "",
      crmId: null,
      bullhornUsername: "",
      bullhornPassword: "",
      apiPassword: "",
      isTrial: false,
      trialEndDate: moment.utc().startOf("day").add(30, "days").hours(18),
      isLoading: false,
      errorMessage: null,
      successMessage: null
    };
  },

  render() {
    const {idToCrm} = this.props;
    const crmList = idToCrm.valueSeq();
    const {
      clientType,
      internalName,
      displayName,
      crmId,
      isTrial,
      trialEndDate,
      isLoading,
      successMessage,
      errorMessage,
      bullhornSfId
    } = this.state;
    const currentState = Immutable.fromJS(this.state);
    const initialState = Immutable.fromJS(this.getInitialState());
    const hasChanged = !currentState.equals(initialState);
    const isFormCompleted = hasChanged && !this.formHasEmptyFields() && isValidRawNameStr(internalName) &&
    isValidBhSfId(bullhornSfId);
    return (
        <div style={{padding: "1rem"}}>
          <h3 style={{marginTop: 0}}>Provision a new client</h3>
          <p style={{fontSize: "0.9rem", fontWeight: "bold", marginBottom: "2rem"}}>
            * NOTE: All fields are required
          </p>
          <div style={{width: 190}}>
            <ImmutableSelect
                placeholder="Select client type"
                options={Immutable.fromJS([{
                  value: "ENTERPRISE",
                  label: "Enterprise"
                }, {
                  value: "X",
                  label: "X"
                }])}
                multi={false}
                searchable={false}
                clearable={false}
                selectedValue={clientType}
                onChange={clientType => this.setState({clientType})} />
          </div>
          <TextField
              floatingLabelText="Internal name"
              value={internalName}
              errorText={
                (internalName && !isValidRawNameStr(internalName)) ?
                    "Only lowercase and underscore characters allowed" : ""
              }
              onChange={e => this.setState({internalName: e.target.value})} />
          <br />
          <em style={{fontSize: "0.9rem"}}>* lowercase alphanumeric and underscore characters only</em>
          <br />
          <TextField
              floatingLabelText="Display name in cube19 app UI"
              value={displayName}
              onChange={e => this.setState({displayName: e.target.value})} />
          <div style={{width: 250, marginTop: "1.5rem", marginBottom: "0.5rem"}}>
            <ImmutableSelect
                options={crmList}
                keys={["id", "name"]}
                multi={false}
                searchable={true}
                clearable={false}
                selectedValue={crmId}
                placeholder="Search for CRM"
                onChange={this.onCrmPickerChange} />
          </div>
          {(crmId && this.isBullhornOrTalentRover(crmId) && this.renderSfIdForm())}
          {(crmId && this.isBullhornClient(crmId)) && this.renderBullhornConfigForm()}
          {(crmId && !this.isBullhornOrTalentRover(crmId) && this.renderApiConfigForm())}
          <Toggle
              style={{width: 250, marginTop: "1.5rem", marginBottom: "0.5rem"}}
              toggled={isTrial}
              label={"Is Trial?"}
              onToggle={(_, newVal) => this.setState({isTrial: newVal})} />
          {isTrial && <div>
            <p>Trials will end at 6pm UTC</p>
            <DatePicker
                autoOk={true}
                floatingLabelText="Trial End Date"
                container="inline"
                value={trialEndDate.toDate()}
                onChange={(e, date) => {
                  this.setState(
                      {trialEndDate: moment.utc(date).hours(18)});
                }} />
          </div>}

          <div style={{marginTop: "2rem", marginBottom: "1rem"}}>
            <RaisedButton
                label="Cancel"
                disabled={!hasChanged || isLoading}
                onClick={this.onCancelBtnClick}
                style={{margin: "0.5rem"}} />
            <RaisedButton
                label={<span>Provision {isLoading && <i className="fa fa-spinner fa-pulse" />}</span>}
                primary={true}
                disabled={isLoading || !isFormCompleted}
                onClick={this.onProvisionBtnClick}
                style={{margin: "0.5rem"}} />
          </div>
          <Success message={successMessage} onRequestClose={() => this.setState({successMessage: null})} />
          <Err message={errorMessage} onRequestClose={() => this.setState({errorMessage: null})} />
        </div>
    );
  },

  renderSfIdForm() {
    const {bullhornSfId} = this.state;
    return (
        <div>
          <TextField
              floatingLabelText="Bullhorn SF ID"
              errorText={!isValidBhSfId(bullhornSfId) && "Must be 18 characters long and alphanumeric"}
              value={bullhornSfId}
              style={{width: "512px", verticalAlign: "top"}}
              onChange={e => this.setState({bullhornSfId: e.target.value.substring(0,18)})} />
        </div>
    );
  },

  renderBullhornConfigForm() {
    return (
        <div>
          <p style={{marginBottom: 0}}>
            The following details are also required to provision Bullhorn clients:
          </p>
          <TextField
              floatingLabelText="Bullhorn username"
              style={{marginRight: "1rem", width: "512px"}}
              value={this.state.bullhornUsername}
              onChange={e => this.setState({bullhornUsername: e.target.value})} />
          <TextField
              floatingLabelText="Bullhorn password"
              value={this.state.bullhornPassword}
              style={{width: "512px"}}
              onChange={e => this.setState({bullhornPassword: e.target.value})} />
        </div>
    );
  },

  renderApiConfigForm() {
    return (
        <div>
          <p style={{marginBottom: 0}}>
            A password is also required to provision API clients:
          </p>
          <TextField
              floatingLabelText="API password"
              value={this.state.apiPassword}
              onChange={e => this.setState({apiPassword: e.target.value})} />
          <br />
        </div>
    );
  },

  onCrmPickerChange(crmId) {
    this.setState({
      crmId,
      bullhornUsername: "",
      bullhornPassword: "",
      apiPassword: ""
    });
  },

  isBullhornClient(crmId) {
    if (!crmId) {
      return false;
    }
    const crm = this.props.idToCrm.get(crmId);
    return crm && crm.get("name").includes("Bullhorn");
  },

  isBullhornOrTalentRover(crmId) {
    if (!crmId) {
      return false;
    }
    const crm = this.props.idToCrm.get(crmId);
    return crm && (crm.get("name").includes("Bullhorn") || crm.get("name") === "Talent Rover");
  },

  onCancelBtnClick() {
    this.setState(this.getInitialState());
  },

  onProvisionBtnClick() {
    this.setState({isLoading: true});
    const params = {
      "client-type": this.state.clientType,
      "internal-name": this.state.internalName,
      "pretty-name": this.state.displayName,
      "crm-id": this.state.crmId,
      "bullhorn-username": this.state.bullhornUsername,
      "bullhorn-password": this.state.bullhornPassword,
      "bullhorn-sf-id": this.state.bullhornSfId,
      "api-password": this.state.apiPassword,
      "is-trial": this.state.isTrial,
      "expires-at": this.state.isTrial ? this.state.trialEndDate : null
    };
    provisionClient(params).then(() => {
      this.setState({
        ...this.getInitialState(),
        successMessage: "New client provisioned"
      });
    }, error => {
      const defaultErrorMsg = "Unable to provision client";
      const errorStatus = error.response.status;
      if (errorStatus === 400) {
        error.response.text()
            .then(errorJsonStr => {
              const errorJson = JSON.parse(errorJsonStr);
              const errorMessage = errorJson.message || defaultErrorMsg;
              this.setState({
                isLoading: false,
                errorMessage
              });
            });
      } else {
        this.setState({
          isLoading: false,
          errorMessage: defaultErrorMsg
        });
      }
    });
  },

  formHasEmptyFields() {
    const formFields = Immutable.Set.of("clientType", "internalName", "displayName", "crmId");
    const bullhornRequiredFormFields = Immutable.Set.of(
        "bullhornUsername",
        "bullhornPassword"
    );
    const apiFormFields = Immutable.Set.of("apiPassword");
    let requiredFields;
    if (this.isBullhornClient(this.state.crmId)) {
      requiredFields = formFields.union(bullhornRequiredFormFields);
    } else if (!this.isBullhornOrTalentRover(this.state.crmId)) {
      requiredFields = formFields.union(apiFormFields)
    } else {
      requiredFields = formFields;
    }
    return requiredFields.some(k => {
      const value = this.state[k];
      return value === null || value.length === 0;
    });
  }

});

const isValidRawNameStr = str => {
  const regex = /^[a-z0-9_]+$/g;
  return regex.test(str);
};

const isValidBhSfId = str => !str || (str.length === 18 && str.match(/^[0-9a-zA-Z]+$/));

const provisionClient = clientParams => fetch.post("client-provision", clientParams);

const ConnectedClientProvisioning = props => {
  const {idToCrm} = React.useContext(CrmsContext);
  return <ClientProvisioning {...props} idToCrm={idToCrm} />;
};

export default ConnectedClientProvisioning;

import React from "react";

import ImmutableSelect from "js/components/immutable-select";
import LoadingSpinner from "js/components/loading-spinner";
import RaisedButton from "material-ui/RaisedButton";
import ValueEditor from "js/app-areas/etl-config/value-editor";

export default React.memo(({
  rejections,
  onChange,
  attr,
  metadataForField,
  loadingMetadata
}) => {
  const [useJsonEditor, setUseJsonEditor] = React.useState(false);

  const name = attr.get("name");

  const onChangeRejections = React.useCallback(
    newRejections => onChange(name, newRejections),
    [name, onChange]);

  rejections = rejections.toSet();
  let allRejectionsExistInMeta;
  if (metadataForField) {
    const allMetadataValues = metadataForField.get("options").map(o => o.get("value")).toSet();
    allRejectionsExistInMeta = rejections.every(rejection => allMetadataValues.includes(rejection));
  } else {
    allRejectionsExistInMeta = false;
  }

  if (loadingMetadata) {
    return <LoadingSpinner />;
  } else if (metadataForField && !useJsonEditor && allRejectionsExistInMeta) {
    return (
      <div
        title={attr.get("label")}
        style={{
          width: "100%",
          marginTop: "1rem",
          marginRight: "1rem"
        }}>
        <ImmutableSelect
          placeholder={attr.get("label")}
          options={metadataForField.get("options")}
          multi={true}
          searchable={true}
          clearable={true}
          closeMenuOnSelect={false}
          selectedValues={rejections}
          onChange={onChangeRejections} />
        <div style={{marginTop: "1rem"}}>
          <RaisedButton
            style={{minWidth: 45}}
            title="Edit the raw JSON"
            label={<span><i className="fa fa-pencil" /> Raw JSON</span>}
            onClick={() => setUseJsonEditor(true)} />
        </div>
      </div>);
  } else {
    return (
      <div style={{width: "100%", marginRight: "1rem"}}>
        <ValueEditor
          prop={attr}
          value={rejections}
          onChange={onChangeRejections} />
        <RaisedButton
          style={{minWidth: 45}}
          title={allRejectionsExistInMeta ? "Use the editing UI" : "Unable to edit with UI, either no metadata available or using values not in metadata"}
          disabled={!allRejectionsExistInMeta}
          label={<span><i className="fa fa-pencil" /> With UI</span>}
          onClick={() => setUseJsonEditor(false)} />
      </div>);
  }
});

const step1BulkIdPath = ["step-1-bulk-load", "id"];
const step1BulkStatusPath = ["step-1-bulk-load", "status"];
const step1SubmissionPath = ["step-1-submission"];
const step1SubmissionIdPath = [...step1SubmissionPath, "submission-id"];
const step1StatusPath = [...step1SubmissionPath, "status"];
const step1SubmittedByPath = [...step1SubmissionPath, "submitted-by"];
const step1PayloadPath = [...step1SubmissionPath, "payload"];
const productTierPath = [...step1PayloadPath, "product-tier"];
const clientNamePath = [...step1PayloadPath, "client-name"];

const step2SubmissionPath = ["step-2-submission"];
const step2SubmissionIdPath = [...step2SubmissionPath, "submission-id"];
const step2StatusPath = [...step2SubmissionPath, "status"];
const step2SubmittedByPath = [...step2SubmissionPath, "submitted-by"];
const step2PayloadPath = [...step2SubmissionPath, "payload"];
const step2ParamsPath = [...step2PayloadPath, "params"];
const step2EtlOptionsPath = [...step2ParamsPath, "etl-options"];
const step2OutputPath = [...step2SubmissionPath, "output"];
const step2KpiConfigsPath = [...step2OutputPath, "kpi-configs"];

const kpiConfigsPath = ["kpiConfigs"];
const kpiSimpleSumMasterKpiTypesPath = ["simple-sum-master-kpi-types"];
const kpiOriginalNamePath = ["originalName"];
const kpiOriginalExplanationPath = ["originalExplanation"];
const kpiMasterKpiTypePath = ["master-kpi-type"];
const kpiVisibilitySetManuallyPath = ["visibility-set-manually"];

const kpiPath = ["kpi"];
const kpiIdPath = ["kpi_id"];
const kpiNamePath = [...kpiPath, "name"];
const kpiExplanationPath = [...kpiPath, "explanation"];
const kpiVisiblePath = [...kpiPath, "visible"];
const kpiCombineWithMasterKpiTypePath = [...kpiPath, "combine_with_master_kpi_type"];
const kpiConfigPath = [...kpiPath, "config"];
const kpiFilterPath = [...kpiConfigPath, "filter"];
const kpiForwardReportPath = [...kpiConfigPath, "forwardReport"];
const kpiDateEntityColumnIdPath = [...kpiConfigPath, "dateEntityColumnId"];
const kpiDateJoinPathPath = [...kpiConfigPath, "dateJoinPath"];
const kpiEntityToOwnerTypesPath = [...kpiConfigPath, "entityToOwnerTypes"];

export {
  step1SubmissionIdPath,
  step1StatusPath,
  step1BulkIdPath,
  step1BulkStatusPath,
  productTierPath,
  clientNamePath,
  step1SubmittedByPath,

  step2SubmissionIdPath,
  step2StatusPath,
  step2SubmittedByPath,
  step2ParamsPath,
  step2EtlOptionsPath,
  step2KpiConfigsPath,

  kpiConfigsPath,
  kpiSimpleSumMasterKpiTypesPath,
  kpiOriginalNamePath,
  kpiOriginalExplanationPath,
  kpiMasterKpiTypePath,
  kpiVisibilitySetManuallyPath,

  kpiIdPath,
  kpiNamePath,
  kpiExplanationPath,
  kpiVisiblePath,
  kpiCombineWithMasterKpiTypePath,
  kpiConfigPath,
  kpiFilterPath,
  kpiForwardReportPath,
  kpiDateEntityColumnIdPath,
  kpiDateJoinPathPath,
  kpiEntityToOwnerTypesPath
};
import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import {connect} from "react-redux";

import DataTable from "js/components/data-table";
import RaisedButton from "material-ui/RaisedButton";
import LoadingSpinner from "js/components/loading-spinner";
import Dialog from "material-ui/Dialog";
import {AccordionContainer, AccordionSection} from "js/components/accordion";

import * as fetch from "js/fetch";

const Page = createReactClass({

    getInitialState() {
        return {
            loading: false,
            slowQueryResultSets: Immutable.Map(),
            activeProcessResultSets: Immutable.Map(),
            loadedAt: null,
            selectedData: "",
        };
    },

    componentDidMount() {
        this.loadAndSet();
    },

    render() {
        if (this.state.loading) {
            return <LoadingSpinner />;
        }

        const {
            slowQueryResultSets,
            activeProcessResultSets,
            loadedAt,
            selectedData
        } = this.state;

        const slowQueryEls = slowQueryResultSets
            .entrySeq()
            .map(([name, resultSet]) => {
                const id = "slow-query" + name;
                const columns = resultSet.get(0);
                return (
                    <AccordionSection key={id} title={"Slow Queries for " + name}>
                        <DataTable
                            columns={columns.toJS()}
                            rows={resultSet.shift().toJS()}
                            initialSortDirection="DESC"
                            onCellClick={cell => this.setState({selectedData: cell})} />
                    </AccordionSection>
                );
            });

        const activeProcessEls = activeProcessResultSets
            .entrySeq()
            .map(([name, resultSet]) => {
                const id = "active-process" + name;
                return (
                    <AccordionSection key={id} title={"Active Processes for " + name}>
                        <DataTable
                            columns={resultSet.get(0).toJS()}
                            rows={resultSet.shift().toJS()}
                            initialSortDirection="DESC"
                            onCellClick={cell => this.setState({selectedData: cell})} />
                    </AccordionSection>
                );
            });

        return (
            <div style={{padding: "1rem"}}>
                <Dialog
                  modal={false}
                  open={!!selectedData}
                  onRequestClose={() => this.setState({selectedData: null})}
                  autoScrollBodyContent={true}>
                    <pre>{selectedData}</pre>
                </Dialog>
                <RaisedButton label="Refresh" onClick={this.loadAndSet} />
                <p>Loaded at {loadedAt && loadedAt.toString()}</p>
                <AccordionContainer allowOnlyOneOpen={false} stateless={true}>
                    {slowQueryEls.concat(activeProcessEls).toArray()}
                </AccordionContainer>
            </div>);
    },

    loadAndSet() {
        this.setState({loading: true, loadedAt: new Date()});

        const slowPromise = loadSlowQueryLogs();
        slowPromise.then(slowQueryResultSets => this.setState({slowQueryResultSets}));
        const activePromise = loadActiveProcesses();
        activePromise.then(activeProcessResultSets => this.setState({activeProcessResultSets}));

        Promise.all([activePromise, slowPromise]).then(() => this.setState({loading: false}));
    }

});

const loadSlowQueryLogs = () => fetch
    .getJson("db-perf/slow-query-logs")
    .then(res => Immutable.fromJS(res));

const loadActiveProcesses = () => fetch
    .getJson("db-perf/active-processes")
    .then(res => Immutable.fromJS(res));

export default connect()(Page);

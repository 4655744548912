import Immutable from "immutable";

import createReducer from "js/create-reducer";


const initialEntityRenamingPageState = Immutable.fromJS({
  entityEtlAndNameMappings: Immutable.List()
});

const entityRenamingPageReducer = createReducer(initialEntityRenamingPageState, {
  MERGE_ENTITY_RENAMING_PAGE: (state, {newState}) => state.merge(newState),
  MERGE_IN_ENTITY_RENAMING_PAGE: (state, {path, defaultState, newState}) => state.updateIn(
      path,
      defaultState,
      s => s.merge(newState)),
  SET_IN_ENTITY_RENAMING_PAGE: (state, {path, newValue}) => state.setIn(path, newValue)
});

const mergeEntityRenamingPageState = newState => ({
  type: "MERGE_ENTITY_RENAMING_PAGE",
  newState
});
const mergeInEntityRenamingPageState = (
    path,
    newState,
    defaultState = Immutable.Map()) => ({
  type: "MERGE_IN_ENTITY_RENAMING_PAGE",
  path,
  defaultState,
  newState
});
const setInEntityRenamingPageState = (path, newValue) => ({
  type: "SET_IN_ENTITY_RENAMING_PAGE",
  path,
  newValue
});

const makeSelector = fn => state => fn(state.get("entityRenamingPage"));
const entityEtlAndNameMappingsSelector = makeSelector(state => state.get("entityEtlAndNameMappings"));

export {
  entityRenamingPageReducer,
  mergeEntityRenamingPageState,
  mergeInEntityRenamingPageState,
  setInEntityRenamingPageState,
  entityEtlAndNameMappingsSelector
};

import * as Fetch from "js/fetch";

const fetchEntityFieldMappings = clientId => Fetch
    .getJson("entity-renaming/mappings/" + clientId);

const fetchDwGroupingEntities = () => Fetch
    .getJson("entity-renaming/dw-grouping-entities");

const fetchLinkContexts = () => Fetch
    .getJson("entity-renaming/link-contexts");

export {fetchEntityFieldMappings, fetchDwGroupingEntities, fetchLinkContexts};

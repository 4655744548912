import Immutable from "immutable";

import {apiLoadedSuccessfully} from "js/data/etl-configs/reducer";
import {getErrorMessage} from "js/utils/errors";
import {fetchEtlConfig} from "js/data/etl-configs/api";

export const mergeEtlConfigsState = newState => ({
  type: "MERGE_ETL_CONFIGS",
  newState
});
export const mergeInEtlConfigsState = (
    path,
    newState,
    defaultState = Immutable.Map()) => ({
  type: "MERGE_IN_ETL_CONFIGS",
  path,
  defaultState,
  newState
});
export const setInEtlConfigsState = (path, newValue) => ({
  type: "SET_IN_ETL_CONFIGS",
  path,
  newValue
});
export const loadEtlConfig = clientId => dispatch => {
  return fetchEtlConfig(clientId).then(
      typeToConfig => {
        return dispatch(mergeEtlConfigsState(Immutable.Map({
          typeToConfig,
          api: Immutable.Map({configs: apiLoadedSuccessfully})
        })));
      },
      error => getErrorMessage(error).then(message => {
        return dispatch(setInEtlConfigsState(["api", "configs", "error"], message));
      }));
};
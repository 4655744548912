import React from "react";

import ChangeSubmissionTable from "js/app-areas/custom-sql/change-submissions-table";
import ProdDBChangeReqForm from "js/app-areas/custom-sql/prod-db-change-req-form";

const CustomSql = () => (
	<div style={{padding: "1rem"}}>
		<ProdDBChangeReqForm />
		<ChangeSubmissionTable />
	</div>);

export default CustomSql;

import React from "react";
import Immutable from "immutable";
import {IconButton, Menu, MenuItem, Popover, RaisedButton} from "material-ui";

import HomePage from "js/app-areas/onboarding/home/home-page";
import StepOnePage from "js/app-areas/onboarding/step-1/step-one-page";
import StepTwoPage from "js/app-areas/onboarding/step-2/step-two-page";
import {ClientsContext, CurrentUserContext, SelectedClientIdContext} from "js/data/contexts";
import * as Fetch from "js/fetch";
import {clientNamePath, productTierPath} from "js/app-areas/onboarding/onboarding-clients-config";
import {productTiers} from "js/app-areas/onboarding/product-tiers";

const onboardingPages = {
    main: "MAIN",
    step1: "STEP1",
    step2: "STEP2"
};

const OnboardingApp = () => {

  const [currentPage, setCurrentPage] = React.useState(onboardingPages.main);
  const [isLoading, setIsLoading] = React.useState(true);
  const [onboardingClients, setOnboardingClients] = React.useState(null);
  const [currentClient, setCurrentClient] = React.useState(null);
  const [isNewClientMenuOpen, setIsNewClientMenuOpen] = React.useState(false);
  const [newClientProductTier, setNewClientProductTier] = React.useState(null);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const {currentUser} = React.useContext(CurrentUserContext);
  const permissions = currentUser.get("permissions");
  const {idToClient} = React.useContext(ClientsContext);
  const {selectedClientId} = React.useContext(SelectedClientIdContext);

  const isReadOnly = (currentClient?.get("reconfigurationTool") ?? "ONBOARDING") !== "ONBOARDING";

  const loadAndSetOnboardingClients = React.useCallback(() => {
    setIsLoading(true);
    return (selectedClientId
        ? loadSubmissionsByClientId(selectedClientId)
        : loadRecentSubmissions())
            .then(clients => {
              setOnboardingClients(clients);
              setIsLoading(false);
            });
  }, [selectedClientId]);

  const updateClient = React.useCallback(client => {
    const clientId = client.get("client-id");
    setOnboardingClients(clients => {
      const index = clients.findKey(c => c.get("client-id") === clientId);
      if (index !== undefined) {
        return clients.set(index, client);
      } else {
        return clients.push(client);
      }
    });
  }, []);

  React.useEffect(() => {
    loadAndSetOnboardingClients();
  }, [loadAndSetOnboardingClients]);

  let content;

  switch (currentPage) {
    case onboardingPages.main:
      content = <HomePage
          idToClient={idToClient}
          setCurrentPage={setCurrentPage}
          onboardingClients={onboardingClients}
          reloadClients={loadAndSetOnboardingClients}
          isLoading={isLoading}
          setCurrentClient={setCurrentClient} />;
      break;
    case onboardingPages.step1:
      content = <StepOnePage
          productTier={(currentClient?.getIn(productTierPath)) ?? newClientProductTier}
          currentClient={currentClient}
          setCurrentPage={setCurrentPage}
          setCurrentClient={setCurrentClient}
          updateClient={updateClient} />;
      break;
    case onboardingPages.step2:
      content = <StepTwoPage
          idToClient={idToClient}
          currentUserUsername={currentUser.get("username")}
          setCurrentPage={setCurrentPage}
          currentClient={currentClient}
          updateClient={updateClient} />;
      break;
    default:
      content = <div>Nothing To See Here</div>;
  }

  const handleOnboardNewClick = event => {
    setCurrentClient(null);
    setIsNewClientMenuOpen(true);
    setMenuAnchor(event.currentTarget);
  };

  const handleRequestMenuClose = () => setIsNewClientMenuOpen(false);

  const handleClientTypeSelect = type => {
    setIsNewClientMenuOpen(false);
    setNewClientProductTier(type);
    setCurrentPage(onboardingPages.step1);
  };

  const HomeButton = () => <IconButton
      style={{alignSelf: "center"}}
      iconStyle={{color: "rgb(0, 188, 212)"}}
      onClick={() => {
        setCurrentPage(onboardingPages.main);
        setCurrentClient(null);
      }}
      iconClassName="fa fa-home"
  />;

  return <div style={{padding: 50}}>
    <div style={{display: "flex", marginBottom: 10, justifyContent: "space-between"}}>
      <div style={{display: "flex"}}>
        <h1>Onboarding</h1>
        {currentPage === onboardingPages.main ?
            <OnboardButton
                permissions={permissions}
                onClick={handleOnboardNewClick}
                onOptionSelect={handleClientTypeSelect}
                onRequestClose={handleRequestMenuClose}
                menuAnchor={menuAnchor}
                isMenuOpen={isNewClientMenuOpen} /> :
            <HomeButton setCurrentPage={setCurrentPage} />
        }
        {(currentPage === onboardingPages.step2 || currentPage === onboardingPages.step1) && <div style={{display: "flex"}}>
          <div
              style={{
                height: 30,
                alignSelf: "center",
                backgroundColor: "lightgray",
                padding: 5,
                textTransform: "uppercase"
              }}>
            {currentClient ?
                currentClient.getIn(clientNamePath)
                + " ("
                + currentClient.getIn(productTierPath)
                + " client)" :
                newClientProductTier}
          </div>
          {isReadOnly && <div
              style={{
                height: 30,
                alignSelf: "center",
                backgroundColor: "#EEBA56",
                padding: 5,
                marginLeft: 5,
                textTransform: "uppercase"
              }}>READ ONLY</div>}
        </div>}
      </div>
      {currentPage === onboardingPages.main && <div style={{width: 450, display: "flex"}}>
        <div style={{marginRight: 10, marginTop: 8, fontSize: "1.2rem", display: "flex", justifyContent: "space-between"}}>
          <i className="fa fa-lock"  />
          <div style={{marginLeft: 10}}>=</div>
        </div>
        <div>Client locked to preserve changes made outside the Onboarding tool. Client can no longer be reconfigured.
        </div>
      </div>}
    </div>
    {content}
  </div>;
};

const OnboardButton = ({onClick, menuAnchor, isMenuOpen, onRequestClose, onOptionSelect, permissions}) => {

  const hasOnboardingPermission = permissions.includes(productTiers.growth.onboardSubmissionPermission) ||
      permissions.includes(productTiers.enterprise.onboardSubmissionPermission);

  return <div style={{alignSelf: "center", width: 200}}>
    <RaisedButton
        style={{height: 30, marginLeft: 20, width: "100%"}}
        disabled={!hasOnboardingPermission}
        label="Onboard New Client"
        primary={true}
        onClick={onClick} />
    <Popover
        style={{width: 230}}
        anchorEl={menuAnchor}
        anchorOrigin={{horizontal: "left", vertical: "bottom"}}
        targetOrigin={{horizontal: "left", vertical: "top"}}
        onRequestClose={onRequestClose}
        open={isMenuOpen}>
      <Menu onChange={(e, value) => onOptionSelect(value)}>
        {permissions.includes(productTiers.growth.onboardSubmissionPermission) && <MenuItem
            value={productTiers.growth.enum}
            primaryText={productTiers.growth.label} />}
        {permissions.includes(productTiers.growthAddOn.onboardSubmissionPermission) && <MenuItem
            value={productTiers.growthAddOn.enum}
            primaryText={productTiers.growthAddOn.label} />}
        {permissions.includes(productTiers.corporate.onboardSubmissionPermission) && <MenuItem
            value={productTiers.corporate.enum}
            primaryText={productTiers.corporate.label} />}
        {permissions.includes(productTiers.enterprise.onboardSubmissionPermission) && <MenuItem
            value={productTiers.enterprise.enum}
            primaryText={productTiers.enterprise.label} />}
      </Menu>
    </Popover>
  </div>;
};

const loadRecentSubmissions = () => Fetch
    .getJson("onboarding/recent-submissions")
    .then(response => Immutable.fromJS(response));

const loadSubmissionsByClientId = (clientId) => Fetch
    .get("onboarding/submissions-by-client", {"cube19-client-id": clientId})
    .then(response => response.status === 200 ? response.json() : null)
    .then(response => Immutable.fromJS(response != null ? [response] : []));

export {onboardingPages}
export default OnboardingApp;

const devApiurl = window.cube19DevApiUrl || "https://pba-admin-console-api.cube19.io";
const prodApiUrl = window.cube19ApiUrl;

export const apiUrl = prodApiUrl ? prodApiUrl : devApiurl;

const devAppApiUrl = window.cube19DevAppApiUrl;
const prodAppApiUrl = window.cube19AppApiUrl;

export const appApiUrl = prodAppApiUrl ? prodAppApiUrl : devAppApiUrl;

const devAppUiUrl = window.cube19DevAppUiUrl;
const prodAppUiUrl = window.cube19AppUiUrl;

export const appUiUrl = prodAppUiUrl ? prodAppUiUrl : devAppUiUrl;
import Immutable from "immutable";

import createReducer from "js/create-reducer";

const REFRESH = "REFRESH_CLIENT_OVERVIEW_PIVOT_TABLE_CONFIG";
const TOGGLE_SHOW_TOTALS = "TOGGLE_SHOW_TOTALS_FOR_CLIENT_OVERVIEW";

const initialState = Immutable.Map({
    showTotals: false,
    options: Immutable.Map({
        aggregatorName: "List Unique Values",
        vals: [ "Nice Value" ],
        rows: ["Category", "Name"],
        cols: ["Client"]
    })
});

export default createReducer(initialState, {
    [REFRESH]: (state, action) => state.set("options", action.options),
    [TOGGLE_SHOW_TOTALS]: (state, action) => state.set("showTotals", action.showTotals)
});

// ACTIONS

export const toggleShowTotals = showTotals => ({
    type: TOGGLE_SHOW_TOTALS,
    showTotals
});

export const onPivotTableRefresh = options => ({
    type: REFRESH,
    options
});

/** @jsxImportSource @emotion/react */

import React from "react";
import * as Immutable from "immutable";
import {css, Global} from "@emotion/react";

import {NavBar, idToPage} from "js/nav-bar";
import Loading from "js/components/loading-spinner";
import LoadingOverlay from "js/components/loading-overlay";
import Login from "js/app-areas/login/page";
import ClientOverview from "js/app-areas/client-overview/page";
import ClientDetails from "js/app-areas/client-details/page";
import ClientMetrics from "js/app-areas/client-metrics/page";
import CustomSql from "js/app-areas/custom-sql/page";
import CustomerSupport from "js/app-areas/customer-support/page";
import ClientProvisioning from "js/app-areas/client-provisioning/page";
import EnvironmentConfig from "js/app-areas/environment-config/page";
import DatabasePerformance from "js/app-areas/database-performance";
import ChargeableUsers from "js/app-areas/chargeable-users/page";
import FunctionalTestPage from "js/app-areas/functional-test/page";
import SwitchUser from "js/app-areas/switch-user/page";
import CrmMetadata from "js/app-areas/crm-metadata/page";
import EtlConfig from "js/app-areas/etl-config/page";
import CrmIntegrations from "js/app-areas/crm-integrations/page";
import EntityRenamingPage from "js/app-areas/entity-renaming/page";
import OnboardingApp from "js/app-areas/onboarding/app";
import Apsco from "js/app-areas/apsco/page";
import UserReportPage from "js/app-areas/active-users-report/page";
import BoxlabPage from "js/app-areas/boxlab";

import {useSyncGlobalState, useAsyncGlobalState, renderContexts} from "js/data/global-state";
import * as fetch from "js/fetch";
import {hasSession} from "js/app-areas/login/auth";
import * as localStore from "js/local-store";
import {indexBy} from "js/utils/collections";
import useMountEffect from "js/utils/hooks/use-mount-effect";
import {
  CurrentPageContext,
  CurrentUserContext,
  ClientsContext,
  CrmsContext,
  SelectedClientIdContext,
  CrmMetadataContext
} from "js/data/contexts";
import * as Rata from "js/data/remote-data";

const globalCss = <Global
    styles={css`
      body,
      html {
        height: 100%;
        font-family: "Lato", sans-serif;
        font-size: 1rem;
        margin: 0;
        padding: 0;
        color: #333;
      }

      #app {
        height: 100%;
        overflow: hidden;
      }

      * {
        box-sizing: border-box;
      }

      .clearfix:after {
        content: "";
        display: block;
        clear: both;
      }

      ul {
        margin: 0;
        padding: 0;
      }

      li {
        list-style: none;
      }
    `} />;

const decideLandingPage = permissions => {
  const previousPageStr = localStore.get("admin-console.last-used-page");
  const previousPage = idToPage.get(previousPageStr);

  if (previousPage && permissions.includes(previousPage.get("permission"))) {
    return previousPage.get("id");
  } else {
    const permissionToPage = indexBy(
        p => p.get("permission"),
        idToPage.valueSeq().sortBy(p => p.get("order")));
    const permission = permissions.find(x => permissionToPage.has(x));
    return permissionToPage.get(permission) ? permissionToPage.get(permission).get("id") : "BLANK_PAGE";
  }
};

const loadCurrentUser = () => fetch
    .getJson("permissions/current-user")
    .then(res => Immutable.fromJS(res).update("permissions", ps => ps.toSet()));

const loadIdToClient = () => fetch
    .getJson("clients")
    .then(res => indexBy(x => x.get("id"), Immutable.fromJS(res)));

const loadIdToCrm = () => fetch
    .getJson("crms")
    .then(res => indexBy(x => x.get("id"), Immutable.fromJS(res)));

const App = () => {
  const CurrentUserState = useAsyncGlobalState(
      Immutable.Map(),
      "currentUser",
      {loader: loadCurrentUser});
  const CurrentPageState = useSyncGlobalState("LOADING", "currentPage");
  const CrmsState = useAsyncGlobalState(
      Immutable.Map(),
      "idToCrm",
      {loader: loadIdToCrm});
  const ClientsState = useAsyncGlobalState(
      Immutable.Map(),
      "idToClient",
      {loader: loadIdToClient});
  const SelectedClientIdState = useSyncGlobalState(null, "selectedClientId");

  const CrmMetadataState = useSyncGlobalState(Immutable.Map(), "clientIdToCrmMetadata");

  const {currentPage, setCurrentPage} = CurrentPageState;
  const setAndSaveCurrentPage = React.useCallback(newPage => {
    localStore.set("admin-console.last-used-page", newPage);
    setCurrentPage(newPage);
  }, [setCurrentPage]);

  useMountEffect(() => {
    hasSession().then(hasSession => {
      if (hasSession) {
        CrmsState.reload();
        ClientsState.reload();
        CurrentUserState.reload().then(currentUser => {
          const landingPage = decideLandingPage(currentUser.get("permissions"));
          setCurrentPage(landingPage);
        });
      } else {
        setCurrentPage("LOGIN");
      }
    });
  }, []);

  const contextsAndValues = React.useMemo(
      () => Immutable.List([{
        context: CurrentUserContext,
        value: CurrentUserState
      }, {
        context: CurrentPageContext,
        value: CurrentPageState
      }, {
        context: CrmsContext,
        value: CrmsState
      }, {
        context: ClientsContext,
        value: ClientsState
      }, {
        context: SelectedClientIdContext,
        value: SelectedClientIdState
      }, {
        context: CrmMetadataContext,
        value: CrmMetadataState
      }]),
      [
        CurrentUserState,
        CurrentPageState,
        CrmsState,
        ClientsState,
        SelectedClientIdState,
        CrmMetadataState
      ]);

  const Page = nameToPageComponent[currentPage] || Loading;

  const {currentUser} = CurrentUserState;
  const isLoggedIn = !!currentUser.get("username");

  const isRequiredDataLoading = ClientsState.status === Rata.Status.LOADING || CrmsState.status === Rata.Status.LOADING;
  let content;
  if (!isLoggedIn) {
    content = <Page />;
  } else {
    content = <div>
      {isRequiredDataLoading && <LoadingOverlay />}
      <Page />
    </div>;
  }

  return <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
    {renderContexts(
        contextsAndValues,
        (<>
              {globalCss}
              <NavBar
                  currentUsername={currentUser.get("username")}
                  permissions={currentUser.get("permissions")}
                  currentPage={currentPage}
                  setCurrentPage={setAndSaveCurrentPage} />
              <main
                  id={"content"}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: "1 1 auto",
                    overflow: "auto"
                  }}>
                {content}
              </main>
            </>
        ))}
  </div>;
};

export default App;

const nameToPageComponent = {
  LOADING: Loading,
  LOGIN: Login,
  APSCO: Apsco,
  CLIENT_OVERVIEW: ClientOverview,
  CLIENT_DETAILS: ClientDetails,
  CLIENT_METRICS: ClientMetrics,
  CUSTOM_SQL: CustomSql,
  CUSTOMER_SUPPORT: CustomerSupport,
  PROVISION_CLIENT: ClientProvisioning,
  ENVIRONMENT_CONFIG: EnvironmentConfig,
  DB_PERF: DatabasePerformance,
  CHARGEABLE_USERS: ChargeableUsers,
  FUNCTIONAL_TEST_VIEWER: FunctionalTestPage,
  SWITCH_USER_SUBMISSION: SwitchUser,
  CRM_METADATA_SUBMISSION: CrmMetadata,
  ETL_CONFIG_SUBMISSION: EtlConfig,
  CRM_INTEGRATIONS: CrmIntegrations,
  ENTITY_RENAMING: EntityRenamingPage,
  ONBOARDING: OnboardingApp,
  USER_REPORT: UserReportPage,
  BOXLAB: BoxlabPage,
  BLANK_PAGE: () => <p>You do not have permission to see any pages</p>
};

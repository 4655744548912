import React from "react";
import pure from "js/pure";

export default pure(() => (
    <div style={containerStyle}>
        <i className="fa fa-spinner fa-pulse fa-4x" style={iconStyle} />
    </div>));

const containerStyle = {
    display: "block",
    width: "100%",
    margin: "10px 0px",
    textAlign: "center"
};

const iconStyle = {
    color: "#555",
    padding: "50px"
};

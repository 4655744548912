import "gridlex/dist/gridlex.css";

import React from "react";
import createReactClass from "create-react-class";
import {connect} from "react-redux";
import RaisedButton from "material-ui/RaisedButton";

import ClientPicker from "js/components/client-picker";
import Err from "js/components/error";
import PivotTable from "js/pivot-table/container";
import {isDataTableTypeReport, downloadTableAsExcelFile} from "js/utils/pivot-utils";
import Modal from "js/modal/root";
import * as modal from "js/modal/reducer";
import * as pivotTableConfig from "js/app-areas/client-metrics/pivot-table-config-reducer";
import * as clientMetricsData from "js/app-areas/client-metrics/pivot-data-reducer";
import getC19TestClients from "js/c19-test-clients";
import ReactDOM from "react-dom";
import FileSaver from "browser-filesaver";
import {ClientsContext} from "js/data/contexts";

const mapStateToProps = (state) => {
  const clientMetricsData = state.get("clientMetricsData");
  const pivotTableConfig = state.get("clientMetricsPivotTableConfig");
  return {
    filters: clientMetricsData.get("filters"),
    isFetchingData: clientMetricsData.get("isFetching"),
    data: clientMetricsData.get("data"),
    error: clientMetricsData.get("error"),
    modal: state.get("modal"),
    pivotTableOptions: pivotTableConfig.get("options"),
    showTotals: pivotTableConfig.get("showTotals")
  };
};

const mapDispatchToProps = {
  onClientPickerChange: clientMetricsData.onClientPickerChange,
  onLoadDataClick: clientMetricsData.loadData,
  onToggleShowTotals: pivotTableConfig.toggleShowTotals,
  onPivotTableRefresh: pivotTableConfig.onPivotTableRefresh,
  openNoDataMessageModal: modal.openNoDataMessageModal,
  openDataTableModal: modal.openDataTableModal,
  closeModal: modal.closeModal
};

const ClientMetrics = connect(mapStateToProps, mapDispatchToProps)(createReactClass({

  render() {
    const {
      idToClient,
      filters,
      pivotTableOptions,
      showTotals,
      isFetchingData,
      data,
      error,
      modal,
      closeModal,
      onPivotTableRefresh,
      onToggleShowTotals
    } = this.props;
    const hasInvalidParams = filters.get("clientIds").isEmpty();
    const isDataLoaded = !!data;

    return (
        <div style={{padding: "1rem"}}>
          <div className="grid">
            <div className="col">
              <div className="clearfix">
                <label>Client(s)</label>
                <ClientPicker
                    multi={true}
                    value={filters.get("clientIds")}
                    idToClient={idToClient}
                    onChange={this.props.onClientPickerChange} />
                {filters.get("clientIds").isEmpty()
                && <Err type="error"
                        message="Select at least 1 client"
                        customStyle={{display: "block", marginTop: "0.5em"}} />}
              </div>
            </div>
            <div>
              <RaisedButton
                  label="Load Data"
                  primary={true}
                  disabled={hasInvalidParams}
                  onClick={() => this.props.onLoadDataClick(filters)}
                  style={{marginTop: "1.15em", marginBottom: "0.5em"}} />
              {error === clientMetricsData.FAILED_TO_LOAD
              && <Err type="error" message="Unable to load data" />}
            </div>
          </div>
          <PivotTable
              options={pivotTableOptions}
              showTotals={showTotals}
              isFetchingData={isFetchingData}
              data={isDataLoaded ? this.getData() : null}
              onRefresh={onPivotTableRefresh}
              onToggleShowTotals={onToggleShowTotals}
              onCellClick={this.onPivotTableCellClick}
              onDownloadReportRequest={this.handleDownloadReportRequest} />
          {modal.get("isModalOpen") &&
          <Modal headerLabel={modal.get("modalHeader")} onRequestClose={closeModal}>
            {modal.get("modalContent")}
          </Modal>}
        </div>
    );
  },

  getData() {
    const {data, excludeC19Clients} = this.props;
    if (excludeC19Clients) {
      return data.filter(row => row === clientMetricsData.COLUMNS || !row.some(cell => getC19TestClients().has(cell)));
    } else {
      return data;
    }
  },

  handleDownloadReportRequest() {
    const {filters, pivotTableOptions} = this.props;
    if (isDataTableTypeReport(pivotTableOptions.get("rendererName"))) {
      const $ = window.jQuery;
      const $this = $(ReactDOM.findDOMNode(this));
      const tableHtml = $this.find(".pvtTable").html();
      const blob = downloadTableAsExcelFile(tableHtml);
      const title = filters.get("clientIds").join();
      const fileName = `client-metrics-client-${title}.xls`;
      FileSaver.saveAs(blob, fileName);
    } else {
      alert("Unable to Save to File:\nThis functionality only works for data tables.\nPlease choose a Table type report (e.g. Table, Table Barchart, Heatmap).");
    }
  },

  onPivotTableCellClick(dataFilter) {
    const {data, openNoDataMessageModal, openDataTableModal} = this.props;
    const rows = data
        .filter(row => Object.keys(dataFilter)
            .every(key => row.includes(dataFilter[key])));

    if (rows.length === 0) {
      openNoDataMessageModal();
    } else {
      const headerLabel = Object.keys(dataFilter)
          .map(key => `${key} - ${dataFilter[key]}`)
          .join(" / ");
      const columns = clientMetricsData.COLUMNS;
      const initialSortBy = columns.indexOf("Metric Template");
      openDataTableModal(headerLabel, columns, rows, initialSortBy);
    }
  }

}));

const ConnectedClientMetrics = props => {
  const {idToClient} = React.useContext(ClientsContext);
  return <ClientMetrics {...props} idToClient={idToClient} />;
};

export default ConnectedClientMetrics;

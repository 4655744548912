import Immutable from "immutable";

import * as fetch from "js/fetch";

const FETCH = "FETCH_CLIENT_DATA";
const SUCCESS = "FETCHED_CLIENT_DATA";
const ERROR = "ERROR_FETCHING_CLIENT_DATA";
const FAILED_TO_LOAD = "FAILED_TO_LOAD";
const NO_DATA_FOUND = "NO_DATA_FOUND";

const SET_CLIENT_FILTER = "FILTER_CLIENT_OVERVIEW_BY_CLIENT";
const SET_CLIENT_TYPE_FILTER = "FILTER_CLIENT_OVERVIEW_BY_TYPE";
const SET_CRM_FILTER = "FILTER_CLIENT_OVERVIEW_BY_CRM";
const CLEAR_FILTERS = "CLEAR_CLIENT_OVERVIEW_FILTERS";
const TOGGLE_EXCLUDE_C19_TEST_CLIENTS = "TOGGLE_EXCLUDE_C19_TEST_CLIENTS_IN_OVERVIEW";

const initialState = Immutable.Map({
    isFetching: false,
    error: null,
    filters: Immutable.Map({
        clientIds: Immutable.List(),
        clientTypes: Immutable.Set.of("ENTERPRISE", "LITE", "X", "GROWTH"),
        crmIds: Immutable.List()
    }),
    excludeC19Clients: true,
    data: null
});

const clientOverviewData = (state = initialState, action) => {
    switch (action.type) {
    case FETCH:
        return state
            .set("isFetching", true)
            .set("error", null)
            .set("data", null);
    case SUCCESS:
        return state
            .set("isFetching", false)
            .set("error", null)
            .set("data", action.data);
    case ERROR:
        return state
            .set("isFetching", false)
            .set("error", action.error)
            .set("data", null);
    case SET_CLIENT_FILTER:
        return state.setIn(["filters", "clientIds"], action.clientIds);
    case SET_CLIENT_TYPE_FILTER:
        return state.setIn(["filters", "clientTypes"], action.clientTypes);
    case SET_CRM_FILTER:
        return state.setIn(["filters", "crmIds"], action.crmIds);
    case CLEAR_FILTERS:
        return state
            .set("filters", Immutable.Map({
                clientIds: Immutable.List(),
                clientTypes: Immutable.List(),
                crmIds: Immutable.List()
            }))
            .set("excludeC19Clients", false);
    case TOGGLE_EXCLUDE_C19_TEST_CLIENTS:
        return state.set("excludeC19Clients", action.excludeC19TestClients);
    default:
        return state;
    }
};

// ACTIONS

const setClientFilter = clientIds => ({
    type: SET_CLIENT_FILTER,
    clientIds
});
const onExcludeC19TestClientsChange = isChecked => ({
    type: TOGGLE_EXCLUDE_C19_TEST_CLIENTS,
    excludeC19TestClients: isChecked
});

const setClientTypeFilter = clientTypes => ({
    type: SET_CLIENT_TYPE_FILTER,
    clientTypes
});

const setCrmFilter = crmIds => ({
    type: SET_CRM_FILTER,
    crmIds
});

const onClearFiltersClick = () => ({ type: CLEAR_FILTERS });

const COLUMNS = [
    "Client ID",
    "Client",
    "Client Type",
    "CRM",
    "Category",
    "Name",
    "Value",
    "Nice Value"
];
const loadData = filters => {
    return dispatch => {
        const params = {
            "client-ids": filters.get("clientIds").toArray(),
            types: filters.get("clientTypes").toArray(),
            "crm-ids": filters.get("crmIds").toArray()
        };
        dispatch({ type: FETCH });

        return fetch.getJson("client-pivot", params).then(
            data => {
                if (data.length === 0) {
                    dispatch({
                        type: ERROR,
                        error: NO_DATA_FOUND
                    });
                } else {
                    dispatch({
                        type: SUCCESS,
                        data: [ COLUMNS, ...data ]
                    });
                }
            },
            error => {
                dispatch({
                    type: ERROR,
                    error: FAILED_TO_LOAD
                });
            });
    };
};

export default clientOverviewData;
export {
    setClientFilter,
    setClientTypeFilter,
    setCrmFilter,
    onExcludeC19TestClientsChange,
    onClearFiltersClick,
    loadData,
    COLUMNS,
    FAILED_TO_LOAD,
    NO_DATA_FOUND
};

import React from "react";
import Immutable from "immutable";
import {createRoot} from "react-dom/client";
import {createStore, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import {batchedSubscribe} from "redux-batched-subscribe";
import {composeWithDevTools} from "redux-devtools-extension";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import App from "js/app";

import createRootReducer from "js/create-root-reducer";

const middleware = composeWithDevTools(
    applyMiddleware(thunk),
    batchedSubscribe(notify => notify())
);

const store = createStore(createRootReducer(), Immutable.Map(), middleware);

const container = document.getElementById("app");
const root = createRoot(container);
root.render((
    <Provider store={store}>
      <MuiThemeProvider>
        <App />
      </MuiThemeProvider>
    </Provider>));

if (module.hot) {
  module.hot.accept("js/app", () => {
    root.render((
        <Provider store={store}>
          <MuiThemeProvider>
            <App />
          </MuiThemeProvider>
        </Provider>));
  });
}

import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";

import ClientPicker from "js/components/client-picker";
import {Success, Err} from "js/components/notification";
import * as fetch from "js/fetch";

import * as Rata from "js/data/remote-data";
import {ClientsContext, SelectedClientIdContext} from "js/data/contexts";

const Form = createReactClass({

  getInitialState() {
    return {
      category: "",
      propertyKey: "",
      propertyValue: "",
      comment: "",
      isLoading: false,
      errorMessage: null,
      successMessage: null
    };
  },

  render() {
    const {idToClient, clientsLoading, selectedClientId, setSelectedClientId} = this.props;
    const currentState = Immutable.fromJS(this.state);
    const initialState = Immutable.fromJS(this.getInitialState());
    const hasChanged = !!selectedClientId || !currentState.equals(initialState);
    const isFormCompleted = hasChanged && !this.formHasEmptyFields();
    const {
      category,
      propertyKey,
      propertyValue,
      comment,
      isLoading,
      successMessage,
      errorMessage
    } = this.state;

    return (
        <div style={{padding: "0.5rem"}}>
          <div style={{width: 500}}>
            <ClientPicker
                loading={clientsLoading}
                value={selectedClientId}
                idToClient={idToClient}
                onChange={setSelectedClientId} />
          </div>
          <TextField
              floatingLabelText="Category"
              value={category}
              onChange={e => this.setState({category: e.target.value})} />
          <br />
          <TextField
              floatingLabelText="Key"
              style={{marginRight: "2rem"}}
              value={propertyKey}
              onChange={e => this.setState({propertyKey: e.target.value})} />
          <TextField
              floatingLabelText="Value"
              value={propertyValue}
              onChange={e => this.setState({propertyValue: e.target.value})} />
          <br />
          <TextField
              floatingLabelText="Comment (optional)"
              fullWidth={true}
              value={comment}
              onChange={e => this.setState({comment: e.target.value})} />
          <div style={{marginTop: "2rem", marginBottom: "1rem"}}>
            <RaisedButton
                label="Clear"
                disabled={(!selectedClientId || !hasChanged) || isLoading}
                onClick={this.handleClearBtnClick}
                style={{margin: "0.5rem"}} />
            <RaisedButton
                label={<span>Set Value {isLoading && <i className="fa fa-spinner fa-pulse" />}</span>}
                primary={true}
                disabled={!isFormCompleted || isLoading}
                onClick={this.handleSetValueBtnClick}
                style={{margin: "0.5rem"}} />
          </div>
          <Success message={successMessage} onRequestClose={() => this.setState({successMessage: null})} />
          <Err message={errorMessage} onRequestClose={() => this.setState({errorMessage: null})} />
        </div>
    );
  },

  handleClearBtnClick() {
    this.setState(this.getInitialState());
    this.props.setSelectedClientId(null);
  },

  handleSetValueBtnClick() {
    const {category, propertyKey, propertyValue, comment} = this.state;
    this.setState({
      isLoading: true
    });
    const params = {
      "client-id": this.props.selectedClientId,
      category,
      "property-key": propertyKey,
      "property-value": propertyValue,
      comment
    };
    addClientConfigProperties(params)
        .then(() => {
          this.setState({
            ...this.getInitialState(),
            successMessage: "Client configuration property value set"
          });
        }, error => {
          const defaultErrorMsg = "Unable to set client configuration property value";
          const errorStatus = error.response.status;
          if (errorStatus === 400) {
            error.response.text()
                .then(errorJsonStr => {
                  const errorJson = JSON.parse(errorJsonStr);
                  const errorMessage = errorJson.message || defaultErrorMsg;
                  this.setState({
                    isLoading: false,
                    errorMessage
                  });
                });
          } else {
            this.setState({
              isLoading: false,
              errorMessage: defaultErrorMsg
            });
          }
        });
  },

  formHasEmptyFields() {
    if (!this.props.selectedClientId) {
      return true;
    }
    const requiredFields = Immutable.Set.of("category", "propertyKey", "propertyValue");
    return requiredFields.some(k => {
      const value = this.state[k];
      return value === null || value.length === 0;
    });
  }

});

const addClientConfigProperties = params => fetch.post("config-properties", params);

const ConnectedForm = props => {
  const {idToClient, idToClientStatus} = React.useContext(ClientsContext);
  const {selectedClientId, setSelectedClientId} = React.useContext(SelectedClientIdContext);
  return <Form
      {...props}
      selectedClientId={selectedClientId}
      setSelectedClientId={setSelectedClientId}
      idToClient={idToClient}
      clientsLoading={idToClientStatus === Rata.Status.LOADING} />;
};

export default ConnectedForm;

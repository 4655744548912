import * as Fetch from "js/fetch";
import * as Immutable from "immutable";
import * as Rata from "js/data/remote-data";
import {getErrorMessage} from "js/utils/errors";

const expiresMinutes = 60;

const load = (cube19ClientId, ignoreServerCache) => Fetch
    .getJson("crm-metadata/" + cube19ClientId, {"force-refresh?": ignoreServerCache})
    .then(res => Immutable.fromJS(res));

const loadAndSet = (
    cube19ClientId,
    cidToMeta,
    setCidToMeta,
    {ignoreLocalCache = false, ignoreServerCache = false} = {}
) => {
  const noExistingMeta = !cidToMeta.has(cube19ClientId);
  if (cube19ClientId && (noExistingMeta || ignoreLocalCache)) {
    setCidToMeta(cidToMeta => cidToMeta.update(cube19ClientId, Rata.toLoading));
    return load(cube19ClientId, ignoreServerCache)
        .then(newMeta => setCidToMeta(x => x.update(
            cube19ClientId,
            wrapper => Rata.toLoaded(wrapper, newMeta, expiresMinutes))))
        .catch(error => setCidToMeta(x => x.update(
            cube19ClientId,
            wrapper => Rata.toError(wrapper, getErrorMessage(error)))));
  } else {
    return Promise.resolve();
  }
};

export {
  loadAndSet
};

/** @jsxImportSource @emotion/react */

import React from "react";
import Immutable from "immutable";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {css, Global} from "@emotion/react";

const findOptionWithValue = (options, value) => {
  if (value) {
    return options.find(o => Immutable.is(value, o.value), null)
  } else {
    return null;
  }
};

const findOptionsWithValues = (options, values) => {
  const valuesSet = values.toSet()
  return options.filter(o => valuesSet.includes(o.value)).toArray();
};

const ImmutableSelect = ({
  options = Immutable.List(),
  keys: [valueKey, labelKey] = ["value", "label"],
  loading = false,
  multi = true,
  searchable = true,
  clearable = true,
  disabled = false,
  placeholder = "Search",
  selectedValues = null,
  selectedValue = null,
  selectedValueOrValues = null,
  className = null,
  closeMenuOnSelect = true,
  newValuesAllowed = false,
  onChange = () => {},
  customStyles,
    ...props
}) => {
  if ((multi && selectedValue) || (!multi && selectedValues)) {
    throw new Error("do not mix up multi/single picker props (multi, selectedValue, selectedValues)");
  }

  // Convert options to JS Object expected by React Select
  options = options.map(o => ({label: o.get(labelKey), value: o.get(valueKey)}));

  let value;
  if (selectedValueOrValues) {
    value = multi
        ? findOptionsWithValues(options, selectedValueOrValues)
        : findOptionWithValue(options, selectedValueOrValues);
  } else if (multi) {
    value = findOptionsWithValues(options, selectedValues);
  } else {
    value = findOptionWithValue(options, selectedValue);
  }

  const SelectTypeComponent = newValuesAllowed ? CreatableSelect : Select;

  return (
      <React.Fragment>
        <Global
            styles={css`
              /* line up React Select dropdown arrow with the Clear button */
              .Select-arrow-zone {
                  line-height: 0.25;
              }

              /* workaround to prevent double height select options, bug only appears with certain prop combinations */
              .Select-aria-only {
                  display: none !important;
              }
            `} />
        <SelectTypeComponent
            styles={customStyles ?? {menu: base => ({...base, zIndex: 3})}}
            placeholder={placeholder}
            isLoading={loading}
            isDisabled={disabled}
            options={options.toArray()}
            isMulti={multi}
            isSearchable={searchable}
            isClearable={clearable}
            closeMenuOnSelect={closeMenuOnSelect}
            backspaceRemovesValue={!!multi}
            value={value}
            className={className}
            onChange={opts => {
              if (multi) {
                onChange(Immutable.Set((opts || []).map(o => o.value)));
              } else if (!opts || opts.length === 0) {
                const newValue = clearable ? opts : (selectedValueOrValues || selectedValue);
                onChange(newValue);
              } else {
                onChange(opts?.value);
              }
            }}
            {...props}
        />
      </React.Fragment>);
};

export default ImmutableSelect;

/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import * as Immutable from "immutable";
import {connect} from "react-redux";
import moment from "moment";
import TimeAgo from "timeago.js";

import * as EtlSchema from "js/app-areas/etl-config/attributes-and-properties";
import * as Filtering from "js/app-areas/etl-config/filtering";

import {mergeStateIn} from "js/data/shared";
import * as CrmMetadata from "js/data/crm-metadata";

import useDimensions from "js/utils/hooks/use-dimensions";
import * as Clipboard from "js/utils/clipboard";

import memoize from "memoize-one";
import {AccordionContainer, AccordionSection} from "js/components/accordion";
import LoadingSpinner from "js/components/loading-spinner";
import ClientPicker from "js/components/client-picker";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import ImmutableSelect from "js/components/immutable-select";
import TimestampedRefreshButton from "js/components/timestamped-refresh-button";
import Overlay from "js/components/overlay";
import Dialog from "material-ui/Dialog";
import Chip from "material-ui/Chip";
import {Err} from "js/components/notification";
import {HighlightedConfig} from "js/app-areas/etl-config/config-rendering";
import TranslationsEditor from "js/app-areas/etl-config/translations-editor";
import RejectionsEditor from "js/app-areas/etl-config/rejections-editor";
import ValueEditor from "js/app-areas/etl-config/value-editor";
import FieldMappingAttributeEditor from "js/app-areas/etl-config/field-mapping-attribute-editor";
import HistoryDrawer from "js/app-areas/etl-config/history-drawer";
import ReviewDialog from "js/app-areas/etl-config/review-dialog";
import {getErrorMessage} from "js/utils/errors";
import {indexBy} from "js/utils/collections";
import {
  fetchEtlConfig,
  saveConfig,
  mergeConfigWithExistingDefault,
  fetchRecentSubmissions,
  getCrmEntities
} from "js/data/etl-configs/api";
import {getEntityLabel, getFullColumnLabel, mergeEtlAndNameMappings} from "js/app-areas/entity-renaming/page";
import {fetchEntityFieldMappings} from "js/data/entity-field-mappings/api";
import {mergeEntityFieldMappingsState, setInEntityFieldMappingsState} from "js/data/entity-field-mappings/actions";
import {apiLoadedSuccessfully} from "js/data/entity-field-mappings/reducer";
import {
  ClientsContext,
  CrmMetadataContext,
  CrmsContext,
  CurrentUserContext,
  SelectedClientIdContext
} from "js/data/contexts";
import * as Rata from "js/data/remote-data";

// ESSENTIAL

// BUGS

// IDEAS
// matching collapsing of common / client / merged - requires controlled accordion
// sync scrolling of common / client / merged - usually bad for perf and brittle
//    could do a simpler version of this by matching entities, but takes away user agency
// readonly UI if submitted/approved config submission for client - seems sensible, but rare occurence
// diff between any two changes (early change new vs late change new, customisable? maybe, maybe not)

const TimeAgoInstance = TimeAgo();

const defaultMaterialShadow = "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px";

const historyButtonStyle = {
  position: "fixed",
  right: "1rem",
  bottom: "1rem",
  zIndex: 1100,
  border: "1px solid #ccc",
  boxShadow: defaultMaterialShadow
};

const defaultPageState = Immutable.Map({
  loadingConfig: false,
  loadedMillis: 0,
  loadErrorMessage: null,

  savingConfig: false,

  crmEntities: Immutable.List(),
  typeToConfig: Immutable.Map(),
  typeToConfigSnapshot: Immutable.Map(),
  clientIdForChanges: null,

  showCommonConfig: false,
  showClientConfigAsReadOnly: false,
  filterText: "",

  showMergedConfig: true,
  needsMerge: false,

  undoStack: Immutable.Stack(),
  redoStack: Immutable.Stack(),
  lastUndoMillis: Date.now(),

  showEditJsonDialog: false,
  showReviewDialog: false,

  loadingSubmissions: false,
  idToSubmission: Immutable.Map(),

  editingIsLocked: false
});

const stateToProps = state => {
  return {
    etlConfigPage: state.getIn(["sharedData", "etlConfigPage"]),
    c19EntityFieldMappings: state.get("c19EntityFieldMappings")
  };
};

const dispatchToProps = {
  mergeStateIn,
  mergeEntityFieldMappingsState,
  setInEntityFieldMappingsState
};

const horizontalSpacer = {marginRight: "1rem"};

const Page = connect(stateToProps, dispatchToProps)(createReactClass({

  componentDidMount() {
    if (this.props.clientId) {
      const hasChanges = !Immutable.is(
          this.pageState().get("typeToConfigSnapshot").get("client"),
          this.getClientConfig());
      const changesForSameClient = this.props.clientId === this.pageState().get("clientIdForChanges");
      if (!(hasChanges && changesForSameClient)) {
        this.loadAndSetChangeSubmissions(this.props.clientId);
        this.loadAndSetConfig(this.props.clientId);
      }
      this.loadAndSetEntityFieldMappings(this.props.clientId);
      this.loadAndSetLockEditing(this.props.clientId);

      this.loadCrmEntities(this.props.clientId);
    }

    document.addEventListener("keydown", this.handleKeyPress);
  },

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  },

  handleKeyPress(event) {
    if (!this.pageState().get("showEditJsonDialog") && !this.pageState().get("showReviewDialog")) {
      if (event.code === "KeyZ" && event.shiftKey && (event.ctrlKey || event.metaKey)) {
        this.redo();
        event.preventDefault();
        return false;
      } else if (event.code === "KeyZ" && (event.ctrlKey || event.metaKey)) {
        this.undo();
        event.preventDefault();
        return false;
      }
    }
  },

  getNameMappings() {
    return this.props.c19EntityFieldMappings.get("mappings");
  },

  memoizedGetMergedConfigAndNameMappings: memoize((nameMappings, mergedEntities) => {
    return mergeEtlAndNameMappings(mergedEntities, nameMappings);
  }),

  getMergedConfigAndNameMappings() {
    const mergedEntities = this.getMergedConfig().get("entities") || Immutable.List();
    return this.memoizedGetMergedConfigAndNameMappings(this.getNameMappings(), mergedEntities);
  },

  getCrmType() {
    const client = this.props.idToClient.get(this.props.clientId, Immutable.Map());
    const crm = this.props.idToCrm.get(client.get("crm_id"), Immutable.Map());
    return crm.get("type");
  },

  getCommonConfig() {
    return this.pageState().getIn(["typeToConfig", "common"], Immutable.Map());
  },

  getCrmConfig() {
    return this.pageState().getIn(["crmEntities"], Immutable.List());
  },

  getClientConfig() {
    return this.pageState().getIn(["typeToConfig", "client"], Immutable.Map());
  },

  getMergedConfig() {
    return this.pageState().getIn(["typeToConfig", "merged"], Immutable.Map());
  },

  loadAndSetConfig(cube19ClientId) {
    this.pageStateChange({loadingConfig: true});
    return fetchEtlConfig(cube19ClientId).then(
        typeToConfig => this.pageStateChange({
          typeToConfig,
          loadErrorMessage: null,
          loadingConfig: false,
          needsMerge: false,
          typeToConfigSnapshot: typeToConfig,
          undoStack: Immutable.Stack(),
          redoStack: Immutable.Stack(),
          loadedMillis: Date.now()
        }),
        error => {
          this.pageStateChange({loadingConfig: false});
          getErrorMessage(error).then(message => this.pageStateChange({loadErrorMessage: message}));
        });
  },

  loadAndSetChangeSubmissions(cube19ClientId) {
    this.pageStateChange({loadingSubmissions: true});
    return fetchRecentSubmissions(cube19ClientId, 30).then(changeSubmissions => {
      this.pageStateChange({
        idToSubmission: indexBy(c => c.get("submission_id"), changeSubmissions),
        loadingSubmissions: false
      });
    });
  },

  loadAndSetEntityFieldMappings(cube19ClientId) {
    return fetchEntityFieldMappings(cube19ClientId).then(
        mappings => this.props.mergeEntityFieldMappingsState(Immutable.fromJS({
          mappings,
          api: {mappings: apiLoadedSuccessfully}
        })),
        error => getErrorMessage(error).then(message => {
          return this.props.setInEntityFieldMappingsState(["api", "mappings", "error"], message);
        }));
  },

  loadAndSetLockEditing(cube19ClientId) {
    const client = this.props.idToClient.get(cube19ClientId, Immutable.Map());
    const shouldLockEditing = client.get("reconfiguration_tool") === "ONBOARDING";
    this.pageStateChange({editingIsLocked: shouldLockEditing});
  },

  loadCrmEntities(cube19ClientId) {
    getCrmEntities(cube19ClientId).then(data => {
      this.pageStateChange({crmEntities: data});
    });
  },

  pageStateChange(change) {
    this.props.mergeStateIn(["etlConfigPage"], change, defaultPageState);
  },

  pageState() {
    return this.props.etlConfigPage || defaultPageState;
  },

  render() {
    const {clientId} = this.props;

    // TODO investigate perf of this, if bad then recalc every 50ms or something
    //const configHasBeenChanged = Immutable.is(this.pageState().get("typeToConfigSnapshot").get("client"),
    // this.getClientConfig());
    const configHasBeenChanged = this.pageState().get("typeToConfigSnapshot").get("client") === this.getClientConfig();

    return (
        <React.Fragment>
          <div style={{margin: "1rem", display: "flex", alignItems: "center", flexWrap: "wrap"}}>
            <ClientPicker
                style={{width: 400, marginRight: "1rem"}}
                loading={this.props.loadingClients}
                value={clientId}
                onChange={this.handleChangeClient}
                idToClient={this.props.idToClient} />

            {clientId && <TimestampedRefreshButton
                style={horizontalSpacer}
                title="Reload config from database"
                disabled={this.pageState().get("loadingConfig")}
                millis={this.pageState().get("loadedMillis")}
                onClick={this.handleRefreshClick} />}

            {(clientId && !this.pageState().get("loadErrorMessage")) && <Toolbar
                filterText={this.pageState().get("filterText")}
                onChangeFilterText={this.handleChangeFilterText}

                onUndoClick={this.undo}
                undoStack={this.pageState().get("undoStack")}

                onRedoClick={this.redo}
                redoStack={this.pageState().get("redoStack")}

                configHasBeenChanged={configHasBeenChanged}

                useReaderMode={this.pageState().get("showClientConfigAsReadOnly")}
                onChangeReaderMode={this.handleChangeReaderMode}
                editingIsLocked={this.pageState().get("editingIsLocked")}

                onEditJsonClick={this.openEditJsonDialog}
                onReviewClick={this.openReviewDialog} />}
          </div>

          {this.renderClientSection()}

          {clientId && <EditJsonDialog
              open={this.pageState().get("showEditJsonDialog")}
              onRequestClose={this.closeEditJsonDialog}
              config={this.getClientConfig()}
              onChange={this.handleEditClientConfig} />}

          {clientId && <ReviewDialog
              open={this.pageState().get("showReviewDialog")}
              onRequestClose={this.closeReviewDialog}
              onSaveClick={this.handleSaveClick}
              crmType={this.getCrmType()}
              originalTypeToConfig={this.pageState().get("typeToConfigSnapshot")}
              currentTypeToConfig={this.pageState().get("typeToConfig")} />}

          {clientId && <HistoryDrawer
              open={this.pageState().get("showHistoryDrawer")}
              onRequestClose={this.closeHistoryDrawer}
              onRevertClick={this.revertToSubmission}
              idToClient={this.props.idToClient}
              idToSubmission={this.pageState().get("idToSubmission")}
              loading={this.pageState().get("loadingSubmissions")}
              permissions={this.props.permissions}
              currentUserId={this.props.currentUserId}
              onChange={this.handleSubmissionChange}
              onError={this.popupError} />}

          <Err
              message={this.pageState().get("popupErrorMessage")}
              onRequestClose={this.clearPopupMessage} />

          <Chip
              style={historyButtonStyle}
              onClick={this.openHistoryDrawer}>
            <span><i className="fa fa-clock-o" /> History</span>
          </Chip>
        </React.Fragment>);
  },

  clearPopupMessage() {
    this.pageStateChange({popupErrorMessage: null});
  },

  handleChangeReaderMode(flag) {
    this.pageStateChange({showClientConfigAsReadOnly: flag});
  },

  handleChangeFilterText(newFilterText) {
    this.pageStateChange({filterText: newFilterText});
  },

  handleRefreshClick() {
    this.loadAndSetConfig(this.props.clientId);
    this.loadAndSetChangeSubmissions(this.props.clientId);
  },

  handleChangeClient(clientId) {
    this.props.setClientId(clientId);
    this.pageStateChange({clientIdForChanges: clientId});
    this.loadAndSetChangeSubmissions(clientId);
    this.loadAndSetConfig(clientId);
    this.loadAndSetEntityFieldMappings(clientId);
    this.loadAndSetLockEditing(clientId);
    this.loadCrmEntities(clientId);
  },

  handleSaveClick(comment) {
    this.saveAndSetConfig(comment).then(() => this.loadAndSetChangeSubmissions(this.props.clientId));
    this.closeReviewDialog();
  },

  openEditJsonDialog() {
    this.pageStateChange({showEditJsonDialog: true});
  },

  closeEditJsonDialog() {
    this.pageStateChange({showEditJsonDialog: false});
  },

  openReviewDialog() {
    this.pageStateChange({showReviewDialog: true});
  },

  closeReviewDialog() {
    this.pageStateChange({showReviewDialog: false});
  },

  openHistoryDrawer() {
    this.pageStateChange({showHistoryDrawer: true});
  },

  closeHistoryDrawer() {
    this.pageStateChange({showHistoryDrawer: false});
  },

  revertToSubmission(submission, toBefore) {
    const hasChanges = !Immutable.is(
        this.pageState().get("typeToConfigSnapshot").get("client"),
        this.getClientConfig());
    if (!hasChanges || window.confirm("Restoring will overwrite your current changes, are you sure?")) {
      const typeToConfig = this.pageState().get("typeToConfig");
      let configToRestore;
      if (toBefore) {
        configToRestore = submission.getIn(["payload", "config-at-submission"]);
      } else {
        configToRestore = submission.getIn(["payload", "new-config"]);
      }
      const newTypeToConfig = typeToConfig.set("client", configToRestore);
      this.scheduleMerge(configToRestore, this.getClientConfig());
      this.pageStateChange({
        showHistoryDrawer: false,
        typeToConfig: newTypeToConfig,
        undoStack: Immutable.Stack(),
        redoStack: Immutable.Stack()
      });
    }
  },

  handleSubmissionChange(submission) {
    this.pageStateChange({
      idToSubmission: this.pageState().get("idToSubmission").set(submission.get("submission_id"), submission)
    });
  },

  popupError(error) {
    getErrorMessage(error).then(message => {
      this.pageStateChange({popupErrorMessage: message});
    });
  },

  saveAndSetConfig(comment) {
    const {clientId} = this.props;
    const originalClientConfig = this.pageState().get("typeToConfigSnapshot").get("client");
    const currentClientConfig = this.getClientConfig();

    this.pageStateChange({savingConfig: true});
    return saveConfig(clientId, originalClientConfig, currentClientConfig, comment).then(
        typeToConfig => {
          this.pageStateChange({
            typeToConfig,
            typeToConfigSnapshot: typeToConfig,
            savingConfig: false
          });
        },
        error => {
          this.pageStateChange({savingConfig: false});
          this.popupError(error);
        });
  },

  undo() {
    const undoStack = this.pageState().get("undoStack");
    const redoStack = this.pageState().get("redoStack");

    if (!undoStack.isEmpty()) {
      const previousClientConfig = undoStack.peek();
      const newTypeToConfig = this.pageState().get("typeToConfig").set("client", previousClientConfig);

      const newUndoStack = undoStack.pop();
      const newRedoStack = redoStack.push(this.getClientConfig());

      this.pageStateChange({typeToConfig: newTypeToConfig, undoStack: newUndoStack, redoStack: newRedoStack});

      this.scheduleMerge(previousClientConfig, this.getClientConfig());
    }
  },

  redo() {
    const undoStack = this.pageState().get("undoStack");
    const redoStack = this.pageState().get("redoStack");

    if (!redoStack.isEmpty()) {
      const previousClientConfig = redoStack.peek();
      const newTypeToConfig = this.pageState().get("typeToConfig").set("client", previousClientConfig);

      const newRedoStack = redoStack.pop();
      const newUndoStack = undoStack.push(this.getClientConfig());

      this.pageStateChange({typeToConfig: newTypeToConfig, undoStack: newUndoStack, redoStack: newRedoStack});

      this.scheduleMerge(previousClientConfig, this.getClientConfig());
    }
  },

  renderClientSection() {
    if (this.pageState().get("loadingConfig")) {
      return <LoadingSpinner />;
    } else if (this.pageState().get("loadErrorMessage")) {
      return <div>Error loading config - {this.pageState().get("loadErrorMessage")}</div>;
    } else if (!this.pageState().get("typeToConfig").isEmpty()) {
      return <div style={{marginLeft: "0.5rem", display: "flex", flex: 1, overflow: "hidden"}}>
        {this.renderDefaultConfig()}
        {this.renderClientConfig()}
        {this.renderMergedConfig()}
      </div>;
    } else {
      return null;
    }
  },

  renderDefaultConfig() {
    const show = this.pageState().get("showCommonConfig");
    const minWidth = show ? `calc(300px + 0.5rem + 0.5rem)` : 100;
    return (
        <div
            style={{
              minWidth,
              flex: show ? 1 : 0,
              overflowY: "auto",
              marginRight: "0.5rem",
              padding: 3,
              marginBottom: "1rem"
            }}>
          <ConfigHeader label="Default" onClick={() => this.pageStateChange({showCommonConfig: !show})} />
          {show && <HighlightedConfig
              filterText={this.pageState().get("filterText")}
              config={this.getCommonConfig()}
              commonConfig={this.getCommonConfig()}
              crmType={this.getCrmType()} />}
        </div>);
  },

  renderMergedConfig() {
    const show = this.pageState().get("showMergedConfig");
    const needsMerge = this.pageState().get("needsMerge");
    const minWidth = show ? `calc(300px + 0.5rem + 0.5rem)` : 100;
    return (
        <div
            style={{
              minWidth,
              flex: show ? 1 : 0,
              marginRight: "0.5rem",
              overflowY: "auto",
              padding: 3,
              marginBottom: "1rem"
            }}>
          <ConfigHeader
              label={(needsMerge && show) ? "Merging" : "Merged"}
              onClick={() => this.pageStateChange({showMergedConfig: !show})} />
          {show && <HighlightedConfig
              loading={needsMerge}
              filterText={this.pageState().get("filterText")}
              config={this.getMergedConfig()}
              commonConfig={this.getCommonConfig()}
              entityEtlAndNameMappings={this.getMergedConfigAndNameMappings()}
              crmType={this.getCrmType()} />}
        </div>);
  },

  renderClientConfig() {
    const showingEither = this.pageState().get("showCommonConfig") || this.pageState().get("showMergedConfig");
    const saving = this.pageState().get("savingConfig");
    return (
        <div
            style={{
              minWidth: `calc(300px + 0.5rem + 0.5rem)`,
              flex: 1,
              marginRight: "0.5rem",
              padding: 3,
              marginBottom: "1rem",
              overflowY: "auto",
              position: "relative"
            }}>
          <ConfigHeader
              label="Client"
              onClick={() => this.pageStateChange({
                showCommonConfig: !showingEither,
                showMergedConfig: !showingEither
              })} />
          {this.pageState().get("showClientConfigAsReadOnly") || this.pageState().get("editingIsLocked") //
              ? <HighlightedConfig
                  loading={saving}
                  filterText={this.pageState().get("filterText")}
                  config={this.getClientConfig()}
                  commonConfig={this.getCommonConfig()}
                  crmType={this.getCrmType()}
                  entityEtlAndNameMappings={this.getMergedConfigAndNameMappings()} />
              : <ConfigEditor
                  loading={saving}
                  crmEntities={this.getCrmConfig()}
                  crmType={this.getCrmType()}
                  config={this.getClientConfig()}
                  commonConfig={this.getCommonConfig()}
                  mergedConfig={this.getMergedConfig()}
                  entityEtlAndNameMappings={this.getMergedConfigAndNameMappings()}
                  onChange={this.handleEditClientConfig}
                  filterText={this.pageState().get("filterText")} />}
        </div>);
  },

  handleEditClientConfig(newConfig) {
    const configBeforeEdit = this.getClientConfig();
    this.pushUndo(configBeforeEdit, newConfig);
    this.pageStateChange({typeToConfig: this.pageState().get("typeToConfig").set("client", newConfig)});
    this.scheduleMerge(newConfig, configBeforeEdit);
  },

  pushUndo(configBeforeEdit, configAfterEdit) {
    const newUndoDelayMillis = 500;
    const lastUndoMillis = this.pageState().get("lastPushUndoAttemptMillis");
    const timeSinceLastUndoMillis = Date.now() - lastUndoMillis;

    const undoStack = this.pageState().get("undoStack");
    const pushNewUndo = (timeSinceLastUndoMillis > newUndoDelayMillis) || undoStack.isEmpty();

    let newUndoStack;
    if (pushNewUndo && !Immutable.is(configBeforeEdit, configAfterEdit)) {
      newUndoStack = undoStack.push(configBeforeEdit);
    } else {
      newUndoStack = undoStack;
    }

    if (pushNewUndo || !this.pageState().get("redoStack").isEmpty() ||
        (timeSinceLastUndoMillis > (newUndoDelayMillis / 3))) {
      this.pageStateChange({
        redoStack: Immutable.Stack(),
        undoStack: newUndoStack,
        lastPushUndoAttemptMillis: Date.now()
      });
    }
  },

  scheduleMerge(configToMerge, oldConfig) {
    this.pageStateChange({needsMerge: true});

    this.mergeTimeoutId && clearTimeout(this.mergeTimeoutId);
    this.mergeTimeoutId = setTimeout(() => {
      if (oldConfig && Immutable.is(configToMerge, oldConfig)) {
        this.pageStateChange({needsMerge: false});
      } else {
        mergeConfigWithExistingDefault(this.props.clientId, configToMerge)
            .then(
                newMerged => {
                  const typeToConfig = this.pageState().get("typeToConfig");
                  const noChangesYet = configToMerge === typeToConfig.get("client");
                  // NOTE compare-and-set here to avoid reversing in-flight changes / ui weirdness
                  // we only update if no edits have been made to the config since we were scheduled
                  if (noChangesYet) {
                    this.pageStateChange({
                      typeToConfig: typeToConfig.set("merged", newMerged),
                      needsMerge: false
                    });
                  }
                },
                error => this.popupError(error));
      }
    }, 200);
  }

}));

const ConnectedPage = props => {
  const {idToCrm} = React.useContext(CrmsContext);
  const {idToClient, idToClientStatus} = React.useContext(ClientsContext);
  const {selectedClientId, setSelectedClientId} = React.useContext(SelectedClientIdContext);
  const {currentUser} = React.useContext(CurrentUserContext);
  return <Page
      {...props}
      idToClient={idToClient}
      idToCrm={idToCrm}
      loadingClients={idToClientStatus === Rata.Status.LOADING}
      permissions={currentUser.get("permissions")}
      currentUserId={currentUser.get("username")}
      clientId={selectedClientId}
      setClientId={setSelectedClientId} />;
};

export default ConnectedPage;

const Toolbar = React.memo(({
  filterText,
  onChangeFilterText,

  onUndoClick,
  undoStack,
  onRedoClick,
  redoStack,

  configHasBeenChanged,
  useReaderMode,
  onChangeReaderMode,

  onEditJsonClick,
  onReviewClick,

  editingIsLocked
}) => {
  const undoLabel = undoStack.isEmpty() ? "" : " (" + undoStack.count() + ")";
  const redoLabel = redoStack.isEmpty() ? "" : " (" + redoStack.count() + ")";

  return (<React.Fragment>
    <TextField
        style={{marginRight: "1rem"}}
        value={filterText}
        onChange={e => onChangeFilterText(e.target.value)}
        hintText="Search entities / fields" />
    {editingIsLocked ?
        <div><i className="fa fa-lock" style={{fontSize: 16, paddingRight: 5, marginLeft: 15}} />Editing locked as
          client is owned by the Onboarding Tool</div> : <>
          <RaisedButton
              style={{minWidth: 45, marginRight: "1rem"}}
              label={<span><i className="fa fa-undo" />{undoLabel}</span>}
              title="Undo"
              disabled={undoStack.isEmpty()}
              onClick={onUndoClick} />
          <RaisedButton
              style={{minWidth: 45, marginRight: "1rem"}}
              label={<span><i className="fa fa-repeat" />{redoLabel}</span>}
              title="Redo"
              disabled={redoStack.isEmpty()}
              onClick={onRedoClick} />
          <RaisedButton
              style={{minWidth: 45, marginRight: "1rem"}}
              label={useReaderMode ? <i className="fa fa-pencil" /> : <i className="fa fa-book" />}
              title="Toggle reader mode / edit mode for client config"
              onClick={() => onChangeReaderMode(!useReaderMode)} />
          <RaisedButton
              style={{marginRight: "1rem"}}
              label={<span><i className="fa fa-pencil" /> JSON</span>}
              title="Useful to import / export from another env"
              onClick={onEditJsonClick} />
          <RaisedButton
              style={{minWidth: 45, marginRight: "1rem"}}
              label={<i className="fa fa-floppy-o" />}
              title="Review and save changes"
              disabled={configHasBeenChanged}
              onClick={onReviewClick} />
        </>}
  </React.Fragment>);
});

const EditJsonDialog = React.memo(({
  open,
  onRequestClose,
  config,
  onChange
}) => {
  return (
      <Dialog
          modal={false}
          style={{zIndex: 9000, paddingTop: 0}}
          open={open}
          onRequestClose={newOpen => newOpen || onRequestClose()}
          contentStyle={{width: "98%", maxWidth: "none", height: "98%", maxHeight: "none"}}
          autoScrollBodyContent={true}
          autoDetectWindowHeight={false}
          repositionOnUpdate={false}>
        {open && <div style={{width: "100%", height: "100%"}}>
          <RaisedButton
              style={{minWidth: 45, marginRight: "1rem"}}
              label="Copy JSON"
              onClick={() => Clipboard.copyText(JSON.stringify(config, null, 2))} />
          <ValueEditor
              prop={Immutable.Map({label: "Client JSON", type: "json-data"})}
              value={config}
              onChange={onChange} />
        </div>}
      </Dialog>);
});

const ConfigHeader = React.memo(({label, onClick}) => {
  return (
      <div
          css={{
            fontSize: "1.4rem",
            marginBottom: "0.5rem",
            textAlign: "center",
            ":hover": {
              backgroundColor: "#ddd"
            },
            cursor: "pointer",
            userSelect: "none"
          }}
          onClick={onClick}>
        {label}
      </div>);
});

const opaqueStyle = {
  backgroundColor: "#fff",
  borderRadius: 5,
  border: "1px solid #999"
};

const Pill = React.memo(({label, value, onClick, transparent}) => {
  if (value) {
    return <span
        onClick={e => {
          if (onClick) {
            onClick(e);
            e.stopPropagation();
          }
        }}
        style={{
          paddingTop: 1,
          paddingBottom: 2,
          paddingLeft: 3,
          paddingRight: 3,

          fontSize: "0.9rem",
          marginRight: "0.5rem",
          ...(transparent ? {} : opaqueStyle)
        }}>
      {label && <span style={{marginRight: 4, fontSize: "0.7rem", color: "#666"}}>
        {label}
      </span>}
      <span>{value}</span>
    </span>;
  } else {
    return <span></span>;
  }
});

// TODO figure out vertical spacing of wrapped rows of pills without ruining top row
const EntityTitle = React.memo(({entity, commonEntity, uiLabel, onDeleteClick}) => {
  const isExtractOnly = entity.get("extractOnly") === true
      || (commonEntity.get("extractOnly") === true && entity.get("extractOnly") !== false);
  const isMapOnly = entity.get("mapOnly") === true
      || (commonEntity.get("mapOnly") === true && entity.get("mapOnly") !== false);

  return (
      <span style={{display: "flex", flexWrap: "wrap"}}>
      <Pill
          value={<i style={{color: "red"}} className="fa fa-times" />}
          transparent={true}
          onClick={() => window.confirm("Delete " + entity.get("name") + " entity?") && onDeleteClick(entity)} />
      <Pill value={<b style={{fontSize: "1.1rem"}}>{entity.get("name")}</b>} transparent={true} />
      <Pill label="c19 entity" value={entity.get("cube19EntityToMapTo") || commonEntity.get("cube19EntityToMapTo")} />
      <Pill label="crm id" value={entity.get("crmIdField") || commonEntity.get("crmIdField")} />
      <Pill label="crm id for search" value={entity.get("crmIdSearchName") || commonEntity.get("crmIdSearchName")} />
      <Pill label="ui label" value={uiLabel} />

      <Pill value={isExtractOnly && "extract only"} />
      <Pill value={isMapOnly && "map only"} />
    </span>);
});

// TODO figure out vertical spacing of wrapped rows of pills without ruining top row
const FieldMappingTitle = React.memo(({fieldMapping, entityName, index, uiLabel, crmLabel, onDeleteClick}) => {
  return (
      <span style={{display: "flex", flexWrap: "wrap"}}>
      <Pill
          value={<i style={{color: "red"}} className="fa fa-times" />}
          transparent={true}
          onClick={() => window.confirm("Delete " + entityName + "." +
              (fieldMapping.get("name") || fieldMapping.get("mapTo")) + " field?") && onDeleteClick(index)} />
      <Pill value={fieldMapping.get("name")} transparent={true} />
      <Pill label="fallback" value={fieldMapping.get("fallback")} />
      <Pill label="map to" value={fieldMapping.get("mapTo")} />
      <Pill label="transl to" value={fieldMapping.get("translateTo")} />

      <Pill label="default transl" value={fieldMapping.get("defaultTranslation")} />
      <Pill label="null transl" value={fieldMapping.get("nullTranslation")} />
      <Pill label="whitespace transl" value={fieldMapping.get("whitespaceTranslation")} />

      <Pill label="crm label" value={crmLabel} />
      <Pill label="ui label" value={uiLabel} />

      <Pill value={fieldMapping.get("clearTranslations") && "clear transl"} />
      <Pill value={fieldMapping.get("extendTranslations") && "extend transl"} />
      <Pill value={fieldMapping.get("clearDefaultTranslation") && "clear default transl"} />

      <Pill value={fieldMapping.get("extractOnly") && "extract only"} />
      <Pill value={fieldMapping.get("mapOnly") && "map only"} />
      <Pill value={EtlSchema.typeNameToType.get(fieldMapping.get("type"), Immutable.Map()).get("label", "")} />
    </span>);
});

const EntityEditor = React.memo(({
  clientId,
  loadMetadata,
  metadataForEntity,
  metadataAgeInMillis,
  loadingMetadata,
  metadataError,

  entity,
  commonEntity,
  entityEtlAndNameMapping,
  entityEtlAndNameMappings,
  existingClientEntityNames,
  existingEntityNames,
  crmType,
  onChange
}) => {
  React.useEffect(() => {
    loadMetadata(clientId);
  }, [clientId, loadMetadata]);

  metadataForEntity = metadataForEntity || Immutable.Map();

  const nameToCommonField = indexBy(f => f.get("name"), commonEntity.get("fieldMappings", Immutable.List()));
  const mapToToCommonField = indexBy(f => f.get("mapTo"), commonEntity.get("fieldMappings", Immutable.List()));

  const cube19EntityToMapTo = entity.get("cube19EntityToMapTo", commonEntity.get("cube19EntityToMapTo"));
  const fieldMappings = entity.get("fieldMappings", Immutable.List());
  const existingClientFieldNames = React.useMemo(
      () => fieldMappings.map(f => f.get("name")).toSet(),
      [fieldMappings]);
  const existingClientFieldMapTos = React.useMemo(
      () => fieldMappings.map(f => f.get("mapTo")).filter(x => !!x).toSet(),
      [fieldMappings]);

  const onChangeProperties = React.useCallback(
      properties => onChange(entity.set("properties", properties), entity),
      [onChange, entity]);

  const onChangeFieldAttribute = React.useCallback(
      (fieldIndex, key, value) => {
        const fieldMapping = entity.getIn(["fieldMappings", fieldIndex]);
        let newFieldMapping;
        if (value === null || value === "" || value === undefined || (value.isEmpty && value.isEmpty())) {
          newFieldMapping = fieldMapping.delete(key, value);
        } else {
          newFieldMapping = fieldMapping.set(key, value);
        }
        onChange(entity.setIn(["fieldMappings", fieldIndex], newFieldMapping), entity);
      },
      [onChange, entity]);

  const onDeleteField = React.useCallback(
      fieldIndex => onChange(entity.deleteIn(["fieldMappings", fieldIndex]), entity),
      [onChange, entity]);

  return (
      <div>
        <AccordionContainer
            stateless={false}
            allowOnlyOneOpen={false}
            lazyRender={true}>
          {fieldMappings.map((fieldMapping, fieldIndex) => {
            // TODO figure out to find the correct common field in cases where original field has been made extractOnly
            // in client json
            const commonFieldMapping = nameToCommonField.get(
                fieldMapping.get("name"),
                mapToToCommonField.get(fieldMapping.get("mapTo"), Immutable.Map()));

            const fieldName = fieldMapping.get(
                "originalCrmFieldName",
                commonFieldMapping.get("originalCrmFieldName", fieldMapping.get("name")));
            const metadataForField = metadataForEntity.get("fields", Immutable.List())
                .find(f => f.get("name") === fieldName);

            const crmLabel = metadataForField !== undefined ? metadataForField.get("label") : "";
            const mapTo = fieldMapping.get("mapTo", commonFieldMapping.get("mapTo"));
            const entityMappingWithField = entityEtlAndNameMappings
                .find(eMapping => eMapping.fieldMappingsById.some(x => x.stagingTable === cube19EntityToMapTo
                    && x.stagingField === mapTo));
            const parentEntityMapping = entityEtlAndNameMapping !== entityMappingWithField
                ? entityEtlAndNameMapping
                : null;
            const uiLabel = entityMappingWithField
                ? getFullColumnLabel(parentEntityMapping, entityMappingWithField, mapTo)
                : "";
            // NOTE using field index instead of field name for key here
            // This prevents textfield that edits the name from losing focus when you type in it
            // Otherwise when you type: it changes the name, thus changing the key, thus re-rendering all dom els
            return (
                <AccordionSection
                    key={fieldIndex}
                    title={<FieldMappingTitle
                        fieldMapping={fieldMapping}
                        entityName={entity.get("name")}
                        index={fieldIndex}
                        uiLabel={uiLabel}
                        crmLabel={crmLabel}
                        onDeleteClick={onDeleteField} />}>
                  <FieldMappingEditor
                      fieldMapping={fieldMapping}
                      existingEntityNames={existingEntityNames}
                      existingClientFieldNames={existingClientFieldNames}
                      metadataAgeInMillis={metadataAgeInMillis}
                      metadataForField={metadataForField}
                      metadataError={metadataError}
                      loadingMetadata={loadingMetadata}
                      crmType={crmType}
                      index={fieldIndex}
                      onChange={onChangeFieldAttribute}
                      commonFieldMapping={commonFieldMapping} />
                </AccordionSection>);
          })}
        </AccordionContainer>
        <div style={{marginLeft: "1rem", marginBottom: "1rem", display: "flex", flexWrap: "wrap"}}>
          <RaisedButton
              style={{minWidth: 45, marginRight: "1rem", marginTop: "1rem"}}
              label={<span><i className="fa fa-plus" /> Add custom field</span>}
              onClick={() => {
                const name = prompt("Enter the field name");
                if (name) {
                  if (existingClientFieldNames.contains(name)) {
                    alert("A field named '" + name + "' already exists");
                  } else {
                    const newField = Immutable.Map({name});
                    const newEntity = entity.update("fieldMappings", Immutable.List(), fs => fs.push(newField));
                    onChange(newEntity, entity);
                  }
                }
              }} />
          <div style={{width: 350, marginRight: "1rem", marginTop: "1rem"}}>
            <ImmutableSelect
                placeholder="Add existing field from common"
                options={commonEntity
                    .get("fieldMappings", Immutable.List())
                    .filter(f => !existingClientFieldNames.contains(f.get("name")))
                    .filter(f => !existingClientFieldMapTos.contains(f.get("mapTo")))
                    .sortBy(f => f.get("name").toLowerCase())
                    .map(f => {
                      const label = f.get("name") + (f.get("mapTo") ? " (" + f.get("mapTo") + ")" : "");
                      return Immutable.Map({value: f, label});
                    })}
                multi={false}
                searchable={true}
                clearable={true}
                selectedValueOrValues={null}
                onChange={newField => {
                  const newEntity = entity.update("fieldMappings", Immutable.List(), fs => fs.push(newField));
                  onChange(newEntity, entity);
                }} />
          </div>
          <div
              style={{width: 350, marginTop: "1rem"}}
              title={metadataError && ("Error loading CRM metadata: " + metadataError)}>
            <ImmutableSelect
                placeholder={metadataError ? "Unable to load metadata from CRM" : "Add field from CRM"}
                options={metadataForEntity
                    .get("fields", Immutable.List())
                    .filter(f => !existingClientFieldNames.contains(f.get("name")))
                    .map(f => {
                      const label = f.get("name") + (f.get("label") ? " (" + f.get("label") + ")" : "");
                      return Immutable.Map({value: f.get("name"), label});
                    })}
                multi={false}
                searchable={true}
                clearable={true}
                disabled={!!metadataError}
                selectedValueOrValues={null}
                onChange={name => {
                  const newField = Immutable.Map({name});
                  const newEntity = entity.update("fieldMappings", Immutable.List(), fs => fs.push(newField));
                  onChange(newEntity, entity);
                }} />
          </div>
        </div>

        <AccordionContainer
            stateless={false}
            allowOnlyOneOpen={false}
            lazyRender={true}>
          <AccordionSection title="Entity Attributes">
            <EntityAttributesEditor
                existingClientEntityNames={existingClientEntityNames}
                entity={entity}
                onChange={onChange} />
          </AccordionSection>
          <AccordionSection title="Entity Properties">
            <EntityPropertiesEditor
                crmType={crmType}
                entityName={entity.get("name")}
                propertiesOnEntity={entity.get("properties") || Immutable.Map()}
                onChange={onChangeProperties} />
          </AccordionSection>
        </AccordionContainer>
      </div>);
});

const EntityPropertiesEditor = React.memo(({
  crmType,
  entityName,
  propertiesOnEntity,
  onChange
}) => {
  const specificEntityProps = EtlSchema.crmTypeAndEntityNameToProps.get(crmType + "_" + entityName, Immutable.List());
  const crmEntityProps = EtlSchema.crmTypeToEntityProps.get(crmType, Immutable.List());
  const entityProps = EtlSchema.commonEntityProps.concat(crmEntityProps).concat(specificEntityProps);

  const [ref, {width}] = useDimensions();
  const fieldWidth = calculateWidth(width, 250, entityProps.count());
  return (
      <div
          style={{
            width: "100%",
            paddingLeft: "0.5rem",
            paddingBottom: "0.5rem"
          }}>
        <div
            ref={ref} style={{
          width: "100%",
          display: "inline-flex",
          flexWrap: "wrap",
          alignItems: "flex-start"
        }}>
          {entityProps.map(prop => {
            const name = prop.get("name");
            const onChangeProp = newValue => {
              if (newValue === null || newValue === "") {
                onChange(propertiesOnEntity.delete(name));
              } else {
                onChange(propertiesOnEntity.set(name, newValue));
              }
            };
            return <ValueEditor
                key={name}
                prop={prop}
                width={fieldWidth}
                value={propertiesOnEntity.get(name)}
                onChange={onChangeProp} />;
          })}
        </div>
      </div>);
});

const EntityAttributesEditor = React.memo(({
  existingClientEntityNames,
  entity,
  onChange
}) => {
  const [ref, {width}] = useDimensions();
  const fieldWidth = calculateWidth(width, 200, EtlSchema.commonEntityAttributes.count());
  const validateNewName = React.useCallback(
      (newName, oldName) => {
        if (newName !== oldName && existingClientEntityNames.contains(newName)) {
          return "An entity named '" + newName + "' already exists";
        }
      },
      [existingClientEntityNames]);
  return (
      <div
          style={{
            width: "100%",
            paddingLeft: "0.5rem",
            paddingBottom: "0.5rem"
          }}>
        <div
            ref={ref} style={{
          width: "100%",
          display: "inline-flex",
          flexWrap: "wrap",
          alignItems: "flex-start"
        }}>
          {EtlSchema.commonEntityAttributes.map(attr => {
            if (attr.get("name") === "name" && attr.get("validator") !== validateNewName) {
              // HACK to allow entity renaming without making duplicates using common etl schema code
              // TODO instead allow validators to be built up with field / entity / config passed in as args
              // how to avoid change cascade? if callback refers to config then it would have to be recreated everytime
              // config changes
              attr = attr.set("validator", validateNewName);
            }
            const onChangeAttribute = (newValue) => {
              let newEntity;
              if (newValue === null || newValue === "" || newValue === undefined) {
                newEntity = entity.delete(attr.get("name"));
              } else {
                newEntity = entity.set(attr.get("name"), newValue);
              }
              onChange(newEntity, entity);
            };
            return (
                <ValueEditor
                    key={attr.get("name")}
                    prop={attr}
                    width={fieldWidth}
                    value={entity.get(attr.get("name"))}
                    onChange={onChangeAttribute} />);
          })}
        </div>
      </div>);
});

const getMetaForEntity = (metaForClient, crmEntityName) => {
  const entities = Rata.getValue(metaForClient, Immutable.Map()).get("entities", Immutable.List());
  return entities.find(e => crmEntityName === e.get("name"));
};

const ConnectedEntityEditor = props => {
  const {selectedClientId} = React.useContext(SelectedClientIdContext);
  const {clientIdToCrmMetadata, setClientIdToCrmMetadata} = React.useContext(CrmMetadataContext);

  const crmEntityName = props.entity.get(
      "originalCrmEntityName",
      props.commonEntity.get("originalCrmEntityName", props.entity.get("name")));

  const loadMetadata = () => {
    CrmMetadata.loadAndSet(
        selectedClientId,
        clientIdToCrmMetadata,
        setClientIdToCrmMetadata);
  };

  const metaForClient = clientIdToCrmMetadata.get(selectedClientId);

  return <EntityEditor
      {...props}
      clientId={selectedClientId}
      loadingMetadata={Rata.isLoading(metaForClient)}
      metadataAgeInMillis={Rata.getValue(metaForClient, Immutable.Map()).get("loaded-millis")}
      metadataForEntity={getMetaForEntity(metaForClient, crmEntityName)}
      metadataError={Rata.getError(metaForClient)}
      loadMetadata={loadMetadata} />;
};

const ConfigEditor = React.memo(({
  crmEntities,
  crmType,
  config,
  commonConfig,
  mergedConfig,
  entityEtlAndNameMappings,
  onChange = () => {
  },
  loading = false,
  filterText = ""
}) => {
  const crmTopLevelProperties = EtlSchema.crmTypeToTopLevelProperties.get(crmType, Immutable.List());
  const topLevelProperties = EtlSchema.commonTopLevelProperties.concat(crmTopLevelProperties);

  const nameToCommonEntity = indexBy(e => e.get("name"), commonConfig.get("entities"));
  const shouldFilter = filterText.length >= 3;
  const words = Filtering.toWords(filterText, shouldFilter);
  const entityNameToMatch = Filtering.getEntityNameToMatch(config, nameToCommonEntity, words, shouldFilter);

  const entities = config.get("entities") || Immutable.List();
  const existingClientEntityNames = React.useMemo(() => entities.map(e => e.get("name")).toSet(), [entities]);
  const existingClientEntityNamesInLowerCase = React.useMemo(() => entities.map(e => e.get("name").toLowerCase())
      .toSet(), [entities]);
  const mergedEntities = mergedConfig.get("entities") || Immutable.List();
  const existingEntityNames = React.useMemo(() => mergedEntities.map(e => e.get("name")).toSet(), [mergedEntities]);

  const onChangeProperties = React.useCallback(
      properties => onChange(config.set("properties", properties)),
      [onChange, config]);

  const onChangeEntity = React.useCallback(
      (entity, originalEntity) => {
        const entityIndex = config.get("entities").findIndex(e => e.get("name") === originalEntity.get("name"));
        onChange(config.setIn(["entities", entityIndex], entity));
      },
      [onChange, config]);

  const onDeleteEntity = React.useCallback(
      entity => {
        const entityIndex = config.get("entities").findIndex(e => e.get("name") === entity.get("name"));
        onChange(config.deleteIn(["entities", entityIndex]));
      },
      [onChange, config]);

  return (
      <div>
        <Overlay show={loading} />
        <AccordionContainer
            stateless={false}
            allowOnlyOneOpen={false}
            lazyRender={true}
            expandAll={shouldFilter ? filterText : false}>
          {entities
              .filter(e => {
                const match = entityNameToMatch.get(e.get("name"));
                return match.get("entityMatches") || match.get("fieldsMatch");
              })
              .sortBy(e => {
                const match = entityNameToMatch.get(e.get("name"));
                return (match.get("entityMatches") ? "0" : "1") + "_" + e.get("name");
              })
              .map(entity => {
                const commonEntity = nameToCommonEntity.get(entity.get("name"), Immutable.Map());
                const name = entity.get("name") || commonEntity.get("name");
                const entityEtlAndNameMapping = entityEtlAndNameMappings.find(mapping => mapping.crmEntities.contains(
                    name));
                const clientUiLabel = entityEtlAndNameMapping ? getEntityLabel(entityEtlAndNameMapping) : "";
                return (
                    <AccordionSection
                        key={entity.get("name")}
                        titleElement={<EntityTitle
                            entity={entity}
                            commonEntity={commonEntity}
                            uiLabel={clientUiLabel}
                            onDeleteClick={onDeleteEntity} />}
                        containerStyle={{marginBottom: "1rem", boxShadow: defaultMaterialShadow}}>
                      <ConnectedEntityEditor
                          entity={entity}
                          commonEntity={commonEntity}
                          entityEtlAndNameMapping={entityEtlAndNameMapping}
                          entityEtlAndNameMappings={entityEtlAndNameMappings}
                          existingClientEntityNames={existingClientEntityNames}
                          existingEntityNames={existingEntityNames}
                          crmType={crmType}
                          onChange={onChangeEntity} />
                    </AccordionSection>);
              })}
        </AccordionContainer>
        <div style={{marginLeft: "1rem", marginBottom: "1rem", display: "flex", flexWrap: "wrap"}}>
          <RaisedButton
              style={{minWidth: 45, marginRight: "1rem", marginTop: "1rem"}}
              label={<span><i className="fa fa-plus" /> Add new custom entity</span>}
              onClick={() => {
                const name = prompt("Enter the entity name");
                if (name) {
                  if (existingClientEntityNames.contains(name)) {
                    alert("An entity named '" + name + "' already exists");
                  } else {
                    const newEntity = Immutable.Map({name});
                    const newConfig = config.update("entities", Immutable.List(), es => es.push(newEntity));
                    onChange(newConfig);
                  }
                }
              }} />
          <div style={{width: 350, marginRight: "1rem", marginTop: "1rem"}}>
            <ImmutableSelect
                placeholder="Add existing entity from common"
                options={commonConfig
                    .get("entities", Immutable.List())
                    .filter(e => !existingClientEntityNames.contains(e.get("name")))
                    .sortBy(e => e.get("name").toLowerCase())
                    .map(e => {
                      const c19Entity = e.get("cube19EntityToMapTo");
                      const label = e.get("name") + (c19Entity ? " (" + c19Entity + ")" : "");
                      return Immutable.Map({value: e.get("name"), label});
                    })}
                multi={false}
                searchable={true}
                clearable={true}
                selectedValueOrValues={null}
                onChange={name => {
                  const newEntity = Immutable.Map({name});
                  const newConfig = config.update("entities", Immutable.List(), es => es.push(newEntity));
                  onChange(newConfig);
                }} />
          </div>

          <div style={{width: 350, marginTop: "1rem"}}>
            <ImmutableSelect
                placeholder="Add entity from CRM"
                options={crmEntities
                    .filter(e => !existingClientEntityNamesInLowerCase.contains(e.get("name").toLowerCase()))
                    .sortBy(e => e.get("name").toLowerCase())
                    .map(e => Immutable.Map({value: e.get("name"), label: e.get("name")}))}
                multi={false}
                searchable={true}
                clearable={true}
                selectedValueOrValues={null}
                onChange={name => {
                  const newEntity = crmEntities.find(x => x.get("name") === name);
                  const newConfig = config.update("entities", Immutable.List(), es => es.push(newEntity));
                  onChange(newConfig);
                }} />
          </div>
        </div>
        <PropertiesEditor
            shouldFilter={shouldFilter}
            filterText={filterText}
            words={words}
            allProperties={topLevelProperties}
            propertiesFromConfig={config.get("properties")}
            onChange={onChangeProperties} />
      </div>);
});

const PropertiesEditor = React.memo(({
  shouldFilter,
  filterText,
  words,
  allProperties,
  propertiesFromConfig,
  onChange
}) => {
  const [ref, {width}] = useDimensions();
  return <AccordionContainer
      stateless={true}
      allowOnlyOneOpen={false}
      lazyRender={true}
      expandAll={shouldFilter ? filterText : false}>
    {allProperties
        .map(p => p.set("section", p.get("section", "General")))
        .filter(p => Filtering.matchesAllWords(p.get("name").trim().toLowerCase(), words)
            || Filtering.matchesAllWords(p.get("label").trim().toLowerCase(), words)
            || Filtering.matchesAllWords(p.get("section").trim().toLowerCase(), words))
        .groupBy(p => p.get("section"))
        .entrySeq()
        .sortBy(([section]) => section)
        .map(([section, props]) => {
          const fieldWidth = calculateWidth(width, 300, props.count());
          return (
              <AccordionSection key={section} title={section + " Properties"}>
                <div
                    style={{
                      width: "100%",
                      paddingLeft: "0.5rem",
                      paddingBottom: "0.5rem"
                    }}>
                  <div
                      ref={ref} style={{
                    width: "100%",
                    display: "inline-flex",
                    flexWrap: "wrap",
                    alignItems: "flex-start"
                  }}>
                    {props.map(prop => {
                      const name = prop.get("name");
                      return <ValueEditor
                          key={name}
                          prop={prop}
                          width={fieldWidth}
                          value={propertiesFromConfig.get(name)}
                          onChange={newValue => {
                            if (newValue === null || newValue === "") {
                              onChange(propertiesFromConfig.delete(name));
                            } else {
                              onChange(propertiesFromConfig.set(name, newValue));
                            }
                          }} />;
                    })}
                  </div>
                </div>
              </AccordionSection>);
        })}
  </AccordionContainer>;
});

const SectionHeader = React.memo(({title}) => {
  return (
      <div
          style={{
            backgroundColor: "#d9d9d9",
            padding: "0.4rem",
            paddingLeft: "1.5rem",
            display: "flex",
            alignItems: "center"
          }}>
        <div style={{fontWeight: "bold", fontSize: "1rem"}}>{title}</div>
      </div>);
});

const calculateWidth = (totalWidth, minimumWidth, count) => {
  if (totalWidth) {
    if (count === 1) {
      if (totalWidth < (minimumWidth * 2)) {
        return "100%";
      } else {
        return (minimumWidth * 2) + "px";
      }
    } else {
      const countPerRow = Math.floor(totalWidth / minimumWidth);
      return (100 / countPerRow) + "%";
    }
  } else {
    return minimumWidth + "px";
  }
};

const FieldMappingEditor = React.memo(({
  fieldMapping,
  index,
  onChange,
  commonFieldMapping = Immutable.Map(),
  existingEntityNames,
  existingClientFieldNames,
  loadingMetadata,
  metadataForField,
  metadataAgeInMillis,
  metadataError,
  crmType
}) => {
  const [ref, {width}] = useDimensions();

  const type = fieldMapping.get("type", commonFieldMapping.get("type", ""));
  const typeAttrs = EtlSchema.typeNameToType.get(type, Immutable.Map()).get("props", Immutable.List());

  const onChangeField = React.useCallback(
      (key, value) => onChange(index, key, value),
      [index, onChange]);

  let metadataAgeMessage;
  if (!loadingMetadata && metadataForField) {
    const date = moment(metadataAgeInMillis);
    metadataAgeMessage = "CRM metadata loaded " + TimeAgoInstance.format(date.toDate());
  } else if (metadataError) {
    metadataAgeMessage = "Error loading CRM metadata: " + metadataError;
  } else {
    metadataAgeMessage = "";
  }

  const validateNewName = React.useCallback(
      (newName, oldName) => {
        if (newName !== oldName && existingClientFieldNames.contains(newName)) {
          return "A field named '" + newName + "' already exists";
        }
      },
      [existingClientFieldNames]);

  return (
      <div>
        {EtlSchema.fieldMappingAttributes
            .groupBy(a => a.get("section"))
            .entrySeq()
            .map(([section, attrs]) => {
              const editorWidth = calculateWidth(
                  width,
                  200,
                  attrs.count() + (section === "Type" ? typeAttrs.count() : 0));
              const sectionTitle =
                  (<span>
              {section}
                    {(section === "Translations" || section === "Rejections") &&
                        <span style={{marginLeft: "0.5rem", fontSize: "0.75rem"}}>({metadataAgeMessage})</span>}
            </span>);
              return (
                  <React.Fragment key={section || "default"}>
                    {section && <SectionHeader title={sectionTitle} />}
                    <div
                        style={{
                          width: "100%",
                          paddingLeft: "1rem",
                          paddingBottom: "1rem"
                        }}>
                      <div
                          ref={ref} style={{
                        width: "100%",
                        display: "inline-flex",
                        flexWrap: "wrap",
                        alignItems: "flex-start"
                      }}>
                        {attrs.map(attr => {
                          if (attr.get("name") === "name" && attr.get("validator") !== validateNewName) {
                            // HACK to allow field renaming without making duplicates using common etl schema code
                            // TODO instead allow validators to be built up with field / entity / config passed in as
                            // args how to avoid change cascade? if callback refers to config then it would have to be
                            // recreated everytime config changes
                            attr = attr.set("validator", validateNewName);
                          }
                          if (attr.get("name") === "translations" && crmType === "bullhornrest") {
                            return <TranslationsEditor
                                key={attr.get("name")}
                                translations={fieldMapping.get(attr.get("name"), Immutable.List())}
                                metadataForField={metadataForField}
                                loadingMetadata={loadingMetadata}
                                commonFieldMapping={commonFieldMapping}
                                attr={attr}
                                onChange={onChangeField} />;
                          } else if (attr.get("name") === "rejections" && crmType === "bullhornrest") {
                            return <RejectionsEditor
                                key={attr.get("name")}
                                rejections={fieldMapping.get(attr.get("name"), Immutable.List())}
                                metadataForField={metadataForField}
                                loadingMetadata={loadingMetadata}
                                commonFieldMapping={commonFieldMapping}
                                attr={attr}
                                onChange={onChangeField} />;
                          } else if (attr.get("name") === "linkCrmEntityName") {
                            const value = fieldMapping.get(attr.get("name"));
                            let options = existingEntityNames
                                .sort()
                                .map(entity => Immutable.Map({label: entity, value: entity}));
                            if (value) {
                              options = options.add(Immutable.Map({label: value, value: value}));
                            }
                            return <FieldMappingAttributeEditor
                                key={attr.get("name")}
                                value={fieldMapping.get(attr.get("name"))}
                                attr={attr}
                                width={editorWidth}
                                onChange={onChangeField}
                                options={options} />;
                          } else if (attr.get("name") === "stripHtml") {
                            return <FieldMappingAttributeEditor
                                key={attr.get("name")}
                                value={fieldMapping.get(attr.get("name"))}
                                attr={attr}
                                width={editorWidth}
                                disabled={type !== "string"}
                                onChange={onChangeField} />;
                          } else {
                            return <FieldMappingAttributeEditor
                                key={attr.get("name")}
                                value={fieldMapping.get(attr.get("name"))}
                                attr={attr}
                                width={editorWidth}
                                onChange={onChangeField} />;
                          }
                        })}
                        {section === "Type" && typeAttrs.map(attr => {
                          return <FieldMappingAttributeEditor
                              key={attr.get("name")}
                              value={fieldMapping.get(attr.get("name"))}
                              attr={attr}
                              width={editorWidth}
                              onChange={onChangeField} />;
                        })}
                      </div>
                    </div>
                  </React.Fragment>);
            })}
      </div>);
});

/** @jsxImportSource @emotion/react */

import Immutable from "immutable";
import reactCreateClass from "create-react-class";
import moment from "moment";
import {RaisedButton} from "material-ui";
import {connect} from "react-redux";

import ImmutableSelect from "js/components/immutable-select";
import {Err, Success} from "js/components/notification";
import {loadAvailableResults, runTest, setResultId} from "js/app-areas/functional-test/data";
import {apiUrl} from "js/base-urls";
import {baseEndpoint} from "js/app-areas/functional-test/page";
import pure from "js/pure";

const ResultsSection = reactCreateClass({

  getInitialState() {
    return {
      successMessage: null,
      errorMessage: null
    };
  },

  componentDidMount() {
    this.props.loadAvailableResults();

    this.resultLoadTimer = setInterval(this.reloadResultsIfRunning, 30_000);
  },

  componentWillMount() {
    clearInterval(this.resultLoadTimer);
  },

  render() {
    const {resultIdToResult, loadingAvailableResults, loadAvailableResults, resultId, setResultId} = this.props;
    const {successMessage, errorMessage} = this.state;
    const dropdownOptions = resultIdToResult
        .map(result => result.set("label", getDropdownResultLabel(result)))
        .sortBy(result => -result.get("id"))
        .valueSeq();

    const result = resultIdToResult.get(resultId);

    return (
        <div style={{padding: "1rem"}}>
          <div style={{display: "flex"}}>
            <RaisedButton
                label={<i className="fa fa-refresh" />}
                disabled={loadingAvailableResults}
                style={{minWidth: 50, marginRight: "1rem"}}
                onClick={loadAvailableResults} />
            <ImmutableSelect
                keys={["id", "label"]}
                multi={false}
                loading={loadingAvailableResults}
                options={dropdownOptions}
                selectedValue={resultId}
                clearable={false}
                css={{width: "30vw", marginRight: "1rem"}}
                onChange={setResultId} />
            {resultId && <RaisedButton
                label={"Re-run"}
                style={{marginRight: "1rem"}}
                onClick={() => this.reRunTest(result)} />}
            {resultId && <a
                style={{marginTop: "auto", marginBottom: "auto"}}
                href={`${apiUrl}/${baseEndpoint}/results/${resultId}`}
                target={"_blank"}
                rel={"noopener noreferrer"}>
              Raw Results
            </a>}
          </div>

          {result && <Parameters result={result} copyToClipboard={this.copyArgumentsToClipboard} />}

          <Success message={successMessage} onRequestClose={() => this.setState({successMessage: null})} />
          <Err message={errorMessage} onRequestClose={() => this.setState({errorMessage: null})} />
        </div>
    );
  },

  copyArgumentsToClipboard(argsStr) {
    navigator.clipboard
        .writeText(argsStr)
        .then(
            () => this.setState({successMessage: "Parameters copied to clipboard"}),
            () => this.setState({errorMessage: "Unable to copy parameters to clipboard"}));
  },

  reRunTest(result) {
    const testName = result.get("test_name");
    const params = result.get("params");
    const redactedParams = params.filter(val => val === "*REDACTED*");

    const enteredParams = redactedParams
        .mapEntries(([key, _]) => [key, prompt(`Enter value for: '${key}'`)]);
    const updatedParams = params.merge(enteredParams);
    runTest(testName, updatedParams)
        .then(response => response.json())
        .then(body => {
          const newResultId = body.id;
          this.setState({successMessage: "Successfully started test. Result ID: " + newResultId});
          this.props.setResultId(newResultId);
          this.props.loadAvailableResults();
        })
        .catch(e => e.response
            .json()
            .then(body => this.setState({errorMessage: "An error occurred: " + body.message})));
  },

  reloadResultsIfRunning() {
    const {resultIdToResult, loadingAvailableResults, loadAvailableResults, resultId} = this.props;
    if (!loadingAvailableResults && resultIdToResult.getIn([resultId, "status"]) === "RUNNING") {
      loadAvailableResults();
    }
  }

});

const Parameters = pure(({result, copyToClipboard}) => {
  const params = result.get("params");
  const paramsString = JSON.stringify(params, null, 2);
  return (
      <div>
        <h3>Parameters</h3>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1rem",
          backgroundColor: "whitesmoke"
        }}>
          <pre>
            {paramsString}
          </pre>

          <RaisedButton
              label={<i className={"fa fa-clipboard"} />}
              style={{minWidth: 50, marginTop: "auto", marginBottom: "1rem", marginRight: "1rem", height: 35}}
              onClick={() => copyToClipboard(paramsString)} />
        </div>
      </div>);
});

const getDropdownResultLabel = (result) => {
  return Immutable
      .fromJS([
        result.get("id"),
        result.get("test_name"),
        moment(result.get("start_timestamp")).format("lll"),
        result.get("status")])
      .join(" - ");
};


const stateToProps = state => state.get("functionalTests").toObject();
const dispatchToProps = {
  loadAvailableResults,
  setResultId
};

export default connect(stateToProps, dispatchToProps)(ResultsSection);

import React from "react";
import createReactClass from "create-react-class";
import pure from "js/pure";
import Modal from "react-modal";
import * as fetch from "js/fetch";
import RaisedButton from "material-ui/RaisedButton";
import {hasSession} from "js/app-areas/login/auth";

const headerStyle = {
    float: "left",
    margin: 0,
    width: "96%",
    fontSize: "1.35rem"
};

const closeButtonStyle = {
    float: "right",
    fontWeight: "bold",
    cursor: "pointer"
};

const TimeoutModal = createReactClass({

	getInitialState(){
		return {
			lastRequestTime: fetch.getLastRequestTime(),
			intervalId: null,
			userClosedModal: false
		};
	},

	render() {
		const lastRequestTime = this.state.lastRequestTime;
		const userClosedModal = this.state.userClosedModal;
        const currentTime = new Date();
        const timeDifferenceInSeconds = (currentTime - lastRequestTime)/1000;
        const showModal = (timeDifferenceInSeconds > 900) && !userClosedModal;

		return <div>
					<Modal
				        isOpen={showModal}
				        onRequestClose={this.onRequestClose}
				        style={{ overlay: { zIndex: 9999 } }}
				        contentLabel="Modal">
				        <div className="clearfix" style={{ marginBottom: "0.5rem" }}>
				            <h1 style={headerStyle}>You are approaching automated timeout</h1>
				            <button style={closeButtonStyle} onClick={this.onRequestClose}>X</button>
				        </div>
				        <div style={{ height: "auto", width: "100%" }}>
				            <TimeoutView secondsSinceRequest={(Math.floor(timeDifferenceInSeconds))} />
				        </div>
				    </Modal>
				</div>;
	},

	onRequestClose(){
		this.setState({userClosedModal: true});
	},

	componentDidMount(){
		this.setState({intervalId: setInterval(() => this.setState((previousState) => this.updateState(previousState)), 1000)});
	},

	componentWillUnmount(){
		clearInterval(this.state.intervalId);
	},

	updateState(previousState){
		if(previousState.lastRequestTime === fetch.getLastRequestTime()){
			return {};
		}
		else{
			return {lastRequestTime: fetch.getLastRequestTime(), userClosedModal: false};
		}
	}

});


const TimeoutView = pure((properties) => {
	const lastRequest = properties.secondsSinceRequest;
	const timeLimit = 1740;
	const timeLeft = timeLimit - lastRequest;
	return (
	<div>
	    <p> {timeLeft > 0 ? timeLeft + " seconds left to keep the session alive" : "You are likely already timed out, please refresh"} </p>
	    <RaisedButton label="Refresh" 
	    	onClick={() => window.location.reload()} 
	    	style={{ marginTop: "1.15rem", marginBottom: "0.5rem", marginLeft: "0.5rem", marginRight: "0.5rem" }}/>
	    <RaisedButton label="Keep Alive" 
	    	onClick={() => hasSession()} 
	    	disabled={!(timeLeft > 0)} 
	    	style={{ marginTop: "1.15rem", marginBottom: "0.5rem", marginLeft: "0.5rem", marginRight: "0.5rem" }}/>
	</div>);});


export {
	TimeoutModal
};

import React, {useState} from "react";
import {Dialog, RaisedButton, TextField} from "material-ui";
import {useDispatch} from "react-redux";
import Immutable from "immutable";

import {
  saveEntityFieldCustomisation,
  saveEntityLinkField,
  saveEntityUiLabel
} from "js/app-areas/entity-renaming/api";
import ImmutableSelect from "js/components/immutable-select";
import {getErrorMessage} from "js/utils/errors";
import {
  mergeInEntityFieldMappingsState,
  setInEntityFieldMappingsState,
} from "js/data/entity-field-mappings/actions";
import {SelectedClientIdContext} from "js/data/contexts";
import {
  ConvertTimezonesToggle,
  IsDeepLinkableToggle,
  LinkContextSelect,
  LinkIsComponentToggle,
  LinkToGroupingEntitySelect, LinkToOverrideSelect, PrefixToggle
} from "js/app-areas/entity-renaming/controls";

export const EntityRenameDialog = React.memo(({isOpen, handleClose, entityEtlAndNameMappings}) => {

  const dispatch = useDispatch();
  const {selectedClientId} = React.useContext(SelectedClientIdContext);

  const [selectedValue, setSelectedValue] = useState();
  const [uiLabel, setUiLabel] = useState("");

  const handleSave = () => {
    if (uiLabel !== selectedValue.clientUiLabel) {
      dispatch(handleEntityUiLabelSave(uiLabel, selectedClientId, selectedValue.dwGroupingEntity));
    }

    handleClose();
  };

  const dropDownOptions = entityEtlAndNameMappings
      .valueSeq()
      .map(entityMapping => Immutable.Map({
        value: entityMapping,
        label: entityMapping.dwGroupingEntity
      }));

  const labelIsDefault = selectedValue ? selectedValue.defaultUiLabel === uiLabel : false;
  const actions = [
    <RaisedButton label="Cancel" onClick={handleClose} style={{marginRight: "1rem"}} />,
    <RaisedButton
        label="Save"
        primary={true}
        disabled={!selectedValue || labelIsDefault}
        title={labelIsDefault ? "Label matches default" : null}
        onClick={handleSave} />];

  return (
      <Dialog
          title={"Rename a new entity"}
          actions={actions}
          modal={false}
          bodyStyle={{overflow: "visible"}}
          open={isOpen}
          onRequestClose={handleClose}>
        <ImmutableSelect
            multi={false}
            placeholder={"Entity to rename"}
            selectedValue={selectedValue}
            onChange={setSelectedValue}
            options={dropDownOptions} />
        <TextField
            style={{marginRight: "2rem"}}
            floatingLabelText={"Entity UI Label"}
            value={uiLabel}
            onChange={(_, newVal) => setUiLabel(newVal)} />
      </Dialog>
  );
});

const geFormatEntities = Immutable.Set(["CV_SENT", "CANDIDATE"]);

export const ColumnRenameDialog =
    React.memo(({
      isOpen,
      handleClose,
      dwGroupingEntity,
      fieldEtlAndNameMappings
    }) => {

      const dispatch = useDispatch();
      const {selectedClientId} = React.useContext(SelectedClientIdContext);
      const geRegex = new RegExp(/^(ENTITY\d+)/);
      const isGenericEntity = geFormatEntities.has(dwGroupingEntity) || geRegex.test(dwGroupingEntity);

      const [selectedValue, setSelectedValue] = useState();
      const [selectedPii, setSelectedPii] = useState();
      const [uiLabel, setUiLabel] = useState("");
      const [trueValueLabel, setTrueValueLabel] = useState("");
      const [falseValueLabel, setFalseValueLabel] = useState("");
      const [shouldPrefix, setShouldPrefix] = useState(true);
      const [needsTimezoneConversion, setNeedsTimezoneConversion] = useState(true);
      const [isDeepLinkableOverride, setIsDeepLinkableOverride] = useState();

      const handleSave = () => {
        if (uiLabel !== selectedValue.clientUiLabel) {
          dispatch(handleEntityFieldCustomisationSave(
              uiLabel,
              trueValueLabel,
              falseValueLabel,
              selectedPii,
              shouldPrefix,
              needsTimezoneConversion,
              isDeepLinkableOverride,
              selectedValue,
              selectedClientId,
              dwGroupingEntity));
        }
        setSelectedPii(null);
        setSelectedValue(null);
        setUiLabel("");
        setShouldPrefix(true);
        setNeedsTimezoneConversion(true);
        setIsDeepLinkableOverride(null);

        handleClose();
      };


      const dropDownOptions = fieldEtlAndNameMappings
          .valueSeq()
          .map(fieldMapping => Immutable.Map({value: fieldMapping, label: fieldMapping.stagingField}));

      const prefixIsDefault = selectedValue ? selectedValue.defaultShouldPrefixWithEntity === shouldPrefix : false;
      const labelIsDefault = selectedValue ? selectedValue.defaultUiLabel === uiLabel : false;
      const settingsAreDefault = prefixIsDefault && labelIsDefault;
      const actions = [
        <RaisedButton label="Cancel" onClick={handleClose} style={{marginRight: "1rem"}} />,
        <RaisedButton
            label="Save"
            primary={true}
            disabled={!selectedValue || (!selectedPii && isGenericEntity) || settingsAreDefault}
            title={settingsAreDefault ? "Options match defaults" : null}
            onClick={handleSave} />];

      return (
          <Dialog
              title={"Customise a new column for this client"}
              actions={actions}
              modal={false}
              bodyStyle={{overflow: "visible"}}
              open={isOpen}
              onRequestClose={handleClose}>
            <ImmutableSelect
                multi={false}
                placeholder={"Column to customise"}
                selectedValue={selectedValue}
                onChange={setSelectedValue}
                options={dropDownOptions} />
            {selectedValue && <div>
              <section>
                <h2>Privacy</h2>
                <p>
                  Mark the PII status of this column. Sensitive PII is PII, which if lost, compromised, or disclosed
                  without authorization could be used to inflict substantial harm, embarrassment, inconvenience, or
                  unfairness to an individual.
                </p>
                <ImmutableSelect
                    multi={false}
                    placeholder={"PII Category of Column"}
                    selectedValue={selectedPii}
                    onChange={setSelectedPii}
                    options={piiCategories} />
              </section>

              <section>
                <h2>Naming</h2>
                <TextField
                    style={{marginRight: "2rem"}}
                    floatingLabelText={"Column UI Label"}
                    value={uiLabel}
                    onChange={(_, newVal) => setUiLabel(newVal)} />
                <PrefixToggle
                    toggled={shouldPrefix}
                    onToggle={(_, newVal) => setShouldPrefix(newVal)} />

                {selectedValue.dataType === "BOOLEAN" &&
                    <>
                      <TextField
                          hintText={"True"}
                          floatingLabelText={"True value label"}
                          style={{display: "block"}}
                          value={trueValueLabel}
                          onChange={(_, newVal) => setTrueValueLabel(newVal)} />

                      <TextField
                          hintText={"False"}
                          floatingLabelText={"False value label"}
                          style={{display: "block"}}
                          value={falseValueLabel}
                          onChange={(_, newVal) => setFalseValueLabel(newVal)} />
                    </>}

              </section>

              <section>
                <h2>Misc Options</h2>
                <ConvertTimezonesToggle
                    toggled={needsTimezoneConversion}
                    onToggle={(_, newVal) => setNeedsTimezoneConversion(newVal)} />
                <IsDeepLinkableToggle
                    toggled={isDeepLinkableOverride}
                    onToggle={(_, newVal) => setIsDeepLinkableOverride(newVal)} />
              </section>
            </div>}
          </Dialog>);
    });

export const LinkColumnDialog =
    React.memo(({
      isOpen,
      handleClose,
      dwGroupingEntity,
      fieldEtlAndNameMappings,
      dwGroupingEntities,
      linkContexts
    }) => {

      const dispatch = useDispatch();
      const {selectedClientId} = React.useContext(SelectedClientIdContext);

      const [selectedValue, setSelectedValue] = useState();
      const [linkToGroupingEntity, setLinkToGroupingEntity] = useState();
      const [linkContext, setLinkContext] = useState();
      const [linkIsComponent, setLinkIsComponent] = useState(false);
      const [isDeepLinkableOverride, setIsDeepLinkableOverride] = useState();

      const handleSave = () => {
        dispatch(handleEntityLinkFieldSave(
            linkToGroupingEntity,
            linkContext,
            linkIsComponent,
            isDeepLinkableOverride,
            selectedValue,
            selectedClientId,
            dwGroupingEntity));
        setSelectedValue(null);
        setLinkToGroupingEntity(null);
        setLinkContext(null);
        setLinkIsComponent(false);

        handleClose();
      };
      const dropDownOptions = fieldEtlAndNameMappings
          .valueSeq()
          .map(fieldMapping => Immutable.Map({value: fieldMapping, label: fieldMapping.stagingField}));

      const groupEntityDropdown = dwGroupingEntities.map(groupingEntity => Immutable.fromJS({
        label: groupingEntity,
        value: groupingEntity
      }));
      const linkContextDropdown = linkContexts.map(linkContext => Immutable.fromJS({
        label: linkContext,
        value: linkContext
      }));

      const fieldsAreNotNull = linkToGroupingEntity != null && linkContext != null;
      const actions = [
        <RaisedButton label="Cancel" onClick={handleClose} style={{marginRight: "1rem"}} />,
        <RaisedButton
            label="Save"
            primary={true}
            disabled={!selectedValue || !fieldsAreNotNull}
            onClick={handleSave} />];

      return (
          <Dialog
              title={"Override a link column"}
              actions={actions}
              modal={false}
              bodyStyle={{overflow: "visible"}}
              open={isOpen}
              onRequestClose={handleClose}>
            <LinkToOverrideSelect
                selectedValue={selectedValue}
                options={dropDownOptions}
                onChange={setSelectedValue} />
            <LinkToGroupingEntitySelect
                selectedValue={linkToGroupingEntity}
                options={groupEntityDropdown}
                onChange={setLinkToGroupingEntity} />
            <LinkContextSelect
                selectedValue={linkContext}
                options={linkContextDropdown}
                onChange={setLinkContext} />
            <LinkIsComponentToggle
                toggled={linkIsComponent}
                onToggle={(_, newVal) => setLinkIsComponent(newVal)} />
            <IsDeepLinkableToggle
                toggled={isDeepLinkableOverride}
                onToggle={(_, newVal) => setIsDeepLinkableOverride(newVal)} />
          </Dialog>);
    });


export const handleEntityFieldCustomisationSave = (
    label,
    trueValueLabel,
    falseValueLabel,
    piiCategory,
    shouldPrefix,
    needsTimezoneConversion,
    isDeepLinkableOverride,
    entityFieldMapping,
    selectedClientId,
    selectedEntity) => dispatch => {
  const comment = `Rename ${selectedEntity}.${entityFieldMapping.stagingField}`;
  return saveEntityFieldCustomisation(
      selectedClientId,
      entityFieldMapping.entityFieldMappingId,
      label,
      trueValueLabel,
      falseValueLabel,
      piiCategory,
      shouldPrefix,
      needsTimezoneConversion,
      isDeepLinkableOverride,
      comment)
      .then(
          response => response
              .json()
              .then(newEntityFieldMappingJs => {
                const newEntityFieldMapping = Immutable.fromJS(newEntityFieldMappingJs);
                dispatch(setInEntityFieldMappingsState(["api", "field", "error"], null));
                return dispatch(mergeInEntityFieldMappingsState(
                    ["mappings", selectedEntity, "entity-field-mappings-by-id"],
                    Immutable.Map({[newEntityFieldMapping.get("entity-field-mapping-id")]: newEntityFieldMapping})));
              }),
          error => getErrorMessage(error).then(message => {
            return dispatch(mergeInEntityFieldMappingsState(["api", "field"], Immutable.Map({error: message})));
          })
      );
};

export const handleEntityLinkFieldSave = (
    linkToGroupingEntity,
    linkContext,
    linkIsComponent,
    isDeepLinkableOverride,
    entityFieldMapping,
    selectedClientId,
    selectedEntity) => dispatch => {
  const comment = `Override link ${selectedEntity}.${entityFieldMapping.stagingField}`;
  return saveEntityLinkField(
      selectedClientId,
      entityFieldMapping.entityFieldMappingId,
      linkToGroupingEntity,
      linkContext,
      linkIsComponent,
      isDeepLinkableOverride,
      comment)
      .then(
          response => response
              .json()
              .then(newEntityFieldMappingJs => {
                const newEntityFieldMapping = Immutable.fromJS(newEntityFieldMappingJs);
                dispatch(setInEntityFieldMappingsState(["api", "field", "error"], null));
                return dispatch(mergeInEntityFieldMappingsState(
                    ["mappings", selectedEntity, "entity-field-mappings-by-id"],
                    Immutable.Map({[newEntityFieldMapping.get("entity-field-mapping-id")]: newEntityFieldMapping})));
              }),
          error => getErrorMessage(error).then(message => {
            return dispatch(mergeInEntityFieldMappingsState(["api", "field"], Immutable.Map({error: message})));
          })
      );
};

export const handleEntityUiLabelSave = (label, selectedClientId, selectedEntity) => dispatch => {
  const comment = `Rename ${selectedEntity}`;
  return saveEntityUiLabel(selectedClientId, selectedEntity, label, comment).then(
      response => response.json().then(newEntityFieldMappings => {
        const entityFieldMappings = Immutable.fromJS(newEntityFieldMappings);
        const dwGroupingEntity = entityFieldMappings.get("dw-grouping-entity");
        dispatch(setInEntityFieldMappingsState(["api", "entity", "error"], null));
        return dispatch(mergeInEntityFieldMappingsState(["mappings", dwGroupingEntity], entityFieldMappings));
      }),
      error => getErrorMessage(error).then(message => {
        return dispatch(mergeInEntityFieldMappingsState(["api", "entity"], Immutable.Map({error: message})));
      }));
};

export const piiCategories = Immutable.fromJS([
  {value: "NOT_PII", label: "Not PII"},
  {value: "PII", label: "PII"},
  {value: "SENSITIVE_PII", label: "Sensitive PII"}]);



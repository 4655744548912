import Immutable from "immutable";

import * as Fetch from "js/fetch";
import {indexBy} from "js/utils/collections";

const loadDropdownData = () => Fetch
    .getJson("onboarding/dropdown-data")
    .then(result => Immutable.fromJS(result));



const loadEntityToFieldConfig = (clientId) => Fetch
    .getJson("onboarding/entity-field-configs", {"cube19-client-id": clientId})
    .then(result => Immutable.fromJS(result))
    .then(result => indexBy(entity => entity.get("name"), result));

const loadBulkStatus = (bulkId) => Fetch
    .getJson("onboarding/bulk/" + bulkId)
    .then(res => Immutable.fromJS(res));

const loadUsersForClient = (clientId) => Fetch
    .getJson("onboarding/users", {"cube19-client-id": clientId})
    .then(res => Immutable.fromJS(res));

const loadDefaultKpiConfigs = (clientConfig) => Fetch
    .post("onboarding/default-kpi-configs", clientConfig)
    .then(response => response.json())
    .then(result => Immutable.fromJS(result))

const generateLegacyKpiConfigs = (clientId) => Fetch
    .getJson("onboarding/legacy-kpi-configs", {"cube19-client-id": clientId})
    .then(result => Immutable.fromJS(result))

const defineClient = (config) => Fetch
    .post("onboarding/define-client", config)
    .then(response => response.json())
    .then(response => Immutable.fromJS(response));

const redefineClient = (config) => Fetch
    .post("onboarding/redefine-client", config)
    .then(response => response.json())
    .then(response => Immutable.fromJS(response));


export {
  loadDropdownData,
  loadDefaultKpiConfigs,
  generateLegacyKpiConfigs,
  loadEntityToFieldConfig,
  loadBulkStatus,
  loadUsersForClient,

  defineClient,
  redefineClient
};
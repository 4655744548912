import React from "react";

import { AccordionContainer, AccordionSection } from "js/components/accordion";
import ConfigProperties from "js/app-areas/environment-config/config-properties-table";
import ClientConfigPropertyForm from "js/app-areas/environment-config/client-config-property-form";
import StandardConfigPropertyForm from "js/app-areas/environment-config/standard-config-property-form";

const EnvironmentConfig = () => (
    <div style={{padding: "1rem"}}>
        <AccordionContainer key="configuration-properties" stateless={true}>
            {getSections().map((section, index) => (
                <AccordionSection key={index} title={section.title}>
                    {section.content}
                </AccordionSection>
            ))}
        </AccordionContainer>
    </div>
);

const getSections = () => [{
    title: "View/Update existing configuration properties",
    content: <ConfigProperties />
},{
    title: "Set a client configuration property value",
    content: <ClientConfigPropertyForm />
},{
    title: "Set a non-client configuration property value",
    content: <StandardConfigPropertyForm />
}];

export default EnvironmentConfig;

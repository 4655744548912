import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

import TimeAgo from "timeago.js";
import moment from "moment";

import RaisedButton from "material-ui/RaisedButton";

const TimeAgoInstance = TimeAgo();

export default createReactClass({

  mixins: [PureRenderMixin],

  componentDidMount() {
    this.intervalId = setInterval(() => this.forceUpdate(), 30 * 1000);
  },

  componentWillUnmount() {
    clearInterval(this.intervalId);
  },

  render() {
    let refreshLabel;
    const date = moment(this.props.millis);
    const age = Date.now() - this.props.millis;
    if (this.props.millis && age > 60 * 1000) {
      refreshLabel = " " + TimeAgoInstance.format(date.toDate());
    } else {
      refreshLabel = "";
    }
    let title;
    const lastLoadedText = "Loaded at " + date.format("YYYY-MM-DD HH:mm:ss");
    if (this.props.title) {
      title = this.props.title + " (" + lastLoadedText + ")";
    } else {
      title = lastLoadedText;
    }
    return <RaisedButton
      title={title}
      label={<span><i className="fa fa-refresh" />{refreshLabel}</span>}
      style={{minWidth: 45, ...(this.props.style || {})}}
      onClick={this.props.onClick}
      disabled={this.props.disabled} />;
  }

});

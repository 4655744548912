import "gridlex/dist/gridlex.css";

import React from "react";
import ReactDOM from "react-dom";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import FileSaver from "browser-filesaver";
import {connect} from "react-redux";
import RaisedButton from "material-ui/RaisedButton";

import ClientPicker from "js/components/client-picker";
import Err from "js/components/error";
import DatePicker from "js/app-areas/client-details/datepicker";
import PivotTable from "js/pivot-table/container";
import {isDataTableTypeReport, downloadTableAsExcelFile} from "js/utils/pivot-utils";
import Modal from "js/modal/root";
import * as modal from "js/modal/reducer";
import * as pivotTableConfig from "js/app-areas/client-details/pivot-table-config-reducer";
import * as clientDetailsData from "js/app-areas/client-details/pivot-data-reducer";
import {ClientsContext} from "js/data/contexts";

const mapStateToProps = (state) => {
  const clientDetailsData = state.get("clientDetailsData");
  const pivotTableConfig = state.get("clientDetailsPivotTableConfig");
  return {
    filters: clientDetailsData.get("filters"),
    isFetchingData: clientDetailsData.get("isFetching"),
    data: clientDetailsData.get("data"),
    error: clientDetailsData.get("error"),
    modal: state.get("modal"),
    pivotTableOptions: pivotTableConfig.get("options"),
    showTotals: pivotTableConfig.get("showTotals")
  };
};

const mapDispatchToProps = {
  onClientPickerChange: clientDetailsData.onClientPickerChange,
  onStartDateChange: clientDetailsData.onStartDateChange,
  onEndDateChange: clientDetailsData.onEndDateChange,
  onLoadDataClick: clientDetailsData.loadData,
  onToggleShowTotals: pivotTableConfig.toggleShowTotals,
  onPivotTableRefresh: pivotTableConfig.onPivotTableRefresh,
  resetPivotTableFilters: pivotTableConfig.resetPivotTableFilters,
  openNoDataMessageModal: modal.openNoDataMessageModal,
  openDataTableModal: modal.openDataTableModal,
  closeModal: modal.closeModal
};

const ClientDetails = connect(mapStateToProps, mapDispatchToProps)(createReactClass({

  render() {
    const {
      idToClient,
      filters,
      pivotTableOptions,
      showTotals,
      isFetchingData,
      data,
      error,
      modal,
      closeModal,
      onStartDateChange,
      onEndDateChange,
      onLoadDataClick,
      onToggleShowTotals,
      onPivotTableRefresh
    } = this.props;
    const hasInvalidParams = filters.get("clientIds").isEmpty()
        || !filters.get("startDate").isValid()
        || !filters.get("endDate").isValid();
    const isDataLoaded = !!data;
    return (
        <div style={{padding: "1rem"}}>
          <div className="grid">
            <div className="col" style={{marginBottom: "1rem"}}>
              <label>Client(s)</label>
              <ClientPicker
                  multi={true}
                  value={filters.get("clientIds")}
                  idToClient={idToClient}
                  onChange={clientIds => {
                    this.props.onClientPickerChange(clientIds);
                    const isClientFilterChanged = !Immutable.is(clientIds, filters.get("clientIds"));
                    if (isClientFilterChanged) {
                      this.props.resetPivotTableFilters();
                    }
                  }} />
              {filters.get("clientIds").isEmpty()
              && <Err type="error"
                      message="Select at least 1 client"
                      customStyle={{display: "block", marginTop: "0.5em"}} />}
            </div>

            <div className="col">
              <DatePicker
                  label="Start date"
                  onDateChange={onStartDateChange}
                  value={filters.get("startDate")} />
            </div>

            <div className="col">
              <DatePicker
                  label="End date"
                  onDateChange={onEndDateChange}
                  value={filters.get("endDate")} />
              {error === clientDetailsData.END_DATE_BEFORE_START_DATE
              && <Err type="error"
                      message="End Date cannot be before Start Date"
                      customStyle={{display: "block", marginTop: "0.5em"}} />}
            </div>

            <div className="col" style={{textAlign: "center"}}>
              <RaisedButton
                  label="Load Data"
                  onClick={() => onLoadDataClick(filters)}
                  disabled={hasInvalidParams}
                  primary={true}
                  style={{marginTop: "1.15em", marginBottom: "0.5em"}} />
              {error === clientDetailsData.FAILED_TO_LOAD
              && <Err type="error" message="Unable to load data" />}
              {error === clientDetailsData.NO_DATA_FOUND
              && <Err type="warning" message="No clients match the filters selected" />}
            </div>
          </div>
          <PivotTable
              options={pivotTableOptions}
              showTotals={showTotals}
              isFetchingData={isFetchingData}
              data={isDataLoaded ? data : null}
              onRefresh={onPivotTableRefresh}
              onToggleShowTotals={onToggleShowTotals}
              onCellClick={this.onPivotTableCellClick}
              onDownloadReportRequest={this.handleDownloadReportRequest} />
          {modal.get("isModalOpen") &&
          <Modal headerLabel={modal.get("modalHeader")} onRequestClose={closeModal}>
            {modal.get("modalContent")}
          </Modal>}
        </div>
    );
  },

  handleDownloadReportRequest() {
    const {filters, pivotTableOptions} = this.props;
    if (isDataTableTypeReport(pivotTableOptions.get("rendererName"))) {
      const $ = window.jQuery;
      const $this = $(ReactDOM.findDOMNode(this));
      const tableHtml = $this.find(".pvtTable").html();
      const blob = downloadTableAsExcelFile(tableHtml);
      const title = filters.get("clientIds").join();
      const startDate = filters.get("startDate").format("YYYYMMMDD");
      const endDate = filters.get("endDate").format("YYYYMMMDD");
      const fileName = `${startDate}-${endDate}-client-${title}.xls`;
      FileSaver.saveAs(blob, fileName);
    } else {
      alert("Unable to Save to File:\nThis functionality only works for data tables.\nPlease choose a Table type report (e.g. Table, Table Barchart, Heatmap).");
    }
  },

  onPivotTableCellClick(dataFilter) {
    const {data, openNoDataMessageModal, openDataTableModal} = this.props;
    const rows = data
        .filter(row => Object.keys(dataFilter)
            .every(key => row.includes(dataFilter[key])));

    if (rows.length === 0) {
      openNoDataMessageModal();
    } else {
      const headerLabel = Object.keys(dataFilter)
          .map(key => `${key} - ${dataFilter[key]}`)
          .join(" / ");
      const columns = clientDetailsData.COLUMNS;
      const initialSortBy = columns.indexOf("Name");
      openDataTableModal(headerLabel, columns, rows, initialSortBy);
    }
  }

}));

const ConnectedClientDetails = props => {
  const {idToClient} = React.useContext(ClientsContext);
  return <ClientDetails {...props} idToClient={idToClient} />;
};

export default ConnectedClientDetails;

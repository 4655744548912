import React from "react";
import Immutable from "immutable";

import DataTable from "js/components/data-table";
import gif1 from "img/giphy-1.gif";
import gif2 from "img/giphy-2.gif";
import gif3 from "img/giphy-3.gif";
import gif4 from "img/giphy-4.gif";
import gif5 from "img/giphy-5.gif";
import gif6 from "img/giphy-6.gif";
import gif7 from "img/giphy-7.gif";
import gif8 from "img/giphy-8.gif";
import gif9 from "img/giphy-9.gif";

const SHOW = "SHOW_MODAL";
const HIDE = "HIDE_MODAL";

const initialState = Immutable.Map({
    isModalOpen: false,
    modalHeader: null,
    modalContent: null
});

const modal = (state = initialState, action) => {
    switch (action.type) {
    case SHOW:
        return state
            .set("isModalOpen", true)
            .set("modalHeader", action.modalHeader)
            .set("modalContent", action.modalContent);
    case HIDE:
        return initialState;
    default:
        return state;
    }
};

const openSimpleMessageModal = (header = "", message = "") => ({
    type: SHOW,
    modalHeader: header,
    modalContent: <p>{message}</p>
});

const NoDataMessage = () => {
  const images = [gif1, gif2, gif3, gif4, gif5, gif6, gif7, gif8, gif9];
  const chosenImage = images[Math.floor((Math.random() * 9))]
    return (
        <div>
            <p>There is no data behind this cell.</p>
            <p>{`But here's something to look at instead... \u{1F601}`}</p>
            <img src={chosenImage} alt="a random GIF" />
        </div>);
};

const openNoDataMessageModal = () => ({
    type: SHOW,
    modalHeader: "No data to display",
    modalContent: <NoDataMessage />
});

const openDataTableModal = (header, columns, rows, initialSortBy = 0) => ({
    type: SHOW,
    modalHeader: header,
    modalContent: <DataTable columns={columns} rows={rows} initialSortBy={initialSortBy}  />
});

const closeModal = () => ({ type: HIDE });

export default modal;
export {
  openSimpleMessageModal,
  openNoDataMessageModal,
  openDataTableModal,
  closeModal
};

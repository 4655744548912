import React from "react";
import moment from "moment";
import Immutable from "immutable";

import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import {AccordionSubmissions} from "js/components/change-submission";
import {Err} from "js/components/notification";
import {indexBy} from "js/utils/collections";
import useMountEffect from "js/utils/hooks/use-mount-effect";
import * as fetch from "js/fetch";
import {ClientsContext, CurrentUserContext} from "js/data/contexts";

const spacingStyle = {display: "inline-block", marginRight: "1rem"};

const submissionsForSection = (idToSubmission, section, filterText) => {
  const lowerFilterText = filterText.toLowerCase();
  return idToSubmission
      .filter(s => s.get("sections").includes(section))
      .filter(s => lowerFilterText === "" || searchableStringForSubmission(s).indexOf(lowerFilterText) !== -1);
};

const ChangeSubmissions = React.memo(({idToClient, permissions, currentUserId}) => {
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [filterText, setFilterText] = React.useState("");
  const [idToSubmission, setIdToSubmission] = React.useState(Immutable.Map());
  const [where, setWhere] = React.useState(getInitialWhere);

  const showErrorMessage = React.useCallback(
      exception => exception.response.json().then(body => setErrorMessage(body.message)),
      []);

  const setSubmission = React.useCallback(submission => {
    const id = submission.get("submission_id");
    const oldSubmission = idToSubmission.get(id);
    const newIdToSubmission = idToSubmission.set(id, submission.set("sections", oldSubmission.get("sections")));
    setIdToSubmission(newIdToSubmission);
  }, [idToSubmission]);

  const loadAndSetChangeSubmissions = whereQuery => {
    setLoading(true);
    loadChangeSubmissions(whereQuery).then(res => {
      const idToMineSubmission = indexBy(c => c.get("submission_id"), res.get("own"))
          .map(s => s.set("sections", Immutable.Set.of("own")));
      const idToHelpSubmission = indexBy(c => c.get("submission_id"), res.get("help"))
          .map(s => s.set("sections", Immutable.Set.of("help")));
      const idToSearchSubmission = indexBy(c => c.get("submission_id"), res.get("search"))
          .map(s => s.set("sections", Immutable.Set.of("search")));

      setIdToSubmission(idToMineSubmission.mergeWith(
          (a, b) => a.update("sections", sections => sections.union(b.get("sections"))),
          idToHelpSubmission,
          idToSearchSubmission));
      setLoading(false);
    });
  };

  useMountEffect(() => loadAndSetChangeSubmissions(where));

  return (
      <div>
        <RaisedButton
            style={spacingStyle}
            label="Refresh"
            onClick={() => loadAndSetChangeSubmissions(where)} />
        <TextField
            style={spacingStyle}
            floatingLabelText="Search submissions on this page"
            value={filterText}
            onChange={e => setFilterText(e.target.value)} />

        <h3>Your submissions</h3>
        <AccordionSubmissions
            idToClient={idToClient}
            idToSubmission={submissionsForSection(idToSubmission, "own", filterText)}
            permissions={permissions}
            isLoading={loading}
            currentUserId={currentUserId}
            onChange={setSubmission}
            onError={showErrorMessage} />
        <br />

        <h3>Submissions you can help with</h3>
        <AccordionSubmissions
            idToClient={idToClient}
            idToSubmission={submissionsForSection(idToSubmission, "help", filterText)}
            permissions={permissions}
            isLoading={loading}
            currentUserId={currentUserId}
            onChange={setSubmission}
            onError={showErrorMessage} />
        <br />

        <h3>Find a submission</h3>
        <RaisedButton
            style={spacingStyle}
            label="Search"
            onClick={() => loadAndSetChangeSubmissions(where)} />
        <TextField
            style={spacingStyle}
            fullWidth={true}
            multiLine={true}
            floatingLabelText="Search all submissions in database (SQL)"
            value={where}
            onChange={e => setWhere(e.target.value)} />
        <AccordionSubmissions
            idToClient={idToClient}
            idToSubmission={submissionsForSection(idToSubmission, "search", filterText)}
            permissions={permissions}
            isLoading={loading}
            currentUserId={currentUserId}
            onChange={setSubmission}
            onError={showErrorMessage} />

        <Err message={errorMessage} onRequestClose={() => setErrorMessage(null)} />
      </div>
  );
});

const getInitialWhere = () => {
  const startDate = moment().subtract(1, "days").format("YYYYMMDD");
  return `submitted_date >= ${startDate} AND dispatch_type IN ('sql', 'cs-proc-call', 'switch-user', 'etl-config')`;
};

const searchableStringForSubmission = c => c.valueSeq().toArray().join(" ").toLowerCase();

const loadChangeSubmissions = where => fetch
    .getJson("change-submissions", {where})
    .then(body => Immutable.fromJS(body));

const ConnectedChangeSubmissions = props => {
  const {idToClient} = React.useContext(ClientsContext);
  const {currentUser} = React.useContext(CurrentUserContext);
  return <ChangeSubmissions
      {...props}
      idToClient={idToClient}
      permissions={currentUser.get("permissions")}
      currentUserId={currentUser.get("username")} />;
};

export default ConnectedChangeSubmissions;

import moment from "moment";
import * as Immutable from "immutable";

import * as Fetch from "js/fetch";


const mergeDefaults = config => config
    .set("entities", config.get("entities") || Immutable.List())
    .set("properties", config.get("properties") || Immutable.Map());

const fetchEtlConfig = cube19ClientId => Fetch
    .getJson("json-etl-config/" + cube19ClientId)
    .then(res => Immutable
        .fromJS(res)
        .update("common", mergeDefaults)
        .update("client", mergeDefaults)
        .update("merged", mergeDefaults));

const fetchRecentSubmissions = (cube19ClientId, days) => Fetch
    .getJson("change-submissions/json-etl-config", {
      "cube19-client-id": cube19ClientId,
      "start-date": moment().subtract(days, "days").format("YYYY-MM-DD"),
      "end-date": moment().format("YYYY-MM-DD")
    })
    .then(res => Immutable.fromJS(res));

const lintConfig = config => Fetch
    .post("json-etl-config/lint", {config})
    .then(res => res.json())
    .then(res => Immutable.fromJS(res));

const saveConfig = (cube19ClientId, currentConfig, newConfig, comment) => Fetch
    .post(
        "json-etl-config/" + cube19ClientId + "/save",
        {currentConfig, newConfig, comment})
    .then(res => res.json())
    .then(res => Immutable.fromJS(res));

const mergeConfigWithExistingDefault = (cube19ClientId, config) => Fetch
    .post("json-etl-config/" + cube19ClientId + "/merge", config.toJS())
    .then(res => res.json())
    .then(res => Immutable.fromJS(res));

const getCrmEntities = (cube19ClientId) => Fetch
    .getJson("json-etl-config/" + cube19ClientId + "/get-crm-entities")
    .then(res => Immutable.fromJS(res));

export {
  fetchEtlConfig,
  fetchRecentSubmissions,
  lintConfig,
  saveConfig,
  mergeConfigWithExistingDefault,
  getCrmEntities
};
/** @jsxImportSource @emotion/react */

import React from "react";
import Immutable from "immutable";
import {Paper} from "material-ui";
import TimestampedRefreshButton from "js/components/timestamped-refresh-button";
import {
  AdditionalDataForm,
  AppointmentToInterviewsForm,
  AppointmentToMeetings,
  CandidateForm,
  CorporateUserForm,
  GeneralForm,
  JobOrderForm,
  JobSubmissionForm,
  LeadForm,
  NoteToCallsForm,
  NoteToCVSentForm,
  NoteToMeetings,
  OpportunityForm,
  PlacementForm,
  TaskForm
} from "js/app-areas/onboarding/step-2/components/etl-config-sections";
import {fields, hasFields, getAvailableAdditionalDataFields} from "js/app-areas/onboarding/step-2/config-helpers";

const MappingSection = ({
  name,
  mapsTo,
  id,
  isExpanded,
  onClick,
  children
}) => <div>
  <div
      onClick={() => onClick(id)}
      css={mappingSectionStyle}>
    <div style={{display: "flex"}}>
      <div style={{padding: 5}}>
        {name}
      </div>
      {mapsTo && mapsTo.map(e => e && <div key={e} style={infoStyle}>→ {e}</div>)}
    </div>
    <i className="fa fa-angle-down" style={{paddingRight: 8, fontSize: "1.4rem"}} />
  </div>
  {isExpanded && <div style={{padding: "0 30px 20px 20px"}}>
    {children}
  </div>}
</div>;

const EtlConfigEditor = React.memo(({
  etlConfig,
  entityFieldConfig,
  handleEtlConfigChange,
  entityNameToMetadataFields,
  isLocked,
  expandedSections,
  onSectionClick,
  hqCurrency,
  users,
  userBulkStatus,
  productTier,
  currencyCodes,
  metadataLoadedMillis,
  onRefreshMetadata,
  showJobsPipelineWarnings
}) => {
  const availableFields = fields.filter(field => field.get("product-tiers").includes(productTier) &&
      (field.get("ui-path").first() !== "Opportunity" || entityNameToMetadataFields.has("Opportunity")));
  const handleUpdatePlacement = newPlacementConfig => handleEtlConfigChange(etlConfig.set(
      "Placement",
      newPlacementConfig));
  const handleUpdateOpportunity = newOpportunityConfig => handleEtlConfigChange(etlConfig.set(
      "Opportunity",
      newOpportunityConfig));
  const handleUpdateJobOrder = newJobOrderConfig => handleEtlConfigChange(etlConfig.set("JobOrder", newJobOrderConfig));
  const handleUpdateJobSubmission = newJobSubmissionConfig => handleEtlConfigChange(etlConfig.set(
      "JobSubmission",
      newJobSubmissionConfig));
  const handleUpdateAppointment = newAppointmentConfig => handleEtlConfigChange(etlConfig.set(
      "Appointment",
      newAppointmentConfig));
  const handleUpdateNote = newNoteConfig => handleEtlConfigChange(etlConfig.set("Note", newNoteConfig));
  const handleUpdateCandidate = newCandidateConfig => handleEtlConfigChange(etlConfig.set(
      "Candidate",
      newCandidateConfig));
  const handleUpdateCorporateUser = newCorporateUserConfig => handleEtlConfigChange(etlConfig.set(
      "CorporateUser",
      newCorporateUserConfig));
  const handleUpdateLead = newLeadConfig => handleEtlConfigChange(etlConfig.set("Lead", newLeadConfig));
  const handleUpdateTask = newTaskConfig => handleEtlConfigChange(etlConfig.set("Task", newTaskConfig));
  const handleUpdateClientContact = newConfig => handleEtlConfigChange(etlConfig.set("ClientContact", newConfig));
  const handleUpdateClientCorporation = newConfig => handleEtlConfigChange(etlConfig.set(
      "ClientCorporation",
      newConfig));
  const handleUpdateProperties = newPropertiesConfig => handleEtlConfigChange(etlConfig.setIn(
      ["Properties"],
      newPropertiesConfig));

  const availableCvSentAdditionalDataColumns = getAvailableAdditionalDataFields(
      Immutable.List([
        ["JobSubmission", "cv-sent", "additional-data", "value"],
        ["Note", "cvSent", "additional-data", "value"]]),
      etlConfig,
      "CV_SENT",
      entityFieldConfig);

  return <Paper style={{padding: "10px 30px 20px 30px", marginBottom: 20}}>
    <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginBottom: "1rem",
          justifyContent: "space-between"
        }}>
      <h3>Client Mapping</h3>
      <TimestampedRefreshButton
          title={"Refresh fields and multi-select options"}
          millis={metadataLoadedMillis}
          onClick={onRefreshMetadata} />
    </div>
    {hasFields(availableFields, "Appointment") && <MappingSection
        name="Appointment"
        id="Appointment"
        mapsTo={Immutable.List(["cube19 Interviews", "cube19 Meetings"])}
        onClick={onSectionClick}
        isExpanded={expandedSections.includes("Appointment")}>
      <div style={{pointerEvents: isLocked && "none"}}>
        <AppointmentToInterviewsForm
            productTier={productTier}
            appointmentConfig={etlConfig.get("Appointment")}
            onUpdateAppointment={handleUpdateAppointment}
            appointmentMetadataField={entityNameToMetadataFields.get("Appointment", Immutable.List())} />
        <AppointmentToMeetings
            productTier={productTier}
            appointmentConfig={etlConfig.get("Appointment")}
            noteConfig={etlConfig.get("Note")}
            onUpdateAppointment={handleUpdateAppointment}
            appointmentMetadataField={entityNameToMetadataFields.get("Appointment", Immutable.List())}
        />
      </div>
    </MappingSection>}
    {hasFields(availableFields, "JobOrder") && <MappingSection
        name="JobOrder"
        id="JobOrder"
        mapsTo={Immutable.List(["cube19 Jobs"])}
        onClick={onSectionClick}
        isExpanded={expandedSections.includes("JobOrder")}>
      <div style={{pointerEvents: isLocked && "none"}}>
        <JobOrderForm
            productTier={productTier}
            onUpdateJobOrder={handleUpdateJobOrder}
            generateCurrencyDefaultText={entity => generateCurrencyDefaultText(entity, hqCurrency)}
            jobOrderMetadataField={entityNameToMetadataFields.get("JobOrder", Immutable.List())}
            jobOrderConfig={etlConfig.get("JobOrder")}
            currencyCodes={currencyCodes}
            availableFields={availableFields}
            showJobsPipelineWarnings={showJobsPipelineWarnings} />
      </div>
    </MappingSection>}
    {hasFields(availableFields, "JobSubmission") && <MappingSection
        name="JobSubmission"
        id="JobSubmission"
        mapsTo={Immutable.List(["cube19 CV Sent"])
            .push(hasFields(availableFields, "JobSubmission", "offer") && "cube19 Offers")}
        onClick={onSectionClick}
        isExpanded={expandedSections.includes("JobSubmission")}>
      <div style={{pointerEvents: isLocked && "none"}}>
        <JobSubmissionForm
            productTier={productTier}
            onUpdateJobSubmission={handleUpdateJobSubmission}
            jobSubmissionMetadataField={entityNameToMetadataFields.get("JobSubmission", Immutable.List())}
            jobSubmissionConfig={etlConfig.get("JobSubmission", Immutable.Map())}
            availableFields={availableFields}
            showJobsPipelineWarnings={showJobsPipelineWarnings}
            availableCvSentAdditionalDataColumns = {availableCvSentAdditionalDataColumns} />
      </div>
    </MappingSection>}
    {hasFields(availableFields, "Note") && <MappingSection
        name="Note"
        id="Note"
        mapsTo={Immutable.List(["cube19 Calls", "cube19 Spec CV Sent", "cube19 Meetings"])}
        onClick={onSectionClick}
        isExpanded={expandedSections.includes("Note")}>
      <div style={{pointerEvents: isLocked && "none"}}>
        <NoteToCallsForm
            productTier={productTier}
            noteConfig={etlConfig.get("Note")}
            onUpdateNote={handleUpdateNote}
            noteMetadataField={entityNameToMetadataFields.get("Note", Immutable.List())} />
        {hasFields(availableFields, "Note", "cvSent") && <NoteToCVSentForm
            productTier={productTier}
            noteConfig={etlConfig.get("Note")}
            onUpdateNote={handleUpdateNote}
            noteMetadataField={entityNameToMetadataFields.get("Note", Immutable.List())}
            availableAdditionalDataColumns = {availableCvSentAdditionalDataColumns} />}
        {hasFields(availableFields, "Note", "meeting") && <NoteToMeetings
            productTier={productTier}
            noteConfig={etlConfig.get("Note")}
            appointmentConfig={etlConfig.get("Appointment")}
            onUpdateNote={handleUpdateNote}
            noteMetadataField={entityNameToMetadataFields.get("Note", Immutable.List())}
        />}
      </div>
    </MappingSection>}
    {hasFields(availableFields, "Placement") && <MappingSection
        name="Placement"
        id="Placement"
        mapsTo={Immutable.List(["cube19 Placements"])}
        onClick={onSectionClick}
        isExpanded={expandedSections.includes("Placement")}>
      <div style={{pointerEvents: isLocked && "none"}}>
        <PlacementForm
            productTier={productTier}
            placementConfig={etlConfig.get("Placement")}
            generateCurrencyDefaultText={entity => generateCurrencyDefaultText(entity, hqCurrency)}
            onUpdatePlacement={handleUpdatePlacement}
            placementMetadataField={entityNameToMetadataFields.get("Placement", Immutable.List())}
            currencyCodes={currencyCodes}
            availableFields={availableFields} />
      </div>
    </MappingSection>}
    {hasFields(availableFields, "Opportunity") && <MappingSection
        name="Opportunity"
        id="Opportunity"
        mapsTo={Immutable.List(["cube19 Opportunities"])}
        onClick={onSectionClick}
        isExpanded={expandedSections.includes("Opportunity")}>
      <div style={{pointerEvents: isLocked && "none"}}>
        <OpportunityForm
            productTier={productTier}
            opportunityConfig={etlConfig.get("Opportunity")}
            generateCurrencyDefaultText={entity => generateCurrencyDefaultText(entity, hqCurrency)}
            onUpdateOpportunity={handleUpdateOpportunity}
            opportunityMetadataField={entityNameToMetadataFields.get("Opportunity", Immutable.List())}
            currencyCodes={currencyCodes} />
      </div>
    </MappingSection>}
    {hasFields(availableFields, "Candidate") && <MappingSection
        name="Candidate"
        id="Candidate"
        mapsTo={Immutable.List(["cube19 Candidate"])}
        onClick={onSectionClick}
        isExpanded={expandedSections.includes("Candidate")}
    >
      <div style={{pointerEvents: isLocked && "none"}}>
        <CandidateForm
            candidateConfig={etlConfig.get("Candidate")}
            onUpdateCandidate={handleUpdateCandidate}
            candidateMetadataField={entityNameToMetadataFields.get("Candidate", Immutable.List())}
            entityFieldConfig = {entityFieldConfig}/>
      </div>
    </MappingSection>}
    {hasFields(availableFields, "CorporateUser") && <MappingSection
        name="CorporateUser"
        id="CorporateUser"
        mapsTo={Immutable.List(["cube19 CorporateUser"])}
        onClick={onSectionClick}
        isExpanded={expandedSections.includes("CorporateUser")}
    >
      <div style={{pointerEvents: isLocked && "none"}}>
        <CorporateUserForm
            corporateUserConfig={etlConfig.get("CorporateUser")}
            onUpdateCorporateUser={handleUpdateCorporateUser}
            corporateUserMetadataField={entityNameToMetadataFields.get("CorporateUser", Immutable.List())} />
      </div>
    </MappingSection>}
    {hasFields(availableFields, "Lead") && <MappingSection
        name="Lead"
        id="Lead"
        mapsTo={Immutable.List(["cube19 Leads"])}
        onClick={onSectionClick}
        isExpanded={expandedSections.includes("Lead")}
    >
      <div style={{pointerEvents: isLocked && "none"}}>
        <LeadForm
            leadConfig={etlConfig.get("Lead")}
            onUpdateLead={handleUpdateLead}
            leadMetadataField={entityNameToMetadataFields.get("Lead", Immutable.List())} />
      </div>
    </MappingSection>}
    {hasFields(availableFields, "Task") && <MappingSection
        name="Task"
        id="Task"
        mapsTo={Immutable.List(["cube19 Calls"])}
        onClick={onSectionClick}
        isExpanded={expandedSections.includes("Task")}>
      <div style={{pointerEvents: isLocked && "none"}}>
        <TaskForm
            taskConfig={etlConfig.get("Task")}
            onUpdateTask={handleUpdateTask}
            taskMetadataField={entityNameToMetadataFields.get("Task", Immutable.List())} />
      </div>
    </MappingSection>}
    {hasFields(availableFields, "ClientContact") && <MappingSection
        name="Client Contact"
        id="ClientContact"
        mapsTo={Immutable.List(["cube19 Client Contact"])}
        onClick={onSectionClick}
        isExpanded={expandedSections.includes("ClientContact")}>
      <div style={{pointerEvents: isLocked && "none"}}>
        <AdditionalDataForm
            config={etlConfig.get("ClientContact")}
            onUpdate={handleUpdateClientContact}
            metadataField={entityNameToMetadataFields.get("ClientContact", Immutable.List())} />
      </div>
    </MappingSection>}
    {hasFields(availableFields, "ClientCorporation") && <MappingSection
        name="Client Corporation"
        id="ClientCorporation"
        mapsTo={Immutable.List(["cube19 Client Corporation"])}
        onClick={onSectionClick}
        isExpanded={expandedSections.includes("ClientCorporation")}>
      <div style={{pointerEvents: isLocked && "none"}}>
        <AdditionalDataForm
            config={etlConfig.get("ClientCorporation")}
            onUpdate={handleUpdateClientCorporation}
            metadataField={entityNameToMetadataFields.get("ClientCorporation", Immutable.List())} />
      </div>
    </MappingSection>}
    {hasFields(availableFields, "Properties") && <MappingSection
        name="General"
        id="general"
        mapsTo={null}
        onClick={onSectionClick}
        isExpanded={expandedSections.includes("general")}>
      <div style={{pointerEvents: isLocked && "none"}}>
        <GeneralForm
            users={users}
            userBulkStatus={userBulkStatus}
            config={etlConfig.get("Properties", Immutable.Map())}
            entityFieldConfig={entityFieldConfig}
            onUpdateProperties={handleUpdateProperties}
            availableFields={availableFields} />
      </div>
    </MappingSection>}
  </Paper>;
});

const generateCurrencyDefaultText = (entity, currency) => `Client doesn't have a ${entity} currency field and should default to the HQ Currency (${currency})`;

const mappingSectionStyle = {
  backgroundColor: "lightgray",
  display: "flex",
  padding: 15,
  marginBottom: 20,
  borderRadius: 5,
  justifyContent: "space-between",
  alignItems: "center"
};

const infoStyle = {
  backgroundColor: "white",
  padding: 5,
  marginLeft: 10,
  borderRadius: 5
};

export {
  EtlConfigEditor
};
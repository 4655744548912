import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import RaisedButton from "material-ui/RaisedButton";

import LoadingSpinner from "js/components/loading-spinner";
import DataTable from "js/components/data-table";
import EnvironmentConfigProperty from "js/app-areas/environment-config/config-property";
import { Err } from "js/components/notification";
import * as fetch from "js/fetch";

const keys = [ "uid", "category", "client-id", "property-key" ];

const columnNameByKey = {
    uid: "UID",
    category: "Category",
    "client-id": "Client ID",
    "property-key": "Key"
};

export default createReactClass({

    getInitialState() {
        return {
            isLoading: false,
            configProperties: Immutable.List(),
            selectedProperty: null,
            successMessage: null,
            errorMessage: null
        };
    },

    componentDidMount() {
        this.loadAndSetConfigurationProperties();
        this.intervalId = setInterval(() => {
            this.forceUpdate();
        }, 30 * 1000);
    },

    componentWillUnmount() {
        clearInterval(this.intervalId);
    },

    render() {
        const {
            isLoading,
            configProperties,
            selectedProperty,
            errorMessage
        } = this.state;
        const columns = keys.map(key => columnNameByKey[key]);
        const rows = configProperties
            .map(p => keys.map(key => p.get(key)))
            .toArray();
        return (
            <div style={{ padding: "0.5rem" }}>
                <div className="clearfix">
                    <p style={{ fontSize: "0.825rem", marginTop: 0, float: "left" }}>
                        <i className="fa fa-info-circle" style={{ fontSize: 16, paddingRight: 5 }} />
                        Click on the row to view/update an environment configuration property's value
                    </p>
                    <RaisedButton
                        label="Refresh"
                        onClick={() => this.loadAndSetConfigurationProperties()}
                        style={{ marginRight: "0.5rem", marginBottom: "0.5rem", float: "right" }} />
                </div>
                {isLoading ?
                    <LoadingSpinner /> :
                    <DataTable columns={columns} rows={rows} onCellClick={this.handleCellClick} />}
                {selectedProperty && <EnvironmentConfigProperty configProperty={selectedProperty} />}
                <Err message={errorMessage} onRequestClose={() => this.setState({ errorMessage: null })} />
            </div>
        );
    },

    handleCellClick(cellValue, row) {
        const selectedProperty = Immutable.fromJS({
            uid: row[keys.indexOf("uid")].originalValue,
            category: row[keys.indexOf("category")].originalValue,
            clientId: row[keys.indexOf("client-id")].originalValue,
            propertyKey: row[keys.indexOf("property-key")].originalValue
        });
        this.setState({ selectedProperty });
    },

    loadAndSetConfigurationProperties() {
        this.setState({ isLoading: true });
        loadConfigProperties()
            .then(configProperties => {
                this.setState({
                    isLoading: false,
                    configProperties
                });
            }, () => {
                this.setState({
                    isUpdating: false,
                    errorMessage: "Unable to load environment config properties, contact support@cube19.com \u{1F430}"
                });
            });
    }

});

const loadConfigProperties = () => fetch
    .getJson("config-properties")
    .then(result => Immutable.fromJS(result));

import Immutable from "immutable";

import createReducer from "js/create-reducer";


const emptyConfig = {entities: [], properties: {}};
const initialEntityRenamingPageState = Immutable.fromJS({
  api: {configs: {error: null, loaded: false, pending: false}},
  typeToConfig: {common: emptyConfig, client: emptyConfig, merged: emptyConfig}
});
export const apiLoadedSuccessfully = Immutable.fromJS({error: null, loaded: true, pending: false});

export const etlConfigsReducer = createReducer(initialEntityRenamingPageState, {
  MERGE_ETL_CONFIGS: (state, {newState}) => state.merge(newState),
  MERGE_IN_ETL_CONFIGS: (state, {path, defaultState, newState}) => state.updateIn(
      path,
      defaultState,
      s => s.merge(newState)),
  SET_IN_ETL_CONFIGS: (state, {path, newValue}) => state.setIn(path, newValue)
});

const makeSelector = fn => state => fn(state.get("etlConfigs"));
export const commonEtlConfigSelector = makeSelector(state => state.getIn(["typeToConfig", "common"]));
export const clientEtlConfigSelector = makeSelector(state => state.getIn(["typeToConfig", "client"]));
export const mergedEtlConfigSelector = makeSelector(state => state.getIn(["typeToConfig", "merged"]));
export const configsApiSelector = makeSelector(state => state.getIn(["api", "configs"]));

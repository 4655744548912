import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

const pure = render => createReactClass({
	mixins: [PureRenderMixin],

	render() {
		return render(this.props);
	}

});

export default pure;

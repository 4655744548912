import Immutable from "immutable";
import {v4 as uuidv4} from "uuid";
import {
  kpiConfigsPath,
  kpiDateEntityColumnIdPath,
  kpiExplanationPath,
  kpiFilterPath,
  kpiForwardReportPath,
  kpiIdPath,
  kpiNamePath,
  kpiOriginalExplanationPath,
  kpiOriginalNamePath,
  kpiSimpleSumMasterKpiTypesPath
} from "js/app-areas/onboarding/onboarding-clients-config";

const placementScalingFactorConverter = obj => {
  const value = obj.get("value");
  if (value) {
    return obj
        .update("value", x => x * 100)
        .set("isToggled", true);
  }
  return Immutable.Map();
};

const mappingConverter = obj => {
  const value = obj.get("value");
  if (!value) {
    return obj;
  }
  const convertedValue = value.entrySeq().flatMap(([to, froms]) => froms.map(from => Immutable.Map({from, to})));
  return obj.set("value", Immutable.List(convertedValue)).set("customOptions", Immutable.List(convertedValue));
};

const defaultCurrencyMappings = Immutable.fromJS([
      {from: "$", to: "USD"},
      {from: "£", to: "GBP"},
      {from: "€", to: "EUR"},
      {from: "EUR", to: "EUR"},
      {from: "Euro", to: "EUR"},
      {from: "EU €", to: "EUR"},
      {from: "GBP", to: "GBP"},
      {from: "USD", to: "USD"},
      {from: "US$", to: "USD"},
      {from: "AU$", to: "AUD"},
      {from: "AUD", to: "AUD"},
      {from: "NZ$", to: "NZD"},
      {from: "HK$", to: "HKD"},
      {from: "HKD", to: "HKD"},
      {from: "SG$", to: "SGD"},
      {from: "SGD", to: "SGD"},
      {from: "JPY", to: "JPY"},
      {from: "YEN", to: "JPY"},
      {from: "CAD", to: "CAD"},
      {from: "NOK", to: "NOK"},
      {from: "DKK", to: "DKK"},
      {from: "CNY", to: "CNY"}
    ]
);

const salaryUnitOptions = Immutable.List([
  "PER_HOUR",
  "PER_DAY",
  "PER_WEEK",
  "PER_MONTH"
]);

const defaultSalaryUnitMappings = Immutable.fromJS([
  {from: "Per Hour", to: "PER_HOUR"},
  {from: "Per Hour, Per Hour", to: "PER_HOUR"},
  {from: "Per Day", to: "PER_DAY"},
  {from: "Per Day, Per Day", to: "PER_DAY"},
  {from: "Per Week", to: "PER_WEEK"},
  {from: "Per Month", to: "PER_MONTH"}
]);

const getMappings = config => {
  return config.get("value", Immutable.List()).reduce(
      (result, item) => result.update(item.get("to"), Immutable.List(), list => list.push(item.get("from"))),
      Immutable.Map());
};

const getCommissionMappings = commissionConfig => commissionConfig.get("value", Immutable.List()).map(
    mapping => [mapping.get("user-id-field"), mapping.get("percent"), mapping.get("type")]);

const checkValueOrDisabled = (config, location) => {

  const value = config.getIn(location.push("value"));
  const noValue = !value || (Immutable.List.isList(value) && value.isEmpty());
  return noValue && !config.getIn(location.push("isDisabled"));
};

const checkValue = (config, location) => {
  const value = config.getIn(location.push("value"));
  return !value || (Immutable.List.isList(value) && value.isEmpty());
};

export const isValidCommissionPercentage = value => !!value
    && !isNaN(value)
    && value >= 0.1
    && value <= 1;

const checkCommissionMapping = (config, location) => {
  const mappings = config.getIn(location.push("value"), Immutable.List());
  return !mappings.every(mapping => mapping.get("user-id-field")
      && mapping.get("percent")
      && (mapping.get("is-field-reference") || isValidCommissionPercentage(mapping.get("percent")))
      && mapping.get("type"));
};

const commissionMappingConverter = obj => {
  const value = obj.get("value");
  if (!value) {
    return obj;
  }
  const convertedValue = value.map(tuple => Immutable.Map({
    "user-id-field": tuple[0],
    "percent": tuple[1],
    "type": tuple[2]
  }));
  return obj.set("value", convertedValue);
};

const checkAdditionalData = (config, location) => {
  const additionalDataMappings = config.getIn(location.push("value"), Immutable.List());
  return !additionalDataMappings.every(mapping => mapping.get("from") && mapping.get("to") && mapping.get("pii"));
};

const addZeroOption = options => {
  return options.includes("1") && !options.includes("0") ? options.unshift("0") : options;
};

const interviewCreatedDate = Immutable.fromJS({
  entityColumnId: 20,
  joinPath: [{tableEntity: "INTERVIEW"}],
  label: "Interview Created Date"
});
const interviewDate = Immutable.fromJS({
  entityColumnId: 18,
  joinPath: [{tableEntity: "INTERVIEW"}],
  label: "Interview Date"
});

const convertLegacyInterviewDate = (obj) => {
  if (obj.hasIn(["value", "entityColumnId"])) {
    return obj;
  } else {
    const legacyDate = obj.get("value");
    if (legacyDate === "creation-date") {
      return obj.set("value", interviewCreatedDate);
    } else {
      return obj.set("value", interviewDate);
    }
  }
}

const meetingCreatedDate = Immutable.fromJS({
  entityColumnId: 30,
  joinPath: [{tableEntity: "MEETING"}],
  label: "Meeting Created Date"
});
const meetingDate = Immutable.fromJS({
  entityColumnId: 31,
  joinPath: [{tableEntity: "MEETING"}],
  label: "Meeting Date"
});

const convertLegacyMeetingDate = (obj) => {
  if (obj.hasIn(["value", "entityColumnId"])) {
    return obj;
  } else {
    const legacyDate = obj.get("value");
    if (legacyDate === "creation-date") {
      return obj.set("value", meetingCreatedDate);
    } else {
      return obj.set("value", meetingDate);
    }
  }
}

const activityOwnersUiPath = ["Properties", "activity-owners"];
const interviewKpisDateUiPath = ["Properties", "metric-dates", "interview"];
const meetingKpisDateUiPath = ["Properties", "metric-dates", "meeting"];

const defaultOfferTypes = Immutable.List(["Offer Extended"]);

const activityOwnersDefault = "record";

const fields = Immutable.fromJS([
  {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Opportunity", "currency", "field"],
    "submission-paths": [["entities", "Opportunity", "currency", "field"]],
    "default": () => "customText1",
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Opportunity", "currency", "crm-currency-to-code"],
    "submission-paths": [["entities", "Opportunity", "currency", "code->crm-currencies"]],
    "ui-to-submission-converter": getMappings,
    "submission-to-ui-converter": mappingConverter,
    "default": () => defaultCurrencyMappings
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Opportunity", "additional-data"],
    "submission-paths": [["entities", "Opportunity", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Placement", "currency", "field"],
    "submission-paths": [["entities", "Placement", "currency", "field"]],
    "default": () => "correlatedCustomText1",
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Placement", "currency", "crm-currency-to-code"],
    "submission-paths": [["entities", "Placement", "currency", "code->crm-currencies"]],
    "ui-to-submission-converter": getMappings,
    "submission-to-ui-converter": mappingConverter,
    "default": () => defaultCurrencyMappings
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Placement", "employmentType", "perm"],
    "submission-paths": [["entities", "Placement", "perm-employment-types"]],
    "default": (getOptions, getTranslations) => {
      const placementEmploymentTypeOptions = getOptions("Placement", "employmentType");
      return getTranslations(["Placement", "employmentType"])
          .map(mapping => mapping.filter(type => placementEmploymentTypeOptions.includes(type)))
          .get("Permanent");
    },
    "check-errors": checkValueOrDisabled,
    "represents": "perm-placements"
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Placement", "employmentType", "contract"],
    "submission-paths": [["entities", "Placement", "contract-employment-types"]],
    "default": (getOptions, getTranslations) => {
      const placementEmploymentTypeOptions = getOptions("Placement", "employmentType");
      return getTranslations(["Placement", "employmentType"])
          .map(mapping => mapping.filter(type => placementEmploymentTypeOptions.includes(type)))
          .get("Contract");
    },
    "check-errors": checkValueOrDisabled,
    "represents": "contract-placements"
  },
  {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Placement", "cube-status->types", "AGREED"],
    "submission-paths": [["entities", "Placement", "cube-status->types", "AGREED"]],
    "default": (getOptions, getTranslations) => getTranslations(["Placement", "status"])
        .get("AGREED", Immutable.List())
        .concat(["Completed", "Terminated"]),
    "check-errors": checkValueOrDisabled,
    "represents": "placement-statuses"
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Placement", "cube-status->types", "DID_NOT_START"],
    "submission-paths": [["entities", "Placement", "cube-status->types", "DID_NOT_START"]],
    "default": (getOptions, getTranslations) => getTranslations(["Placement", "status"])
        .get("DID_NOT_START", Immutable.List()),
    "represents": "placement-statuses"
  },
  {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Placement", "placement-scaling-factor"],
    "submission-paths": [["properties", "placement-scaling-factor"]],
    "default": () => null,
    "ui-to-submission-converter": x => x.get("value") && x.get("value") / 100,
    "submission-to-ui-converter": placementScalingFactorConverter,
    "check-errors": (config, location) => {
      const value = config.getIn(location.push("value"));
      const toggled = config.getIn(location.push("isToggled"));
      return (toggled && (!value || isNaN(value) || value <= 0 || value > 100));
    }
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "created-date"],
    "submission-paths": [["entities", "Placement", "created-date"]],
    "default": () => Immutable.Map({field: "dateAdded"}),
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "start-date"],
    "submission-paths": [["entities", "Placement", "start-date"]],
    "default": () => Immutable.Map({field: "dateBegin"}),
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "end-date"],
    "submission-paths": [["entities", "Placement", "end-date"]],
    "default": () => Immutable.Map({field: "dateEnd"}),
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "invoice-date"],
    "submission-paths": [["entities", "Placement", "invoice-date"]],
    "default": () => Immutable.Map(),
    "check-errors": () => false
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "employmentType", "perm", "salary"],
    "submission-paths": [["entities", "Placement", "salary"]],
    "default": () => Immutable.Map({field: "salary"}),
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "employmentType", "perm", "fee-percentage"],
    "submission-paths": [["entities", "Placement", "fee-percentage"]],
    "default": () => Immutable.Map({field: "fee"}),
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Placement", "employmentType", "perm", "fees"],
    "submission-paths": [["entities", "Placement", "fees"]],
    "default": () => Immutable.Map(),
    "check-errors": () => false
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "employmentType", "contract", "hours-per-day"],
    "submission-paths": [["entities", "Placement", "hours-per-day"]],
    "default": () => Immutable.Map({field: "hoursPerDay"}),
    "check-errors": () => false
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "employmentType", "contract", "days-per-week"],
    "submission-paths": [["entities", "Placement", "days-per-week"]],
    "default": () => Immutable.Map({field: "daysGuaranteed"}),
    "check-errors": () => false
  },
  {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "employmentType", "contract", "salary-unit", "field"],
    "submission-paths": [["entities", "Placement", "salary-unit", "field"]],
    "default": () => Immutable.Map({field: "salaryUnit"}),
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "employmentType", "contract", "salary-unit", "mappings"],
    "submission-paths": [["entities", "Placement", "salary-unit", "mappings"]],
    "default": () => defaultSalaryUnitMappings,
    "ui-to-submission-converter": getMappings,
    "submission-to-ui-converter": mappingConverter,
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "employmentType", "contract", "pay-rate"],
    "submission-paths": [["entities", "Placement", "pay-rate"]],
    "default": () => Immutable.Map({field: "payRate"}),
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "employmentType", "contract", "client-bill-rate"],
    "submission-paths": [["entities", "Placement", "client-bill-rate"]],
    "default": () => Immutable.Map({field: "clientBillRate"}),
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "employmentType", "contract", "burden-rate"],
    "submission-paths": [["entities", "Placement", "burden-rate"]],
    "default": () => Immutable.Map(),
    "check-errors": () => false
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "employmentType", "contract", "reported-margin"],
    "submission-paths": [["entities", "Placement", "reported-margin"]],
    "default": () => Immutable.Map({field: "reportedMargin"}),
    "check-errors": () => false
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "copy-placement", "field"],
    "submission-paths": [["entities", "Placement", "copy-placement", "field"]],
    "default": () => null,
    "submission-to-ui-converter":  obj => obj.set("isToggled", !!obj.get("value")),
    "check-errors": (config, location) => {
      const value = config.getIn(location.push("value"));
      const toggled = config.getIn(location.push("isToggled"));
      return (toggled && !value);
    }
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "copy-placement", "previous-or-original"],
    "submission-paths": [["entities", "Placement", "copy-placement", "previous-or-original"]],
    "default": () => "original",
    "check-errors": () => false
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "alternative-commission-mapping"],
    "submission-paths": [["entities", "Placement", "alternative-commission-mapping"]],
    "default": () => Immutable.List(),
    "ui-to-submission-converter": getCommissionMappings,
    "submission-to-ui-converter": commissionMappingConverter,
    "check-errors": checkCommissionMapping
  },

  {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Placement", "additional-data"],
    "submission-paths": [["entities", "Placement", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Placement", "properties", "dateFieldToInfer"],
    "submission-paths": [["entities", "Placement", "properties", "dateFieldToInfer"]],
    "default": () => "START_DATE",
    "check-errors": () => false
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["JobOrder", "currency", "field"],
    "submission-paths": [["entities", "JobOrder", "currency", "field"]],
    "default": () => "correlatedCustomText1",
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["JobOrder", "currency", "crm-currency-to-code"],
    "submission-paths": [["entities", "JobOrder", "currency", "code->crm-currencies"]],
    "ui-to-submission-converter": getMappings,
    "submission-to-ui-converter": mappingConverter,
    "default": () => defaultCurrencyMappings
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["JobOrder", "priority", "field"],
    "submission-paths": [["entities", "JobOrder", "priority", "field"]],
    "default": () => "type",
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["JobOrder", "priority", "types"],
    "submission-paths": [["entities", "JobOrder", "priority", "types"]],
    "default": (getOptions) => addZeroOption(getOptions("JobOrder", "type")),
    "check-errors": checkValueOrDisabled,
    "represents": "job-priorities"
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["JobOrder", "employmentType", "perm"],
    "submission-paths": [["entities", "JobOrder", "perm-employment-types"]],
    "default": (getOptions) => {
      const options = getOptions("JobOrder", "employmentType");
      return Immutable.List(["Permanent", "Direct Hire", "Perm"]).filter(o => options.includes(o));
    },
    "check-errors": checkValueOrDisabled,
    "represents": "perm-jobs"
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["JobOrder", "employmentType", "contract"],
    "submission-paths": [["entities", "JobOrder", "contract-employment-types"]],
    "default": (getOptions) => {
      const options = getOptions("JobOrder", "employmentType");
      return Immutable.List(["Contract", "Temporary", "Temp", "Fixed Term"]).filter(o => options.includes(o));
    },
    "check-errors": checkValueOrDisabled,
    "represents": "contract-jobs"
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["JobOrder", "expected-value-fields", "salary-min-field"],
    "submission-paths": [["entities", "JobOrder", "salary-min-field"]],
    "default": () => "salary",
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["JobOrder", "expected-value-fields", "salary-max-field"],
    "submission-paths": [["entities", "JobOrder", "salary-max-field"]],
    "default": () => null,
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["JobOrder", "expected-value-fields", "salary-likely-field"],
    "submission-paths": [["entities", "JobOrder", "salary-likely-field"]],
    "default": () => null,
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["JobOrder", "expected-value-fields", "fee-percentage-field"],
    "submission-paths": [["entities", "JobOrder", "fee-percentage-field"]],
    "default": () => "feeArrangement",
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["JobOrder", "expected-value-fields", "value-field"],
    "submission-paths": [["entities", "JobOrder", "value-field"]],
    "default": () => "customFloat3",
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["JobOrder", "additional-data"],
    "submission-paths": [["entities", "JobOrder", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Appointment", "type", "field"],
    "submission-paths": [
      ["entities", "Appointment", "interview", "type", "field"],
      ["entities", "Appointment", "meeting", "type", "field"]],
    "default": () => "type",
    "check-errors": checkValue
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Appointment", "type", "firstTypes"],
    "submission-paths": [["entities", "Appointment", "interview", "type", "first-types"]],
    "default": (getOptions, getTranslations) => getTranslations(["AppointmentInterview", "type"]).get("1st Interview"),
    "check-errors": checkValueOrDisabled,
    "represents": "first-interviews"
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Appointment", "type", "furtherTypes"],
    "submission-paths": [["entities", "Appointment", "interview", "type", "further-types"]],
    "default": (getOptions, getTranslations) => getTranslations(["AppointmentInterview", "type"])
        .get("Further Interview"),
    "check-errors": checkValueOrDisabled,
    "represents": "further-interviews"
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Appointment", "additional-data"],
    "submission-paths": [["entities", "Appointment", "interview", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Appointment", "meeting", "action", "candidate-types"],
    "submission-paths": [["entities", "Appointment", "meeting", "type", "candidate-types"]],
    "default": (getOptions) => {
      const options = getOptions("Appointment", "type");
      return Immutable.List(["Candidate Meeting"]).filter(o => options.includes(o));
    },
    "check-errors": checkValueOrDisabled,
    "represents": "candidate-meetings"
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Appointment", "meeting", "action", "client-types"],
    "submission-paths": [["entities", "Appointment", "meeting", "type", "client-types"]],
    "default": (getOptions) => {
      const options = getOptions("Appointment", "type");
      return Immutable.List(["Client Visit", "Client Meeting"]).filter(o => options.includes(o));
    },
    "check-errors": checkValueOrDisabled,
    "represents": "client-meetings"
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Appointment", "meeting", "additional-data"],
    "submission-paths": [["entities", "Appointment", "meeting", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Note", "call", "action", "field"],
    "submission-paths": [
      ["entities", "Note", "call", "action", "field"],
      ["entities", "Note", "meeting", "action", "field"],
      ["entities", "Note", "cv-sent", "action", "field"]],
    "default": () => "action",
    "check-errors": checkValue
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Note", "call", "action", "types"],
    "submission-paths": [["entities", "Note", "call", "action", "types"]],
    "default": () => Immutable.List(),
    "check-errors": checkValue,
    "represents": "calls"
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Note", "call", "additional-data"],
    "submission-paths": [["entities", "Note", "call", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Note", "meeting", "action", "candidate-types"],
    "submission-paths": [["entities", "Note", "meeting", "action", "candidate-types"]],
    "default": (getOptions) => {
      const options = getOptions("Note", "action");
      return Immutable.List(["Candidate Meeting"]).filter(o => options.includes(o));
    },
    "check-errors": checkValueOrDisabled,
    "represents": "candidate-meetings"
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Note", "meeting", "action", "client-types"],
    "submission-paths": [["entities", "Note", "meeting", "action", "client-types"]],
    "default": (getOptions) => {
      const options = getOptions("Note", "action");
      return Immutable.List(["Client Visit", "Client Meeting"]).filter(o => options.includes(o));
    },
    "check-errors": checkValueOrDisabled,
    "represents": "client-meetings"
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Note", "meeting", "additional-data"],
    "submission-paths": [["entities", "Note", "meeting", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Note", "cvSent", "action", "types"],
    "submission-paths": [["entities", "Note", "cv-sent", "action", "spec-cv-sent-types"]],
    "default": () => Immutable.List(),
    "check-errors": checkValueOrDisabled,
    "represents": "spec-cvs"
  },
  {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Note", "cvSent", "additional-data"],
    "submission-paths": [["entities", "Note", "cv-sent", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  },
  {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["JobSubmission", "cv-sent", "type", "shortlist-types"],
    "submission-paths": [["entities", "JobSubmission", "cv-sent", "type", "shortlist-types"]],
    "default": (getOptions) => {
      const options = getOptions("JobSubmission", "status");
      return Immutable.List(["Shortlisted", "Submitted", "Internally Submitted"]).filter(o => options.includes(o));
    },
    "check-errors": checkValueOrDisabled,
    "represents": "shortlisted-candidates"
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["JobSubmission", "cv-sent", "type", "reject-types"],
    "submission-paths": [["entities", "JobSubmission", "cv-sent", "type", "reject-types"]],
    "default": (getOptions) => {
      const options = getOptions("JobSubmission", "status");
      return Immutable.List([
        "Candidate Not Interested",
        "Consultant Rejected",
        "Sales Rep Rejected",
        "Candidate Rejected",
        "Client Rejected",
        "Offer Rejected"]).filter(o => options.includes(o));
    },
    "check-errors": checkValueOrDisabled,
    "represents": "rejected-candidates"
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["JobSubmission", "cv-sent", "additional-data"],
    "submission-paths": [["entities", "JobSubmission", "cv-sent", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["JobSubmission", "offer", "types"],
    "submission-paths": [["entities", "JobSubmission", "offer", "types"]],
    "default": (getOptions) => {
      const options = getOptions("JobSubmission", "status");
      return defaultOfferTypes.filter(o => options.includes(o));
    },
    "check-errors": checkValueOrDisabled,
    "represents": "offers"
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["JobSubmission", "offer", "additional-data"],
    "submission-paths": [["entities", "JobSubmission", "offer", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Candidate", "additional-data"],
    "submission-paths": [["entities", "Candidate", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["CorporateUser", "additional-data"],
    "submission-paths": [["entities", "CorporateUser", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Lead", "additional-data"],
    "submission-paths": [["entities", "Lead", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Task", "call", "action", "field"],
    "submission-paths": [["entities", "Task", "call", "action", "field"]],
    "default": () => "type",
    "check-errors": checkValueOrDisabled
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Task", "call", "action", "types"],
    "submission-paths": [["entities", "Task", "call", "action", "types"]],
    "default": () => Immutable.List(),
    "check-errors": checkValueOrDisabled,
    "represents": "calls"
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["Task", "additional-data"],
    "submission-paths": [["entities", "Task", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["ClientCorporation", "additional-data"],
    "submission-paths": [["entities", "ClientCorporation", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["ENTERPRISE"],
    "ui-path": ["ClientContact", "additional-data"],
    "submission-paths": [["entities", "ClientContact", "additional-data"]],
    "default": () => Immutable.List(),
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map(),
    "check-errors": checkAdditionalData
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE"],
    "ui-path": activityOwnersUiPath,
    "submission-paths": [["properties", "activity-owners"]],
    "default": () => activityOwnersDefault,
    "ui-to-submission-converter": obj => obj.get("value") === "record-and-job"
        ? ["RECORD:OWNER", "JOB:OWNER"]
        : ["RECORD:OWNER"],
    "submission-to-ui-converter": obj => obj.update(
        "value",
        x => Immutable.is(Immutable.List(["RECORD:OWNER"]), x) ? "record" : "record-and-job")
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE", "ENTERPRISE"],
    "ui-path": ["Properties", "exclude-data-for-user-ids"],
    "submission-paths": [["properties", "exclude-data-for-user-ids"]],
    "default": () => null,
    "submission-to-ui-converter": obj => !!obj.get("value") ? obj : Immutable.Map()
  }, {
    "product-tiers": ["GROWTH_ADD_ON", "CORPORATE"],
    "ui-path": interviewKpisDateUiPath,
    "submission-paths": [["properties", "interview-dates"]],
    "default": () => interviewCreatedDate,
    "submission-to-ui-converter": convertLegacyInterviewDate
  }, {
    "product-tiers": ["GROWTH_ADD_ON", "CORPORATE"],
    "ui-path": meetingKpisDateUiPath,
    "submission-paths": [["properties", "meeting-dates"]],
    "default": () => meetingCreatedDate,
    "submission-to-ui-converter": convertLegacyMeetingDate
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE"],
    "ui-path": ["Properties", "default-user-template", "data-visibility"],
    "submission-paths": [["properties", "default-user-template", "data-visibility"]],
    "default": () => "SELF"
  }, {
    "product-tiers": ["GROWTH", "GROWTH_ADD_ON", "CORPORATE"],
    "ui-path": ["Properties", "default-user-template", "ip-restricted"],
    "submission-paths": [["properties", "default-user-template", "ip-restricted"]],
    "default": () => false
  }]);

const getInitialEtlConfig = (productTier, entityNameToMetadataFields, prevConfig, dropdownData) => {
  const entityNameToDefaultFieldMappings = dropdownData
      .getIn(["etl-common-config", "entities"])
      .reduce(
          (result, item) => result.set(
              item.get("name"),
              item
                  .get("fieldMappings")
                  .reduce((result, item) => result.set(item.get("name"), item))),
          Immutable.Map());
  const getTranslations = location => entityNameToDefaultFieldMappings
      .getIn(Immutable.List(location).push("translations"), Immutable.Map())
      .groupBy(mapping => mapping.get("toValue"))
      .map(mapping => mapping.map(item => item.get("fromValue")));
  const getOptions = (entity, field) => entityNameToMetadataFields.get(entity, Immutable.Map())
      .reduce((result, item) => result.set(item.get("name"), item), Immutable.Map())
      .getIn([field, "options"], Immutable.List())
      .map(option => option.get("value"));

  return Immutable.Map().withMutations(config => {
    fields.filter(field => field.get("product-tiers").includes(productTier))
        .forEach(field => {
          let result;
          const defaultGenerator = field.get("default");
          const defaultValue = defaultGenerator(getOptions, getTranslations);
          const path = field.get("submission-paths").first();
          if (prevConfig && prevConfig.hasIn(path)) {
            const converter = field.get("submission-to-ui-converter", x => x);
            const value = prevConfig.getIn(path);
            const isList = Immutable.List.isList(defaultValue);
            const isDisabled = !value || (isList && value.isEmpty());
            const customOptions = isList ? value : [value];
            result = converter(Immutable.fromJS({value, isDisabled, customOptions}));
          } else {
            result = Immutable.fromJS({...(!!defaultValue && {value: defaultValue})});
          }

          config.setIn(field.get("ui-path"), result);
        });
  });
};


const getEtlConfigForSubmission = (etlConfig, productTier) => {
  const config = Immutable.fromJS({properties: {}, entities: {}});
  return config.withMutations(config => {
    fields.filter(field => field.get("product-tiers").includes(productTier))
        .forEach(field => {
          const converter = field.get("ui-to-submission-converter", x => x.get("value"));
          const value = converter(etlConfig.getIn(field.get("ui-path")));
          field.get("submission-paths").forEach(path => {
            config.setIn(path, value);
          });
        });
  });
};

const toEntityToKpiConfigsState = kpiConfigs => kpiConfigs
    .map(kpiConfig => kpiConfig
        .set("originalName", kpiConfig.getIn(kpiNamePath))
        .set("originalExplanation", kpiConfig.getIn(kpiExplanationPath))
        .updateIn(kpiIdPath, id => id ?? uuidv4()))
    .sort((a, b) => a.getIn(kpiNamePath).localeCompare(b.getIn(kpiNamePath)))
    .groupBy(kpiConfig => kpiConfig.get("entity"))
    .map(kpiConfigs => Immutable.Map({
      isErroring: false,
      "kpiConfigs": kpiConfigs
    }));

const getEntityKpiConfigForSubmission = entityKpiConfig => entityKpiConfig
    .valueSeq()
    .flatMap(entityConfig => entityConfig
        .getIn(kpiConfigsPath)
        .map(kpiConfig => {
          let updatedKpiConfig = kpiConfig
              .delete("isErroring")
              .deleteIn(kpiOriginalExplanationPath)
              .deleteIn(kpiOriginalNamePath);
          if ((typeof kpiConfig.getIn(kpiIdPath)) === "string") {
            updatedKpiConfig = updatedKpiConfig.deleteIn(kpiIdPath);
          }
          return updatedKpiConfig;
        }))
    .toJS();

const findEtlConfigErrors = (etlConfig, productTier, entityNameToMetadataFields) => {
  const fieldsToCheck = fields.filter(field => field.get("product-tiers").includes(productTier) &&
      (field.get("ui-path").first() !== "Opportunity" || entityNameToMetadataFields.has("Opportunity")) &&
      field.has("check-errors"));
  let erroringSections = Immutable.Set();
  etlConfig = etlConfig.withMutations(mutableConfig => {
    fieldsToCheck.forEach(field => {
      const hasError = field.get("check-errors")(etlConfig, field.get("ui-path"));
      mutableConfig.setIn(field.get("ui-path").push("hasErrors"), hasError);
      if (hasError) {
        erroringSections = erroringSections.add(field.get("ui-path").first());
      }
    });
  });
  return [etlConfig, erroringSections];
};

const toTypeFilters = filterParam =>  Immutable.List([filterParam])
    .filter(x => x)
    .flatMap(filter => filter.get("type") === "GROUP" ? filter.get("children") : Immutable.List.of(filter))
    .filter(filter => validOperators.includes(filter.get("operator")));

const toFilterParam = typeFilters => Immutable.Map({
  type: "GROUP",
  combination: "AND",
  children: typeFilters
})

const areTypeFiltersErroring = typeFilters => typeFilters.some(typeFilter => {
  return !typeFilter.get("entityColumnId")
      || !typeFilter.get("operator")
      || typeFilter.get("value").isEmpty();
});

const validOperators = Immutable.List(["IN", "NOT_IN"]);

const findEntityKpiConfigErrors = entityToKpiConfigsState => {
  let isEntityKpiConfigErroring;

  const kpiConfigs = entityToKpiConfigsState
      .map(kpiConfigState => kpiConfigState.getIn(kpiConfigsPath))
      .valueSeq()
      .flatten(true);

  const duplicateNames = kpiConfigs
      .countBy(kpi => kpi.getIn(kpiNamePath))
      .filter(count => count > 1)
      .keySeq();

  entityToKpiConfigsState = entityToKpiConfigsState
      .map(config => {
        let isEntityErroring;
        const entityWithErrors = config.updateIn(kpiConfigsPath, kpis => kpis.map(kpi => {

          const typeFilters = toTypeFilters(kpi.getIn(kpiFilterPath));

          const isForwardReport = !!kpi.getIn(kpiForwardReportPath);
          const isSimpleSum = !!kpi.getIn(kpiSimpleSumMasterKpiTypesPath);
          const customDateRequired = !isForwardReport && !isSimpleSum;

          const kpiName = kpi.getIn(kpiNamePath);
          const nameIsInvalid = !kpiName || duplicateNames.includes(kpiName);
          const isErroring = nameIsInvalid
              || (customDateRequired && !kpi.getIn(kpiDateEntityColumnIdPath))
              || areTypeFiltersErroring(typeFilters);

          isEntityErroring = isEntityErroring || isErroring;
          return kpi.set("isErroring", isErroring);
        }));
        isEntityKpiConfigErroring = isEntityKpiConfigErroring || isEntityErroring;

        return entityWithErrors.set("isErroring", isEntityErroring);
      });

  return [entityToKpiConfigsState, isEntityKpiConfigErroring];
};

const findErrors = (etlConfig, entityToKpiConfigsState, productTier, entityNameToMetadataFields) => {

  const [etlConfigWithErrors, erroringSections] = findEtlConfigErrors(etlConfig, productTier, entityNameToMetadataFields);
  const [metricConfigWithErrors, isMetricConfigErroring] = findEntityKpiConfigErrors(entityToKpiConfigsState);

  return [etlConfigWithErrors, erroringSections, metricConfigWithErrors, isMetricConfigErroring];
};

const hasFields = (availableFields, ...path) => {
  const immutablePath = Immutable.List(path);
  return !!availableFields.find(field => field.get("ui-path").slice(0, immutablePath.size).equals(immutablePath));
};

const getAvailableAdditionalDataFields = (additionalDataEtlpaths, etlConfig, entityName, entityFieldConfig) => {
  const usedColumns = additionalDataEtlpaths
      .flatMap(path => etlConfig.getIn(path, Immutable.List()))
      .map(additionalData => additionalData.get("ge-column-data"))
      .toSet();
  return entityFieldConfig
      .getIn([entityName, "additionalDataFields"], Immutable.List())
      .filter(column => !usedColumns.includes(column));
}

export {
  getInitialEtlConfig,
  getEtlConfigForSubmission,
  toEntityToKpiConfigsState,
  getEntityKpiConfigForSubmission,
  findErrors,
  findEtlConfigErrors,
  findEntityKpiConfigErrors,
  toTypeFilters,
  toFilterParam,
  areTypeFiltersErroring,
  addZeroOption,
  defaultCurrencyMappings,
  defaultSalaryUnitMappings,
  salaryUnitOptions,
  fields,
  hasFields,
  getAvailableAdditionalDataFields,
  validOperators,
  activityOwnersUiPath,
  interviewKpisDateUiPath,
  meetingKpisDateUiPath,
  activityOwnersDefault,
  defaultOfferTypes
};
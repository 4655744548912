/** @jsxImportSource @emotion/react */

import React from "react";
import Immutable from "immutable";
import {css} from "@emotion/react";

import {apiUrl} from "js/base-urls";

import Drawer from "material-ui/Drawer";
import Chip from "material-ui/Chip";
import Avatar from "material-ui/Avatar";
import CheckedIcon from "material-ui/svg-icons/action/visibility";
import UncheckedIcon from "material-ui/svg-icons/action/visibility-off";
import PowerIcon from "material-ui/svg-icons/action/power-settings-new";
import {TimeoutModal} from "js/components/timeout-modal";

import * as localStore from "js/local-store";
import {indexBy} from "js/utils/collections";
import * as fetch from "./fetch";
import useDimensions from "js/utils/hooks/use-dimensions";

const pages = Immutable.fromJS([{
  permission: "APSCO",
  id: "APSCO",
  label: "Apsco"
}, {
  permission: "CLIENT_REPORTING",
  id: "CLIENT_OVERVIEW",
  label: "Client Overview"
}, {
  permission: "CLIENT_REPORTING",
  id: "CLIENT_DETAILS",
  label: "Client Details"
}, {
  permission: "CLIENT_REPORTING",
  id: "CLIENT_METRICS",
  label: "Client Metrics"
}, {
  permission: "CUSTOM_SQL_SUBMISSION",
  id: "CUSTOM_SQL",
  label: "SQL"
}, {
  permission: "CUSTOMER_SUPPORT",
  id: "CUSTOMER_SUPPORT",
  label: "Support"
}, {
  permission: "CRM_METADATA_SUBMISSION",
  id: "CRM_METADATA_SUBMISSION",
  label: "Crm Metadata"
}, {
  permission: "ETL_CONFIG_SUBMISSION",
  id: "ETL_CONFIG_SUBMISSION",
  label: "ETL Config"
}, {
  permission: "CRM_INTEGRATIONS",
  id: "CRM_INTEGRATIONS",
  label: "Crm Info"
}, {
  permission: "SWITCH_USER_SUBMISSION",
  id: "SWITCH_USER_SUBMISSION",
  label: "Login to Analytics"
}, {
  permission: "PROVISION_CLIENT",
  id: "PROVISION_CLIENT",
  label: "Provision Client"
}, {
  permission: "ENVIRONMENT_CONFIG",
  id: "ENVIRONMENT_CONFIG",
  label: "Env"
}, {
  permission: "DB_PERF",
  id: "DB_PERF",
  label: "DB Perf"
}, {
  permission: "CHARGEABLE_USERS",
  id: "CHARGEABLE_USERS",
  label: "Chargeable Users"
}, {
  permission: "BOXLAB",
  id: "BOXLAB",
  label: "BoxLab"
}, {
  permission: "FUNCTIONAL_TEST_VIEWER",
  id: "FUNCTIONAL_TEST_VIEWER",
  label: "Functional Tests"
}, {
  permission: "ENTITY_RENAME_SUBMISSION",
  id: "ENTITY_RENAMING",
  label: "Entity Renaming"
}, {
  permission: "ONBOARDING_TAB",
  id: "ONBOARDING",
  label: "Onboarding"
}, {
  permission: "USER_REPORT",
  id: "USER_REPORT",
  label: "Active User Report"
}]);

const height = 48;

const idToPage = indexBy(p => p.get("id"), pages.map((p, i) => p.set("order", i)));

const pinnedKey = "cube19.admin-console.pinned-page-ids";

const NavBar = React.memo(({currentUsername, permissions, currentPage, setCurrentPage}) => {
  const [open, setOpen] = React.useState(false);
  const [pinnedPageIds, setPinnedPageIds] = React.useState(() => {
    const savedPinnedPageIds = Immutable.Set(localStore.get(pinnedKey, "").split(",").filter(x => !!x));
    if (savedPinnedPageIds.isEmpty()) {
      return pages.map(p => p.get("id")).toSet();
    } else {
      return savedPinnedPageIds;
    }
  });

  let barColor;
  if (apiUrl === "https://admin-console-api.cube19.io" || apiUrl.indexOf("localhost") !== -1) {
    barColor = "#f3f3f5";
  } else {
    barColor = "#f37fc4";
  }

  const togglePinnedPageId = React.useCallback(pageId => {
    let newPinnedPageIds;
    if (pinnedPageIds.includes(pageId)) {
      newPinnedPageIds = pinnedPageIds.remove(pageId);
    } else {
      newPinnedPageIds = pinnedPageIds.add(pageId);
    }
    setPinnedPageIds(newPinnedPageIds);
  }, [pinnedPageIds]);

  React.useEffect(() => {
    localStore.set(pinnedKey, pinnedPageIds.join(","));
  }, [pinnedPageIds]);

  const [ref, {width: chipWidth}] = useDimensions();

  if (!currentUsername) {
    return null;
  }

  return (
      <>
        <div style={{backgroundColor: barColor, borderBottom: "1px solid #d5d5d5"}}>
          <div
              style={{
                position: "relative",
                display: "flex",
                overflowX: "auto",
                borderRight: "1px solid #222"
              }}
              css={css`width: calc(100% - ${chipWidth}px)`}>
            {pages
                .filter(page => permissions.includes(page.get("permission")))
                .filter(page => pinnedPageIds.includes(page.get("id")))
                .map(page => {
                  const isCurrentPage = currentPage === page.get("id");
                  return <PageLink
                      key={page.get("id")}
                      onClick={() => setCurrentPage(page.get("id"))}
                      active={isCurrentPage}
                      label={page.get("label")} />;
                })}
          </div>
          <div ref={ref} style={{position: "fixed", right: 0, top: 0, padding: "0.5rem"}}>
            <Chip onClick={() => setOpen(!open)}>
              <Avatar size={32}>{currentUsername.charAt(0).toUpperCase()}</Avatar>
              {currentUsername}
            </Chip>
          </div>
        </div>
        <TimeoutModal />
        <Drawer
            docked={false}
            openSecondary={true}
            width={250}
            open={open}
            onRequestChange={newOpen => setOpen(newOpen)}>
          {pages
              .filter(page => permissions.includes(page.get("permission")))
              .map(page => {
                const isCurrentPage = currentPage === page.get("id");
                const pinned = pinnedPageIds.includes(page.get("id"));
                const Icon = pinned ? CheckedIcon : UncheckedIcon;
                return (
                    <div
                        key={page.get("id")}
                        style={{
                          padding: "0.5rem",
                          display: "flex",
                          backgroundColor: (isCurrentPage ? "#ffe436" : null),
                          cursor: "pointer"
                        }}>
                      <Icon style={{marginRight: "0.5rem"}}
                            onClick={() => togglePinnedPageId(page.get("id"))} />
                      <div style={{lineHeight: "1.5rem", width: "100%"}} onClick={() => setCurrentPage(page.get("id"))}>
                        {page.get("label")}
                      </div>
                    </div>);
              })
              .toArray()}
          <div style={{padding: "0.5rem", display: "flex", cursor: "pointer"}} onClick={logout}>
            <PowerIcon style={{marginRight: "0.5rem"}} />
            <div style={{lineHeight: "1.5rem"}}>
              Logout
            </div>
          </div>
        </Drawer>
      </>);
});

const logout = () => fetch
    .post("logout", null, null, false)
    .then(() => window.location.reload());

const activeStyle = {
  color: "#000",
  backgroundColor: "#ffe436",
  borderBottom: "3px solid #222"
};

const PageLink = ({label, active, onClick}) => {
  const style = {
    height,
    cursor: "pointer",
    padding: "10px",
    color: "#000",
    whiteSpace: "nowrap",
    ...(active ? activeStyle : {})
  };
  return <div style={style} onClick={onClick}>{label}</div>;
};

export {
  pages,
  idToPage,
  NavBar
};

import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";

import { Success, Err } from "js/components/notification";
import * as fetch from "js/fetch";

export default createReactClass({

    getInitialState() {
        return {
            category: "",
            propertyKey: "",
            propertyValue: "",
            comment: "",
            isLoading: false,
            errorMessage: null,
            successMessage: null
        };
    },

    render() {
        const {
            category,
            propertyKey,
            propertyValue,
            comment,
            isLoading,
            successMessage,
            errorMessage
        } = this.state;
        const currentState = Immutable.fromJS(this.state);
        const initialState = Immutable.fromJS(this.getInitialState());
        const hasChanged = !currentState.equals(initialState);
        const isFormCompleted = hasChanged && !this.formHasEmptyFields();
        return (
            <div style={{ padding: "0.5rem" }}>
                <TextField
                    floatingLabelText="Category"
                    value={category}
                    onChange={e => this.setState({ category: e.target.value })} />
                <br />
                <TextField
                    floatingLabelText="Key"
                    style={{ marginRight: "2rem" }}
                    value={propertyKey}
                    onChange={e => this.setState({ propertyKey: e.target.value })} />
                <TextField
                    floatingLabelText="Value"
                    value={propertyValue}
                    onChange={e => this.setState({ propertyValue: e.target.value })} />
                <TextField
                    floatingLabelText="Comment (optional)"
                    fullWidth={true}
                    value={comment}
                    onChange={e => this.setState({ comment: e.target.value })} />
                <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
                    <RaisedButton
                        label="Clear"
                        disabled={!hasChanged || isLoading}
                        onClick={() => this.setState(this.getInitialState())}
                        style={{ margin: "0.5rem" }} />
                    <RaisedButton
                        label={<span>Set Value {isLoading && <i className="fa fa-spinner fa-pulse" />}</span>}
                        primary={true}
                        disabled={!isFormCompleted || isLoading}
                        onClick={this.handleSetValueBtnClick}
                        style={{ margin: "0.5rem" }} />
                </div>
                <Success message={successMessage} onRequestClose={() => this.setState({ successMessage: null })} />
                <Err message={errorMessage} onRequestClose={() => this.setState({ errorMessage: null })} />
            </div>
        );
    },

    handleSetValueBtnClick() {
        const { category, propertyKey, propertyValue, comment } = this.state;
        this.setState({
            isLoading: true
        });
        const params = {
            "client-id": 0,
            category,
            "property-key": propertyKey,
            "property-value": propertyValue,
            comment
        };
        addNonClientConfigProperties(params)
            .then(() => {
                this.setState({
                    ...this.getInitialState(),
                    successMessage: "Non-client configuration property value set"
                });
            }, error => {
                const defaultErrorMsg = "Unable to set non-client configuration property value";
                const errorStatus = error.response.status;
                if (errorStatus === 400) {
                    error.response.text()
                        .then(errorJsonStr => {
                            const errorJson = JSON.parse(errorJsonStr);
                            const errorMessage = errorJson.message || defaultErrorMsg;
                            this.setState({
                                isLoading: false,
                                errorMessage
                            });
                        });
                } else {
                    this.setState({
                        isLoading: false,
                        errorMessage: defaultErrorMsg
                    });
                }
            });
    },

    formHasEmptyFields() {
        const requiredFields = Immutable.Set.of("category", "propertyKey", "propertyValue");
        return requiredFields.some(k => {
            const value = this.state[k];
            return value === null || value.length === 0;
        });
    }

});

const addNonClientConfigProperties = params => fetch.post("config-properties", params);

import React from "react";
import * as Immutable from "immutable";

import Overlay from "js/components/overlay";

import * as Filtering from "js/app-areas/etl-config/filtering";
import * as EtlSchema from "js/app-areas/etl-config/attributes-and-properties";
import {getEntityLabel, getFullColumnLabel} from "js/app-areas/entity-renaming/page";

const isDefined = value => value !== "" && value !== undefined && value !== null;

const indexBy = (f, coll) => coll.groupBy(f).map(xs => xs.first());

const headerColor = "#dc322f";
const subHeaderColor = "#dc322f";
const labelColor = "#268bd2";
const valueColor = "#859900";

const headerHtml = text => <b style={{color: headerColor, fontSize: "1.2rem"}}>{text}</b>;
const headerUiLabelHtml = text => <span style={{fontSize: "1.2rem"}}>{text}</span>;
const subHeaderHtml = text => <span style={{color: subHeaderColor}}>{text}</span>;
const subHeaderUiLabelHtml = text => <span>{text}</span>;
const labelHtml = text => <span style={{color: labelColor}}>{text}</span>;
const valueHtml = text => <span style={{color: valueColor}}>{text}</span>;
const labelValueHtml = (label, value) => <React.Fragment>{labelHtml(label)} {valueHtml(value)}</React.Fragment>;

const labelForProp = prop => (prop.get("section") ? prop.get("section") + ": " : "") + prop.get("label");
const translationToText = translation => translation.get("fromValue") + " => " + translation.get("toValue");

const formatValueToHtml = (prop, value) => {
  const isSmallFlatArray = ((prop.get("type") === "enum" && prop.get("multi"))
    || (prop.get("type") === "json-data" && prop.get("jsonShape") === "flat-array"))
    && (value || Immutable.List()).count() <= 5;
  if (isSmallFlatArray) {
    return value.join(", ");
  } else if (prop.get("type") === "json-data") {
    const jsonStr = JSON.stringify(value, null, 2);
    return <pre style={{margin: 0, marginLeft: "1rem", marginBottom: "-1rem"}}>{jsonStr}</pre>;
  } else {
    return prop.get("type") === "boolean" ? value + "" : value;
  }
};

const expensiveValueEquality = (oldProps, newProps) => {
  if (oldProps === newProps) {
    return true;
  }

  const oldKeys = Object.keys(oldProps);
  const newKeys = Object.keys(newProps);

  if (oldKeys.length !== newKeys.length) {
    return false;
  }

  var newHasOwnProperty = Object.prototype.hasOwnProperty.bind(newProps);
  for (let i = 0; i < oldKeys.length; i++) {
    const oldKey = oldKeys[i];
    if (!newHasOwnProperty(oldKey)) {
      return false;
    }

    const oldValue = oldProps[oldKey];
    const newValue = newProps[oldKey];
    if (Immutable.isImmutable(oldValue) && Immutable.isImmutable(newValue)) {
      const equal = Immutable.is(oldValue, newValue);
      if (!equal) {
        return false;
      }
    } else {
      const equal = oldValue === newValue;
      if (!equal) {
        return false;
      }
    }
  }

  return true;
};

const HighlightedConfig = React.memo(({
  config,
  commonConfig,
  filterText = "",
  entityEtlAndNameMappings = Immutable.Map(),
  crmType,
  loading = false
}) => {
  const nameToCommonEntity = indexBy(e => e.get("name"), commonConfig.get("entities"));
  const shouldFilter = filterText.length >= 3;
  const words = Filtering.toWords(filterText, shouldFilter);
  const entityNameToMatch = Filtering.getEntityNameToMatch(config, nameToCommonEntity, words, shouldFilter);
  const filteredConfig = config.update("entities", es => {
    return es
      .filter(e => {
        const match = entityNameToMatch.get(e.get("name"));
        return match.get("entityMatches") || match.get("fieldsMatch");
      })
      .sortBy(e => {
        const match = entityNameToMatch.get(e.get("name"));
        return (match.get("entityMatches") ? "0" : "1") + "_" + e.get("name");
      });
  });
  return (
    <div style={{fontFamily: "monospace", overflow: "auto", position: "relative"}}>
      <Overlay show={loading} />
      <Props props={filteredConfig.get("properties")} crmType={crmType} />
      <Entities
          entities={filteredConfig.get("entities")}
          entityEtlAndNameMappings={entityEtlAndNameMappings}
          nameToCommonEntity={nameToCommonEntity}
          crmType={crmType} />
    </div>);
});

const Props = React.memo(({props, crmType}) => {
  const crmTopLevelProperties = EtlSchema.crmTypeToTopLevelProperties.get(crmType, Immutable.List());
  const topLevelProperties = EtlSchema.commonTopLevelProperties.concat(crmTopLevelProperties);
  const topLevelPropertiesWithValue = topLevelProperties.filter(prop => isDefined(props.get(prop.get("name"))));

  return (
    <React.Fragment>
      {topLevelPropertiesWithValue.map(prop => {
        const value = props.get(prop.get("name"));
        return (
          <React.Fragment key={prop.get("name")}>
            {labelValueHtml(labelForProp(prop), formatValueToHtml(prop, value))}
            <br />
          </React.Fragment>);
      })}
     {!topLevelPropertiesWithValue.isEmpty() && <br />}
    </React.Fragment>);
}, expensiveValueEquality);

const Entities = React.memo(({entities, entityEtlAndNameMappings, nameToCommonEntity, crmType}) => {
  return entities.map(e =>
      <Entity
          key={e.get("name")}
          entity={e}
          commonEntity={nameToCommonEntity.get(e.get("name"), Immutable.Map())}
          entityEtlAndNameMappings={entityEtlAndNameMappings} crmType={crmType} />);
});

const Entity = React.memo(({entity, commonEntity, entityEtlAndNameMappings, crmType}) => {
  const entityEtlAndNameMapping = entityEtlAndNameMappings.find(mapping => mapping.crmEntities.contains(
      entity.get("name")));
  const uiLabel = entityEtlAndNameMapping ? getEntityLabel(entityEtlAndNameMapping) : "";
  return (
    <React.Fragment>
      {headerHtml(entity.get("name"))}
      <EntityAttributes entity={entity} uiLabel={uiLabel} />
      <EntityFields
          entity={entity}
          commonEntity={commonEntity}
          entityEtlAndNameMapping={entityEtlAndNameMapping}
          entityEtlAndNameMappings={entityEtlAndNameMappings} />
      <EntityProps
        entityName={entity.get("name")}
        props={entity.get("properties") || Immutable.Map()}
        crmType={crmType} />
      <br/>
      <br/>
    </React.Fragment>);
});

const EntityAttributes = React.memo(({entity, uiLabel}) => {
  const attributesWithValue = EtlSchema.commonEntityAttributes
    .filter(attr => isDefined(entity.get(attr.get("name"))) && attr.get("name") !== "name");
  return (
    <div style={{marginLeft: "1rem"}}>
      {uiLabel && <>
        {headerUiLabelHtml(uiLabel)}
        <br />
      </>}
     {attributesWithValue.map(attr => {
        const value = entity.get(attr.get("name"));
        return (
          <React.Fragment key={attr.get("name")}>
            {labelValueHtml(attr.get("label"), formatValueToHtml(attr, value))}
            <br />
          </React.Fragment>);
     })}
     {!attributesWithValue.isEmpty() && <br />}
    </div>);
}, expensiveValueEquality);

const EntityFields = React.memo(({entity, commonEntity, entityEtlAndNameMapping, entityEtlAndNameMappings}) => {
  const nameToCommonField = indexBy(f => f.get("name"), commonEntity.get("fieldMappings", Immutable.List()));
  const mapToToCommonField = indexBy(f => f.get("mapTo"), commonEntity.get("fieldMappings", Immutable.List()));

  const cube19EntityToMapTo = entity.get("cube19EntityToMapTo", commonEntity.get("cube19EntityToMapTo"));
  const fields = entity.get("fieldMappings") || Immutable.List();
  return (
    <div style={{marginLeft: "1rem"}}>
      {fields.map(field =>
          <EntityField
              key={field.get("name")}
              field={field}
              cube19EntityToMapTo={cube19EntityToMapTo}
              nameToCommonField={nameToCommonField}
              mapToToCommonField={mapToToCommonField}
              entityEtlAndNameMapping={entityEtlAndNameMapping}
              entityEtlAndNameMappings={entityEtlAndNameMappings}
          />)}
    </div>);
});

const EntityField = React.memo(({field, cube19EntityToMapTo, nameToCommonField, mapToToCommonField, entityEtlAndNameMapping, entityEtlAndNameMappings}) => {
  const typeAttrs = EtlSchema.typeNameToType.getIn([field.get("type"), "props"], Immutable.List());

  const fieldAttrsWithValue = EtlSchema.fieldMappingAttributes
    .concat(typeAttrs)
    .filter(attr => isDefined(field.get(attr.get("name"))) && attr.get("name") !== "name");

  let uiLabel;
  if (!field.get("extractOnly")) {
    const commonField = nameToCommonField.get(
        field.get("name"),
        mapToToCommonField.get(field.get("mapTo"), Immutable.Map()));

    const mapTo = field.get("mapTo", commonField.get("mapTo"));
    const entityMappingWithField = entityEtlAndNameMappings
        .find(eMapping => eMapping.fieldMappingsById.some(x => x.stagingTable === cube19EntityToMapTo
            && x.stagingField === mapTo));
    const parentEntityMapping = entityEtlAndNameMapping !== entityMappingWithField
        ? entityEtlAndNameMapping
        : null;
    uiLabel = entityMappingWithField
        ? getFullColumnLabel(parentEntityMapping, entityMappingWithField, mapTo)
        : "";
  }

  return (
    <React.Fragment>
      {subHeaderHtml(field.get("name"))}
      <div style={{marginLeft: "1rem"}}>
        {uiLabel && <>
          {subHeaderUiLabelHtml(uiLabel)}
          <br />
        </>}
        {fieldAttrsWithValue.map(attr => {
          const value = field.get(attr.get("name"));
          if (attr.get("name") === "translations") {
            const translations = field.get(attr.get("name")) || Immutable.List();
            return (
              <React.Fragment key={attr.get("name")}>
                {labelHtml(attr.get("label"))}
                <br />
                <div style={{marginLeft: "1rem"}}>
                  {translations.map((translation, tIndex) => {
                    return (
                      <React.Fragment key={translation + "+" + tIndex}>
                        {valueHtml(translationToText(translation))}
                        <br />
                      </React.Fragment>)
                  })}
                </div>
              </React.Fragment>);
          } else {
            return (
              <React.Fragment key={attr.get("name")}>
                {labelValueHtml(attr.get("label"), formatValueToHtml(attr, value))}
                <br />
              </React.Fragment>);
          }
        })}
      </div>
    </React.Fragment>);
});

const EntityProps = React.memo(({entityName, props, crmType}) => {
  const specificEntityProps = EtlSchema.crmTypeAndEntityNameToProps.get(crmType + "_" + entityName, Immutable.List());
  const crmEntityProps = EtlSchema.crmTypeToEntityProps.get(crmType, Immutable.List());
  const entityProps = EtlSchema.commonEntityProps.concat(crmEntityProps).concat(specificEntityProps);

  const entityPropsWithValue = entityProps.filter(prop => isDefined(props.get(prop.get("name"))) && prop.get("name") !== "name");

  return (
    <div style={{marginLeft: "1rem"}}>
      {!entityPropsWithValue.isEmpty() && <br />}
      {entityPropsWithValue.map(prop => {
        const value = props.get(prop.get("name"));
        return (
          <React.Fragment key={prop.get("name")}>
            {labelValueHtml(prop.get("label"), formatValueToHtml(prop, value))}
            <br />
          </React.Fragment>);
      })}
    </div>);
}, expensiveValueEquality);












const indentSpaces = "   ";

const repeat = (str, n) => {
  let output = "";
  for (let i = 0; i < n; i++) {
    output += str;
  }
  return output;
};

const formatJson = (html, json, indent) => {
  const jsonStr = JSON.stringify(json, null, 2);
  if (html) {
    // NOTE negative margin hack to remove new line after pre tag (cos its hard to not add new line to everything)
    return <pre style={{margin: 0, marginLeft: indent + "rem", marginBottom: "-1rem"}}>{jsonStr}</pre>;
  } else {
    const indentStr = repeat(indentSpaces, indent);
    return indentStr + jsonStr.replace(/\n/g, "\n" + indentStr);
  }
};

const formatValue = (html, indent, prop, value) => {
  const isSmallFlatArray = ((prop.get("type") === "enum" && prop.get("multi"))
    || (prop.get("type") === "json-data" && prop.get("jsonShape") === "flat-array"))
    && (value || Immutable.List()).count() <= 5;
  if (html) {
    if (isSmallFlatArray) {
      return value.join(", ");
    } else if (prop.get("type") === "json-data") {
      return formatJson(html, value.toJS(), indent + 1);
    } else {
      return prop.get("type") === "boolean" ? value + "" : value;
    }
  } else {
    if (isSmallFlatArray) {
      return value.join(", ");
    } else if (prop.get("type") === "json-data") {
      return "\n" + formatJson(html, value.toJS(), indent + 1);
    } else {
      return value;
    }
  }
};

const line = (key, html, indent, text) => {
  if (html) {
    return <span key={key} style={{marginLeft: indent + "rem"}}>{text}<br/></span>;
  } else {
    return repeat(indentSpaces, indent) + text;
  }
};

const headerLine = (html, indent, text) => {
  if (html) {
    return line(text, html, indent, headerHtml(text));
  } else {
    return line(text, html, indent, text);
  }
};

const subHeaderLine = (key, html, indent, text) => {
  if (html) {
    return line(key, html, indent, subHeaderHtml(text));
  } else {
    return line(key, html, indent, text);
  }
};

const labelValueLine = (key, html, indent, label, value) => {
  if (html) {
    return line(key, html, indent, labelValueHtml(label, value));
  } else {
    return line(key, html, indent, label + ": " + value);
  }
};

const labelLine = (key, html, indent, label, value) => {
  if (html) {
    return line(key, html, indent, labelHtml(label));
  } else {
    return line(key, html, indent, label);
  }
};

const propLine = (key, html, indent, label, value, prop) => {
  return labelValueLine(key, html, indent, label, formatValue(html, indent, prop, value));
};

const blankLine = (html) => html ? <br key={Math.random()} /> : "";

// TODO remove all html stuff from this
const configToText = (config, crmType) => {
  const html = false;
  const crmTopLevelProperties = EtlSchema.crmTypeToTopLevelProperties.get(crmType, Immutable.List());
  const topLevelProperties = EtlSchema.commonTopLevelProperties.concat(crmTopLevelProperties);

  const topLevelPropertiesWithValue = topLevelProperties.filter(prop => isDefined(config.getIn(["properties", prop.get("name")])));

  let topLevelPropertyLines;
  if (topLevelPropertiesWithValue.isEmpty()) {
    topLevelPropertyLines = Immutable.List();
  } else {
    topLevelPropertyLines = topLevelPropertiesWithValue
      .map(prop => {
        const value = config.getIn(["properties", prop.get("name")]);
        const label = (prop.get("section") ? prop.get("section") + ": " : "") + prop.get("label");
        return propLine(prop.get("name"), html, 0, label, value, prop);
      })
      .push(blankLine(html));
  }

  const lines = Immutable.List()
    .concat(topLevelPropertyLines)
    .concat(config
      .get("entities")
      .flatMap(e => {
        const entityName = e.get("name");

        const specificEntityProps = EtlSchema.crmTypeAndEntityNameToProps.get(crmType + "_" + entityName, Immutable.List());
        const crmEntityProps = EtlSchema.crmTypeToEntityProps.get(crmType, Immutable.List());
        const entityProps = EtlSchema.commonEntityProps.concat(crmEntityProps).concat(specificEntityProps);

        const attributesWithValue = EtlSchema.commonEntityAttributes.filter(attr => isDefined(e.get(attr.get("name"))) && attr.get("name") !== "name");

        const entityPropsWithValue = entityProps.filter(prop => isDefined(e.getIn(["properties", prop.get("name")])) && prop.get("name") !== "name");
        let entityPropLines;
        if (entityPropsWithValue.isEmpty()) {
          entityPropLines = Immutable.List();
        } else {
          entityPropLines = entityPropsWithValue
            .map(prop => {
              const value = e.getIn(["properties", prop.get("name")]);
              return propLine(entityName + "prop" + prop.get("name"), html, 1, prop.get("label"), value, prop);
            })
            .push(blankLine(html));
        }

        let entityAttributeLines;
        if (attributesWithValue.isEmpty()) {
          entityAttributeLines = Immutable.List();
        } else {
          entityAttributeLines = attributesWithValue
            .map(attr => {
              const value = e.get(attr.get("name"));
              return propLine(entityName + "attr" + attr.get("name"), html, 1, attr.get("label"), value, attr);
            })
            .push(blankLine(html));
        }

        const entityFields = e.get("fieldMappings", Immutable.List());
        let entityFieldLines;
        if (entityFields.isEmpty()) {
          entityFieldLines = Immutable.List();
        } else {
          entityFieldLines = entityFields
            .flatMap(fieldMapping => {
              const fieldMappingTypeAttributes = EtlSchema.typeNameToType.getIn([fieldMapping.get("type"), "props"], Immutable.List());

              const fieldMappingAttributesWithValue = EtlSchema.fieldMappingAttributes
                .concat(fieldMappingTypeAttributes)
                .filter(attr => isDefined(fieldMapping.get(attr.get("name"))) && attr.get("name") !== "name");

              return Immutable.List
                .of(subHeaderLine(entityName + fieldMapping.get("name"), html, 1, fieldMapping.get("name")))
                .concat(fieldMappingAttributesWithValue.flatMap(attr => {
                  if (attr.get("name") === "translations") {
                    const translations = fieldMapping.get(attr.get("name"), Immutable.List());
                    return Immutable.List
                      .of(labelLine(entityName + fieldMapping.get("name") + attr.get("label"), html, 2, attr.get("label")))
                      .concat(translations
                        .sortBy(t => t.get("toValue") + "_" + t.get("fromValue"))
                        .map((translation, tIndex) => {
                          let valueDisplay;
                          if (html) {
                            valueDisplay = valueHtml(translation.get("fromValue") + " => " + translation.get("toValue"));
                          } else {
                            valueDisplay = translation.get("fromValue") + " => " + translation.get("toValue");
                          }
                          return line(entityName + fieldMapping.get("name") + translation.get("fromValue") + translation.get("toValue") + tIndex, html, 3, valueDisplay);
                        }));
                  } else {
                    const value = fieldMapping.get(attr.get("name"));
                    return Immutable.List.of(propLine(entityName + fieldMapping.get("name") + attr.get("name"), html, 2, attr.get("label"), value, attr));
                  }
                }));
              })
              .push(blankLine(html));
        }

        return Immutable.List()
          .push(headerLine(html, 0, entityName))
          .concat(entityAttributeLines)
          .concat(entityFieldLines)
          .concat(entityPropLines)
          .push(blankLine(html));
      }));

    if (html) {
      return lines.toArray();
    } else {
      return lines.join("\n");
    }
};


/*
// TODO continue plain text only implementation of renderer
const configToText = (config, crmType) => {
  const propLines = propsToLines(config.get("properties"), crmType);
  const entityLines = config.get("entities").flatMap(e => entityToLines(e, crmType));
  return propLines.concat(entityLines).join("\n");
};

const propsToLines = (props, crmType) => {
  return Immutable.List();
};

const entityToLines = (entity, crmType) => {
  const headerLine = entity.get("name");
  const entityAttributeLines = entityAttributesToLines(entity, crmType);
  const entityFieldLines = entityFieldsToLines(entity.get("fieldMappings"), crmType);
  const entityPropLines = entityPropsToLines(entity, crmType);
  return Immutable.List.of(headerLine).concat(entityAttributeLines, entityFieldLines, entityPropLines);
};

const entityAttributesToLines = (entity, crmType) => {
  return Immutable.List();
};

const entityFieldsToLines = (fields, crmType) => {
  return Immutable.List();
};

const entityPropsToLines = (props, crmType) => {
  return Immutable.List();
};*/


export {
  configToText,
  HighlightedConfig
};

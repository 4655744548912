import React from "react";
import * as Immutable from "immutable";

import {configToText} from "js/app-areas/etl-config/config-rendering";
import Drawer from "material-ui/Drawer";
import Paper from "material-ui/Paper";
import ReactDiffViewer, {DiffMethod} from "react-diff-viewer";
import RaisedButton from "material-ui/RaisedButton";
import {AccordionSubmissions} from "js/components/change-submission";

export default React.memo(({
  open,
  onRequestClose,
  onRevertClick,
  idToClient,
  idToSubmission,
  onChange,
  onError,
  permissions,
  currentUserId,
  loading
}) => {
  return (
    <Drawer
      docked={false}
      openSecondary={true}
      width="90%"
      open={open}
      onRequestChange={newOpen => newOpen || onRequestClose()}>
      {open && <AccordionSubmissions
        idToClient={idToClient}
        dispatchTypeToOutputClass={Immutable.Map({"etl-config": createRevertableSubmissionOutput(onRevertClick)})}
        idToSubmission={idToSubmission}
        permissions={permissions}
        currentUserId={currentUserId}
        loadingSubmissions={loading}
        onChange={onChange}
        onError={onError} />}
    </Drawer>);
});

const createRevertableSubmissionOutput = revertCallback => {
  return React.memo(({submission}) => {
    const payload = submission.get("payload");
    const configBefore = payload.get("config-at-submission");
    const configAfter = payload.get("new-config");
    const crmType = payload.get("crm-type", "bullhornrest");
    return (
        <Paper style={{padding: "1rem 1rem"}}>
            {(submission.get("output") && !submission.get("output").isEmpty()) && <span>
              <h3>Output</h3>
              <pre style={{whiteSpace: "pre-wrap", overflow: "auto"}}>{JSON.stringify(submission.get("output"), null, 2)}</pre>
            </span>}
            <ReactDiffViewer
              compareMethod={DiffMethod.WORDS}

              leftTitle="Before"
              oldValue={configToText(configBefore, crmType)}

              rightTitle="After"
              newValue={configToText(configAfter, crmType)}
              
              splitView={true} />
            <div style={{marginTop: "1rem"}}>
              <RaisedButton
                style={{marginRight: "1rem"}}
                label="Edit before"
                onClick={() => revertCallback(submission, true)} />
              <RaisedButton
                label="Edit after"
                onClick={() => revertCallback(submission, false)} />
            </div>
        </Paper>);
  });
};

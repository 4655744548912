import {useState, useCallback, useLayoutEffect} from "react";
import ResizeObserver from 'resize-observer-polyfill';

import throttle from "js/utils/throttle";

const useDimensions = ({throttleDelayInMillis = 50} = {}) => {
  const [dimensions, setDimensions] = useState({});
  const [node, setNode] = useState(null);

  const ref = useCallback(node => setNode(node), []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () => window.requestAnimationFrame(() => {
        const {width, height} = node.getBoundingClientRect();
        setDimensions({width, height});
      });
      let throttledMeasure;
      if (throttleDelayInMillis) {
        throttledMeasure = throttle(measure, throttleDelayInMillis);
      } else {
        throttledMeasure = measure;
      }
      throttledMeasure();

      const resizeObserver = new ResizeObserver(() => {
        throttledMeasure(node);
      });
      resizeObserver.observe(node);

      window.addEventListener("resize", throttledMeasure);
      return () => {
        window.removeEventListener("resize", throttledMeasure);
        resizeObserver.disconnect();
      };
    }
  }, [node, throttleDelayInMillis]);

  return [ref, dimensions, node];
};

export default useDimensions;

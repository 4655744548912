import React from "react";
import {Dialog} from "material-ui";
import moment from "moment-timezone";
import * as Immutable from "immutable";

const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday"];
const weekendDays = ["saturday", "sunday"];

const isWeekday = day => weekDays.includes(day);
const isWeekend = day => weekendDays.includes(day);

const getSchedule = bulkSchedule => {
  const timezone = bulkSchedule.get("timezone");
  const currentDate = moment().tz(timezone);

  const schedule = bulkSchedule.get("json-schedule")
      ? Immutable.fromJS(JSON.parse(bulkSchedule.get("json-schedule")))
      : Immutable.Map();
  const currentDay = days[currentDate.day()];
  const currentDaySchedule = schedule.get(currentDay)
      || (isWeekday(currentDay) && schedule.get("weekdays"))
      || (isWeekend(currentDay) && schedule.get("weekends"));

  if (currentDaySchedule) {
    const currentTime = currentDate.hours();
    const currentOutagePeriod = currentDaySchedule.find(schedule => schedule.get("start") < currentTime && schedule.get(
        "end") > currentTime);
    const endOfCurrentPeriod = currentOutagePeriod && currentOutagePeriod.get("end");
    const nextOutagePeriod = currentDaySchedule.find(schedule => schedule.get("start") > currentTime);
    const startOfNextOutagePeriod = nextOutagePeriod && nextOutagePeriod.get("start");
    const toUTC = (hour) => moment.tz(hour, "HH", timezone).utc().format("HH");

    if (endOfCurrentPeriod) {
      return `Bulk jobs are currently disabled.  Next available period begins at ${toUTC(endOfCurrentPeriod) + ":00 UTC."}`;
    } else if (startOfNextOutagePeriod) {
      return `Bulk jobs are currently available to be picked up.  Next disabled period begins at ${toUTC(startOfNextOutagePeriod)
      + ":00 UTC."}`;
    } else if (currentDaySchedule) {
      return `Bulk jobs are currently available to be picked up.`;
    }
  } else {
    return `No schedule set, bulk jobs will start at any time.`;
  }
};

const BulkScheduleInfo = ({bulkSchedule, loading}) => {
  const [isScheduleDialogOpen, setIsScheduleDialogOpen] = React.useState(false);

  if (loading) {
    return <div style={{fontSize: "0.8rem", marginTop: 5}}>
      <i className="fa fa-spinner fa-pulse" style={{ fontSize: 16, paddingRight: 5 }} />
    </div>
  } else if (bulkSchedule === null) {
    return <div style={{fontSize: "0.8rem", marginTop: 5}}>
      <i className="fa fa-warning" style={{ fontSize: 16, paddingRight: 5 }} />
      Failed to load client schedule.
    </div>
  }

  return <div style={{fontSize: "0.8rem", marginTop: 5}}>
    <i className="fa fa-info-circle" style={{fontSize: 16, paddingRight: 5}} />
    {getSchedule(bulkSchedule)}
    {bulkSchedule.get("json-schedule") && <div><span style={{marginLeft: 5, marginRight: 2}}>></span>
      <span
          style={{
            textDecoration: "underline",
            cursor: "pointer"
          }} onClick={() => setIsScheduleDialogOpen(true)}>See Client Schedule </span>
      <Dialog
          open={isScheduleDialogOpen}
          bodyStyle={{overflowY: "scroll"}}
          style={{zIndex: 9999}}
          onRequestClose={() => setIsScheduleDialogOpen(false)}>
        <pre>{"Root group timezone: " + bulkSchedule.get("timezone") + "\n\n" +
        JSON.stringify(JSON.parse(bulkSchedule.get("json-schedule")), null, 2)}</pre>
      </Dialog>
    </div>}
  </div>;
};

export default BulkScheduleInfo;
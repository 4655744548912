import React from "react";

const Error = ({ type = "error", message = "", customStyle = {} }) => (
    <span style={{ ...style.common, ...style[type], ...customStyle }}>
        <i className="fa fa-exclamation-triangle" style={{ display:"table-cell", paddingRight:"8px" }} />
        <span style={{ display:"table-cell" }}>{message}</span>
    </span>
);

const style = {
    common: {
        display: "table",
        margin: "0 auto",
        padding: "5px 10px",
        borderWidth: "1px",
        borderStyle: "solid",
        whiteSpace: "pre-line"
    },

    error: {
        color: "#bd0000",
        borderColor: "#bd0000",
        backgroundColor: "#ffeded"
    },

    warning: {
        color: "#bd0000",
        borderColor: "#ff890c",
        backgroundColor: "#fbf7a1"
    }
};

export default Error;

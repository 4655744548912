import {combineReducers} from "redux-immutable";

import clientOverviewData from "js/app-areas/client-overview/pivot-data-reducer";
import clientOverviewPivotTableConfig from "js/app-areas/client-overview/pivot-table-config-reducer";
import clientDetailsData from "js/app-areas/client-details/pivot-data-reducer";
import clientDetailsPivotTableConfig from "js/app-areas/client-details/pivot-table-config-reducer";
import clientMetricsData from "js/app-areas/client-metrics/pivot-data-reducer";
import clientMetricsPivotTableConfig from "js/app-areas/client-metrics/pivot-table-config-reducer";
import modal from "js/modal/reducer";

import {reducer as functionalTestsReducer} from "js/app-areas/functional-test/data";
import {reducer as sharedReducer} from "js/data/shared";
import {c19EntityFieldMappingsReducer} from "js/data/entity-field-mappings/reducer";
import {entityRenamingPageReducer} from "js/app-areas/entity-renaming/page-reducer";
import {etlConfigsReducer} from "js/data/etl-configs/reducer";

const createRootReducer = () => combineReducers({
  sharedData: sharedReducer,
  clientOverviewPivotTableConfig,
  clientOverviewData,
  clientDetailsPivotTableConfig,
  clientDetailsData,
  clientMetricsPivotTableConfig,
  clientMetricsData,
  modal,
  functionalTests: functionalTestsReducer,

  // API data
  c19EntityFieldMappings: c19EntityFieldMappingsReducer,
  etlConfigs: etlConfigsReducer,

  entityRenamingPage: entityRenamingPageReducer
});

export default createRootReducer;

import React from "react";
import Snackbar from "material-ui/Snackbar";

const Notification = ({open, message, onRequestClose, ...props}) => {
    const hasOpenProp = open !== undefined;
    return <Snackbar
    	open={hasOpenProp ? open : !!message}
    	message={message || ""}
    	onRequestClose={onRequestClose}
    	autoHideDuration={5000}
    	{...props} />;
};

const Success = Notification;

const Err = Notification;

export {
	Notification,
	Success,
	Err
};

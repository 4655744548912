import React from "react";
import pure from "js/pure";

export default pure(() => {
  return <div style={containerStyle}>
    <i className="fa fa-spinner fa-pulse fa-4x" style={iconStyle} />
  </div>;
});

const containerStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0 ,0, 0, 0.6)"
};

const iconStyle = {
  color: "#FFF"
};

import React from "react";
import * as Immutable from "immutable";

import ImmutableSelect from "js/components/immutable-select";
import LoadingSpinner from "js/components/loading-spinner";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import ValueEditor from "js/app-areas/etl-config/value-editor";

export default React.memo(({
  translations,
  onChange,
  attr,
  metadataForField,
  loadingMetadata
}) => {
  const [useJsonEditor, setUseJsonEditor] = React.useState(false);

  const optionsFromMetadata = (metadataForField || Immutable.Map()).get("options", Immutable.List());

  const name = attr.get("name");
  const onChangeTranslations = React.useCallback(
    newTranslations => onChange(name, newTranslations),
    [name, onChange]);

  const onChangeTranslation = React.useCallback((fromValue, toValue, transIndex) => {
      let newTranslations;
      if (fromValue || toValue) {
        const newTranslation = Immutable.Map({fromValue, toValue});
        newTranslations = translations.set(transIndex, newTranslation);
      } else {
        newTranslations = translations.delete(transIndex);
      }
      onChangeTranslations(newTranslations);
    },
    [translations, onChangeTranslations]);

  if (loadingMetadata) {
    return <LoadingSpinner />;
  } else if (!useJsonEditor) {

    const existingFromValues = translations.map(t => t.get("fromValue")).toSet();
    const allMetadataValues = optionsFromMetadata.map(o => o.get("value")).toSet();
    return (
      <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
        {translations.isEmpty() && <br/>}
        {translations.map((translation, transIndex) => {
          const fromValue = translation.get("fromValue");
          const toValue = translation.get("toValue");

          let editorForFromValue;
          if ((allMetadataValues.includes(fromValue) || !fromValue) && !optionsFromMetadata.isEmpty()) {
            editorForFromValue = (
              <div
                title="From value"
                style={{
                  width: 300,
                  marginRight: "1rem"
                }}>
                <span style={{color: "#bbb", fontSize: "12px", fontFamily: "Roboto,sans-serif"}}>From value</span>
                <div style={{marginTop: 3}}>
                  <ImmutableSelect
                    placeholder="From value"
                    options={optionsFromMetadata.filter(o => o.get("value") === fromValue || !existingFromValues.includes(o.get("value")))}
                    multi={false}
                    searchable={true}
                    clearable={true}
                    selectedValue={fromValue}
                    onChange={newFromValue => onChangeTranslation(newFromValue, toValue, transIndex)} />
                </div>
              </div>);
          } else {
            editorForFromValue = <TextField
              style={{marginRight: "1rem"}}
              title="This value doesn't exist in bullhorn's metadata"
              value={fromValue}
              onChange={e => onChangeTranslation(e.target.value, toValue, transIndex)}
              floatingLabelText="From value" />;
          }

          return (
            <div key={transIndex} style={{marginBottom: "0.5rem", width: "100%", display: "flex", alignItems: "center"}}>
              <i onClick={() => onChangeTranslation("", "", transIndex)}
                style={{cursor: "pointer", color: "red", marginTop: "1.1rem", marginRight: "0.5rem"}}
                className="fa fa-times" />
              {editorForFromValue}
              <TextField
                style={{marginRight: "1rem"}}
                value={toValue}
                onChange={e => onChangeTranslation(fromValue, e.target.value, transIndex)}
                floatingLabelText="To value" />
            </div>);
        })}
        <div>
          <RaisedButton
            style={{minWidth: 45, marginRight: "1rem"}}
            label={<span><i className="fa fa-plus" /></span>}
            onClick={() => onChangeTranslations(translations.push(Immutable.Map({fromValue: "", toValue: ""})))} />
          <RaisedButton
            style={{minWidth: 45}}
            title="Edit the raw JSON"
            label={<span><i className="fa fa-pencil" /> Raw JSON</span>}
            onClick={() => setUseJsonEditor(true)} />
        </div>
      </div>);
  } else {
    return (
      <div style={{width: "100%", marginRight: "1rem"}}>
        <ValueEditor
          prop={attr}
          value={translations}
          onChange={onChangeTranslations} />
        <RaisedButton
          style={{minWidth: 45}}
          title="Use the editing UI"
          label={<span><i className="fa fa-pencil" /> With UI</span>}
          onClick={() => setUseJsonEditor(false)} />
      </div>);
  }
});

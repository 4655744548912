/** @jsxImportSource @emotion/react */

import reactCreateClass from "create-react-class";
import * as Immutable from "immutable";
import moment from "moment";
import RaisedButton from "material-ui/RaisedButton";
import Draggable from "react-draggable";
import TextField from "material-ui/TextField";
import Checkbox from "material-ui/Checkbox";

import {AccordionContainer, AccordionSection} from "js/components/accordion";
import {AccordionSubmissions} from "js/components/change-submission";
import LoadingSpinner from "js/components/loading-spinner";
import {Err} from "js/components/notification";

import BullhornCredentialsForm from "js/app-areas/customer-support/bullhorn-credentials-form";
import Etl2DeltaJobForm from "js/app-areas/customer-support/etl2-delta-form";
import BulkForm from "js/app-areas/customer-support/batch-bulk-form";
import DeleteAssetsForm from "js/app-areas/customer-support/delete-assets-form";
import CsProcedures, {loadUndefinedMasterMetrics} from "js/app-areas/customer-support/cs-procs";

import ClientPicker from "js/components/client-picker";
import * as fetch from "js/fetch";
import * as localStore from "js/local-store";
import React from "react";
import {ClientsContext, CrmsContext, CurrentUserContext, SelectedClientIdContext} from "js/data/contexts";
import * as Rata from "js/data/remote-data";

const splitPreferenceKey = "admin-console.cs-procs.split-preference";
const barrierXPreferenceKey = "admin-console.cs-procs.barrier-x";
const barrierYPreferenceKey = "admin-console.cs-procs.barrier-y";

const barrierThickness = 6;
const barrierHalfThickness = barrierThickness / 2;

const barrierZIndex = 8000;

const CSClient = reactCreateClass({

  getInitialState() {
    const contentRoot = document.getElementById("content");
    const contentWidth = contentRoot.clientWidth;
    const contentHeight = contentRoot.clientHeight;

    return {
      splitSideToSide: localStore.get(splitPreferenceKey, true),
      filterText: "",
      showDeadClients: false,

      loadingCsUiTopermission: false,
      csUiToPermissions: Immutable.Map(),

      loadingProcs: false,
      procs: Immutable.Map(),

      existingBulkJobs: Immutable.List(),

      loadingDropdownData: false,
      dropdownNameToData: Immutable.Map(),
      dropdownNameToAliases: Immutable.Map(),

      loadingSubmissions: false,
      idToSubmission: Immutable.Map(),

      clientUndefinedMasterMetrics: Immutable.List(),
      barrierX: localStore.get(barrierXPreferenceKey, contentWidth / 2),
      barrierY: localStore.get(barrierYPreferenceKey, contentHeight / 2)
    };
  },

  componentDidMount() {
    this.reloadAllData(this.props.selectedClientId);
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedClientId !== nextProps.selectedClientId) {
      loadUndefinedMasterMetrics(nextProps.selectedClientId).then(
          clientUndefinedMasterMetrics => {
            this.setState({
              clientUndefinedMasterMetrics: clientUndefinedMasterMetrics
            });
          },
          error => {
            if (error.response && error.response.json) {
              error.response.json().then(body => {
                console.error(body);
                this.setState({
                  clientUndefinedMasterMetrics: Immutable.List(),
                  errorMessage: body.message
                });
              });
            } else {
              this.setState({
                clientUndefinedMasterMetrics: Immutable.List(),
                errorMessage: "An unexpected error occurred while loading undefined master metrics"
              });
            }
          });
      this.reloadAllData(nextProps.selectedClientId);
    }
  },

  render() {
    const {
      idToClient,
      clientsLoading,
      selectedClientId,
      setClientId
    } = this.props;
    const {
      showDeadClients,
      splitSideToSide,
      barrierX,
      barrierY,
      filterText,
      clientUndefinedMasterMetrics,
      loadingProcs,
      loadingSubmissions,
      loadingDropdownData,
      loadingCsUiToPermission,
      loadingBulkJobs
    } = this.state;

    const contentRoot = document.getElementById("content");
    const contentWidth = contentRoot.clientWidth;
    const contentHeight = contentRoot.clientHeight;

    const leftStyle = {
      position: "absolute",
      top: 0,
      left: 0,
      width: barrierX - barrierHalfThickness,
      height: contentHeight,
      overflow: "auto",
      padding: "0.2rem 1rem 1rem 1rem"
    };

    const rightStyle = {
      position: "absolute",
      top: 0,
      left: barrierX + barrierHalfThickness,
      width: contentWidth - barrierX - barrierHalfThickness,
      height: contentHeight,
      overflow: "auto",
      padding: "0rem 1rem 1rem 1rem"
    };

    const topStyle = {
      position: "absolute",
      top: 0,
      left: 0,
      height: barrierY + barrierHalfThickness,
      width: "100%",
      overflow: "auto",
      padding: "0.2rem 1rem 1rem 1rem"
    };

    const bottomStyle = {
      position: "absolute",
      top: barrierY + barrierHalfThickness,
      left: 0,
      height: contentHeight - barrierY - barrierHalfThickness,
      width: "100%",
      overflow: "auto"
    };

    const sideToSideBarrierStyle = {
      position: "absolute",
      cursor: "move",
      zIndex: barrierZIndex,
      height: contentHeight,
      width: barrierThickness,
      backgroundColor: "black"
    };

    const topToBottomBarrierStyle = {
      position: "absolute",
      cursor: "move",
      zIndex: barrierZIndex,
      height: barrierThickness,
      width: contentWidth,
      backgroundColor: "black"
    };

    const sideToSidePosition = {x: barrierX, y: 0};
    const topToBottomPosition = {x: 0, y: barrierY};
    const unknownVerticalAdjustment = 45;

    const isLoadingData = loadingProcs
        || loadingSubmissions
        || loadingDropdownData
        || loadingCsUiToPermission
        || loadingBulkJobs;

    return (
        <div style={{position: "relative"}}>
          <div style={splitSideToSide ? leftStyle : topStyle}>
            <div style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              marginTop: "0.5rem",
              marginBottom: "1rem"
            }}>
              <RaisedButton
                  disabled={isLoadingData}
                  label={<i className="fa fa-refresh" />}
                  style={{minWidth: 45, marginRight: "0.5rem"}}
                  onClick={() => this.reloadAllData(this.props.selectedClientId)} />
              <RaisedButton
                  label={<i className={splitSideToSide ? "fa fa-minus-square-o" : "fa fa-columns"} />}
                  style={{minWidth: 45, marginRight: "0.5rem"}}
                  onClick={() => {
                    localStore.set(splitPreferenceKey, !splitSideToSide);
                    this.setState({splitSideToSide: !splitSideToSide});
                  }} />
              <ClientPicker
                  css={{width: 320, display: "inline-block", marginRight: "0.5rem"}}
                  loading={clientsLoading}
                  value={selectedClientId}
                  onChange={setClientId}
                  clients={idToClient.valueSeq()}
                  showDead={showDeadClients} />
              <Checkbox
                  style={{width: 190, display: "inline-block"}}
                  label="Show dead clients"
                  checked={showDeadClients}
                  onCheck={(_, isChecked) => this.setState({showDeadClients: isChecked})} />
              <TextField
                  hintText="Search sections/procedures"
                  value={filterText}
                  onChange={e => this.setState({filterText: e.target.value})} />
            </div>

            {clientUndefinedMasterMetrics.size > 0 &&
            renderUndefinedMasterMetricsWarning(clientUndefinedMasterMetrics.size)}
            {selectedClientId && this.renderSections()}
            {selectedClientId &&
            <CsProcedures
                key={"cs-procs" + selectedClientId}
                cube19ClientId={selectedClientId}
                filterText={filterText}
                procs={this.state.procs}
                dropdownNameToData={this.state.dropdownNameToData}
                dropdownNameToAliases={this.state.dropdownNameToAliases}
                loading={this.state.loadingProcs || this.state.loadingDropdownData}
                onRequestReload={() => this.reloadAllData(selectedClientId)} />}
          </div>

          <Draggable
              axis={splitSideToSide ? "x" : "y"}
              position={splitSideToSide ? sideToSidePosition : topToBottomPosition}
              onDrag={e => {
                if (splitSideToSide) {
                  this.setState({barrierX: e.x});
                } else {
                  this.setState({barrierY: e.y - unknownVerticalAdjustment});
                }
              }}
              onStop={() => {
                localStore.set(barrierXPreferenceKey, barrierX);
                localStore.set(barrierYPreferenceKey, barrierY);
              }}>
            <div style={splitSideToSide ? sideToSideBarrierStyle : topToBottomBarrierStyle} />
          </Draggable>

          <div style={splitSideToSide ? rightStyle : bottomStyle}>
            {selectedClientId && this.renderRecentSubmissions()}
          </div>

          <Err message={this.state.errorMessage} onRequestClose={() => this.setState({errorMessage: null})} />
        </div>);
  },

  renderSections() {
    const {
      idToClient,
      idToCrm,
      selectedClientId,
      userPermissions
    } = this.props;
    const {filterText, csUiToPermissions, loadingCsUiTopermission} = this.state;
    if (loadingCsUiTopermission || csUiToPermissions.isEmpty()) {
      return <LoadingSpinner />;
    }

    const sectionEls = getSectionsForClient(
        selectedClientId,
        idToClient,
        idToCrm,
        userPermissions,
        csUiToPermissions,
        () => this.reloadAllData(this.props.selectedClientId),
        this.loadAndSetExistingBulkJobs,
        this.state.existingBulkJobs)
        .filter(section => filterText === ""
            || section.title.toLowerCase().indexOf(filterText.toLowerCase()) !== -1)
        .map((section, index) => {
          const titleStyle = section.titleColor ? {backgroundColor: section.titleColor} : {};
          return (
              <AccordionSection key={index} title={section.title} titleStyle={titleStyle}>
                {section.content}
              </AccordionSection>
          );
        });
    return (
        <AccordionContainer key={"ui-sections" + selectedClientId} stateless={true}>
          {sectionEls}
        </AccordionContainer>
    );
  },

  renderRecentSubmissions() {
    return (
        <div key={this.props.selectedClientId}>
          <h3 style={{display: "inline-block", marginRight: "1rem"}}>Last 7 Days</h3>
          {this.state.loadingSubmissions ? <LoadingSpinner /> : <AccordionSubmissions
              idToClient={this.props.idToClient}
              idToSubmission={this.state.idToSubmission}
              permissions={this.props.userPermissions}
              currentUserId={this.props.currentUserId}
              loadingSubmissions={this.state.loadingSubmissions}
              onChange={this.handleSubmissionChange}
              onError={this.showError} />}
        </div>
    );
  },

  loadAndSetDropdownData(cube19ClientId) {
    this.setState({loadingDropdownData: true});
    loadDropdownData(cube19ClientId).then(res => {
      this.setState({
        dropdownNameToData: res.get("dropdown-name->data"),
        dropdownNameToAliases: res.get("dropdown-name->aliases"),
        loadingDropdownData: false
      });
    });
  },

  loadAndSetProcs() {
    this.setState({loadingProcs: true});
    loadProcs().then(procs => {
      this.setState({procs, loadingProcs: false});
    });
  },

  loadAndSetExistingBulkJobs(clientId) {
    this.setState({loadingBulkJobs: true});
    fetch
        .getJson("etl2-bulk-job", {"client-id": clientId})
        .then(response => this.setState({existingBulkJobs: Immutable.fromJS(response), loadingBulkJobs: false}))
  },

  loadAndSetCsUiToPermissions() {
    this.setState({loadingCsUiToPermission: true});
    loadCsUiToPermissions().then(csUiToPermissions => {
      this.setState({csUiToPermissions, loadingCsUiToPermission: false});
    });
  },

  loadAndSetChangeSubmissions(cube19ClientId) {
    this.setState({loadingSubmissions: true});
    loadRecentSubmissions(cube19ClientId).then(changeSubmissions => {
      const idToSubmission = changeSubmissions
          .groupBy(c => c.get("submission_id"))
          .map(cs => cs.first());
      this.setState({
        idToSubmission,
        loadingSubmissions: false
      });
    });
  },

  reloadAllData(cube19ClientId) {
    if (cube19ClientId) {
      this.loadAndSetProcs();
      this.loadAndSetChangeSubmissions(cube19ClientId);
      this.loadAndSetDropdownData(cube19ClientId);
      this.loadAndSetCsUiToPermissions();
      this.loadAndSetExistingBulkJobs(cube19ClientId);
    }
  },

  showError(exception) {
    exception
        .response
        .json()
        .then(body => this.setState({
          isProcessing: false,
          errorMessage: body.message
        }));
  },

  handleSubmissionChange(submission) {
    this.loadAndSetDropdownData(this.props.selectedClientId);
    this.setSubmissionInState(submission);
  },

  setSubmissionInState(submission) {
    const id = submission.get("submission_id");
    const idToSubmission = this.state.idToSubmission.set(id, submission);
    this.setState({
      isProcessing: false,
      idToSubmission
    });
  }

});

const renderUndefinedMasterMetricsWarning = (count) => {
  const style = {
    padding: "0.5rem",
    marginBottom: "1rem",
    backgroundColor: "yellow",
    fontWeight: "bold"
  };
  return <p style={style}>Client has {count} undefined core master metric(s)</p>;
};

const getSubPermForUi = (uiName, csUiToPermissions) => csUiToPermissions.getIn([uiName, "submission-permission"]);

const getSectionsForClient = (clientId, idToClient, idToCrm, userPermissions, csUiToPermissions, onRequestReload, loadAndSetExistingBulkJobs, existingBulkJobs) => {
  return [{
    title: "Edit Delta ETL Job",
    content: <Etl2DeltaJobForm onRequestReload={onRequestReload} />,
    clientFilterFn: clientId => userPermissions.includes(getSubPermForUi("ETL_DELTA_JOB", csUiToPermissions))
  }, {
    title: "Request Bulk ETL Job",
    content: <BulkForm clientId={clientId}
                       existingBulkJobs={existingBulkJobs}
                       loadAndSetExistingBulkJobs={loadAndSetExistingBulkJobs}
                       onRequestReload={onRequestReload}
                       client={idToClient.get(clientId)}
                       crmType={getCrmTypeForClient(clientId, idToClient, idToCrm)}/>,
    clientFilterFn: clientId => userPermissions.includes(getSubPermForUi("ETL_BULK_JOB", csUiToPermissions))
  }, {
    title: "Update Bullhorn Credentials",
    content: <BullhornCredentialsForm onRequestReload={onRequestReload} />,
    clientFilterFn: clientId => getCrmTypeForClient(clientId, idToClient, idToCrm) === "bullhornrest"
        && userPermissions.includes("BH_PASSWORD_SUBMISSION")
  }, {
    title: "Delete Assets",
    content: <DeleteAssetsForm />,
    clientFilterFn: clientId => userPermissions.includes(getSubPermForUi("DELETE_ASSETS", csUiToPermissions))
  }].filter(section => section.clientFilterFn(clientId));
};

const getCrmTypeForClient = (clientId, idToClient, idToCrm) => {
  const client = idToClient.get(clientId);
  const crm = idToCrm.get(client.get("crm_id"));
  return crm.get("type");
};

const loadCsUiToPermissions = () => fetch
    .getJson("permissions/cs-uis")
    .then(res => Immutable.fromJS(res));

const loadProcs = () => fetch
    .getJson("procedures")
    .then(procs => Immutable.fromJS(procs));

const loadDropdownData = cube19ClientId => {
  const params = cube19ClientId ? {"cube19-client-id": cube19ClientId} : {};
  return fetch
      .getJson("procedures/dropdown-data", params)
      .then(res => Immutable
          .fromJS(res)
          .update("dropdown-name->aliases", dropdownNameToAliases => dropdownNameToAliases.map(x => x.toSet()))
          .update("dropdown-name->data", dropdownNameToOptions => dropdownNameToOptions
              .map(options => options
                  ? options.map(option => stringifyOptionValue(option))
                  : Immutable.List())));
};

const loadRecentSubmissions = cube19ClientId => fetch
    .getJson("change-submissions/customer-support", {
      "cube19-client-id": cube19ClientId,
      "start-date": moment().subtract(7, "days").format("YYYYMMDD"),
      "end-date": moment().format("YYYYMMDD")
    })
    .then(res => Immutable.fromJS(res));

const stringifyOptionValue = option => option.update("value", v => typeof (v) === "number" ? v + "" : v);

const Connected = props => {
  const {idToCrm} = React.useContext(CrmsContext);
  const {idToClient, idToClientStatus} = React.useContext(ClientsContext);
  const {selectedClientId, setSelectedClientId} = React.useContext(SelectedClientIdContext);
  const {currentUser} = React.useContext(CurrentUserContext);
  return <CSClient
      {...props}
      idToCrm={idToCrm}
      idToClient={idToClient}
      clientsLoading={idToClientStatus === Rata.Status.LOADING}
      selectedClientId={selectedClientId}
      setClientId={setSelectedClientId}
      currentUserId={currentUser.get("username")}
      userPermissions={currentUser.get("permissions")} />;
};

export default Connected;

import * as Immutable from "immutable";
import $ from "jquery";

import createReducer from "js/create-reducer";

const REFRESH = "REFRESH_CLIENT_DETAILS_PIVOT_TABLE_CONFIG";
const RESET_FILTERS = "RESET_CLIENT_DETAILS_PIVOT_TABLE_FILTERS";
const TOGGLE_SHOW_TOTALS = "TOGGLE_SHOW_TOTALS_FOR_CLIENT_DETAILS";

const defaultOrderedNameColumns = [
    "app:loaded",
    "oneview:loaded",
    "kpi_ct:open",
    "kpi_ct:change_tab",
    "ratio_ct:open",
    "ratio_ct:change_tab",
    "job-pipeline:loaded",
    "job-pipeline:run-report",
    "job-pipeline:ct",
    "job-pipeline:bh-link",
    "job-pipeline:collapse-pipeline",
    "job-pipeline:expand-pipeline",
    "job-pipeline:expand-job",
    "job-pipeline:job-changed",
    "onpoint:loaded",
    "onpoint:save-report",
    "onpoint:load-data",
    "onpoint:run-saved-report",
    "charting:loaded",
    "charting:standard-edit-clicked",
    "charting:plot-standard",
    "charting:plot-advanced",
    "charting:save",
    "main_snd:loaded",
    "main_snd:run_saved_report",
    "main_snd:run_custom_report",
    "main_snd:run_shared_report",
    "main_snd:download_report",
    "# Saved S&D Reports",
    "admin:loaded",
    "# Tags",
    "target_created",
    "# Priority Targets",
    "# Targets"
];

const sorters = Immutable.fromJS({
    Name: $.pivotUtilities.sortAs(defaultOrderedNameColumns)
});

const initialState = Immutable.fromJS({
    showTotals: false,
    options: {
        aggregatorName: "Sum (to Whole Numbers)",
        vals: ["Value"],
        rows: ["Group Breadcrumbs", "User Name"],
        cols: ["Name"],
        sorters,
        inclusions: {
            Name: defaultOrderedNameColumns
        },
        exclusions: {
            "User Name": ["n/a"]
        }
    }
});

export default createReducer(initialState, {
    [REFRESH]: (state, action) => {
        // pivot.js sends back the config on refresh but it loses the custom sorters each time
        const pivotTableOptions = action.options;
        return state.set("options", pivotTableOptions.set("sorters", sorters));
    },
    [RESET_FILTERS]: state => {
        // pivot.js auto populates the inclusions map with the selected (innermost) row's generated data cells
        // this needs to be reset to initial state when the report filters change else the new pivot filters are
        // deselected
        const currentPivotTableOptions = state.get("options");
        const initialPivotTableOptions = initialState.get("options");
        const newPivotTableOptions = currentPivotTableOptions
            .setIn(["inclusions", "Name"], initialPivotTableOptions.getIn(["inclusions", "Name"]))
            .deleteIn(["inclusions", "User Name"]);
        return state.set("options", newPivotTableOptions);
    },
    [TOGGLE_SHOW_TOTALS]: (state, action) => state.set("showTotals", action.showTotals)
});

// ACTIONS

export const toggleShowTotals = showTotals => ({
    type: TOGGLE_SHOW_TOTALS,
    showTotals
});

export const onPivotTableRefresh = options => ({
    type: REFRESH,
    options
});

export const resetPivotTableFilters = () => ({
    type: RESET_FILTERS
});

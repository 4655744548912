import Immutable from "immutable";
import React from "react";
import {Checkbox, CircularProgress, Paper, RaisedButton} from "material-ui";

import * as CrmMetadata from "js/data/crm-metadata";
import {CrmMetadataContext} from "js/data/contexts";
import ClientConfigEditor, {cleanAdminUsername} from "js/app-areas/onboarding/step-2/components/client-config-editor";
import {EtlConfigEditor} from "js/app-areas/onboarding/step-2/components/etl-config-editor";
import Err from "js/components/error";
import * as Rata from "js/data/remote-data";
import {Warning} from "js/app-areas/onboarding/step-2/components/etl-config-sections";
import {
  activityOwnersDefault,
  activityOwnersUiPath,
  fields,
  findEntityKpiConfigErrors,
  findErrors,
  findEtlConfigErrors,
  toEntityToKpiConfigsState,
  getEntityKpiConfigForSubmission,
  getEtlConfigForSubmission,
  getInitialEtlConfig,
  interviewKpisDateUiPath,
  meetingKpisDateUiPath
} from "js/app-areas/onboarding/step-2/config-helpers";
import UnsavedChangesDialog from "js/components/unsaved-changes-dialog";
import KpiEditor from "js/app-areas/onboarding/step-2/components/kpi-editor";
import {onboardingPages} from "js/app-areas/onboarding/app";
import {
  clearStoredChanges,
  retrieveStoredChangeDateTime,
  retrieveStoredChanges,
  saveChangesToLocalStorage
} from "js/app-areas/onboarding/step-2/local-store";
import {
  loadDropdownData,
  loadEntityToFieldConfig,
  loadDefaultKpiConfigs,
  loadBulkStatus,
  loadUsersForClient,
  redefineClient,
  defineClient,
  generateLegacyKpiConfigs
} from "js/app-areas/onboarding/step-2/api";
import {
  clientNamePath,
  kpiConfigsPath,
  kpiDateEntityColumnIdPath,
  kpiDateJoinPathPath,
  kpiEntityToOwnerTypesPath,
  kpiFilterPath,
  kpiMasterKpiTypePath,
  kpiNamePath,
  kpiOriginalNamePath,
  kpiVisibilitySetManuallyPath,
  kpiVisiblePath,
  productTierPath,
  step1BulkIdPath,
  step2EtlOptionsPath,
  step2KpiConfigsPath,
  step2ParamsPath,
  step2StatusPath
} from "js/app-areas/onboarding/onboarding-clients-config";
import {getErrorMessage} from "js/utils/errors";
import {productTiers} from "js/app-areas/onboarding/product-tiers";
import useMountEffect from "js/utils/hooks/use-mount-effect";
import {indexBy} from "js/utils/collections";
import * as BulkSchedule from "js/data/bulk-schedule";
import BulkScheduleInfo from "js/components/bulk-schedule-info";

const unsavedChangesMessage = "You have unsaved changes. Don't worry, we'll hold on to them until you come back.";
const errorMessage = "Please amend the highlighted fields and resubmit the form";
const reconfigurationWarning = "Any changes to this client outside of this tool may be lost when this reconfiguration is submitted. Metric columns and explanations will not be updated by changes here. Metric names will only be updated if the region has changed";
const bulkScheduleMessage = "Reloading loaded client data for mapping changes prevents the loading of new data until the reload is complete. " +
"The job will be picked up outside of working hours.";
const ignoreScheduleWarning = "Full data reload will begin immediately. Changes to data will not be loaded until the full reload completes.";
const metadataLoadFailedWarning = "ATS Metadata load failed. Mapping options will be very limited";

const activityOwnerDropdownValueToKpiConfigValue = dropdownValueString => {
  switch (dropdownValueString) {
    case "record":
      return Immutable.fromJS({"RECORD": ["OWNER"]});
    case "record-and-job":
      return Immutable.fromJS({
        "RECORD": ["OWNER"],
        "JOB": ["OWNER"]
      });
    default:
      throw Error(`Unsupported activity owners value for conversion to KPI config value: ${dropdownValueString}`);
  }
};

const StepTwoPage = ({idToClient, setCurrentPage, currentClient, updateClient, currentUserUsername}) => {
  const productTier = currentClient.getIn(productTierPath);
  const userBulkId = currentClient.getIn(step1BulkIdPath);
  const currentClientId = currentClient.get("client-id");
  const clientName = currentClient.getIn(clientNamePath);
  const originalClientConfig = currentClient.get("step-2-submission") ?
      currentClient.getIn(step2ParamsPath) :
      Immutable.Map({
        "client-id": currentClientId,
        "admin-username": cleanAdminUsername(clientName),
        region: "UK",
        "locale-name": "UK",
        timezone: "Etc/GMT",
        "hq-currency": "GBP",
        "week-start-day": "MONDAY",
        "year-start-month": "JANUARY",
        ...(productTier === "CORPORATE" && {"product-option": "jobs-pipeline"})
      });
  const storeKeyRoot = "cube19.admin-console.onboarding." + currentClientId + "." + currentUserUsername;
  const reconfigurationTool = idToClient.getIn([currentClient.get("client-id"), "reconfiguration_tool"]);
  const isReconfiguring = currentClient.getIn(step2StatusPath) === "SUCCEEDED";
  const isReadOnly = reconfigurationTool !== "ONBOARDING";
  const isLegacyEnterprise = isReconfiguring && productTier === productTiers.enterprise.enum && !currentClient.getIn(
      step2KpiConfigsPath);
  // TODO store submission id, prevent restore stored changes if it has changed

  const {clientIdToCrmMetadata, setClientIdToCrmMetadata} = React.useContext(CrmMetadataContext);
  const metaForClient = clientIdToCrmMetadata.get(currentClientId);

  const [dropdownData, setDropdownData] = React.useState(null);
  const [isLoadingDropdownData, setIsLoadingDropdownData] = React.useState(true);
  const [isLoadingEntityFieldConfig, setIsLoadingEntityFieldConfig] = React.useState(true);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [clientConfig, setClientConfig] = React.useState(originalClientConfig);

  const [etlConfig, setEtlConfig] = React.useState(Immutable.Map());
  const [originalEtlConfig, setOriginalEtlConfig] = React.useState(Immutable.Map());
  const [expandedSections, setExpandedSections] = React.useState(Immutable.Set("Placement"));
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const isLoadingMetadata = Rata.isLoading(metaForClient);
  const metadataLoadFailed = Rata.hasError(metaForClient);
  const [ignoreClientBulkSchedule, setIgnoreClientBulkSchedule] = React.useState(false);
  const [storedChangeDateTime, setStoredChangeDateTime] = React.useState(() => retrieveStoredChangeDateTime(storeKeyRoot));
  const [isSubmittingConfig, setIsSubmittingConfig] = React.useState(false);
  const [submitPending, setSubmitPending] = React.useState(false);
  const [hasLoadedKpiDefaultsForSubmit, setHasLoadedKpiDefaultsForSubmit] = React.useState(false);

  const [entityFieldConfig, setEntityFieldConfig] = React.useState(Immutable.Map());
  const [entityToKpiConfigsState, setEntityToKpiConfigsState] = React.useState(Immutable.Map());
  const [isLoadingKpiConfigs, setIsLoadingKpiConfigs] = React.useState(true);
  const [kpiConfigsLoadedForRegion, setKpiConfigsLoadedForRegion] = React.useState(clientConfig.get("region"));
  const [originalEntityToKpiConfigsState, setOriginalEntityToKpiConfigsState] = React.useState(Immutable.Map());

  const [loadingBulkSchedule, setLoadingBulkSchedule] = React.useState(true);
  const [bulkSchedule, setBulkSchedule] = React.useState(null);

  const [users, setUsers] = React.useState(Immutable.List());
  const [userBulkStatus, setUserBulkStatus] = React.useState(null);

  const entityNameToMetadataFields = React.useMemo(
      () => clientIdToCrmMetadata
          .getIn([currentClientId, "value", "entities"], Immutable.Map())
          .reduce(
              (result, item) => result.set(item.get("name"), item.get("fields")),
              Immutable.Map())
          // TODO remove TO_ONE filter and bring in subfields
          .map(entityFields => entityFields.filter(field => field.get("type") !== "TO_ONE")),
      [clientIdToCrmMetadata, currentClientId]);

  const isMultiCurrencyDisabled = React.useMemo(() => etlConfig.getIn(["JobOrder", "currency", "field", "isDisabled"])
      && etlConfig.getIn(["Placement", "currency", "field", "isDisabled"])
      && etlConfig.getIn(["Opportunity", "currency", "field", "isDisabled"]), [etlConfig]);

  const updateKpiConfigDefaults = React.useCallback((existingKpiConfigs, masterKpiToNewKpiConfig) => {
    return existingKpiConfigs.map(existingKpiConfig => {
      const masterKpiType = existingKpiConfig.getIn(kpiMasterKpiTypePath);
      let updatedKpiConfig = existingKpiConfig;
      if (masterKpiType) {
        const newKpiConfig = masterKpiToNewKpiConfig.get(masterKpiType);
        const kpiName = existingKpiConfig.getIn(kpiNamePath);
        const kpiOriginalName = existingKpiConfig.getIn(kpiOriginalNamePath);
        const newKpiName = newKpiConfig.getIn(kpiNamePath);
        if (kpiName === kpiOriginalName) {
          updatedKpiConfig = updatedKpiConfig
              .setIn(kpiNamePath, newKpiName)
              .setIn(kpiOriginalNamePath, newKpiName);
        } else {
          updatedKpiConfig = updatedKpiConfig.setIn(kpiOriginalNamePath, newKpiName);
        }

        const kpiHasEntityColumn = newKpiConfig.get("kpi-has-entity-column");
        if (kpiHasEntityColumn) {
          updatedKpiConfig = updatedKpiConfig.set("kpi-has-entity-column", kpiHasEntityColumn);
        }
      }

      return updatedKpiConfig;
    });
  }, []);

  React.useEffect(
      () => {
        if (submitPending) {
          const [
            etlConfigWithErrors,
            erroringSections,
            entityToKpiConfigsStateWithErrors,
            isEntityKpiConfigErroring
          ] = findErrors(etlConfig, entityToKpiConfigsState, productTier, entityNameToMetadataFields);
          const configHasErrors = !erroringSections.isEmpty() || isEntityKpiConfigErroring;

          if (configHasErrors) {
            setEntityToKpiConfigsState(entityToKpiConfigsStateWithErrors);
            setEtlConfig(etlConfigWithErrors);
            setExpandedSections(erroringSections);
            setErrorMsg(errorMessage);
            setSubmitPending(false);
          } else if (!hasLoadedKpiDefaultsForSubmit) {
            const clientConfigWithEtlOptions = clientConfig
                .set("etl-options", getEtlConfigForSubmission(etlConfig, productTier));
            setIsLoadingKpiConfigs(true);
            loadDefaultKpiConfigs(clientConfigWithEtlOptions)
                .then(newKpiConfigs => {
                  setEntityToKpiConfigsState(entityToKpiConfigsState => {
                    const masterKpiToNewKpiConfig = indexBy(
                        kpiConfig => kpiConfig.getIn(kpiMasterKpiTypePath),
                        newKpiConfigs);
                    return entityToKpiConfigsState
                        .map(kpiConfigsState => kpiConfigsState.updateIn(
                            kpiConfigsPath,
                            existingKpiConfigs => updateKpiConfigDefaults(
                                existingKpiConfigs,
                                masterKpiToNewKpiConfig)));
                  });
                  setHasLoadedKpiDefaultsForSubmit(true);
                })
                .finally(() => setIsLoadingKpiConfigs(false));
          } else if (!hasClientConfigError(clientConfig)) {
            const kpiConfigsForSubmission = getEntityKpiConfigForSubmission(entityToKpiConfigsState);
            let configForSubmission = clientConfig
                .set("etl-options", getEtlConfigForSubmission(etlConfig, productTier))
                .set("kpi-configs", kpiConfigsForSubmission);
            const isResubmission = !!currentClient.get("step-2-submission")
                && currentClient.getIn(step2StatusPath) !== "FAILED";
            if (isResubmission) {
              configForSubmission =
                  configForSubmission.set("ignore-client-bulk-schedule", ignoreClientBulkSchedule);
            }

            setIsLoadingDropdownData(true);
            setIsSubmittingConfig(true);
            const submitEndpoint = isResubmission ? redefineClient : defineClient;
            submitEndpoint(configForSubmission.toJS())
                .then(client => {
                  updateClient(client);
                  setCurrentPage(onboardingPages.main);
                })
                .catch(err => {
                  getErrorMessage(err).then(setErrorMsg);
                })
                .finally(() => {
                  setIsSubmittingConfig(false);
                  setIsLoadingDropdownData(false);
                  setSubmitPending(false);
                });
          }
        }

      },
      [
        submitPending,
        hasLoadedKpiDefaultsForSubmit,
        clientConfig,
        entityNameToMetadataFields,
        entityToKpiConfigsState,
        etlConfig,
        ignoreClientBulkSchedule,
        productTier,
        currentClient,
        updateClient,
        setCurrentPage,
        updateKpiConfigDefaults]);

  // Keep default KPI names up to date with the current region
  React.useEffect(() => {
    const region = clientConfig.get("region");
    if (!entityToKpiConfigsState.isEmpty() && kpiConfigsLoadedForRegion !== region) {
      setIsLoadingKpiConfigs(true);
      loadDefaultKpiConfigs(clientConfig)
          .then(newKpiConfigs => {
            const masterKpiTypeToNewKpiConfig = indexBy(
                kpiConfig => kpiConfig.getIn(kpiMasterKpiTypePath),
                newKpiConfigs);
            setEntityToKpiConfigsState(entityToKpiConfigsState => {
              return entityToKpiConfigsState
                  .map(kpiConfigsState => {
                    return kpiConfigsState.updateIn(kpiConfigsPath, kpiConfigs => {
                      return updateKpiConfigDefaults(kpiConfigs, masterKpiTypeToNewKpiConfig);
                    });
                  });
            });
            setKpiConfigsLoadedForRegion(region);
          })
          .finally(() => setIsLoadingKpiConfigs(false));
    }
  }, [entityToKpiConfigsState, updateKpiConfigDefaults, clientConfig, kpiConfigsLoadedForRegion]);

  // Keep KPI visibility, filters and options up to date with ETL config
  React.useEffect(() => {
    if (!etlConfig?.isEmpty()) {
      setEntityToKpiConfigsState(entityToKpiConfigsState => {
        return updateKpiConfigsFromEtlConfig(etlConfig, entityToKpiConfigsState);
      });
      if (productTier === productTiers.enterprise.enum) {
        setEntityFieldConfig(entityFieldConfig => {
          return updateEntityFieldConfigFromEtlConfig(etlConfig, entityFieldConfig);
        });
      }
    }
  }, [etlConfig, productTier]);


  // Load initial data from the API
  useMountEffect(() => {
    setIsLoadingEntityFieldConfig(true)
    loadEntityToFieldConfig(currentClient.get("client-id"))
        .then(entityFieldConfig => {
          setEntityFieldConfig(updateEntityFieldConfigFromEtlConfig(etlConfig, entityFieldConfig));
        })
        .finally(() => setIsLoadingEntityFieldConfig(false));

    if (isReconfiguring) {
      BulkSchedule.load(currentClientId)
          .then(schedule => {
            setBulkSchedule(schedule);
          })
          .finally(() => {
            setLoadingBulkSchedule(false);
          });
    }

    setIsLoadingDropdownData(true);
    loadDropdownData()
        .then(setDropdownData)
        .finally(() => setIsLoadingDropdownData(false));
  });

  React.useEffect(
      () => {
        if (originalEtlConfig.isEmpty() && !isLoadingMetadata && !isLoadingEntityFieldConfig && dropdownData) {
          const initialEtlConfig = getInitialEtlConfig(
              productTier,
              entityNameToMetadataFields,
              currentClient.getIn(step2EtlOptionsPath),
              dropdownData);
          setOriginalEtlConfig(initialEtlConfig);
          setEtlConfig(prevVal => prevVal.isEmpty() ? initialEtlConfig : prevVal);

          let kpiPromise;
          if (isReconfiguring) {
            const prevConfig = currentClient.getIn(step2KpiConfigsPath);
            if (prevConfig) {
              kpiPromise = Promise.resolve(prevConfig);
            } else if (isLegacyEnterprise) {
              kpiPromise = Promise.resolve(Immutable.List());
            } else {
              kpiPromise = generateLegacyKpiConfigs(currentClientId);
            }
          } else {
            kpiPromise = loadDefaultKpiConfigs(clientConfig);
          }
          kpiPromise
              .then(kpiConfigs => updateKpiConfigsFromEtlConfig(
                  initialEtlConfig,
                  toEntityToKpiConfigsState(kpiConfigs)))
              .then(entityToKpiConfigsState => {
                // Don't overwrite configs that have come from elsewhere e.g. local storage
                setEntityToKpiConfigsState(prevVal => prevVal.isEmpty() ? entityToKpiConfigsState : prevVal);
                setOriginalEntityToKpiConfigsState(entityToKpiConfigsState);
              })
              .finally(() => setIsLoadingKpiConfigs(false));
        }
      },
      [
        originalEtlConfig,
        productTier,
        entityNameToMetadataFields,
        currentClient,
        currentClientId,
        dropdownData,
        clientConfig,
        isReconfiguring,
        isLegacyEnterprise,
        isLoadingMetadata,
        isLoadingEntityFieldConfig]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      userBulkStatus !== "COMPLETED" && loadBulkStatus(userBulkId)
          .then(result => setUserBulkStatus(result.get("status")));
    }, 5000);

    return () => clearInterval(interval);
  }, [userBulkId, userBulkStatus]);

  React.useEffect(() => {
    userBulkStatus === "COMPLETED" && loadUsersForClient(currentClientId).then(users => setUsers(users));
  }, [currentClientId, userBulkStatus]);

  React.useEffect(() => {
    CrmMetadata.loadAndSet(currentClientId, clientIdToCrmMetadata, setClientIdToCrmMetadata);
  }, [currentClientId, clientIdToCrmMetadata, setClientIdToCrmMetadata]);

  useMountUnmountEffect(
      ref => {
        const unloadHandler = (event) => {
          const [etlConfig, clientConfig, entityToKpiConfigsState, originalEtlConfig, originalClientConfig, originalEntityToKpiConfigsState, isSubmittingConfig, isReadOnly] = ref.current;
          const etlConfigChanged = !Immutable.is(originalEtlConfig, etlConfig);
          const clientConfigChanged = !Immutable.is(originalClientConfig, clientConfig);
          const kpiConfigChanged = !Immutable.is(originalEntityToKpiConfigsState, entityToKpiConfigsState);
          const hasChanges = etlConfigChanged || clientConfigChanged || kpiConfigChanged;
          if (hasChanges && !isSubmittingConfig && !isReadOnly) {
            event.preventDefault();
            saveChangesToLocalStorage(storeKeyRoot, clientConfig, etlConfig, entityToKpiConfigsState);
          }
        };

        // At mount, add listener to confirm user wants to close the page
        window.addEventListener("beforeunload", unloadHandler);

        // At component unmount (e.g. navigate away in app), remove listener above and warn user of unsaved changes
        return () => {
          window.removeEventListener("beforeunload", unloadHandler);
          const [etlConfig, clientConfig, entityToKpiConfigsState, originalEtlConfig, originalClientConfig, originalEntityToKpiConfigsState, isSubmittingConfig, isReadOnly] = ref.current;
          const etlConfigChanged = !Immutable.is(originalEtlConfig, etlConfig);
          const clientConfigChanged = !Immutable.is(originalClientConfig, clientConfig);
          const kpiConfigChanged = !Immutable.is(originalEntityToKpiConfigsState, entityToKpiConfigsState);
          const hasChanges = etlConfigChanged || clientConfigChanged || kpiConfigChanged;
          if (hasChanges && !isSubmittingConfig && !isReadOnly) {
            saveChangesToLocalStorage(storeKeyRoot, clientConfig, etlConfig, entityToKpiConfigsState);
            alert(unsavedChangesMessage);
          }
        };
      },
      [
        etlConfig,
        clientConfig,
        entityToKpiConfigsState,
        originalEtlConfig,
        originalClientConfig,
        originalEntityToKpiConfigsState,
        isSubmittingConfig,
        isReadOnly]);


  const handleDiscardStoredChanges = React.useCallback(() => {
    clearStoredChanges(storeKeyRoot);
    setStoredChangeDateTime(null);
  }, [storeKeyRoot]);

  const handleRetrieveStoredChanges = React.useCallback(() => {
    const {clientConfig, etlConfig, entityToKpiConfigsState} = retrieveStoredChanges(storeKeyRoot);

    setEtlConfig(etlConfig);
    setClientConfig(clientConfig);
    setEntityToKpiConfigsState(entityToKpiConfigsState);

    handleDiscardStoredChanges();
  }, [storeKeyRoot, handleDiscardStoredChanges]);

  const handleSectionClick = React.useCallback(name => {
    setExpandedSections(expandedSections => expandedSections.includes(name)
        ? expandedSections.delete(name)
        : expandedSections.add(name));
  }, []);

  const handleEtlConfigChange = React.useCallback(newConfig => {
    // Run validation after first submit attempt to keep validation up to date
    if (submitAttempted) {
      const [configWithErrors, erroringSections] = findEtlConfigErrors(
          newConfig,
          productTier,
          entityNameToMetadataFields);
      setEtlConfig(configWithErrors);
      if (!erroringSections.isEmpty() || hasClientConfigError(clientConfig)) {
        setErrorMsg(errorMessage);
      } else {
        setErrorMsg(null);
      }
    } else {
      setEtlConfig(newConfig);
    }
  }, [entityNameToMetadataFields, submitAttempted, productTier, clientConfig]);

  const handleEntityKpiConfigChange = React.useCallback(updateFn => {
    // Run validation after first submit attempt to keep validation up to date
    if (submitAttempted) {
      setEntityToKpiConfigsState(entityToKpiConfigsState => {
        const [configWithErrors] = findEntityKpiConfigErrors(updateFn(entityToKpiConfigsState));
        return configWithErrors;
      });
    } else {
      setEntityToKpiConfigsState(updateFn);
    }
  }, [submitAttempted]);

  const handleRefreshMetadata = React.useCallback(() => {
    CrmMetadata.loadAndSet(
        currentClientId,
        clientIdToCrmMetadata,
        setClientIdToCrmMetadata,
        {ignoreLocalCache: true, ignoreServerCache: true})
        .then(() => {
          loadEntityToFieldConfig(currentClientId).then(setEntityFieldConfig);
        });
  }, [currentClientId, clientIdToCrmMetadata, setClientIdToCrmMetadata]);


  const handleFormSubmit = () => {
    setSubmitAttempted(true);
    setHasLoadedKpiDefaultsForSubmit(false);
    setSubmitPending(true);
  };

  return <div style={{display: "flex", flexDirection: "column"}}>
    {isLoadingDropdownData
        ? <div style={{display: "flex", justifyContent: "center", marginTop: 30}}>
          <CircularProgress />
        </div>
        : <div>
          <UnsavedChangesDialog
              onRetrieveClick={handleRetrieveStoredChanges}
              onDiscardClick={handleDiscardStoredChanges}
              lastUpdated={storedChangeDateTime?.format("LLL")}
              open={!!storedChangeDateTime && !isReadOnly} />
          <ClientConfigEditor
              clientConfig={clientConfig}
              setClientConfig={setClientConfig}
              productTier={productTier}
              dropdownData={dropdownData}
              isLocked={isReadOnly} />
          {metadataLoadFailed && <div style={{marginBottom: 15}}>
            <Err
                type="error"
                message={metadataLoadFailedWarning}
                customStyle={{
                  display: "block",
                  margin: "1em 0",
                  borderColor: "#DA4453",
                  backgroundColor: "#DA4453",
                  color: "#fff"
                }} />
          </div> }
          {isLoadingMetadata ? <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
                marginBottom: 30
              }}>
            <CircularProgress />
          </div> : <EtlConfigEditor
              etlConfig={etlConfig}
              entityFieldConfig={entityFieldConfig}
              handleEtlConfigChange={handleEtlConfigChange}
              isLocked={isReadOnly}
              entityNameToMetadataFields={entityNameToMetadataFields}
              expandedSections={expandedSections}
              users={users}
              userBulkStatus={userBulkStatus}
              onSectionClick={handleSectionClick}
              hqCurrency={clientConfig.get("hq-currency")}
              productTier={productTier}
              currencyCodes={dropdownData.get("currencies")}
              metadataLoadedMillis={Rata.getValue(metaForClient, Immutable.Map()).get("loaded-millis", 0)}
              onRefreshMetadata={handleRefreshMetadata}
              showJobsPipelineWarnings={productTier
              !== "CORPORATE"
              || clientConfig.get("product-option")
              === "jobs-pipeline"}
          />}
          {isReconfiguring && !isReadOnly && <Paper
              style={{
                padding: "10px 30px 20px 30px",
                marginBottom: 20
              }}>
            <h3>Client Data</h3>
            <div style={{display: "flex", flexDirection: "column", pointerEvents: isReadOnly && "none"}}>
              <div style={{display: "flex", marginBottom: 10}}>
                {bulkScheduleMessage}
              </div>
              <div style={{display: "flex"}}>
                <Checkbox
                    inputStyle={{pointerEvents: isReadOnly && "none"}}
                    checked={ignoreClientBulkSchedule}
                    style={{marginLeft: 10, width: 20, marginTop: 10}}
                    onCheck={e => setIgnoreClientBulkSchedule(e.target.checked)} />
                <div style={{fontSize: "1rem", maxWidth: 300, marginTop: 11, marginBottom: 15}}>{"Ignore schedule and load immediately"}</div>
              </div>
              <div style={{marginBottom: 15}}>
                <BulkScheduleInfo bulkSchedule={bulkSchedule} loading={loadingBulkSchedule} />
              </div>
            </div>
          </Paper>}
          {isMultiCurrencyDisabled && <div style={{marginBottom: 15}}>
            <Warning message="As you have disabled all currency fields multi currency will be disabled and all values will be displayed in HQ currency." />
          </div>}
          {productTier === productTiers.enterprise.enum && !isLegacyEnterprise && <KpiEditor
              entityFieldConfig={entityFieldConfig}
              entityToKpiConfigsState={entityToKpiConfigsState}
              onEntityKpiConfigChange={handleEntityKpiConfigChange}
              isReconfiguring={isReconfiguring}
              isLocked={isReadOnly}
              isLoading={isLoadingKpiConfigs} />}
          {errorMsg
          && <Err
              type="error"
              message={errorMsg}
              customStyle={{
                display: "block",
                margin: "1em 0",
                borderColor: "#DA4453",
                backgroundColor: "#DA4453",
                color: "#fff"
              }} />}
          {ignoreClientBulkSchedule && <div style={{marginBottom: 15}}>
            <Warning message={ignoreScheduleWarning} />
          </div> }
          {isReconfiguring && !isReadOnly
          && <Warning message={reconfigurationWarning} />}
          <div style={{display: "flex", justifyContent: "flex-end"}}>
            <RaisedButton
                style={{width: 150}}
                disabled={!isReadOnly && (isLoadingMetadata
                    || isLoadingKpiConfigs
                    || submitPending
                    || isSubmittingConfig)}
                onClick={isReadOnly ? () => setCurrentPage(onboardingPages.main) : handleFormSubmit}
                label={isReadOnly ? "Home" : "Submit"}
                primary={true} />
          </div>
        </div>}
  </div>;
};

const hasClientConfigError = clientConfig => !clientConfig.get("admin-username") ||
    (clientConfig.get("bullhorn-sf-id") &&
        (!(clientConfig.get("bullhorn-sf-id").length === 18) || !clientConfig.get("bullhorn-sf-id")
            .match(/^[0-9a-zA-Z]+$/)));

const filterMatchesTemplate = (filter, template) => {
  return filter.get("entityColumnId") === template.get("entityColumnId")
      && filter.get("operator") === template.get("operator")
      && Immutable.is(filter.get("joinPath"), template.get("joinPath"));
};

const updateEntityFieldConfigFromEtlConfig = (etlConfig, entityFieldConfig) => {
  const representedToValues = fields.reduce((result, field) => {
    if (field.has("represents")) {
      const represents = field.get("represents");
      const path = field.get("ui-path");
      const types = etlConfig.getIn([...path, "isDisabled"])
          ? Immutable.List()
          : etlConfig.getIn([...path, "value"], Immutable.List());
      return result.update(represents, Immutable.List(), v => v.concat(types));
    }
    return result;
  }, Immutable.Map());

  return entityFieldConfig.map(entity => entity.update("typeFields", typeFields => typeFields.map(typeField => {
    const mappedOptions = typeField
        .get("mappedOptions", Immutable.List())
        .flatMap(x => representedToValues.get(x, Immutable.List()));
    return typeField.set("combinedOptions", typeField.get("options").concat(mappedOptions));
  })));
};

const updateKpiConfigsFromEtlConfig = (etlConfig, entityToKpiConfigsState) => {
  const representedToValues = fields.reduce((result, field) => {
    if (field.has("represents")) {
      const represents = field.get("represents");
      const path = field.get("ui-path");
      const types = etlConfig.getIn([...path, "isDisabled"])
          ? Immutable.List()
          : etlConfig.getIn([...path, "value"], Immutable.List());
      return result.set(represents, result.get(represents, Immutable.List()).concat(types));
    }
    return result;
  }, Immutable.Map());

  const typeNameToTypeValues = Immutable.Map({
    "shortlisted-candidate-statuses": representedToValues.get("shortlisted-candidates"),
    "rejected-candidate-statuses": representedToValues.get("rejected-candidates")
  });

  const activityOwners = activityOwnerDropdownValueToKpiConfigValue(
      etlConfig.getIn([...activityOwnersUiPath, "value"], activityOwnersDefault));

  const interviewsDate = etlConfig.getIn([...interviewKpisDateUiPath, "value"]);
  const meetingsDate = etlConfig.getIn([...meetingKpisDateUiPath, "value"]);


  const featureSet = representedToValues
      .filter(types => !types.isEmpty())
      .keySeq()
      .toSet();

  return entityToKpiConfigsState
      .map(kpiConfigsState => {
        return kpiConfigsState.updateIn(kpiConfigsPath, kpiConfigs => {
          return kpiConfigs.map(kpiConfig => {
            const usesActivityOwners = kpiConfig.get("uses-activity-owners?", false);
            const usesInterviewsDate = kpiConfig.get("uses-interviews-date?", false);
            const usesMeetingsDate = kpiConfig.get("uses-meetings-date?", false);
            const forClientWithAll = kpiConfig.get("for-client-with-all");
            const hasFeatureRestrictions = !!forClientWithAll;
            const visibilitySetManually = kpiConfig.getIn(kpiVisibilitySetManuallyPath, false);
            const filterTemplates = kpiConfig.get("filter-type->filter-templates", Immutable.List());

            let updatedKpiConfig = kpiConfig;
            if (hasFeatureRestrictions && !visibilitySetManually) {
              const isMissingFeatures = !featureSet.isSuperset(forClientWithAll);
              if (isMissingFeatures) {
                updatedKpiConfig = updatedKpiConfig.setIn(kpiVisiblePath, false);
              } else {
                updatedKpiConfig = updatedKpiConfig.deleteIn(kpiVisiblePath);
              }
            }
            if (usesActivityOwners) {
              updatedKpiConfig = updatedKpiConfig.setIn(kpiEntityToOwnerTypesPath, activityOwners);
            }
            if (usesInterviewsDate && interviewsDate) {
              const dateEntityColumnId = interviewsDate.get("entityColumnId");
              const dateJoinPath = interviewsDate.get("joinPath");
              updatedKpiConfig = updatedKpiConfig
                  .setIn(kpiDateEntityColumnIdPath, dateEntityColumnId)
                  .setIn(kpiDateJoinPathPath, dateJoinPath);
            } else if (usesMeetingsDate && meetingsDate) {
              const dateEntityColumnId = meetingsDate.get("entityColumnId");
              const dateJoinPath = meetingsDate.get("joinPath");
              updatedKpiConfig = updatedKpiConfig
                  .setIn(kpiDateEntityColumnIdPath, dateEntityColumnId)
                  .setIn(kpiDateJoinPathPath, dateJoinPath);
            }

            const applyFilterTemplates = (filterTemplates, filter) => {
              let updatedFilter;
              if (filter.get("type") === "GROUP") {
                updatedFilter = filter;
              } else {
                updatedFilter = Immutable.Map({
                  type: "GROUP",
                  combination: "AND",
                  children: Immutable.List([filter])
                });
              }

              return updatedFilter.update(
                  "children",
                  children => filterTemplates.reduce(
                      (acc, template) => {
                        const index = acc.findIndex(filter => filterMatchesTemplate(filter, template));
                        const typeName = template.get("value");
                        const typesFromEtlConfig = typeNameToTypeValues.get(typeName).toSet();
                        const typesFromTemplate = template.get("value-default").toSet();
                        const typesToSet = typesFromEtlConfig.isEmpty()
                            ? typesFromTemplate
                            : typesFromEtlConfig;
                        if (index !== -1) {
                          return acc.update(
                              index,
                              filter => filter.update("value", value => typesToSet.union(value)));
                        } else {
                          return acc.push(template.set("value", typesToSet).delete("value-default"));
                        }
                      },
                      children));
            };

            if (!filterTemplates.isEmpty()) {
              updatedKpiConfig = updatedKpiConfig.updateIn(
                  kpiFilterPath,
                  Immutable.Map(),
                  filter => applyFilterTemplates(filterTemplates, filter));
            }

            return updatedKpiConfig;
          });
        });
      });
};

const useMountUnmountEffect = (effectFn, dependencies) => {
  const ref = React.useRef(dependencies);
  React.useEffect(() => {
    ref.current = dependencies;
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    return effectFn(ref);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default StepTwoPage;
import React from "react";
import Immutable from "immutable";

import * as Rata from "js/data/remote-data";

// TODO STATE is there anything to be done here for perf?
const renderContexts = (contextsAndValues, children) => {
  if (contextsAndValues.isEmpty()) {
    return children;
  } else {
    const contextAndValue = contextsAndValues.first();
    const Provider = contextAndValue.context.Provider;
    return <Provider value={contextAndValue.value}>{renderContexts(contextsAndValues.rest(), children)}</Provider>;
  }
};

const resolveFnOrValue = x => typeof (x) === "function" ? x() : x;

// TODO STATE consider how this will work with things like kpis
//    loaded all at once
//    updated one at a time or many at a time
//    can this just be ignored?
//      updating a single element shouldn't be considered "loading" for the whole collection anywy
const useAsyncGlobalState = (
    initialValue,
    keyName,
    {
      loader,
      expiresMinutes
    } = {}
) => {
  const [wrapper, setWrapper] = React.useState(() => Rata.wrapInitialValue(resolveFnOrValue(initialValue)));

  const loadAndSet = React.useCallback((...loaderArgs) => {
    setWrapper(Rata.toLoading);
    return loader(...loaderArgs).then(
        newValue => {
          setWrapper(wrapper => Rata.toLoaded(wrapper, newValue, expiresMinutes));
          return newValue;
        },
        error => {
          setWrapper(wrapper => Rata.toError(wrapper, error));
          // TODO STATE consider this api choice
          //  we are "handling" the error by setting it into the wrapper for later use
          //  BUT people listening to the returned promise would probably prefer to see it fail
          //  potential downsides of throwing:
          //    error will show up as unhandled/uncaught
          throw error;
        });
  }, [expiresMinutes, loader]);

  const reloadIfNecessary = React.useCallback((...args) => {
    if (!Rata.isLoading(wrapper) && Rata.isExpired(wrapper)) {
      return loadAndSet(...args);
    } else {
      return Promise.resolve(wrapper);
    }
  }, [loadAndSet, wrapper]);

  const setValue = React.useCallback(newValue => {
    if (typeof (newValue) === "function") {
      setWrapper(wrapper => wrapper.update("value", newValue));
    } else {
      setWrapper(wrapper => wrapper.set("value", newValue));
    }
  }, []);

  return React.useMemo(() => {
    const standard = {
      status: wrapper.get("status"),
      error: wrapper.get("error"),
      value: wrapper.get("value"),
      setValue,

      wrapper,
      setWrapper,

      reloadIfNecessary,
      reload: loadAndSet
    };
    let custom;
    if (keyName) {
      const capitalisedKeyName = capitalise(keyName);
      custom = {
        [keyName + "Status"]: wrapper.get("status"),
        [keyName + "Error"]: wrapper.get("error"),
        [keyName]: wrapper.get("value"),
        ["set" + capitalisedKeyName]: setValue,

        [keyName + "Wrapper"]: wrapper,
        ["set" + capitalisedKeyName + "Wrapper"]: setWrapper,

        ["reload" + capitalisedKeyName + "IfInvalid"]: reloadIfNecessary,
        ["reload" + capitalisedKeyName]: loadAndSet
      };
    } else {
      custom = {};
    }
    return freeze({
      ...standard,
      ...custom
    });
  }, [
    reloadIfNecessary,
    setValue,
    loadAndSet,
    wrapper,
    keyName
  ]);
};

// TODO STATE this is very similar to the core useState hook
//    main advantages are
//      labelled fns instead of positional
//      the ability to extend functionality easily in all places
const useSyncGlobalState = (initialValue, keyName) => {
  const [value, setValue] = React.useState(() => resolveFnOrValue(initialValue));

  return React.useMemo(() => {
    const standard = {value, setValue};
    let custom;
    if (keyName) {
      const capitalisedKeyName = capitalise(keyName);
      custom = {
        [keyName]: value,
        ["set" + capitalisedKeyName]: setValue
      };
    } else {
      custom = {};
    }
    return freeze({
      ...standard,
      ...custom
    });
  }, [keyName, value]);
};

// TODO work in progress for things like CrmMetadata
//    unlikely to have much usage in main app so not a priority
//    consider loading multiple ids at once
//    consider loading "all" where ids are not known
const WIPuseIndexedGlobalState = ({
  initialValue = Immutable.Map(),
  dataKeyName,
  idKeyName,
  singleIdLoader,
  expiresMinutes
}) => {
  const [idToWrapper, setIdToWrapper] = React.useState(initialValue);

  const loadForIdIgnoringLocalCache = React.useCallback((id, ...args) => {
    setIdToWrapper(idToWrapper => idToWrapper.update(id, Rata.toLoading));
    return singleIdLoader(id, ...args).then(
        newValueForId => {
          setIdToWrapper(idToWrapper => idToWrapper.update(id, wrapperForId => Rata.toLoaded(wrapperForId, newValueForId, expiresMinutes)));
          return newValueForId;
        },
        errorForId => {
          setIdToWrapper(idToWrapper => idToWrapper.update(id, wrapperForId => Rata.toError(wrapperForId, errorForId)));
          throw errorForId;
        });
  }, [singleIdLoader, expiresMinutes]);

  const loadForIdIfNecessary = React.useCallback((id, ...args) => {
    const wrapper = idToWrapper.get(id);
    if (Rata.isLoading(wrapper) && Rata.isExpired(wrapper)) {
      return loadForIdIgnoringLocalCache(id, ...args);
    } else {
      return Promise.resolve(Rata.getValue(wrapper));
    }
  }, [loadForIdIgnoringLocalCache, idToWrapper]);

  return React.useMemo(() => {
    const standard = {
      loadForId: loadForIdIgnoringLocalCache,
      loadForIdIfNecessary,
      idToWrapper,
      setIdToWrapper
    };
    let custom;
    if (dataKeyName && idKeyName) {
      const capitalisedDataKeyName = capitalise(dataKeyName);
      const capitalisedIdKeyName = capitalise(idKeyName);
      custom = {
        ["load" + capitalisedDataKeyName + "For" + capitalisedIdKeyName]: loadForIdIgnoringLocalCache,
        ["load" + capitalisedDataKeyName + "For" + capitalisedIdKeyName + "IfNecessary"]: loadForIdIgnoringLocalCache,
        [idKeyName + "To" + capitalisedDataKeyName]: idToWrapper,
        ["set" + capitalisedIdKeyName + "To" + capitalisedDataKeyName]: setIdToWrapper
      };
    } else {
      custom = {};
    }
    return freeze({
      ...standard,
      ...custom
    });
  }, [loadForIdIgnoringLocalCache, loadForIdIfNecessary, idToWrapper, dataKeyName, idKeyName]);
};

const freeze = obj => process.env.NODE_ENV === "production" ? obj : Object.freeze(obj);

const capitalise = str => str.charAt(0).toUpperCase() + str.substring(1);

export {
  useAsyncGlobalState,
  useSyncGlobalState,
  WIPuseIndexedGlobalState,
  renderContexts,
  capitalise
};

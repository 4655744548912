import moment from "moment/moment";

const MYSQL_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const MYSQL_DATE_FORMAT = "YYYY-MM-DD";

const parseDateTime = (dateStr, format = MYSQL_DATETIME_FORMAT) => moment(dateStr, format);
const formatDateTime = (momentObj, pattern = MYSQL_DATETIME_FORMAT) => momentObj.format(pattern);

const parseDate = (dateStr, format = MYSQL_DATE_FORMAT) => moment(dateStr, format);
const formatDate = (momentObj, pattern = MYSQL_DATE_FORMAT) => momentObj.format(pattern);

export {MYSQL_DATETIME_FORMAT, MYSQL_DATE_FORMAT, parseDateTime, formatDateTime, parseDate, formatDate};
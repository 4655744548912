import React from "react";
import createReactClass from "create-react-class";

import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import {Success, Err} from "js/components/notification";
import * as fetch from "js/fetch";
import {ClientsContext, CurrentUserContext, SelectedClientIdContext} from "js/data/contexts";

const BullhornCredentials = createReactClass({

  getInitialState() {
    return {
      isLoading: false,

      currentUsername: null,
      newUsername: "",
      currentPassword: null,
      newPassword: "",

      comment: "",
      successMessage: null,
      errorMessage: null
    };
  },

  componentDidMount() {
    this.loadAndSetUsername();
  },

  render() {
    return <div>{this.props.selectedClientId && this.renderForm()}</div>;
  },

  renderForm() {
    const {selectedClientId, idToClient, canDecryptCreds} = this.props;
    const {
      currentUsername,
      newUsername,
      currentPassword,
      newPassword,
      comment,
      successMessage,
      errorMessage
    } = this.state;
    const client = idToClient.get(selectedClientId);
    const commentPrefix = `Change bullhorn creds for ${client.get("internal_name")} with username '${currentUsername || newUsername || "<not set>"}': `;
    const isMissingComment = !comment;
    const isCurrentPasswordVisible = !!currentPassword;
    const buttonMargins = {
      marginTop: "2rem",
      marginRight: "1rem"
    };

    return (
        <div style={{padding: "0rem 1rem 1rem 1rem"}}>
          {canDecryptCreds && <div>
            <RaisedButton
                label={isCurrentPasswordVisible ? "Current Password" : "Show Current Password"}
                onClick={() => this.showCurrentPassword()}
                disabled={isCurrentPasswordVisible}
                style={{marginTop: "2rem"}}
                secondary={true} />
            {currentPassword !== null && <span style={{marginLeft: "1rem"}}>{currentPassword}</span>}
          </div>}

          <span style={{marginRight: "0.5rem"}}>Username: {currentUsername}</span>
          <TextField
              floatingLabelText="Username"
              style={{marginRight: "1rem"}}
              value={newUsername}
              onChange={e => this.setState({newUsername: e.target.value})} />
          <TextField
              floatingLabelText="New Password"
              style={{marginRight: "1rem"}}
              value={newPassword}
              onChange={e => this.setState({newPassword: e.target.value})} />
          <TextField
              floatingLabelText="Comment"
              value={commentPrefix + comment}
              multiLine={true}
              fullWidth={true}
              style={{marginTop: "2rem"}}
              onChange={e => this.setState({comment: e.target.value.substring(commentPrefix.length)})}
              errorText={comment.length === 0 && "A reason for this change is required"} />
          <div>
            <RaisedButton
                label="Cancel"
                onClick={() => {
                  this.setState(this.getInitialState());
                  this.loadAndSetUsername();
                }}
                style={buttonMargins} />
            <RaisedButton
                label="Update"
                primary={true}
                disabled={isMissingComment}
                onClick={() => {
                  const params = {
                    "client-id": selectedClientId,
                    "username": newUsername,
                    "password": newPassword,
                    comment: commentPrefix + comment
                  };
                  updateBullhornCredentials(params)
                      .then(() => {
                            this.props.onRequestReload();
                            this.setState({
                              successMessage: `Customer's Bullhorn credentials updated`,
                              newPassword: "",
                              currentPassword: null,
                              comment: ""
                            });
                          },
                          error => {
                            this.props.onRequestReload();
                            error
                                .response
                                .json()
                                .then(body => this.setState({errorMessage: body.message}));
                          });
                }}
                style={buttonMargins} />
          </div>
          <Success message={successMessage} onRequestClose={() => this.setState({successMessage: null})} />
          <Err message={errorMessage} onRequestClose={() => this.setState({errorMessage: null})} />
        </div>
    );
  },

  showCurrentPassword() {
    this.setState({isLoading: true});
    getBullhornCredentialForClient(this.props.selectedClientId, "password")
        .then(currentPassword => {
          this.setState({
            isLoading: false,
            currentPassword
          });
        }, () => {
          this.setState({
            isLoading: false,
            errorMessage: "Unable to load customer's Bullhorn password, contact support@cube19.com \u{1F430}"
          });
        });
  },

  loadAndSetUsername() {
    this.setState({isLoading: true});
    getBullhornCredentialForClient(this.props.selectedClientId, "username")
        .then(username => {
          this.setState({
            isLoading: false,
            currentUsername: username
          });
        }, () => {
          this.setState({
            isLoading: false,
            errorMessage: "Unable to load customer's Bullhorn username, contact support@cube19.com \u{1F430}"
          });
        });
  }

});

const getBullhornCredentialForClient = (clientId, propertyKey) => {
  const params = {
    "client-id": clientId,
    "property-key": propertyKey
  };
  return fetch
      .getJson("client-bullhorn-config", params)
      .then(response => response.value);
};

const updateBullhornCredentials = params => fetch.post("client-bullhorn-config", params);

const Connected = props => {
  const {idToClient} = React.useContext(ClientsContext);
  const {selectedClientId} = React.useContext(SelectedClientIdContext);
  const {currentUser} = React.useContext(CurrentUserContext);
  return <BullhornCredentials
      {...props}
      idToClient={idToClient}
      selectedClientId={selectedClientId}
      canDecryptCreds={currentUser.get("permissions").includes("BULLHORN_DECRYPTER")}
  />;
};

export default Connected;
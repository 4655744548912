import Immutable from "immutable";

import createReducer from "js/create-reducer";

const initialEntityFieldMappingState = Immutable.fromJS({
  api: {
    mappings: {error: null, loaded: false, pending: false},
    entity: {error: null},
    field: {error: null}
  },
  mappings: [],
  dwGroupingEntities: [],
  linkContexts: []
});

const apiLoadedSuccessfully = Immutable.fromJS({error: null, loaded: true, pending: false});

const c19EntityFieldMappingsReducer = createReducer(initialEntityFieldMappingState, {
  MERGE_ENTITY_FIELD_MAPPINGS: (state, {newState}) => state.merge(newState),
  MERGE_IN_ENTITY_FIELD_MAPPINGS: (state, {path, defaultState, newState}) => state.updateIn(
      path,
      defaultState,
      s => s.merge(newState)),
  SET_IN_ENTITY_FIELD_MAPPINGS: (state, {path, newValue}) => state.setIn(path, newValue)
});

const makeSelector = fn => state => fn(state.get("c19EntityFieldMappings"));
const mappingsSelector = makeSelector(state => state.get("mappings"));
const dwGroupingEntitiesSelector = makeSelector(state => state.get("dwGroupingEntities"));
const linkContextsSelector = makeSelector(state => state.get("linkContexts"));
const mappingsApiSelector = makeSelector(state => state.getIn(["api", "mappings"]));

export {
  apiLoadedSuccessfully,
  c19EntityFieldMappingsReducer,
  mappingsSelector,
  dwGroupingEntitiesSelector,
  linkContextsSelector,
  mappingsApiSelector
};

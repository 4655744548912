import Immutable from "immutable";
import * as Fetch from "js/fetch";

const declareClient = (clientName, bhApiUsername, bhApiPassword, productTier, preferNewClientIfDuplicateCorpId) => Fetch
    .post("onboarding/declare-client", {
      "client-name": clientName,
      "bh-api-username": bhApiUsername,
      "bh-api-password": bhApiPassword,
      "product-tier": productTier,
      "prefer-new-client-if-duplicate-corp-id?": preferNewClientIfDuplicateCorpId
    })
    .then(response => response.json())
    .then(response => Immutable.fromJS(response));

const redeclareClient = (clientId, clientName, bhApiUsername, bhApiPassword, productTier) => Fetch
    .post("onboarding/redeclare-client", {
      "client-id": clientId,
      "client-name": clientName,
      "product-tier": productTier,
      ...(!!bhApiUsername && {"bh-api-username": bhApiUsername}),
      ...(!!bhApiPassword && {"bh-api-password": bhApiPassword})
    })
    .then(response => response.json())
    .then(response => Immutable.fromJS(response));

export {declareClient, redeclareClient}
import React from "react";
import Immutable from "immutable";
import moment from "moment";

import * as Fetch from "js/fetch";

import DataTable from "js/components/data-table";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {RaisedButton, TextField} from "material-ui";
import useMountEffect from "js/utils/hooks/use-mount-effect";

const reportLabelToColor = Immutable.fromJS({
  "JAMES_COMPARE": "#1434A4",
  "JON_COMPARE": "#F28C28"
});

const Trend = React.memo(({kpiLabel, rows, dateGetter}) => {
  const reportLabelToRows = React.useMemo(() => {
    return rows.groupBy(r => r.get("report-label"));
  }, [rows]);

  const chartOptions = React.useMemo(() => {
    const series = reportLabelToRows
        .entrySeq()
        .map(([reportLabel, rows]) => {
          return {
            name: reportLabel,
            color: reportLabelToColor.get(reportLabel),
            data: rows
                .map(row => [new Date(dateGetter(row)).getTime(), row.get("benchmark-value")])
                .toArray()
          };
        })
        .toArray();
    return {
      chart: {
        type: "line"
      },
      xAxis: {
        type: "datetime"
      },
      yAxis: {
        min: 0
      },
      title: {
        text: kpiLabel
      },
      series: series
    };
  }, [kpiLabel, reportLabelToRows, dateGetter]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} immutable={true} />;
});

const Page = () => {
  const [monthlyStartDate, setMonthlyStartDate] = React.useState("2020-11-01");
  // todo start end of current month
  const [dailyStartDate, setDailyStartDate] = React.useState("2021-08-01");
  const [dailyEndDate, setDailyEndDate] = React.useState("2021-08-31");
  const [reportLabels, setReportLabels] = React.useState("JON_COMPARE,JAMES_COMPARE");

  const [monthlyReportRows, setMonthlyReportRows] = React.useState(Immutable.List());
  const [dailyReportRows, setDailyReportRows] = React.useState(Immutable.List());

  const loadAndSetData = React.useCallback(() => {
    loadMonthlyReports(moment(monthlyStartDate), reportLabels.split(",")).then(rows => {
      setMonthlyReportRows(rows);
    });
    loadDailyReports(moment(dailyStartDate), moment(dailyEndDate), reportLabels.split(",")).then(rows => {
      setDailyReportRows(rows);
    });
  }, [monthlyStartDate, dailyStartDate, dailyEndDate, reportLabels]);

  useMountEffect(loadAndSetData);

  const kpiLabelToMonthlyRows = React.useMemo(() => {
    return monthlyReportRows.groupBy(r => r.get("kpi-label"));
  }, [monthlyReportRows]);

  const kpiLabelToDailyRows = React.useMemo(() => {
    return dailyReportRows.groupBy(r => r.get("kpi-label"));
  }, [dailyReportRows]);

  const monthlyColumns = ["Report", "Metric", "Month", "Month on Month Change", "Benchmark", "Month Value", "Previous Month Value", "Data"];
  const monthlyRows = monthlyReportRows
      .map(row => {
        return [
          row.get("report-label"),
          row.get("kpi-label"),
          row.get("for-month-starting"),
          row.get("month-on-month-change"),
          row.get("benchmark-value"),
          row.get("current-month-value"),
          row.get("previous-month-value"),
          row.get("data")
        ];
      })
      .toArray();

  const dailyColumns = ["Report", "Metric", "Day", "Day on Day Change", "Benchmark", "Daily Value", "Previous Day Value", "Data"];
  const dailyRows = dailyReportRows
      .map(row => {
        return [
          row.get("report-label"),
          row.get("kpi-label"),
          row.get("for-date"),
          row.get("day-on-day-change"),
          row.get("benchmark-value"),
          row.get("current-day-value"),
          row.get("previous-day-value"),
          row.get("data")
        ];
      })
      .toArray();

  return (<div style={{padding: "1rem"}}>
    <TextField
        name="msd"
        label="Monthly Start Date"
        value={monthlyStartDate}
        onChange={e => setMonthlyStartDate(e.target.value)}
        style={{marginRight: "0.5rem"}} />
    <TextField
        name="dsd"
        label="Daily Start Date"
        value={dailyStartDate}
        onChange={e => setDailyStartDate(e.target.value)}
        style={{marginRight: "0.5rem"}} />
    <TextField
        name="ded"
        label="Daily End Date"
        value={dailyEndDate}
        onChange={e => setDailyEndDate(e.target.value)}
        style={{marginRight: "0.5rem"}} />
    <TextField
        name="r"
        label="Reports"
        value={reportLabels}
        onChange={e => setReportLabels(e.target.value)}
        style={{marginRight: "0.5rem"}} />
    <RaisedButton
        primary={true}
        label="Load"
        onClick={loadAndSetData}
        style={{marginRight: "0.5rem"}} />
    {kpiLabelToMonthlyRows.entrySeq().map(([kpiLabel, rows]) => {
      return <Trend key={kpiLabel + "monthly"} kpiLabel={kpiLabel} rows={rows} dateGetter={row => row.get("for-month-starting")} />;
    })}
    <div style={{height: "auto"}}>
      <DataTable maxTableHeight={400} downloadable={true} columns={monthlyColumns} rows={monthlyRows} />
    </div>
    {kpiLabelToDailyRows.entrySeq().map(([kpiLabel, rows]) => {
      return <Trend key={kpiLabel + "daily"} kpiLabel={kpiLabel} rows={rows} dateGetter={row => row.get("for-date")} />;
    })}
    <div style={{height: "auto"}}>
      <DataTable maxTableHeight={400} downloadable={true} columns={dailyColumns} rows={dailyRows} />
    </div>
  </div>);
};
export default Page;

const formatDate = date => date.format("YYYY-MM-DD");

const loadMonthlyReports = (startDate, reportLabels) => Fetch
    .getJson("apsco/monthly-reports", {"start-date": formatDate(startDate), "report-labels": reportLabels})
    .then(rows => Immutable.fromJS(rows));

const loadDailyReports = (startDate, endDate, reportLabels) => Fetch
    .getJson("apsco/daily-reports",
        {
          "start-date": formatDate(startDate),
          "end-date": formatDate(endDate),
          "report-labels": reportLabels
        })
    .then(rows => Immutable.fromJS(rows));
import React from "react";
import createReactClass from "create-react-class";
import * as Immutable from "immutable";
import moment from "moment";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import $ from "jquery";

import * as fetch from "js/fetch";
import LoadingSpinner from "js/components/loading-spinner";
import {AccordionSubmissions} from "js/components/change-submission";
import ImmutableSelect from "js/components/immutable-select";
import ClientPicker from "js/components/client-picker";
import {Err} from "js/components/notification";
import {appApiUrl, appUiUrl} from "js/base-urls";
import {ClientsContext, CurrentUserContext, SelectedClientIdContext} from "js/data/contexts";
import * as Rata from "js/data/remote-data";
import {Popover} from "material-ui";

const Page = createReactClass({

  getInitialState() {
    return {
      loadingCreds: false,
      loadingSubmissions: false,
      idToSubmission: Immutable.Map(),
      users: Immutable.List(),
      comment: "",
      popoverAnchor: null,
      tempUrl: ""
    };
  },

  componentDidMount() {
    this.loadAndSetChangeSubmissions();
    if (this.props.selectedClientId) {
      this.loadAndSetUsers(this.props.selectedClientId);
    }
  },

  render() {
    const {idToClient, loadingClients, permissions, currentUserId, selectedClientId} = this.props;
    const {
      idToSubmission,
      loadingCreds,
      loadingUsers,
      users,
      userId,
      loadingSubmissions,
      comment,
      errorMessage,
      popoverAnchor,
      tempUrl
    } = this.state;
    const userList = users.map(u => u.set("label", u.get("name") + " - " + u.get("username")));
    return <div>
      <form
          onSubmit={formEvent => this.submitForm(formEvent)}
          style={{display: "block", width: "50%", float: "left", padding: "1rem"}}>
        <h3>Request Login</h3>
        <ClientPicker
            style={{marginBottom: "0.5rem"}}
            loading={loadingClients}
            value={selectedClientId}
            onChange={this.handleClientSelect}
            clients={idToClient.valueSeq()} />
        <ImmutableSelect
            options={userList}
            keys={["id", "label"]}
            searchable={true}
            disabled={!selectedClientId}
            loading={loadingUsers}
            multi={false}
            selectedValue={userId}
            placeholder="Search for User"
            onChange={userId => this.setState({userId})} />
        <TextField
            fullWidth={true}
            floatingLabelText={"Reason should make sense in 6 months, ideally have Zendesk ID, don't skimp on beta"}
            errorText={(!comment && userId) && "You must provide a reason."}
            value={comment}
            onChange={e => this.setState({comment: e.target.value})} />
        <div style={{display: "flex", flexDirection: "row-reverse"}}>
          <RaisedButton
              label={"Submit"}
              type={"submit"}
              style={{marginLeft: "0.5rem"}}
              disabled={!selectedClientId || !userId || !comment || loadingCreds}/>
          <RaisedButton
              label={"Login with URL"}
              disabled={!selectedClientId || !userId || !comment || loadingCreds}
              onClick={this.generateLoginUrl} />
          <Popover
              anchorEl={popoverAnchor}
              anchorOrigin={{horizontal: "left", vertical: "bottom"}}
              targetOrigin={{horizontal: "left", vertical: "top"}}
              onRequestClose={this.clearTempUrl}
              useLayerForClickAway={false}
              open={!!tempUrl}>
            <div style={{
              width: 250,
              fontSize: "small",
              padding: "0.5rem",
              display: "flex",
              flexDirection: "column",
              wordWrap: "break-word"}}>
              <span>The below link has been copied to the clipboard:</span>
              <span>
                <a
                  href={tempUrl}
                  target={"_blank"}
                  rel="noreferrer">{tempUrl}</a>
              </span>
            </div>
          </Popover>
        </div>
      </form>
      <div style={{display: "block", width: "50%", float: "right", padding: "1rem 1rem 1rem 0rem"}}>
        {this.state.loadingSubmissions ? <LoadingSpinner /> : <AccordionSubmissions
            idToClient={idToClient}
            idToSubmission={idToSubmission}
            permissions={permissions}
            currentUserId={currentUserId}
            loadingSubmissions={loadingSubmissions}
            onChange={this.handleSubmissionChange}
            onError={this.showError} />}
      </div>
      <Err message={errorMessage} onRequestClose={() => this.setState({errorMessage: null})} />
    </div>;
  },

  generateLoginUrl(event) {
    this.setState({
      loadingCreds: true,
      popoverAnchor: event.currentTarget});
    const {selectedClientId} = this.props;
    const {userId, comment} = this.state;
    submitSwitchRequest(selectedClientId, userId, comment)
        .then(response => {
          const submission = response.get("submission");
          const tempData = response.get("temp-data");

          let tempUrl;
          if (isSuccess(submission, tempData)) {
            const encodedUsername = encodeURIComponent(tempData.get("username"));
            const encodedPassword = encodeURIComponent(tempData.get("password"));
            tempUrl = appUiUrl + "?username=" + encodedUsername + "&password=" + encodedPassword;
            navigator.clipboard.writeText(tempUrl)
                .catch(() => {
                  this.setState({errorMessage: "Failed to copy url to clipboard"});
                }
            );
          }
          this.setState(state => ({
            loadingCreds: false,
            tempUrl,
            comment: "",
            idToSubmission: state.idToSubmission.set(submission.get("submission_id"), submission)
          }));
        }, () => {
          this.setState({
            loadingCreds: false,
            popoverAnchor: null});
        });
  },

  clearTempUrl() {
    this.setState({
      popoverAnchor: null,
      tempUrl: ""});
  },

  submitForm(formEvent) {
    formEvent.preventDefault();
    this.setState({loadingCreds: true});
    const {selectedClientId} = this.props;
    const {userId, comment} = this.state;
    submitSwitchRequest(selectedClientId, userId, comment)
        .then(response => {
          const submission = response.get("submission");
          const tempData = response.get("temp-data");

          this.openAppIfRequired(submission, tempData);
          this.setState(state => ({
            loadingCreds: false,
            comment: "",
            idToSubmission: state.idToSubmission.set(response.get("submission").get("submission_id"), submission)
          }));
        }, () => {
          this.setState({loadingCreds: false});
        });
  },

  loadAndSetChangeSubmissions() {
    this.setState({loadingSubmissions: true});
    const canApprove = this.props.permissions.includes("SWITCH_USER_APPROVAL");
    loadRecentSubmissions().then(changeSubmissions => {
      const idToSubmission = changeSubmissions
          .filter(c => c.get("submitted_by") === this.props.currentUserId
              || (canApprove && c.get("status") === "SUBMITTED"))
          .groupBy(c => c.get("submission_id"))
          .map(cs => cs.first());
      this.setState({
        idToSubmission,
        loadingSubmissions: false
      });
    });
  },

  handleClientSelect(clientId) {
    this.props.setClientId(clientId);
    this.loadAndSetUsers(clientId);
  },

  loadAndSetUsers(clientId) {
    this.setState({
      userId: null,
      users: Immutable.List(),
      loadingUsers: true
    });
    loadUsersForClient(clientId).then(users => {
      const sortedUsers = users.sort((a, b) => {
        if (a.get("is-cube19-user") === b.get("is-cube19-user")) {
          return a.get("name").localeCompare(b.get("name"));
        }
        return a.get("is-cube19-user") ? -1 : 1;
      });
      this.setState({
        userId: sortedUsers.first().get("id"),
        loadingUsers: false,
        users: sortedUsers
      });
    });
  },

  handleSubmissionChange(submission, tempData) {
    this.setState(state => ({
      idToSubmission: state.idToSubmission.set(submission.get("submission_id"), submission)
    }));
    this.openAppIfRequired(submission, tempData);
  },

  showError(exception) {
    exception.response.json().then(body => this.setState({
      isProcessing: false,
      errorMessage: body.message
    }));
  },

  openAppIfRequired(submission, tempData) {
    if (isSuccess(submission, tempData)) {
      openApp(tempData.get("username"), tempData.get("password"))
          .catch(() => {
            this.setState({errorMessage: "Error logging in as requested user. Please try again"});
          });
    }
  }

});

const isSuccess = (submission, tempData) => {
  return submission.get("status") === "SUCCEEDED" && tempData && tempData.has("username") && tempData.has("password");
}

const loadRecentSubmissions = () => fetch
    .getJson("change-submissions/switching-user", {
      "start-date": moment().subtract(7, "days").format("YYYY-MM-DD"),
      "end-date": moment().format("YYYY-MM-DD")
    })
    .then(res => Immutable.fromJS(res));

const loadUsersForClient = (clientId) => fetch
    .getJson("switching-users/available-users", {
      "cube19-client-id": clientId
    })
    .then(res => Immutable.fromJS(res));

const submitSwitchRequest = (clientId, userId, comment) => {
  return fetch
      .post("change-submissions/switching-user", {
        "cube19-client-id": clientId,
        "user-id": userId,
        "comment": comment
      })
      .then(res => res.json())
      .then(json => Immutable.fromJS(json));
};

const logout = () => {
  const options = {
    url: appApiUrl + "/auth/logout",
    xhrFields: {withCredentials: true}
  };
  return post(options)
      .catch(e => {
        // NOTE - expired users can cause 401s on logout
        if (e.status !== 401) {
          throw(e);
        }
      });
};

const login = (username, password) => {
  const options = {
    url: appApiUrl + "/auth/login/" + username,
    data: {password},
    xhrFields: {withCredentials: true}
  };
  return post(options)
      .catch(e1 => {
        // NOTE - intermittent login failures, retry once
        return post(options)
            .then((result) => {
              notifyLoginFailures(e1);
              return result;
            }, (e2) => {
              notifyLoginFailures(e1, e2);
              throw(e2);
            });
      });
};

const notifyLoginFailures = (...errorResponses) => {
  fetch.post("switching-users/login-failure", {
    "error-responses": errorResponses.map(({status, responseText}) => ({status, responseText}))
  });
};

const openApp = (username, password) => logout()
    .then(() => login(username, password))
    .then(() => window.open(appUiUrl, "_blank"));

const post = (settings) => Promise.resolve($.post(settings));

const ConnectedPage = props => {
  const {currentUser} = React.useContext(CurrentUserContext);
  const {selectedClientId, setSelectedClientId} = React.useContext(SelectedClientIdContext);
  const {idToClient, idToClientStatus} = React.useContext(ClientsContext);
  return <Page
      {...props}
      permissions={currentUser.get("permissions")}
      currentUserId={currentUser.get("username")}
      selectedClientId={selectedClientId}
      setClientId={setSelectedClientId}
      idToClient={idToClient}
      loadingClients={idToClientStatus === Rata.Status.LOADING}/>;
};

export default ConnectedPage;

import Immutable from "immutable";
import createReducer from "js/create-reducer";

const defaultEtlConfigPageState = Immutable.Map({
    loadingConfig: false,
    loadedMillis: 0,
    loadErrorMessage: null,

    savingConfig: false,

    typeToConfig: Immutable.Map(),
    typeToConfigSnapshot: Immutable.Map(),
    clientIdForChanges: null,

    showCommonConfig: false,
    showClientConfigAsReadOnly: false,
    filterText: "",

    showMergedConfig: true,
    needsMerge: false,

    undoStack: Immutable.Stack(),
    redoStack: Immutable.Stack(),
    lastUndoMillis: Date.now(),

    showEditJsonDialog: false,
    showReviewDialog: false,

    loadingSubmissions: false,
    idToSubmission: Immutable.Map(),
});

const initialState = Immutable.fromJS({
    etlConfigPage: defaultEtlConfigPageState
});

const reducer = createReducer(initialState, {
    MERGE: (state, {newState}) => state.merge(newState),
    MERGE_IN: (state, {path, defaultState, newState}) => state.updateIn(path, defaultState, s => s.merge(newState)),
    SET_IN: (state, {path, newState}) => state.setIn(path, newState)
});

const mergeState = newState => ({type: "MERGE", newState: Immutable.Map(newState)});
const mergeStateIn = (path, newState, defaultState = Immutable.Map()) => ({type: "MERGE_IN", path, defaultState, newState: Immutable.Map(newState)});
const setStateIn = (path, newState) => ({type: "SET_IN", path, newState: Immutable.Map(newState)});

const selectEtlConfigPageClientIdForChanges = state => state.getIn(["sharedData", "etlConfigPage", "clientIdForChanges"])

export {
    selectEtlConfigPageClientIdForChanges,
    mergeState,
    mergeStateIn,
    setStateIn,
    reducer
};

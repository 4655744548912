const throttle = (fn, delayInMillis) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      return;
    }

    timeoutId = setTimeout(() => {
      timeoutId = null;
    }, delayInMillis);

    return fn.apply(null, args);
  };
};

export default throttle;

import "css/datepicker.css";

import React from "react";
import {DatePickerInput} from "rc-datepicker";
import pure from "js/pure";

import Err from "js/components/error";
import {formatDate, parseDate} from "js/utils/time";

const DISPLAY_FORMAT = "DD/MM/YYYY";

const DatePicker = pure(({label, placeholder, value, onDateChange}) => {
  return (
      <div>
        <label>{label}</label>
        <DatePickerInput
            iconClassName="fa fa-calendar"
            displayFormat={DISPLAY_FORMAT}
            placeholder={placeholder}
            value={formatDate(value)}
            onChange={(jsDate, dateStr) => {
              const date = parseDate(dateStr);
              onDateChange(date, isInvalidDate(date));
            }} />
        {isInvalidDate(value)
            && <Err
                message={`Please enter a valid date (format: ${DISPLAY_FORMAT})`}
                customStyle={{margin: 0}} />}
      </div>);
});

const isInvalidDate = date => date && !date.isValid();

export default DatePicker;
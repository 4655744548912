import React from "react";
import Immutable from "immutable";

import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import ImmutableSelect from "js/components/immutable-select";
import Checkbox from "material-ui/Checkbox";
import FormattedJsonDataField from "js/components/formatted-json-data-field";

const oppsDynamicFilterSupport = {
  tables: Immutable.Set.of(
    "SALARY_UNIT",
    "OPPORTUNITY_PRIORITY",
    "OPPORTUNITY_REASON_CLOSED",
    "OPPORTUNITY_SOURCE",
    "OPPORTUNITY_TYPE",
    "OPPORTUNITY_STATUS"),
  booleans: Immutable.Set.of("R_FOPP_is_open", "R_FOPP_is_committed")
};

const actualsDynamicFilterSupport = {
  tables: Immutable.Set.of("ACTUALS_TYPE", "CUBE19_ACTUALS_TYPE"),
  booleans: Immutable.Set.of("R_FACT_is_ui_actual")
};

// NOTE hard coded duplication of implementations of `SimpleKpiQuery.getIndexSupport`
// this is ok because:
//   - generic entities don't require this
//   - future improvements to recruitment entities will follow the core_type model from generic entities
// therefore it is extremely unlikely to change
const queryTypeToDynamicFilterSupport = Immutable.fromJS({
  NEW_CLIENT_CONTACTS: {tables: Immutable.Set.of("CLIENT_CONTACT_STATUS"), booleans: Immutable.Set()},
  CALL: {tables: Immutable.Set.of("CALL_TYPE", "CRM_CALL_TYPE", "CALL_OUTCOME"), booleans: Immutable.Set()},
  LEADS_ADDED: {
    tables: Immutable.Set.of("LEAD_CONVERSION_SOURCE", "LEAD_CAMPAIGN_SOURCE", "LEAD_SOURCE", "LEAD_PRIORITY", "LEAD_STATUS", "LEAD_TYPE"),
    booleans: Immutable.Set()
  },
  JOB_ACTUALS: actualsDynamicFilterSupport,
  ACTUALS: actualsDynamicFilterSupport,
  OPPORTUNITIES_ADDED: oppsDynamicFilterSupport,
  LIVE_OPPORTUNITIES_IN_PERIOD: oppsDynamicFilterSupport
});

const TableFilter = React.memo(({
  filter,
  index,
  onChange,
  onDelete,
  support,
  disabled
}) => {
  const onChangeTable = React.useCallback(
    newTable => onChange(filter.set("table", newTable), index),
    [onChange, filter, index]);
  const onChangeWhere = React.useCallback(
    e => onChange(filter.set("where", e.target.value), index),
    [onChange, filter, index]);
  return (
    <div style={{display: "flex", alignItems: "baseline"}}>
      <div style={{color: "red", marginRight: "0.5rem", cursor: "pointer"}} onClick={() => onDelete(index)}>x</div>
      <div
        style={{
          width: "calc(40% - 1rem)",
          marginTop: "1rem",
          marginRight: "1rem"
        }}>
        <ImmutableSelect
          placeholder="Table to filter"
          options={support.get("tables").map(t => Immutable.Map({label: t, value: t}))}
          multi={false}
          searchable={false}
          clearable={false}
          disabled={disabled}
          selectedValue={filter.get("table")}
          onChange={onChangeTable} />
        </div>
      <TextField
        style={{marginRight: "1rem", width: "calc(60% - 1rem)"}}
        hintText="SQL where clause"
        value={filter.get("where")}
        onChange={onChangeWhere}
        disabled={disabled} />
    </div>);
});

const BooleanFilter = React.memo(({
  filter,
  index,
  onChange,
  onDelete,
  support,
  disabled
}) => {
  const onChangeField = React.useCallback(
    newField => onChange(filter.set("booleanField", newField), index),
    [onChange, filter, index]);
  const onChangeValue = React.useCallback(
    (_, newFlag) => onChange(filter.set("value", newFlag), index),
    [onChange, filter, index]);
  return (
    <div style={{display: "flex", alignItems: "baseline"}}>
      <div style={{color: "red", marginRight: "0.5rem", cursor: "pointer"}} onClick={() => onDelete(index)}>x</div>
      <div
        style={{
          width: "calc(40% - 1rem)",
          marginTop: "1rem",
          marginRight: "1rem"
        }}>
        <ImmutableSelect
          placeholder="Boolean field"
          options={support.get("booleans").map(b => Immutable.Map({label: b, value: b}))}
          multi={false}
          searchable={false}
          clearable={false}
          disabled={disabled}
          selectedValue={filter.get("booleanField")}
          onChange={onChangeField} />
        </div>
      <Checkbox
        style={{marginRight: "1rem", width: "calc(60% - 1rem)"}}
        label="Yes"
        checked={filter.get("value")}
        onCheck={onChangeValue}
        disabled={disabled || !filter.get("booleanField")} />
    </div>);
});

const componentForFilter = filter => {
  if (filter.has("table")) {
    return TableFilter;
  } else {
    return BooleanFilter;
  }
};

export default React.memo(({
  parameter,
  paramNameToValue,
  dropdownNameToData,
  label,
  value,
  onChange,
  disabled
}) => {
  const [useJson, setUseJson] = React.useState(false);

  const kpiTemplateId = paramNameToValue.get("_kpi_template_id");
  const queryType = React.useMemo(
    () => {
      const templates = dropdownNameToData.get("kpi-templates");
      const template = templates.find(kt => kt.get("value") === "" + kpiTemplateId);
      return template ? template.get("query_type") : null;
    },
    [dropdownNameToData, kpiTemplateId]);
  const filterSupport = queryTypeToDynamicFilterSupport.get(queryType);

  const filters = React.useMemo(() => {
    if (filterSupport && value) {
      return Immutable.fromJS(JSON.parse(value));
    } else {
      return Immutable.List();
    }
  }, [filterSupport, value]);
  const onChangeFilters = React.useCallback(
    newFilters => {
      const newValue = newFilters !== null ? JSON.stringify(newFilters.toJS(), null, 2) : "[]";
      onChange(newValue);
    },
    [onChange]);
  const onChangeFilter = React.useCallback(
    (newFilter, index) => onChangeFilters(filters.set(index, newFilter)),
    [onChangeFilters, filters]);
  const onDeleteFilter = React.useCallback(
    index => onChangeFilters(filters.delete(index)),
    [onChangeFilters, filters]);

  if (filterSupport) {
    if (useJson) {
      return (
        <div>
          <FormattedJsonDataField
            disabled={disabled}
            floatingLabelText={label}
            value={filters}
            onChange={onChangeFilters} />
          <RaisedButton
            style={{minWidth: 45}}
            title="Use the editing UI"
            disabled={disabled}
            label={<span><i className="fa fa-pencil" /> With UI</span>}
            onClick={() => setUseJson(false)} />
        </div>);
    } else {
      return (
        <div style={{border: "1px solid #ddd", backgroundColor: "#eee", padding: "1rem", marginTop: "1rem"}}>
          <span style={{marginRight: "0.5rem"}}>{label}</span>
          <RaisedButton
            style={{minWidth: 45, marginRight: "0.5rem"}}
            title="Edit the raw JSON"
            label={<span><i className="fa fa-pencil" /> Raw JSON</span>}
            onClick={() => setUseJson(true)} />
          {!filterSupport.get("tables").isEmpty() && <RaisedButton
            label={<span><i className="fa fa-plus" /> Table Filter</span>}
            onClick={() => onChangeFilters(filters.push(Immutable.Map({table: "", where: ""})))}
            disabled={disabled}
            style={{marginRight: "0.5rem"}} />}
          {!filterSupport.get("booleans").isEmpty() && <RaisedButton
            label={<span><i className="fa fa-plus" /> Boolean Filter</span>}
            onClick={() => onChangeFilters(filters.push(Immutable.Map({booleanField: "", flag: false})))}
            disabled={disabled}
            style={{marginRight: "0.5rem"}} />}
          {filters.map((filter, index) => {
            const FilterComponent = componentForFilter(filter);
            return <FilterComponent
              key={index}
              index={index}
              filter={filter}
              onChange={onChangeFilter}
              onDelete={onDeleteFilter}
              support={filterSupport}
              disabled={disabled} />;
          })}
        </div>);
    }
  } else {
    return null;
  }
});
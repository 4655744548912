import React from "react";
import * as Immutable from "immutable";

import TextField from "material-ui/TextField";

//TO DO: provide information on the json shape expected and validate e.g. array vs object
const validateJson = str => {
  if (!str) {
    return null;
  } else {
    try {
      const parsedStr = JSON.parse(str);
      if(!Array.isArray(parsedStr) && !(typeof(parsedStr) === 'object')){
        return('This value must be a JSON array or object')
      }
      return null;
    } catch (e) {
      return e.message || "Invalid JSON";
    }
  }
};

export default React.memo(({disabled, floatingLabelText, style, value, onChange}) => {
  const [str, setStr] = React.useState("");

  React.useEffect(() => {
    const initialStr = (value && value.toJS) ? JSON.stringify(value.toJS(), null, 2) : "";
    setStr(initialStr);
  }, [value]);

  const setStrIfChanged = React.useCallback(newStr => {
    if (newStr !== str) {
      setStr(newStr);
    }
  }, [str]);

  const handleChange = React.useCallback(event => {
    const newStr = event.target.value;
    setStrIfChanged(newStr);
  }, [setStrIfChanged]);

  const handleBlur = React.useCallback(() => {
    try {
      if (str === "") {
        onChange(null);
      } else {
        const parsedValue = JSON.parse(str);
        if(Array.isArray(parsedValue) || typeof(parsedValue) === 'object'){
          onChange(Immutable.fromJS(parsedValue));
        }
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        // NOTE we don't care about json parsing errors here, we just don't want to propagate invalid json
      } else {
        throw error;
      }
    }
  }, [str, onChange]);

  const jsonParseError = React.useMemo(() => validateJson(str), [str]);

  return (
    <TextField
      disabled={disabled}
      floatingLabelText={floatingLabelText}
      style={style}
      fullWidth={true}
      multiLine={true}
      value={str}
      onChange={handleChange}
      onBlur={handleBlur}
      errorText={jsonParseError} />);
});

/** @jsxImportSource @emotion/react */

import React, {useEffect, useState} from "react";
import Immutable from "immutable";
import {IconButton, RaisedButton, TextField} from "material-ui";
import ActionDelete from "material-ui/svg-icons/action/delete";
import Save from "material-ui/svg-icons/content/save";
import {useDispatch, useSelector} from "react-redux";

import ClientPicker from "js/components/client-picker";
import LoadingSpinner from "js/components/loading-spinner";
import {Err} from "js/components/notification";
import {AccordionContainer, AccordionSection} from "js/components/accordion";
import {indexBy} from "js/utils/collections";
import {
  dwGroupingEntitiesSelector,
  linkContextsSelector,
  mappingsApiSelector,
  mappingsSelector
} from "js/data/entity-field-mappings/reducer";
import {configsApiSelector, mergedEtlConfigSelector} from "js/data/etl-configs/reducer";
import {
  entityEtlAndNameMappingsSelector,
  setInEntityRenamingPageState
} from "js/app-areas/entity-renaming/page-reducer";
import {
  ColumnRenameDialog,
  EntityRenameDialog,
  handleEntityFieldCustomisationSave,
  handleEntityLinkFieldSave,
  handleEntityUiLabelSave,
  LinkColumnDialog,
  piiCategories
} from "js/app-areas/entity-renaming/rename-dialogs";
import {
  loadDwGroupingEntities,
  loadEntityFieldMappings,
  loadLinkContexts,
  mergeInEntityFieldMappingsState,
  setInEntityFieldMappingsState
} from "js/data/entity-field-mappings/actions";
import {loadEtlConfig, mergeInEtlConfigsState} from "js/data/etl-configs/actions";
import ImmutableSelect from "js/components/immutable-select";
import {ClientsContext, SelectedClientIdContext} from "js/data/contexts";
import * as Rata from "js/data/remote-data";
import {
  ConvertTimezonesToggle,
  IsDeepLinkableToggle,
  LinkContextSelect,
  LinkIsComponentToggle,
  LinkToGroupingEntitySelect, PrefixToggle
} from "js/app-areas/entity-renaming/controls";
import {css} from "@emotion/react";

const geFormatEntities = Immutable.Set(["cv_sent", "candidate"]);

const hrStyle = css(`
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
`);

const EntityColumn = React.memo(({fieldMapping, onLabelChange, fullUiLabel}) => {
  const [uiLabel, setUiLabel] = useState(fieldMapping.clientUiLabel ?? fieldMapping.defaultUiLabel);
  const [trueValueLabel, setTrueValueLabel] = useState(fieldMapping.clientTrueValueLabel
      ?? fieldMapping.defaultTrueValueLabel);
  const [falseValueLabel, setFalseValueLabel] = useState(fieldMapping.clientFalseValueLabel
      ?? fieldMapping.defaultTrueValueLabel);
  const [piiCategory, setPiiCategory] = useState(fieldMapping.piiCategory);
  const [shouldPrefix, setShouldPrefix] = useState(fieldMapping.shouldPrefixWithEntity ??
      fieldMapping.defaultShouldPrefixWithEntity);
  const [needsTimezoneConversion, setNeedsTimezoneConversion] = useState(fieldMapping.needsTimezoneConversion ??
      true);
  const [isDeepLinkableOverride, setIsDeepLinkableOverride] = useState(fieldMapping.isDeepLinkableOverride ??
      fieldMapping.isDeepLinkable);
  const geRegex = new RegExp(/^(entity\d+)/);
  const isGenericEntity = geFormatEntities.has(fieldMapping.stagingTable) || geRegex.test(fieldMapping.stagingTable);

  const fieldsChanged = uiLabel !== (fieldMapping.clientUiLabel ?? fieldMapping.defaultUiLabel)
      || trueValueLabel !== (fieldMapping.clientTrueValueLabel ?? fieldMapping.defaultTrueValueLabel)
      || falseValueLabel !== (fieldMapping.clientFalseValueLabel ?? fieldMapping.defaultFalseValueLabel)
      || piiCategory !== fieldMapping.piiCategory
      || shouldPrefix !== (fieldMapping.shouldPrefixWithEntity ?? fieldMapping.defaultShouldPrefixWithEntity)
      || needsTimezoneConversion !== (fieldMapping.needsTimezoneConversion ?? true)
      || isDeepLinkableOverride !== (fieldMapping.isDeepLinkableOverride ?? fieldMapping.isDeepLinkable);

  const crmFieldTitle = fieldMapping.crmField
      ? fieldMapping.crmField
      : <i
          title={"This field does not have an ETL mapping"}
          className={"fa fa-warning"}
          style={{color: "orange", display: "inline"}} />;

  return (
      <div css={columnBoxStyle}>
        <div>
          <p css={{fontSize: "0.8rem", color: "gray"}}>
            c19: {fieldMapping.stagingField} | crm: {crmFieldTitle}
            {isGenericEntity && piiCategory === null && <i
                title={"This field must have a PII category set"}
                className={"fa fa-warning"}
                style={{color: "red", display: "inline", float: "right", fontSize: "1.2rem"}} />}
          </p>
          <ImmutableSelect
              multi={false}
              placeholder={"PII Category of Column"}
              clearable={!isGenericEntity}
              selectedValue={piiCategory}
              onChange={piiCategory => setPiiCategory(piiCategory)}
              options={piiCategories} />

          <hr css={hrStyle} />

          <div>
            <TextField
                id={fieldMapping.stagingField + "-column-label"}
                floatingLabelText={"Column label"}
                style={{marginRight: "1rem"}}
                value={uiLabel}
                onChange={(_, newVal) => setUiLabel(newVal)} />
            <PrefixToggle toggled={shouldPrefix} onToggle={(_, newVal) => setShouldPrefix(newVal)} />
            <p>
              <span css={{fontSize: "0.8rem", color: "gray", marginRight: "1rem"}}>Label Preview:</span>
              {fullUiLabel}
            </p>

            {fieldMapping.dataType === "BOOLEAN" &&
                <>
                  <TextField
                      id={fieldMapping.stagingField + "-true-value-label"}
                      hintText={"True"}
                      floatingLabelText={"True value label"}
                      style={{display: "block", marginRight: "1rem"}}
                      value={trueValueLabel}
                      onChange={(_, newVal) => setTrueValueLabel(newVal)} />

                  <TextField
                      id={fieldMapping.stagingField + "-false-value-label"}
                      hintText={"False"}
                      floatingLabelText={"False value label"}
                      style={{display: "block", marginRight: "1rem"}}
                      value={falseValueLabel}
                      onChange={(_, newVal) => setFalseValueLabel(newVal)} />
                </>}
          </div>

          <hr css={hrStyle} />

          <ConvertTimezonesToggle
              toggled={needsTimezoneConversion}
              onToggle={(_, newVal) => setNeedsTimezoneConversion(newVal)} />
          <IsDeepLinkableToggle
              toggled={isDeepLinkableOverride}
              onToggle={(_, newVal) => setIsDeepLinkableOverride(newVal)} />
        </div>

        <div css={{display: "flex", justifyContent: "end", width: "100%"}}>
          <IconButton
              title={"Delete client column override"}
              style={{...iconButtonStyle, alignSelf: "flex-end"}}
              disabled={fieldMapping.clientUiLabel === null && fieldMapping.shouldPrefixWithEntity === null}
              onClick={() => {
                onLabelChange(
                    fieldMapping.defaultUiLabel,
                    fieldMapping.defaultTrueValueLabel,
                    fieldMapping.defaultFalseValueLabel,
                    null,
                    fieldMapping.defaultShouldPrefixWithEntity,
                    null,
                    null,
                    fieldMapping);
              }}>
            <ActionDelete />
          </IconButton>
          <IconButton
              title={"Save client column override"}
              style={{...iconButtonStyle, alignSelf: "flex-end"}}
              disabled={(isGenericEntity && piiCategory === null)
                  || !fieldsChanged}
              onClick={() => {
                onLabelChange(
                    uiLabel || fieldMapping.defaultUiLabel,
                    trueValueLabel || fieldMapping.defaultTrueValueLabel,
                    falseValueLabel || fieldMapping.defaultFalseValueLabel,
                    piiCategory,
                    shouldPrefix,
                    needsTimezoneConversion,
                    isDeepLinkableOverride,
                    fieldMapping);
              }}>
            <Save />
          </IconButton>
        </div>
      </div>
  );
});

const EntityLinkColumn = React.memo(({fieldMapping, onLinkSave, dwGroupingEntities, linkContexts}) => {
  const [linkToGroupingEntity, setLinkToGroupingEntity] = useState(fieldMapping.linkToGroupingEntity);
  const [linkContext, setLinkContext] = useState(fieldMapping.linkContext);
  const [linkIsComponent, setLinkIsComponent] = useState(fieldMapping.linkIsComponent ?? false);
  const [isDeepLinkableOverride, setIsDeepLinkableOverride] = useState(fieldMapping.isDeepLinkableOverride ??
      fieldMapping.isDeepLinkable);
  const groupEntityDropdown = dwGroupingEntities.map(groupingEntity => Immutable.fromJS({
    label: groupingEntity,
    value: groupingEntity
  }));
  const linkContextDropdown = linkContexts.map(linkContext => Immutable.fromJS({
    label: linkContext,
    value: linkContext
  }));
  const linkIsAlreadyOverridden = fieldMapping.linkToGroupingEntity != null &&
      fieldMapping.linkContext != null &&
      fieldMapping.linkIsComponent != null;
  const fieldsAreNotNull = linkToGroupingEntity != null && linkContext != null;
  const fieldsHaveChanges = linkToGroupingEntity !== fieldMapping.linkToGroupingEntity ||
      linkContext !== fieldMapping.linkContext ||
      linkIsComponent !== fieldMapping.linkIsComponent ||
      fieldMapping.linkIsComponent == null ||
      (isDeepLinkableOverride !== (fieldMapping.isDeepLinkableOverride ?? fieldMapping.isDeepLinkable));

  const crmFieldTitle = fieldMapping.crmField ?
      fieldMapping.crmField
      : <i
          title={"This field does not have an ETL mapping"}
          className={"fa fa-warning"}
          style={{color: "orange", display: "inline"}} />;

  return (
      <div css={columnBoxStyle}>
        <div>
          <p css={{fontSize: "0.8rem", color: "gray"}}>
            c19: {fieldMapping.stagingField} | crm: {crmFieldTitle}
          </p>

        </div>
        <div css={{width: "20em"}}>
          <LinkToGroupingEntitySelect
              selectedValue={linkToGroupingEntity}
              options={groupEntityDropdown}
              onChange={setLinkToGroupingEntity} />
          <LinkContextSelect
              selectedValue={linkContext}
              options={linkContextDropdown}
              onChange={setLinkContext} />
          <LinkIsComponentToggle
              toggled={linkIsComponent}
              onToggle={(_, lic) => setLinkIsComponent(lic)} />
          <IsDeepLinkableToggle
              toggled={isDeepLinkableOverride}
              onToggle={(_, newVal) => setIsDeepLinkableOverride(newVal)} />
        </div>

        <div css={{display: "flex", justifyContent: "space-between", width: "100%"}}>
          <IconButton
              title={"Delete link column"}
              style={{...iconButtonStyle, alignSelf: "flex-end"}}
              disabled={!linkIsAlreadyOverridden}
              onClick={() => {
                onLinkSave(
                    null,
                    null,
                    null,
                    null,
                    fieldMapping);
              }}>
            <ActionDelete />
          </IconButton>
          <IconButton
              title={"Save link column"}
              style={{...iconButtonStyle, alignSelf: "flex-end"}}
              disabled={!(fieldsAreNotNull && fieldsHaveChanges)}
              onClick={() => {
                onLinkSave(
                    linkToGroupingEntity,
                    linkContext,
                    linkIsComponent,
                    isDeepLinkableOverride,
                    fieldMapping);
              }}>
            <Save />
          </IconButton>
        </div>
      </div>
  );
});

const EntityTitle = React.memo(({parentEntityMapping, entityMapping}) => {
  const dwGroupingEntity = entityMapping.dwGroupingEntity;
  const crmEntity = entityMapping.crmEntities
      .sort((a, b) => a.localeCompare(b, "en", {numeric: true}))
      .join(", ");
  const uiLabel = getEntityLabel(entityMapping);

  const [derivedFromCrmEntity, derivedFromCrmField] = getDerivedFromEntityAndField(parentEntityMapping, entityMapping);

  let crmEntityTitle;
  if (crmEntity || derivedFromCrmEntity) {
    if (derivedFromCrmEntity) {
      crmEntityTitle = <i
          title={`Derived from CRM ${derivedFromCrmEntity}.${derivedFromCrmField}`}
          className={"fa fa-info-circle"}
          style={{display: "inline"}} />;
    } else {
      crmEntityTitle = crmEntity;
    }
  } else {
    crmEntityTitle = <i
        title={"This entity does not have an ETL mapping"}
        className={"fa fa-warning"}
        style={{color: "orange", display: "inline"}} />;
  }

  return <div style={{display: "flex", flexWrap: "wrap"}}>
    <div css={titleElementStyle}>
      <span css={{color: "grey"}}>c19: </span><span>{dwGroupingEntity}</span>
    </div>
    <div css={titleElementStyle}>
      <span css={{color: "grey"}}>CRM: </span><span>{crmEntityTitle}</span>
    </div>
    <div css={titleElementStyle}>
      <span css={{color: "grey"}}>UI Label: </span><span>{uiLabel}</span>
    </div>
  </div>;
});

const Entity = React.memo(({parentEntityMapping, entityMapping}) => {
  const dwGroupingEntity = entityMapping.dwGroupingEntity;
  const uiLabel = getEntityLabel(entityMapping);

  const dispatch = useDispatch();
  const {selectedClientId} = React.useContext(SelectedClientIdContext);
  const entityEtlAndNameMappings = useSelector(entityEtlAndNameMappingsSelector);
  const dwGroupingEntities = useSelector(dwGroupingEntitiesSelector);
  const linkContexts = useSelector(linkContextsSelector);
  const linkRegex = new RegExp(/^link\d+/);

  const shouldShowToFieldMappings = entityMapping
      .fieldMappingsById
      .filter(fieldMapping => !linkRegex.test(fieldMapping.stagingField))
      .sort((a, b) => a.stagingField.localeCompare(b.stagingField, "en", {numeric: true}))
      .groupBy(fieldMapping => !!(fieldMapping.crmField || fieldMapping.clientUiLabel != null ||
          fieldMapping.shouldPrefixWithEntity != null));

  const shouldShowToLinkFieldMappings = entityMapping
      .fieldMappingsById
      .filter(fieldMapping => linkRegex.test(fieldMapping.stagingField))
      .sort((a, b) => a.stagingField.localeCompare(b.stagingField, "en", {numeric: true}))
      .groupBy(fieldMapping => !!(fieldMapping.crmField || fieldMapping.linkToGroupingEntity != null ||
          fieldMapping.linkContext != null || fieldMapping.linkIsComponent != null));

  const componentEntities = entityMapping.componentDwGroupingEntities
      .filter(groupingEntity => entityEtlAndNameMappings.has(groupingEntity))
      .map(groupingEntity => entityEtlAndNameMappings.get(groupingEntity));

  const actualParentEntityMapping = entityMapping.dwGroupingEntity.match(entityCoreTypeRegex)
      ? parentEntityMapping
      : entityMapping;

  const [entityName, setEntityName] = useState(entityMapping.clientUiLabel ?? entityMapping.defaultUiLabel);
  const [isOpen, setIsOpen] = useState(false);
  const [addColumnDialogIsOpen, setAddColumnDialogIsOpen] = useState(false);
  const [addLinkColumnDialogIsOpen, setAddLinkColumnDialogIsOpen] = useState(false);

  React.useEffect(() => {
    setEntityName(entityMapping.clientUiLabel ?? entityMapping.defaultUiLabel);
  }, [entityMapping.clientUiLabel, entityMapping.defaultUiLabel]);

  return (
      <AccordionSection
          key={dwGroupingEntity}
          contentStyle={{padding: "1rem"}}
          titleElement={<EntityTitle parentEntityMapping={parentEntityMapping} entityMapping={entityMapping} />}
          isOpen={isOpen}
          onClick={() => setIsOpen(prevState => !prevState)}>

        <div css={{display: "flex", flexWrap: "wrap", alignItems: "baseline", marginBottom: "1rem"}}>
          <TextField
              floatingLabelText={"Entity UI Name"}
              value={entityName}
              disabled={!!entityMapping.dwGroupingEntity.match(entityCoreTypeRegex)}
              onChange={(_, newVal) => setEntityName(newVal)}
              onBlur={e => {
                const newVal = e.target.value;
                if (newVal !== uiLabel) {
                  dispatch(handleEntityUiLabelSave(newVal, selectedClientId, dwGroupingEntity));
                }
              }} />

          <IconButton
              title={"Delete entity rename"}
              style={{...iconButtonStyle, marginRight: "2rem"}}
              disabled={entityMapping.clientUiLabel === null}
              onClick={() => {
                dispatch(handleEntityUiLabelSave("", selectedClientId, dwGroupingEntity));
              }}>
            <ActionDelete />
          </IconButton>

          <RaisedButton
              style={{marginRight: "5px"}}
              primary={true}
              label={"Add Column"}
              disabled={shouldShowToFieldMappings.get(false, Immutable.Map()).isEmpty()}
              onClick={() => setAddColumnDialogIsOpen(true)}
              icon={<i className={"fa fa-plus"} style={{color: "white"}} />} />
          <RaisedButton
              primary={true}
              label={"Add Link Column"}
              disabled={shouldShowToLinkFieldMappings.get(false, Immutable.Map()).isEmpty()}
              onClick={() => setAddLinkColumnDialogIsOpen(true)}
              icon={<i className={"fa fa-plus"} style={{color: "white"}} />} />
        </div>

        {!shouldShowToFieldMappings.get(true, Immutable.Map()).isEmpty() &&
            <>
              <h4>Field Mappings</h4>
              <div css={{display: "flex", flexWrap: "wrap"}}>
                {shouldShowToFieldMappings
                    .get(true, Immutable.Map())
                    .valueSeq()
                    .map(mapping => <EntityColumn
                        key={mapping}
                        fieldMapping={mapping}
                        onLabelChange={
                          (
                              label,
                              trueValueLabel,
                              falseValueLabel,
                              piiCategory,
                              shouldPrefix,
                              needsTimezoneConversion,
                              isDeepLinkableOverride,
                              entityFieldMapping
                          ) => dispatch(
                              handleEntityFieldCustomisationSave(
                                  label,
                                  trueValueLabel,
                                  falseValueLabel,
                                  piiCategory,
                                  shouldPrefix,
                                  needsTimezoneConversion,
                                  isDeepLinkableOverride,
                                  entityFieldMapping,
                                  selectedClientId,
                                  dwGroupingEntity))}
                        fullUiLabel={getFullColumnLabel(parentEntityMapping, entityMapping, mapping.stagingField)} />)}
              </div>
            </>}

        {!shouldShowToLinkFieldMappings.get(true, Immutable.Map()).isEmpty() &&
            <>
              <h4>Link Columns</h4>
              <div css={{display: "flex", flexWrap: "wrap"}}>
                {shouldShowToLinkFieldMappings
                    .get(true, Immutable.Map())
                    .valueSeq()
                    .map(mapping => <EntityLinkColumn
                        key={mapping}
                        fieldMapping={mapping}
                        onLinkSave={
                          (linkToGroupingEntity,
                              linkContext,
                              linkIsComponent,
                              isDeepLinkableOverride,
                              entityFieldMapping) => dispatch(handleEntityLinkFieldSave(
                              linkToGroupingEntity,
                              linkContext,
                              linkIsComponent,
                              isDeepLinkableOverride,
                              entityFieldMapping,
                              selectedClientId,
                              dwGroupingEntity))}
                        dwGroupingEntities={dwGroupingEntities}
                        linkContexts={linkContexts} />)}
              </div>
            </>}

        <EntityList parentEntityMapping={actualParentEntityMapping} entityMappings={componentEntities} />

        <ColumnRenameDialog
            isOpen={addColumnDialogIsOpen}
            handleClose={() => setAddColumnDialogIsOpen(false)}
            dwGroupingEntity={dwGroupingEntity}
            fieldEtlAndNameMappings={shouldShowToFieldMappings.get(false, Immutable.Map())} />
        <LinkColumnDialog
            isOpen={addLinkColumnDialogIsOpen}
            handleClose={() => setAddLinkColumnDialogIsOpen(false)}
            dwGroupingEntity={dwGroupingEntity}
            fieldEtlAndNameMappings={shouldShowToLinkFieldMappings.get(false, Immutable.Map())}
            dwGroupingEntities={dwGroupingEntities}
            linkContexts={linkContexts} />
      </AccordionSection>
  );
});

const EntityList = React.memo(({parentEntityMapping = null, entityMappings}) => {

  const {selectedClientId} = React.useContext(SelectedClientIdContext);

  const [addEntityDialogIsOpen, setAddEntityDialogIsOpen] = useState(false);

  const shouldShowToEntityMappings = entityMappings
      .sort((a, b) => a.dwGroupingEntity.localeCompare(b.dwGroupingEntity, "en", {numeric: true}))
      .groupBy(shouldDisplayEntity);

  return (
      <React.Fragment>
        <AccordionContainer>
          {shouldShowToEntityMappings
              .get(true, Immutable.List())
              .map(entityMapping => <Entity
                  key={entityMapping.dwGroupingEntity}
                  parentEntityMapping={parentEntityMapping}
                  entityMapping={entityMapping} />)}
        </AccordionContainer>

        <RaisedButton
            primary={true}
            label={"Add Entity"}
            icon={<i className={"fa fa-plus"} style={{color: "white"}} />}
            disabled={!selectedClientId || shouldShowToEntityMappings.get(false, Immutable.List()).isEmpty()}
            onClick={() => setAddEntityDialogIsOpen(true)} />

        <EntityRenameDialog
            isOpen={addEntityDialogIsOpen}
            handleClose={() => setAddEntityDialogIsOpen(false)}
            entityEtlAndNameMappings={shouldShowToEntityMappings.get(false, Immutable.List())} />
      </React.Fragment>);
});

const EntityRenamingPage = () => {

  const dispatch = useDispatch();

  const {idToClient, idToClientStatus} = React.useContext(ClientsContext);
  const loadingClients = idToClientStatus === Rata.Status.LOADING;
  const {selectedClientId, setSelectedClientId} = React.useContext(SelectedClientIdContext);

  const configsApi = useSelector(configsApiSelector);
  const mergedConfig = useSelector(mergedEtlConfigSelector);
  const etlMappings = mergedConfig.get("entities", Immutable.List());

  const entityApiError = useSelector(state => state.getIn(["c19EntityFieldMappings", "api", "entity", "error"]));
  const fieldApiError = useSelector(state => state.getIn(["c19EntityFieldMappings", "api", "field", "error"]));

  const mappingsApi = useSelector(mappingsApiSelector);
  const nameMappings = useSelector(mappingsSelector);

  const entityEtlAndNameMappings = useSelector(entityEtlAndNameMappingsSelector);

  const handleClientSelected = clientId => {
    if (clientId !== selectedClientId) {
      dispatch(mergeInEtlConfigsState(["api", "configs"], Immutable.Map({loaded: false, pending: true})));
      dispatch(mergeInEntityFieldMappingsState(["api", "mappings"], Immutable.Map({loaded: false, pending: true})));
      setSelectedClientId(clientId);
    }
  };

  const etlConfigsPending = configsApi.get("pending");
  const mappingsPending = mappingsApi.get("pending");
  const etlConfigsLoaded = configsApi.get("loaded");
  const mappingsLoaded = mappingsApi.get("loaded");

  useEffect(() => {
    if (etlConfigsLoaded && mappingsLoaded) {
      const mappings = mergeEtlAndNameMappings(etlMappings, nameMappings);
      dispatch(setInEntityRenamingPageState(["entityEtlAndNameMappings"], mappings));
    }
  }, [etlMappings, nameMappings, dispatch, etlConfigsLoaded, mappingsLoaded]);

  useEffect(() => {
    if (selectedClientId) {
      dispatch(loadEtlConfig(selectedClientId));
      dispatch(loadEntityFieldMappings(selectedClientId));
      dispatch(loadDwGroupingEntities());
      dispatch(loadLinkContexts());
    }
  }, [dispatch, selectedClientId]);

  return (
      <div style={{margin: "1rem"}}>
        <ClientPicker
            style={{width: 400, marginBottom: "1rem", marginRight: "1rem"}}
            loading={loadingClients}
            value={selectedClientId}
            onChange={handleClientSelected}
            clients={idToClient.valueSeq()} />

        {(etlConfigsPending || mappingsPending) && <LoadingSpinner />}
        {(selectedClientId && etlConfigsLoaded && mappingsLoaded) &&
            <EntityList entityMappings={entityEtlAndNameMappings.filter(mapping => mapping.isRoot).valueSeq()} />}

        <Err
            message={entityApiError}
            onRequestClose={() => dispatch(setInEntityFieldMappingsState(["api", "entity", "error"], null))} />
        <Err
            message={fieldApiError}
            onRequestClose={() => dispatch(setInEntityFieldMappingsState(["api", "field", "error"], null))} />
      </div>
  );
};

const entityCoreTypeRegex = /_CORE_TYPE$/;

const shouldDisplayField = fieldMapping => {
  const isEtlMappedField = fieldMapping.crmField !== null;
  const hasLabelOverride = fieldMapping.clientUiLabel !== null;
  const hasShouldPrefixOverride = fieldMapping.shouldPrefixWithEntity != null;
  const hasTimezoneConversionOverride = fieldMapping.needsTimezoneConversion != null;
  return isEtlMappedField || hasLabelOverride || hasShouldPrefixOverride || hasTimezoneConversionOverride;
};

const shouldDisplayEntity = entityMapping => {
  const isEtlMappedEntity = !entityMapping.crmEntities.isEmpty();
  const hasRename = entityMapping.clientUiLabel !== null;

  const hasCustomisedField = entityMapping.fieldMappingsById.some(shouldDisplayField);

  const isCoreType = !!entityMapping.dwGroupingEntity.match(entityCoreTypeRegex);

  return isEtlMappedEntity || hasRename || hasCustomisedField || isCoreType;
};

const shouldPrefixWithEntity = fieldMapping => {
  const shouldPrefixOrDefault = fieldMapping.shouldPrefixWithEntity ?? fieldMapping.defaultShouldPrefixWithEntity;
  const isCoreTypeField = fieldMapping.stagingField.match(/type([0-9]){1,2}/);
  return shouldPrefixOrDefault || isCoreTypeField;
};

const getEntityLabel = (entityMapping) => {
  return entityMapping.clientUiLabel ?? entityMapping.defaultUiLabel;
};

const getFullColumnLabel = (parentEntityMapping, entityMapping, fieldName) => {
  const fieldMapping = entityMapping.fieldMappingsById.find(
      mapping => mapping.stagingField === fieldName,
      new FieldMapping());
  const fieldUiLabel = fieldMapping.clientUiLabel ?? fieldMapping.defaultUiLabel;

  let result;
  if (shouldPrefixWithEntity(fieldMapping)) {
    const entityUiLabel = getEntityLabel(entityMapping);

    if (parentEntityMapping && parentEntityMapping.dwGroupingEntity !== "PLACEMENT_SPLIT") {
      const coreEntityUiLabel = getEntityLabel(parentEntityMapping);
      result = `${coreEntityUiLabel} ${entityUiLabel} ${fieldUiLabel}`;
    } else {
      result = `${entityUiLabel} ${fieldUiLabel}`;
    }
  } else {
    if (parentEntityMapping) {
      const coreEntityUiLabel = getEntityLabel(parentEntityMapping);
      result = `${coreEntityUiLabel} ${fieldUiLabel}`;
    } else {
      result = `${fieldUiLabel}`;
    }
  }

  return result.trim();
};

const getDerivedFromEntityAndField = (parentEntityMapping, entityMapping) => {
  if (!entityMapping.isRoot && !entityMapping.dwGroupingEntity.match(entityCoreTypeRegex)) {
    const field = entityMapping.fieldMappingsById.find(
        mapping => parentEntityMapping.crmEntities.contains(mapping.crmEntity),
        null,
        new FieldMapping());
    const crmEntity = field.crmEntity;
    return [crmEntity, field.crmField];
  } else {
    return [null, null];
  }
};

const mergeEtlAndNameMappings = (etlMappings, nameMappings) => {
  const entityToMapToToEtlMapping = etlMappings
      .map(entity => entity.update(
          "fieldMappings",
          Immutable.Map(),
          fieldMappings => indexBy(x => x.get("mapTo"), fieldMappings.filter(y => y.has("mapTo")))
              .concat(indexBy(x => x.get("translateTo"), fieldMappings.filter(y => y.has("translateTo"))))))
      .groupBy(mapping => mapping.get("cube19EntityToMapTo"));

  return nameMappings
      .map(mapping => {
        const dwGroupingEntity = mapping.get("dw-grouping-entity");
        const isRoot = mapping.get("is-root?");

        let crmEntities = EntityMapping.crmEntities;
        if (isRoot) {
          const stagingTable = mapping.get("entity-field-mappings-by-id").first().get("staging-table");
          const etlMappingsForEntity = entityToMapToToEtlMapping.get(stagingTable, Immutable.List());
          crmEntities = etlMappingsForEntity.map(etlMapping => etlMapping.get("name"));
        }

        return new EntityMapping({
          dwGroupingEntity,
          crmEntities,
          defaultUiLabel: mapping.get("default-ui-label"),
          clientUiLabel: mapping.get("client-ui-label"),
          isRoot,
          componentDwGroupingEntities: mapping.get("component-dw-grouping-entities"),
          fieldMappingsById: mapping
              .get("entity-field-mappings-by-id")
              .map(columnData => {
                const stagingTable = columnData.get("staging-table");
                const stagingField = columnData.get("staging-name");
                const etlMappingsForEntity = entityToMapToToEtlMapping.get(stagingTable, Immutable.List());
                const etlMapping = etlMappingsForEntity.find(m => m.hasIn(["fieldMappings", stagingField, "name"]));
                const crmFieldPath = ["fieldMappings", stagingField, "name"];
                return new FieldMapping({
                  stagingTable,
                  stagingField,
                  crmEntity: etlMapping?.get("name") ?? FieldMapping.crmEntity,
                  crmField: etlMapping?.getIn(crmFieldPath) ?? FieldMapping.crmField,
                  dataType: columnData.get("data-type"),
                  defaultUiLabel: columnData.get("default-ui-label"),
                  clientUiLabel: columnData.get("client-ui-label"),
                  defaultTrueValueLabel: columnData.get("default-true-value-label"),
                  clientTrueValueLabel: columnData.get("client-true-value-label"),
                  defaultFalseValueLabel: columnData.get("default-false-value-label"),
                  clientFalseValueLabel: columnData.get("client-false-value-label"),
                  piiCategory: columnData.get("pii-category"),
                  defaultShouldPrefixWithEntity: columnData.get("default-prefix-with-entity?"),
                  shouldPrefixWithEntity: columnData.get("prefix-with-entity?"),
                  entityFieldMappingId: columnData.get("entity-field-mapping-id"),
                  linkToGroupingEntity: columnData.get("link-to-grouping-entity"),
                  linkContext: columnData.get("link-context"),
                  linkIsComponent: columnData.get("link-is-component"),
                  needsTimezoneConversion: columnData.get("needs-timezone-conversion"),
                  isDeepLinkable: columnData.get("is-deep-linkable"),
                  isDeepLinkableOverride: columnData.get("is-deep-linkable-override")
                });
              })
        });
      });
};

const EntityMapping = Immutable.Record({
  dwGroupingEntity: "",
  crmEntities: Immutable.List(),
  defaultUiLabel: "",
  clientUiLabel: null,
  isRoot: false,
  componentDwGroupingEntities: Immutable.List(),
  fieldMappingsById: Immutable.Map(),
  linkColumns: Immutable.List()
});

const FieldMapping = Immutable.Record({
  crmEntity: null,
  crmField: null,
  stagingTable: "",
  stagingField: "",
  dataType: "",
  defaultUiLabel: "",
  clientUiLabel: null,
  defaultTrueValueLabel: "",
  clientTrueValueLabel: null,
  defaultFalseValueLabel: "",
  clientFalseValueLabel: null,
  piiCategory: null,
  entityFieldMappingId: undefined,
  defaultShouldPrefixWithEntity: undefined,
  shouldPrefixWithEntity: undefined,
  linkToGroupingEntity: null,
  linkContext: null,
  linkIsComponent: null,
  needsTimezoneConversion: null,
  isDeepLinkable: null,
  isDeepLinkableOverride: null
});

const columnBoxStyle = {
  display: "flex",
  flexFlow: "column wrap",
  alignItems: "baseline",
  padding: "0 1rem 1rem 1rem",
  marginRight: "2rem",
  marginBottom: "2rem",
  border: "1px solid"
};

const titleElementStyle = {
  display: "inline-block",
  width: "25em",
  marginRight: "1em"
};

const iconButtonStyle = {
  height: "unset",
  width: "unset",
  padding: 8
};

export default EntityRenamingPage;
export {
  mergeEtlAndNameMappings,
  getFullColumnLabel,
  getEntityLabel
};

import React from "react";
import createReactClass from "create-react-class";
import pure from "js/pure";
import * as Immutable from "immutable";

import * as Strings from "js/utils/strings";
import * as Colors from "js/utils/colors";
import * as Rata from "js/data/remote-data";

import {AccordionContainer, AccordionSection} from "js/components/accordion";
import LoadingSpinner from "js/components/loading-spinner";
import ClientPicker from "js/components/client-picker";
import TimestampedRefreshButton from "js/components/timestamped-refresh-button";
import TextField from "material-ui/TextField";
import Checkbox from "material-ui/Checkbox";
import {ClientsContext, CrmMetadataContext, CrmsContext, SelectedClientIdContext} from "js/data/contexts";
import * as CrmMetadata from "js/data/crm-metadata";

const Page = createReactClass({

  componentDidMount() {
    if (this.props.clientId) {
      this.props.loadAndSetMetaForClient(this.props.clientId, {ignoreServerCache: false});
    }
  },

  getInitialState() {
    return {
      filterText: "",
      showAllFields: false
    };
  },

  render() {
    const {clientId, metaForClient} = this.props;
    const filterText = this.state.filterText;
    const hasValidCrm = clientId && this.clientHasValidCrm(clientId);
    return (
        <div style={{margin: "1rem"}}>
          <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", marginBottom: "1rem"}}>
            <ClientPicker
                style={{width: 400, marginRight: "1rem"}}
                loading={this.props.loadingClients}
                value={clientId}
                onChange={clientId => {
                  this.props.setClientId(clientId);
                  this.props.loadAndSetMetaForClient(clientId, {ignoreServerCache: false});
                }}
                idToClient={this.props.idToClient} />
            {hasValidCrm && <TimestampedRefreshButton
                style={{marginRight: "1rem"}}
                disabled={Rata.isLoading(metaForClient)}
                millis={Rata.getValue(metaForClient, Immutable.Map()).get("loaded-millis", 0)}
                onClick={() => this.props.loadAndSetMetaForClient(this.props.clientId, {
                  ignoreLocalCache: true,
                  ignoreServerCache: true
                })} />}
            {hasValidCrm && <TextField
                style={{marginRight: "1rem"}}
                value={filterText}
                onChange={e => this.setState({filterText: e.target.value})}
                hintText="Search meta" />}
            {hasValidCrm && <Checkbox
                style={{width: 300}}
                checked={this.state.showAllFields}
                onCheck={(e, checked) => this.setState({showAllFields: checked})}
                title="By default we only show fields with options"
                label="Show all fields" />}
          </div>
          {clientId && this.renderClientSection()}
        </div>);
  },

  clientHasValidCrm(clientId) {
    const client = this.props.idToClient.get(clientId);
    const crmType = this.props.idToCrm.get(client.get("crm_id")).get("type");
    return crmType !== "push_api";
  },

  renderClientSection() {
    const {clientId, metaForClient} = this.props;
    if (Rata.isLoading(metaForClient)) {
      return <LoadingSpinner />;
    } else if (clientId && !this.clientHasValidCrm(clientId)) {
      return <div>Functionality is only supported on Bullhorn REST</div>;
    } else if (Rata.hasError(metaForClient)) {
      return <div>Error loading metadata - {Rata.getError(metaForClient).get("message")}</div>;
    } else if (Rata.isLoaded(metaForClient)) {
      const filterText = this.state.filterText;
      return <Meta
          entities={Rata.getValue(metaForClient).get("entities", Immutable.List())}
          showAllFields={this.state.showAllFields}
          filterText={filterText} />;
    } else {
      return null;
    }
  }
});

const ConnectedPage = props => {
  const {selectedClientId, setSelectedClientId} = React.useContext(SelectedClientIdContext);
  const {idToClient, idToClientStatus} = React.useContext(ClientsContext);
  const {idToCrm} = React.useContext(CrmsContext);
  const {clientIdToCrmMetadata, setClientIdToCrmMetadata} = React.useContext(CrmMetadataContext);
  const loadAndSetMetaForClient = React.useCallback((clientId, {ignoreLocalCache, ignoreServerCache}) => {
    CrmMetadata.loadAndSet(
        clientId,
        clientIdToCrmMetadata,
        setClientIdToCrmMetadata,
        {
          ignoreLocalCache,
          ignoreServerCache
        });
  }, [clientIdToCrmMetadata, setClientIdToCrmMetadata]);
  return <Page
      {...props}
      clientId={selectedClientId}
      setClientId={setSelectedClientId}
      idToClient={idToClient}
      idToCrm={idToCrm}
      metaForClient={clientIdToCrmMetadata.get(selectedClientId)}
      loadAndSetMetaForClient={loadAndSetMetaForClient}
      loadingClients={idToClientStatus === Rata.Status.LOADING} />;
};

export default ConnectedPage;

const scoreEntities = (filterText, entities) => {
  const identityScore = filterText ? 0 : 1;
  return entities.map(entity => {
    const entityName = entity.get("name");
    const entityLabel = entity.get("label");

    const entityNameScore = Math.max(
        Strings.similarityScore(entityName, filterText),
        Strings.similarityScore(entityLabel, filterText),
        identityScore);

    const scoredFields = entity.get("fields").map(field => {
      const fieldName = field.get("name");
      const fieldLabel = field.get("label");
      const fieldNameScore = Math.max(
          Strings.similarityScore(entityName + " " + fieldName, filterText),
          Strings.similarityScore(entityLabel + " " + fieldLabel, filterText),
          identityScore);

      const scoredOptions = field.get("options", Immutable.List()).map(option => {
        const optionScore = Math.max(
            Strings.similarityScore(option.get("value"), filterText),
            Strings.similarityScore(option.get("label"), filterText),
            identityScore);
        return option.set("score", optionScore);
      });

      const maxOptionScore = scoredOptions.map(o => o.get("score")).max() || identityScore;

      return field
          .set("score", fieldNameScore)
          .set("highestScoreInTree", Math.max(maxOptionScore, fieldNameScore))
          .set("highestOptionScore", maxOptionScore)
          .set("options", scoredOptions);
    });

    const maxFieldScore = scoredFields.map(f => f.get("highestScoreInTree")).max() || identityScore;

    return entity
        .set("score", entityNameScore)
        .set("highestScoreInTree", Math.max(entityNameScore, maxFieldScore))
        .set("fields", scoredFields);
  });
};

const threshold = 0.01;

const Meta = pure(({entities, showAllFields, filterText}) => {
  const scoredEntities = scoreEntities(filterText, entities);
  const filtered = !!filterText;
  return (
      <AccordionContainer
          stateless={true}
          allowOnlyOneOpen={false}
          lazyRender={true}
          expandAll={filterText}>
        {scoredEntities
            .filter(entity => entity.get("highestScoreInTree") > threshold)
            .sortBy(entity => entity.get("name"))
            .map(entity => {
              const name = entity.get("name");
              const label = entity.get("label");
              const isRenamed = name !== label;
              const title = isRenamed ? name + " (label: " + label + ")" : name;
              return (
                  <AccordionSection
                      key={entity.get("name")}
                      title={title}
                      showArrows={true}>
                    {entity
                        .get("fields")
                        .filter(field => showAllFields || field.get("options").count() > 0)
                        .filter(field => field.get("highestScoreInTree") > threshold)
                        .map(field => {
                          const isDifferent = field.get("label").trim().toLowerCase() !==
                              field.get("name").trim().toLowerCase();
                          const label = isDifferent ? field.get("name") + " (label: " + field.get("label") + ")" :
                              field.get("name");
                          return (
                              <div key={field.get("name")} style={{margin: "0.5rem"}}>
                                <div style={{fontWeight: "bold"}}>{label}</div>
                                <div>
                                  {field
                                      .get("options", Immutable.List())
                                      .sortBy(o => o.get("value").trim().toLowerCase())
                                      .map(o => {
                                        const isDifferent = o.get("label").trim().toLowerCase() !==
                                            o.get("value").trim().toLowerCase();
                                        const label = isDifferent ?
                                            o.get("value") + " (label: " + o.get("label") + ")" : o.get("value");
                                        const highlighted = o.get("score") > threshold;
                                        const color = (filtered && highlighted)
                                            ?
                                            Colors.interpolateHex("#eeeeee", "#66dd66", normalise(o.get("score"), -0.5, field.get("highestOptionScore")))
                                            : "#eeeeee";
                                        return (
                                            <div
                                                key={o.get("value")}
                                                style={{
                                                  margin: "0.2rem",
                                                  padding: "0.2rem",
                                                  display: "inline-block",
                                                  backgroundColor: color,
                                                  borderRadius: "0.3rem"
                                                }}>
                                              {label}
                                            </div>);
                                      })}
                                </div>
                              </div>);
                        })}
                  </AccordionSection>);
            })}
      </AccordionContainer>);
});

const normalise = (x, min, max) => (x - min) / (max - min);
